import { Box } from "@mui/material";
import Desktop from "../Desktop/Desktop";
import Mobile from "../Mobile/Mobile";
import useMedia from "../../../../../hooks/useMedia/useMedia";
import useHeaderHeight from "../hooks/useHeaderHeight";
import { QueryEnum } from "../../../../../hooks/useMedia/enums/QueryEnum";
import CreateOfftimeDialog from "../../../../../../modules/offtime/containers/OfftimeManagement/CreateDialog";

const Header = () => {
  const matchesMax992Screen = useMedia(QueryEnum.MAX_WIDTH_992);
  const { HEADER_HEIGHT_PX } = useHeaderHeight();

  return (
    <Box
      height={HEADER_HEIGHT_PX}
      bgcolor="_components.appBar.defaultFill"
      px={matchesMax992Screen ? "8px" : "12px"}
      width="100%"
      boxShadow={4}
      position="fixed"
      left={0}
      top={0}
      zIndex={21}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      gap="12px"
    >
      {matchesMax992Screen ? <Mobile /> : <Desktop />}

      <CreateOfftimeDialog />
    </Box>
  );
};

export default Header;
