import { useAppDispatch } from "../../../../../../hooks/redux";
import { navigationActions } from "../../../../../../store/navigationSlice";
import { IconButton } from "@mui/material";
import { MenuTwoTone } from "@mui/icons-material";

const NavigationIcon = () => {
  const dispatch = useAppDispatch();

  return (
    <IconButton
      children={<MenuTwoTone />}
      onClick={() => {
        dispatch(navigationActions.toggle());
      }}
      sx={{
        height: "40px",
        width: "40px",
        color: "#FFFFFF8F",
      }}
    />
  );
};

export default NavigationIcon;
