import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../common/hooks/redux";
import useForm from "../../../common/hooks/useForm/useForm";
import { useArchiveBoardService } from "../services/useArchiveBoardService";
import { orgActions } from "../../org/slices/orgSlice";
import { systemNotificationActions } from "../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { THttpClientError } from "../../../common/modules/httpClient/types/THttpClientError";

export const useArchiveBoardHandler = (
  boardRef: string,
  projectAbbr: string
) => {
  const dispatch = useAppDispatch();
  const { getValidationErrorAtIndex } = useForm();
  const navigate = useNavigate();
  const { dispatch: dispatchArchiveBoardService } =
    useArchiveBoardService(boardRef);

  const archiveBoard = () => {
    dispatchArchiveBoardService()
      .then(() => {
        navigate(`/`);
        setTimeout(() => {
          dispatch(orgActions.removeBoard({ ref: boardRef, projectAbbr }));
        }, 1000);
        dispatch(
          systemNotificationActions.open({
            message:
              "Your archive request has been received successfully. It may take some time for all board-related components to be archived across all locations.",
            variant: "success",
          })
        );
      })
      .catch((error: THttpClientError) => {
        if (error.status === 422) {
          const validationFirstError = getValidationErrorAtIndex(0, error);

          if (validationFirstError) {
            dispatch(
              systemNotificationActions.open({
                message: validationFirstError,
                variant: "warning",
              })
            );
          }
        } else {
          dispatch(
            systemNotificationActions.open({
              message: "Failed to archive board. Please try again.",
              variant: "error",
            })
          );
        }
      });
  };

  return archiveBoard;
};
