import Navigation from "../Navigation/Navigation";
import Content from "../Content/Content";
// !!! Important, this modal is being rendered here because it is being triggered from inner navigation component
// and the navigation can be unmounted, while the modal must maintain
import CreateBoardModal from "../../../../../modules/board/containers/CreateBoard/CreateModal";
import { jwt } from "../../../../utils/jwt";
import SystemNotification from "../../../../modules/systemNotification/containers/SystemNotification";
import GlobalStyle from "../../../../modules/theme/containers/GlobalStyle/GlobalStyle";
import { useEffect } from "react";
import { useSignOutHandler } from "../../../../../modules/user/hooks/useSignOutHandler";
import FloatingActions from "../FloatingActions/containers/FloatingActions";
import ConfirmRedirectionDialog from "../../../../modules/materialUI/containers/ConfirmRedirectionDialog";
import useHeaderHeight from "../Header/hooks/useHeaderHeight";
import FirebaseConfig from "../../../../modules/firebase/components/FirebaseConfig";
import AppRoutes from "../Routes/AppRoutes";
import Header from "../Header/Header/Header";
import Tips from "../../../../../modules/tip/containers/Tips";

/**
 * @description !!! IMPORTANT - for layout redirecting purposes we should never use useNavigate() in such high
 * components because it is causing unnecessary re-renders every time the route changed, that is why <Navigate />
 * component is used instead
 */
const Layout = () => {
  const hasAccessToken = jwt.valid();
  const signOutHandler = useSignOutHandler({ rememberState: true });

  const { HEADER_HEIGHT_PX } = useHeaderHeight();

  useEffect(() => {
    if (!hasAccessToken) {
      signOutHandler();
    }
  }, [hasAccessToken]);

  return (
    <>
      {hasAccessToken && (
        <>
          <Tips />
          <ConfirmRedirectionDialog />
          <GlobalStyle />
          <SystemNotification />
          <Header />
          <div style={{ marginTop: HEADER_HEIGHT_PX }}>
            <CreateBoardModal />
            <Navigation />
            <Content>
              <AppRoutes />
            </Content>
            <FloatingActions />
          </div>
          <FirebaseConfig />
        </>
      )}
    </>
  );
};

export default Layout;
