import { useAppDispatch } from "../../../../../../common/hooks/redux";
import { TTaskTypeFormProps } from "./types/TTaskTypeFormProps";
import { useUpdateTaskService } from "../../../../services/useUpdateTaskService";
import { ITaskPatchResource } from "../../interfaces/ITaskPatchResource";
import { THttpClientError } from "../../../../../../common/modules/httpClient/types/THttpClientError";
import { systemNotificationActions } from "../../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { TTaskEvent } from "../types/TTaskEvent";
import { EventEnum } from "../../../../../../common/modules/eventProvider/enums/EventEnum";
import { useDispatchEvent } from "../../../../../../common/modules/eventProvider";
import EntityTaskTypeUpdateForm from "../../../../../taskType/containers/EntityTaskTypeUpdateForm/EntityTaskTypeUpdateForm";

const TaskTypeForm = ({ task, noLabel }: TTaskTypeFormProps) => {
  const dispatch = useAppDispatch();
  const dispatchEvent = useDispatchEvent();
  const { dispatch: dispatchUpdateTaskService } = useUpdateTaskService(
    task.ref
  );

  const changeHandler = (taskTypeId: number | null) => {
    dispatchUpdateTaskService({
      body: {
        taskTypeId: taskTypeId,
      },
    })
      .then((taskResource: ITaskPatchResource) => {
        const taskEvent = {
          initTask: task,
          task: taskResource.task,
        } as TTaskEvent;
        dispatchEvent(EventEnum.ON_TASK_MAIN_DATA_UPDATED, taskEvent);
      })
      .catch((_: THttpClientError) => {
        dispatch(
          systemNotificationActions.open({
            message:
              "There was a problem with updating the Task Type. Please try again.",
            variant: "error",
          })
        );
      });
  };

  return (
    <EntityTaskTypeUpdateForm
      projectRef={task.projectRef}
      taskTypeId={task.taskTypeId}
      onChange={changeHandler}
      isLoading={!task.id}
      noLabel={noLabel}
    />
  );
};

export default TaskTypeForm;
