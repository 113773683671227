import { menuItem } from "../../../../common/containers/Menu/utils/menuItem";
import { Box, Switch, Typography } from "@mui/material";
import { holisticViewActions } from "../../modules/holisticView/slices/holisticViewSlice";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import { propsForMenuItems } from "../../../../common/containers/Menu/common/propsForMenuItems";
import { useState } from "react";
import { TFilterProps } from "../Filter/Filter/types/TFilterProps";
import { useAccessControl } from "../../../../common/modules/accessControl/hooks/useAccessControl";
import { AbilityEnum } from "../../../../common/modules/accessControl/enums/AbilityEnum";

const DisplayCostPerItemMenuItem = (props: TFilterProps) => {
  const dispatch = useAppDispatch();
  const { can } = useAccessControl();
  const [isChecked, setChecked] = useState<boolean>(
    useAppSelector((state) => state.holisticView.displayCostPerItem)
  );

  const clickHandler = () => {
    setChecked((prevState) => {
      const checked = !prevState;
      dispatch(holisticViewActions.displayCostPerItem(checked));
      return checked;
    });
  };

  return menuItem.make({
    component: (
      <Box display="flex" gap={1} alignItems="center" mt={0.6}>
        <Switch size="small" checked={isChecked} />
        <Typography variant="subtitle2" fontWeight="400" color="text.secondary">
          Display Cost Per Item
        </Typography>
      </Box>
    ),
    handler: clickHandler,
    when: can(AbilityEnum.financing) && !!props.renderCostPerItems,
    hasDivider: false,
    menuItemsProps: propsForMenuItems,
    closeOnClick: false,
  });
};

export default DisplayCostPerItemMenuItem;
