import { IconButton } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { TIconLibraryPickerProps } from "./types/TIconLibraryPickerProps";
import { iconLibraryUtil } from "../../utils/iconLibraryUtil";
import Icon from "./Icon";
import { useState } from "react";
import { MoreHoriz } from "@mui/icons-material";
import MoreIconsDialog from "./MoreIconsDialog";

const DEFAULT_VISIBLE_ICONS_COUNT = 5;

const IconLibraryPicker = ({
  iconType,
  selectedIconPath,
  onSelect,
}: TIconLibraryPickerProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const defaultIconsEnum = iconLibraryUtil.getDefaultIconEnum(iconType);
  const defaultIconPaths = Object.values(defaultIconsEnum).slice(
    0,
    DEFAULT_VISIBLE_ICONS_COUNT
  );
  // Default state logic: if selected icon is not in the default list, then set selected uploaded icon
  const [selectedUploadedIconPath, setSelectedUploadedIconPath] = useState<
    string | null
  >(
    selectedIconPath && defaultIconPaths.includes(selectedIconPath)
      ? null
      : selectedIconPath
  );
  // If we need to add selected icon (that's not in the list of default icons), then remove last default icon from the list
  const mainIconPaths = selectedUploadedIconPath
    ? [selectedUploadedIconPath, ...defaultIconPaths.slice(0, -1)]
    : [...defaultIconPaths];

  return (
    <>
      <Grid container alignItems="center" spacing={1}>
        {mainIconPaths.map((iconPath) => (
          <Grid key={iconPath}>
            <Icon
              filePath={iconPath}
              isSelected={selectedIconPath === iconPath}
              onSelect={onSelect}
            />
          </Grid>
        ))}
        <Grid>
          <IconButton onClick={() => setIsDialogOpen(true)}>
            <MoreHoriz />
          </IconButton>
        </Grid>
      </Grid>

      <MoreIconsDialog
        iconType={iconType}
        open={isDialogOpen}
        onSelect={(dialogSelectedIconPath) => {
          onSelect(dialogSelectedIconPath);
          setSelectedUploadedIconPath(
            defaultIconPaths.includes(dialogSelectedIconPath)
              ? null
              : dialogSelectedIconPath
          );
          setIsDialogOpen(false);
        }}
        onCancel={() => setIsDialogOpen(false)}
      />
    </>
  );
};

export default IconLibraryPicker;
