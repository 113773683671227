import { TTaskRowProps } from "./types/TTaskRowProps";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Tooltip } from "../../../../modules/materialUI";
import TaskMenu from "../Menu/TaskMenu";
import useGetTaskType from "../../../../../modules/taskType/hooks/useGetTaskType";
import useGetTaskPriority from "../../../../../modules/taskPriority/hooks/useGetTaskPriority";
import AvatarGroup from "../../../../../modules/user/containers/AvatarGroup/AvatarGroup";
import useSprintHelper from "../../../../../modules/boardView/hooks/sprintHelper/useSprintHelper";
import { BoardViewEnum } from "../../../../../modules/boardView/enums/BoardViewEnum";
import { useAppSelector } from "../../../../hooks/redux";
import { pointConverter } from "../../../../utils/pointConverter";
import RiskStatusIcon from "../RiskStatus/RiskStatusIcon";

const RightContainer = (props: TTaskRowProps) => {
  const sprintHelper = useSprintHelper({ view: BoardViewEnum.LIST_VIEW });
  const taskType = useGetTaskType({
    taskTypeId: props.task.taskTypeId,
  });
  const taskPriority = useGetTaskPriority({
    taskPriorityId: props.task.taskPriorityId,
  });
  const groupedBySprint = useAppSelector(
    (state) => state.boardView.listView.groupBySprint
  );
  const riskStatus = props.isSegmentView
    ? props.task.riskStatus
    : props.task.aggRiskStatus;

  const users =
    props?.users?.filter((user) =>
      props.isSegmentView
        ? user.id === props.task.assignedTo
        : props.task.assignees.includes(user.id)
    ) ?? [];

  const time: string = pointConverter.WorkingHoursOrDayToPoints(
    props.isSegmentView
      ? props.task.estimationPoints
      : props.task.aggEstimationPoints
  );

  return (
    <Box
      sx={{
        display: "flex",
        gap: "0.5rem",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      <RiskStatusIcon
        riskStatus={riskStatus}
        style={{ display: "inline-flex" }}
        iconProps={{ fontSize: "small" }}
        iconSize={20}
      />
      {!groupedBySprint &&
        sprintHelper.chipLabel(!!props.isSegmentView, props.task)}
      <Box display="flex" gap={0.5}>
        <Typography variant="subtitle2" lineHeight="normal">
          {time}
        </Typography>
      </Box>
      <Box>
        {taskPriority ? (
          <Tooltip title={taskPriority.name}>
            <img
              src={`${process.env.CDN_URL}/${taskPriority.iconFilePath}`}
              alt={taskPriority.name}
              width={16}
              height={16}
              style={{ display: "flex" }}
            />
          </Tooltip>
        ) : (
          <Box width={16} />
        )}
      </Box>
      <Box>
        {taskType ? (
          <Tooltip title={taskType.name}>
            <img
              src={`${process.env.CDN_URL}/${taskType.iconFilePath}`}
              alt={taskType.name}
              width={16}
              height={16}
              style={{ display: "flex" }}
            />
          </Tooltip>
        ) : (
          <Box width={16} />
        )}
      </Box>
      {!props.hideAvatar && (
        <>
          {users.length ? (
            <Typography variant="caption">
              <AvatarGroup users={users} avatarSize={24} />
            </Typography>
          ) : (
            <Grid width={30} />
          )}
        </>
      )}

      <TaskMenu
        taskRef={props.task.ref!}
        taskId={props.task.id}
        taskInternalType={props.task.internalType}
        projectRef={props.task.projectRef}
      />
    </Box>
  );
};

export default RightContainer;
