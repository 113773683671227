import List from "../../common/List/List";
import { TSectionProps } from "../../types/TSectionProps";
import IconImage from "../../../../../../components/Icon/IconImage/IconImage";

const UserProfileSection = (props: TSectionProps) => {
  return (
    <List
      title="Account Settings"
      items={[
        {
          id: "UserProfileInfoListItem",
          name: "Personal Data",
          icon: (
            <IconImage
              imgDark="/app-v2/assets/Account-Circle-Filled-Light.svg"
              img="/app-v2/assets/Account-Circle-Filled-Dark.svg"
              size={16}
            />
          ),
          path: `/user/account`,
          navigateToPath: true,
          onNavigate: props.onClick,
        },
        {
          id: "UserProfileResetPassword",
          name: "Change Password",
          icon: (
            <IconImage
              imgDark="/app-v2/assets/Key-Light.svg"
              img="/app-v2/assets/Key-Dark.svg"
              size={16}
            />
          ),
          path: `/user/resetPassword`,
          navigateToPath: true,
          onNavigate: props.onClick,
        },
      ]}
    ></List>
  );
};

export default UserProfileSection;
