import { AddRoad, Calculate } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/hooks/redux";
import { TBoardParams } from "../../../../../board/containers/Board/types/TBoardParams";
import { menuItem } from "../../../../../../common/containers/Menu/utils/menuItem";
import Section from "../../../../containers/AddButton/Section";
import { TMenuItemProps } from "../../../../../../common/containers/Menu/types/TMenuItemProps";
import AddOfftimeMenu from "../../../../../offtime/containers/Menu/AddOfftimeMenu";
import { guesstimationItemCreateActions } from "../../../../../guesstimation/modules/create/slices/guesstimationItemCreateSlice";
import Menu from "../../../../../../common/containers/Menu/Menu";
import ShortcutProTip from "../../../../../../common/modules/keyboardShortcut/containers/ProTip/ShortcutProTip";
import { KeyboardShortcutEnum } from "../../../../../../common/modules/keyboardShortcut/hooks/enums/KeyboardShortcutEnum";
import { useIsFeatureAllowed } from "../../../../../../common/modules/accessControl/hooks/useIsFeatureAllowed";
import { FeatureEnum } from "../../../../../../common/modules/accessControl/enums/FeatureEnum";
import { featureUtil } from "../../../../../../common/modules/accessControl/utils/featureUtil";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Button } from "../../../../../../common/modules/materialUI";
import { useState } from "react";

const AddButton = () => {
  const sprintLabel = useAppSelector((state) => state.customs.sprintLabel);
  const { projectAbbr } = useParams<TBoardParams>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isGuesstimationAllowed = useIsFeatureAllowed(
    FeatureEnum.GUESSTIMATION_BOARD
  );
  const [isOpenClose, setOpenClose] = useState<boolean>(false);

  const addGuesstimationItemClickHandler = () => {
    dispatch(guesstimationItemCreateActions.openCreationModal());
  };

  const addSprintClickHandler = () => {
    navigate(`/settings/project/${projectAbbr}/sprints?modalOpen=true`);
  };

  return (
    <Menu
      openHandler={(openClose) => setOpenClose(openClose)}
      stretchOnMobile={false}
      closeOnSelection={true}
      button={(clickHandler) => (
        <Button onClick={clickHandler} sx={{ padding: "0" }}>
          <ArrowDropDownIcon
            sx={{
              transform: isOpenClose ? "rotate(180deg)" : "rotate(0deg)",
            }}
          />
        </Button>
      )}
      menuItems={
        [
          menuItem.make({
            component: (
              <Section
                helperText={
                  <ShortcutProTip
                    shortcutKeyString={KeyboardShortcutEnum.t}
                    description="add an item"
                  />
                }
                icon={<Calculate sx={{ color: "action.active" }} />}
                name={`Add Item`}
              />
            ),
            handler: addGuesstimationItemClickHandler,
            disabled: !isGuesstimationAllowed,
            disabledTooltipMessage: featureUtil.notAvailableText,
          }),
          AddOfftimeMenu(),
          menuItem.make({
            component: (
              <Section
                icon={<AddRoad sx={{ color: "action.active" }} />}
                name={`Add ${sprintLabel}`}
              />
            ),
            handler: addSprintClickHandler,
          }),
        ] as TMenuItemProps[]
      }
    />
  );
};

export default AddButton;
