import { ListItemIcon, Tooltip, Typography } from "@mui/material";
import { Person } from "@mui/icons-material";
import { NavLink } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import { menuItem } from "../../../../common/containers/Menu/utils/menuItem";
import { useEffect, useMemo } from "react";
import { userActions } from "../../slices/userSlice";
import { jwt } from "../../../../common/utils/jwt";

const AccountSettings = () => {
  const dispatch = useAppDispatch();
  const userJwt = useAppSelector((state) => state.user.userInfo);
  const url = "/user/account";

  const jwtParsed = useMemo(() => jwt.parse(), [userJwt?.id]);

  useEffect(() => {
    jwtParsed?.id && dispatch(userActions.setUserInfo(jwtParsed));
  }, [jwtParsed?.id]);

  return [
    menuItem.make({
      component: (
        <>
          <Tooltip followCursor title={userJwt?.name}>
            <Typography noWrap variant="subtitle1" color="textPrimary">
              {userJwt?.name}
            </Typography>
          </Tooltip>
          <Tooltip followCursor title={userJwt?.email}>
            <Typography
              noWrap
              display="block"
              variant="caption"
              color="textPrimary"
            >
              {userJwt?.email}
            </Typography>
          </Tooltip>
        </>
      ),
      isHeaderItem: true,
    }),
    menuItem.make({
      component: (
        <NavLink to={url} style={{ width: "100%" }}>
          <ListItemIcon>
            <Person
              sx={{
                color: "action.active",
              }}
            />
          </ListItemIcon>
          <Typography variant="body2" color="textPrimary">
            My Account
          </Typography>
        </NavLink>
      ),
      hasDivider: false,
    }),
  ];
};

export default AccountSettings;
