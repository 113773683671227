// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { Analytics, getAnalytics } from "firebase/analytics";
import { useEffect } from "react";
import { env } from "../../../utils/env";
import { useLocation } from "react-router-dom";
import { useFirebaseEventHelper } from "../hooks/useFirebaseEventHelper";
import { FirebaseEventEnum } from "../hooks/types/FirebaseEventEnum";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: env.firebase.apiKey,
  authDomain: `${env.firebase.projectId}.firebaseapp.com`,
  projectId: env.firebase.projectId,
  storageBucket: `${env.firebase.projectId}.firebasestorage.app`,
  messagingSenderId: env.firebase.messagingSenderId,
  appId: env.firebase.appId,
  measurementId: env.firebase.measurementId,
};

export let analytics: Analytics | undefined;
if (env.firebase.enabled) {
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  analytics = getAnalytics(app);
}

export default function FirebaseConfig() {
  const location = useLocation();
  const { addToFirebaseQueue } = useFirebaseEventHelper();

  useEffect(() => {
    // Log the page_view event whenever the location (route) changes
    addToFirebaseQueue(FirebaseEventEnum.PAGE_VIEW);
  }, [location]);

  return null;
}
