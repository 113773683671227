import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ITip } from "../../../entities/ITip";

export type TState = {
  globalTip?: ITip;
};

const initialState: TState = {};

const slice = createSlice({
  name: "tip",
  initialState,
  reducers: {
    setGlobalTip: (state, action: PayloadAction<ITip>) => {
      state.globalTip = action.payload;
    },
    seenGlobalTip: (state) => {
      state.globalTip = undefined;
    },
  },
});

export const tipReducer = slice.reducer;
export const tipActions = slice.actions;
