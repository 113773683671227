import { Box, BoxProps } from "@mui/material";
import { lightTheme } from "../../../theme/lightTheme";

type TBadgeProps = {
  variant?: "filled" | "outlined";
} & BoxProps;

const Badge = ({ variant = "filled", ...props }: TBadgeProps) => {
  return (
    <Box
      color="text.primary"
      fontFamily={(theme) => theme.typography.fontFamily}
      fontSize="12px"
      fontWeight={500}
      letterSpacing="0.14px"
      lineHeight="20px"
      {...(variant === "filled" && {
        color: lightTheme.palette.text.primary,
        minWidth: "20px",
        p: "0px 6.5px",
        borderRadius: "100px",
        bgcolor: "secondary.main",
      })}
      {...props}
    >
      {props.children}
    </Box>
  );
};

export default Badge;
