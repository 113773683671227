import { Box, Typography, useTheme } from "@mui/material";
import { Tooltip } from "../../modules/materialUI";

type TTask = {
  segmentCount: number;
  segmentCountInGroup: number;
  number: number;
};

const SegmentCount = ({
  isSegmentView,
  task,
  statusName,
}: {
  isSegmentView?: boolean;
  task: TTask;
  statusName: string;
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        borderRadius: 1,
        paddingX: "2px",
        border: "1px solid " + theme.palette.divider,
        backgroundColor:
          theme.palette.backgroundElevation.backgroundPaperElevation_2,
        display: "inline-flex",
      }}
    >
      <Tooltip
        title={`${task.segmentCountInGroup} of ${
          task.segmentCount
        } splits are ${statusName.toLocaleLowerCase()}`}
        when={!isSegmentView}
      >
        <Typography
          variant={"caption"}
          sx={{
            letterSpacing: "0.14px",
            fontWeight: 500,
            display: "inline-flex",
            lineHeight: "150%",
          }}
        >
          {isSegmentView ? ` #${task.number}` : ""}
          {!isSegmentView
            ? `${task.segmentCountInGroup} / ${task.segmentCount}`
            : ""}
        </Typography>
      </Tooltip>
    </Box>
  );
};

export default SegmentCount;
