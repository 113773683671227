import { menuItem } from "../../../../common/containers/Menu/utils/menuItem";
import { Box, Typography } from "@mui/material";
import { propsForMenuItems } from "../../../../common/containers/Menu/common/propsForMenuItems";
import ShareIcon from "@mui/icons-material/Share";
import { readOnlyTokenUtil } from "../../../../common/utils/readOnlyTokenUtil";

type TProps = {
  matchesScreen?: boolean;
  clickHandler: () => void;
};

const ShareMenuItem = ({ matchesScreen, clickHandler }: TProps) => {
  const readonlyToken = readOnlyTokenUtil.fetch();

  return menuItem.make({
    component: (
      <Box
        display="flex"
        alignItems="center"
        gap={1}
        color="pickerPallet.secondary"
      >
        <ShareIcon
          sx={{
            color: "action.active",
            width: "24px",
          }}
        />
        <Typography variant="subtitle2" fontWeight="400" color="text.primary">
          Share
        </Typography>
      </Box>
    ),
    handler: clickHandler,
    hasDivider: false,
    when: !readonlyToken && matchesScreen,
    menuItemsProps: propsForMenuItems,
  });
};

export default ShareMenuItem;
