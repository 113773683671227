import { menuItem } from "../../../../../../../common/containers/Menu/utils/menuItem";
import WorkDaysItem from "../Items/WorkDaysItem";

const WorkdaysMenuItem = ({
  workingDaysCount,
  tsPoints,
}: {
  workingDaysCount?: number;
  tsPoints?: number;
}) => {
  return menuItem.make({
    component: (
      <WorkDaysItem workingDaysCount={workingDaysCount} tsPoints={tsPoints} />
    ),
    isHeaderItem: true,
  });
};
export default WorkdaysMenuItem;
