import { Theme } from "@mui/material";
import { CSSProperties } from "react";

export const spikeStyleConfig = (theme: Theme, isHovered?: boolean) => ({
  containerStyle: {
    backgroundColor: undefined,
    border: theme.palette.spike.border,
    borderRadius: "12px",
  } as CSSProperties,
  contentStyle: {
    backgroundColor: isHovered
      ? theme.palette.spike.hover
      : theme.palette.spike.backgroundColor,
    color: theme.palette.spike.color,
    borderRadius: "10px",
    width: "calc(100% - 4px)",
    height: "calc(100% - 4px)",
  } as CSSProperties,
  smallContainerStyle: {
    borderRadius: "12px",
  } as CSSProperties,
  smallContentStyle: {
    borderRadius: "10px",
    width: "calc(100% - 4px)",
    height: "calc(100% - 4px)",
  } as CSSProperties,
  nameStyle: {
    textAlign: "center",
  } as CSSProperties,
});
