import { useHttpClientDispatchable } from "../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";
import { IReadOnlyAccessToken } from "../../../entities/IReadOnlyAccessToken";

export const useDisableReadOnlyAccessService = () => {
  const response = useHttpClientDispatchable({
    url: `/readOnlyAccessToken/disable`,
    method: RequestMethodEnum.PATCH,
  });

  return {
    ...response,
    data: response.data as IReadOnlyAccessToken,
  };
};
