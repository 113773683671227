import { useCreateStripePortalSessionService } from "../../services/useCreateStripePortalSessionService";
import { IStripeSessionResource } from "../../interfaces/IStripeSessionResource";
import { OpenInNew } from "@mui/icons-material";
import Loading from "../../../../common/components/Icon/Loading/Loading";
import useWindow from "../../../../common/hooks/useWindow";
import { useTheme } from "@mui/material";

const ManageSubscription = () => {
  const { openNewTab } = useWindow();
  const theme = useTheme();
  const { isLoading, dispatch: dispatchCreateStripePortalSessionService } =
    useCreateStripePortalSessionService();

  const manageSubscriptionHandler = () => {
    dispatchCreateStripePortalSessionService().then(
      (resource: IStripeSessionResource) => {
        openNewTab(resource.url, true);
      }
    );
  };

  return {
    id: "ManageSubscription",
    name: "Manage Subscription",
    icon: isLoading ? (
      <Loading />
    ) : (
      <OpenInNew style={{ color: theme.palette.action.active }} />
    ),
    path: ``,
    navigateToPath: false,
    onClick: manageSubscriptionHandler,
  };
};

export default ManageSubscription;
