import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../common/hooks/redux";
import { Box, Typography } from "@mui/material";
import { Clear } from "@mui/icons-material";
import { TBoardQueryState } from "../../../slices/types/TBoardQueryState";
import { boardQueryActions } from "../../../slices/boardQuerySlice";
import { TListItem } from "../../../../../common/types/TListItem";
import Badge from "../../../../../common/modules/materialUI/components/Badge/Badge";
import Chip from "../../../../../common/modules/materialUI/components/Chip/Chip";

const ExtraFilters = () => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector((state) => state.boardQuery.extraFilters);
  const filterExists = Object.values(filters).some(
    (value) => value !== null && value.length > 0
  );

  const removeFilterHandler = (
    filterItem: TListItem<number | string>,
    key: keyof TBoardQueryState["extraFilters"]
  ) => {
    const filter = filters[key].find((item) => item.id === filterItem.id);
    if (filter) {
      const filtersList = filters[key].filter(
        (filterItem) => filterItem.id !== filter.id
      );
      dispatch(
        boardQueryActions.setExtraFiltering({
          ...filters,
          [key]: filtersList,
        })
      );
    } else {
      const filtersList = [...filters.taskTypeIds, filterItem];
      boardQueryActions.setExtraFiltering({
        ...filters,
        [key]: filtersList,
      });
    }
  };

  if (!filters || !filterExists) {
    return null;
  }

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      gap="12px"
      my="12px"
      color="text.secondary"
      width="100%"
    >
      <Badge variant="outlined">Filters Applied:</Badge>
      {Object.keys(filters).map((key) => {
        const filterKey = key as keyof TBoardQueryState["extraFilters"];

        return filters[filterKey].map((filterItem, index) => (
          <Box
            key={key + index}
            display="flex"
            alignItems="center"
            flexWrap="wrap"
            gap="4px"
            sx={{
              cursor: "pointer",
              "&:not(:last-child)::after": {
                content: '""',
                display: "block",
                width: "1px",
                height: "50%",
                backgroundColor: "divider",
                marginLeft: "12px",
              },
            }}
          >
            <Typography sx={{ fontSize: "0.875rem" }}>
              {filterItem.label}
            </Typography>
            <Chip
              size="small"
              onClick={() => removeFilterHandler(filterItem, filterKey)}
              label={
                <Clear
                  sx={{
                    fontSize: "14px",
                    color: "brightCardBackground.paper",
                  }}
                />
              }
              sx={{
                width: "16px",
                height: "16px",
                padding: 0,
                background: "pickerPallet.primary",
                opacity: 0.3,
                "& .MuiChip-label": {
                  px: 0,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                },
                "&:hover": {
                  opacity: 0.4,
                  background: "pickerPallet.primary",
                },
              }}
            />
          </Box>
        ));
      })}
    </Box>
  );
};

export default ExtraFilters;
