import { Box, Typography } from "@mui/material";

const WorkDaysItem = ({
  workingDaysCount,
  tsPoints,
}: {
  workingDaysCount?: number;
  tsPoints?: number;
}) => {
  return (
    <Box display="flex" gap={1}>
      <Typography variant="caption">Workdays:</Typography>
      <Typography component="strong" variant="caption">
        {" "}
        {workingDaysCount} / {tsPoints}
      </Typography>
    </Box>
  );
};
export default WorkDaysItem;
