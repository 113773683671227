import { useTheme } from "@mui/material";
import Grid from "@mui/material/Grid2";
import TSSigninForm from "./TSSigninForm";
import OrSection from "../components/OrSection/OrSection";
import MSSignInLayout from "../../../../integrations/modules/MSAuth/containers/SignIn/SignInLayout";

const SignIn = () => {
  const theme = useTheme();

  return (
    <>
      <Grid>
        <TSSigninForm />
      </Grid>
      <Grid>
        <OrSection />
      </Grid>
      <Grid style={{ paddingTop: theme.spacing(2) }}>
        <MSSignInLayout isLogin={true} />
      </Grid>
    </>
  );
};

export default SignIn;
