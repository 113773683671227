import { array } from "../../../../../../utils/array";
import classes from "../../common/Navigation.module.scss";
import List from "../../common/List/List";
import { Apps } from "@mui/icons-material";
import CircleIcon from "@mui/icons-material/Circle";
import { BoardTypeEnum } from "../../../../../../../entities/columns/board/BoardTypeEnum";
import IconImage from "../../../../../../components/Icon/IconImage/IconImage";
import { AbilityEnum } from "../../../../../../modules/accessControl/enums/AbilityEnum";
import { Box, Typography, useTheme } from "@mui/material";
import NavigationTitle from "../../../../../../components/navigationTitle/NavigationTitle";
import { TDoubleNavigationContentProps } from "./types/TDoubleNavigationContentProps";
import { useState } from "react";
import { doubleNavigationSize, navigationSize } from "../../Navigation";
import OrgMenu from "../../../../../Menu/OrgMenu/OrgMenu";
import Divider from "../../../../../../components/Divider/Divider";
import {
  DIVIDER_MARGIN_Y,
  getBoardItemIcon,
} from "../Main/MainNavigationContent";
import { Tooltip } from "../../../../../../modules/materialUI";
import { useNavigationLogic } from "../hooks/useNavigationLogic";
import CreateBoard from "../../../../../../../modules/board/containers/CreateBoard/CreateBoard";
import listClasses from "../../../../../../components/Navigation/List/List.module.css";
import useMedia from "../../../../../../hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../../../hooks/useMedia/enums/QueryEnum";

const getBoardPath = (
  projectAbbr: string,
  boardRef: string,
  boardType?: BoardTypeEnum
) => {
  switch (boardType) {
    case BoardTypeEnum.BACKLOG:
      return `${projectAbbr}/board/${boardRef}/backlog`;
    default:
      return `${projectAbbr}/board/${boardRef}`;
  }
};

const DoubleNavigationContent = ({
  navigationRequestedHandler,
  projects,
  orgInitDataLoading,
  content,
}: TDoubleNavigationContentProps) => {
  const theme = useTheme();
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [isTransitionEnded, setIsTransitionEnded] = useState(true);

  const mouseEnterHandle = () => {
    setIsHovered(true);
    setIsTransitionEnded(false);
  };

  const mouseLeaveHandle = () => {
    setIsHovered(false);
    setIsTransitionEnded(false);
  };

  const hasProjects = projects.length > 0;
  const matchesMax992Screen = useMedia(QueryEnum.MAX_WIDTH_992);
  const isTouchDevice = useMedia(QueryEnum.IS_TOUCH_DEVICE);

  const { userName, orgRef, orgRefHeight, HEADER_HEIGHT_PX, can } =
    useNavigationLogic(navigationSize + doubleNavigationSize);

  return (
    <nav
      style={{
        backgroundColor: theme.palette.background.paper,
        width: navigationSize + doubleNavigationSize,
        display: "flex",
        padding: "0",
        borderRight: "1px solid " + theme.palette.divider,
        height: `calc(100dvh - ${HEADER_HEIGHT_PX})`,
      }}
      className={array.toClassName([classes.navigation])}
    >
      <Box
        borderRight={"1px solid " + theme.palette.divider}
        padding="0 8px"
        position={
          isTransitionEnded ? (isHovered ? "absolute" : "static") : "absolute"
        }
        zIndex={1}
        width={isHovered ? navigationSize : doubleNavigationSize}
        height="100%"
        bgcolor="background.paper"
        sx={{
          transition: "width 0.2s linear",
        }}
        onMouseEnter={matchesMax992Screen ? undefined : mouseEnterHandle}
        onMouseLeave={matchesMax992Screen ? undefined : mouseLeaveHandle}
        onTransitionEnd={(e) => {
          if (e.propertyName === "width") {
            setIsTransitionEnded(true);
          }
        }}
      >
        {orgInitDataLoading && (
          <List isDropdownList onlyIconList loading={true} />
        )}
        {!orgInitDataLoading && (
          <Box ref={orgRef} pt="12px">
            <OrgMenu
              onlyLogo={isTransitionEnded ? !isHovered : !isHovered}
              mouseLeaveHandle={mouseLeaveHandle}
            />
            <Divider sx={{ my: DIVIDER_MARGIN_Y }} />
          </Box>
        )}
        {!orgInitDataLoading && (
          <Box
            overflow="auto"
            pb="10px"
            height={`calc(100dvh - ${HEADER_HEIGHT_PX} - ${orgRefHeight}px - ${DIVIDER_MARGIN_Y} - ${
              isTouchDevice || matchesMax992Screen ? 55 : 0
            }px )`}
            sx={{
              scrollbarWidth: "none",
              "&::-webkit-scrollbar": { display: "none" },
            }}
          >
            <NavigationTitle
              textInCenter={isTransitionEnded ? !isHovered : false}
              title="Views"
            />
            <List
              items={[
                ...(hasProjects
                  ? [
                      {
                        id: "priorityView",
                        path: "/priorityView",
                        name: isHovered
                          ? (userName?.endsWith("s")
                              ? userName
                              : userName + "'s") +
                            " " +
                            "View"
                          : "",
                        icon: (
                          <IconImage
                            imgDark="/app-v2/assets/Priority-View-Light.svg"
                            img="/app-v2/assets/Priority-View-Dark.svg"
                            size={16}
                          />
                        ),
                        onNavigate: navigationRequestedHandler,
                      },
                      {
                        id: "projectView",
                        path: "/projectView",
                        name: isHovered ? "Project View" : "",
                        icon: (
                          <Apps
                            style={{ color: theme.palette.action.active }}
                          />
                        ),
                        onNavigate: navigationRequestedHandler,
                      },
                    ]
                  : []),
                ...(can(AbilityEnum.manage)
                  ? [
                      {
                        id: "objectives",
                        path: "/objectives",
                        name: isHovered ? "Objectives" : "",
                        icon: (
                          <IconImage
                            imgDark="/app-v2/assets/objective-light.svg"
                            img="/app-v2/assets/objective-dark.svg"
                            size={16}
                          />
                        ),
                        onNavigate: navigationRequestedHandler,
                      },
                    ]
                  : []),
              ]}
            />
            <Divider />
            {projects.length > 0 && (
              <NavigationTitle
                textInCenter={isTransitionEnded ? !isHovered : false}
                title="Projects"
              />
            )}

            <>
              {projects.map((data) => {
                const boards = [...data.boards];
                const sortedBoards = [
                  ...boards.splice(
                    boards.findIndex(
                      (board) => board.type === BoardTypeEnum.HOLISTIC
                    ),
                    1
                  ),
                  ...boards.splice(
                    boards.findIndex(
                      (board) => board.type === BoardTypeEnum.BACKLOG
                    ),
                    1
                  ),
                  ...boards,
                ];

                return (
                  <List
                    isDropdownList
                    onlyIconList={!isHovered}
                    action1Element={
                      isTransitionEnded && isHovered ? (
                        <CreateBoard
                          className={listClasses["action-1-icon"]}
                          projectRef={data.project.ref!}
                          projectName={data.project.name}
                          onClick={navigationRequestedHandler}
                        />
                      ) : undefined
                    }
                    title={
                      <>
                        <CircleIcon
                          style={{
                            color:
                              data.project.color ?? theme.palette.primary.main,
                            maxWidth: "8px",
                            maxHeight: "8px",
                            marginLeft: "6px",
                            marginRight: "6px",
                          }}
                        />
                        {isHovered && (
                          <Tooltip title={data.project.name}>
                            <Typography
                              noWrap
                              width="65%"
                              fontSize="14px"
                              color="text.secondary"
                              fontWeight={500}
                            >
                              {data.project.name}
                            </Typography>
                          </Tooltip>
                        )}
                      </>
                    }
                    key={data.project.ref}
                    items={sortedBoards.map((board) => {
                      return {
                        className:
                          board.type === BoardTypeEnum.BACKLOG
                            ? classes["board-item-padding-bottom"]
                            : undefined,
                        id: board.ref!,
                        name: isHovered ? board.name : "",
                        path: getBoardPath(
                          data.project.abbr,
                          board.ref!,
                          board.type
                        ),
                        icon: getBoardItemIcon(theme, board.type),
                        onNavigate: navigationRequestedHandler,
                      };
                    })}
                  />
                );
              })}
            </>
          </Box>
        )}
        {(isTouchDevice || matchesMax992Screen) && !orgInitDataLoading && (
          <Box
            display="flex"
            justifyContent={isHovered ? "flex-end" : "center"}
            alignItems="center"
            mt="15px"
          >
            <Typography
              component="span"
              sx={{
                rotate: isHovered ? "180deg" : "0deg",
              }}
              onClick={() => {
                setIsHovered(!isHovered);
                setIsTransitionEnded(false);
              }}
            >
              <IconImage
                img={"/app-v2/assets/navigation-open-dark.svg"}
                imgDark={"/app-v2/assets/navigation-open-light.svg"}
                size={24}
              />
            </Typography>
          </Box>
        )}
      </Box>
      <Box
        width={navigationSize}
        p="12px 8px 0 8px"
        ml={
          isTransitionEnded
            ? isHovered
              ? doubleNavigationSize + "px"
              : ""
            : doubleNavigationSize + "px"
        }
        sx={{
          transition: "width 0.2s linear",
        }}
      >
        {content}
      </Box>
    </nav>
  );
};

export default DoubleNavigationContent;
