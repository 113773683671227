import { Box, Typography } from "@mui/material";
import { numberOfCollisionsToShow } from "../KpiSection";
import { Fragment } from "react";
import { useRoleMapHelper } from "../../../hooks/useRoleMapHelper";
import { ICollisionOverview } from "../../../../../../../entities/columns/guesstimationItem/ICollisionOverview";
import Tooltip from "../../../../../../../common/modules/materialUI/components/Tooltip/Tooltip";

const CollisionsItem = ({
  collisionsOverview,
  collisionsOverviewLength,
}: {
  collisionsOverview: ICollisionOverview[];
  collisionsOverviewLength?: number;
}) => {
  const roleMapHelper = useRoleMapHelper();

  const renderCollisionsList = () =>
    collisionsOverview
      .slice(0, numberOfCollisionsToShow)
      .map((collisionOverview, index) => (
        <Fragment key={index}>
          <Typography variant="caption" component="strong">
            {roleMapHelper.getRoleMapLabel(collisionOverview.userRole)}-
            {collisionOverview.count}
          </Typography>
          {collisionsOverviewLength! - 1 !== index && <span>, </span>}
        </Fragment>
      ));

  return (
    <Tooltip
      when={collisionsOverview.length > 1}
      title={<>{renderCollisionsList()}</>}
    >
      <Box display="flex" gap={1} maxWidth="150px">
        <Typography variant="caption">Collisions:</Typography>
        <Typography
          variant="caption"
          component="strong"
          noWrap
          sx={{
            color: "error.main",
          }}
        >
          {" "}
          {renderCollisionsList()}
        </Typography>
      </Box>
    </Tooltip>
  );
};
export default CollisionsItem;
