import { TMoveToTaskFormProps } from "./types/TMoveToTaskFormProps";
import useForm from "../../../../../common/hooks/useForm/useForm";
import { TMoveToTaskFormAttributes } from "./types/TMoveToTaskFormAttributes";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../common/hooks/redux";
import Form from "../../../../../common/components/Form/Form";
import { useEffect } from "react";
import {
  Autocomplete,
  Button,
  TextField,
} from "../../../../../common/modules/materialUI";
import { useUserListService } from "../../../../user/services/useUserListService";
import { useFetchCustomFieldsByBoardRefService } from "../../../../customField/services/useFetchCustomFieldsByBoardRefService";
import { useGetBacklogItemDetailsService } from "../../view/services/useGetBacklogItemDetailsService";
import CustomFieldInput from "../../../../customField/containers/CustomFieldInput/CustomFieldInput";
import { BoardTypeEnum } from "../../../../../entities/columns/board/BoardTypeEnum";
import { customFieldInputUtil } from "../../../../customField/utils/customFieldInputUtil";
import { TListItem } from "../../../../../common/types/TListItem";
import Loading from "../../../../../common/components/Icon/Loading/Loading";
import { useConvertBacklogItemToEntityService } from "../services/useConvertBacklogItemToEntityService";
import { TMoveToTaskFormAttributesTransformed } from "./types/TMoveToTaskFormAttributesTransformed";
import { EntityTypeEnum } from "../../../../../entities/enums/EntityTypeEnum";
import { IConvertBacklogItemToEntityResource } from "../interfaces/IConvertBacklogItemToEntityResource";
import { systemNotificationActions } from "../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { backlogItemViewActions } from "../../view/slices/viewSlice";
import { useDispatchEvent } from "../../../../../common/modules/eventProvider";
import { EventEnum } from "../../../../../common/modules/eventProvider/enums/EventEnum";
import { TBacklogItemDeletedEvent } from "../../view/containers/types/TBacklogItemDeletedEvent";
import { useNavigate } from "react-router-dom";
import { THttpClientError } from "../../../../../common/modules/httpClient/types/THttpClientError";
import Grid from "@mui/material/Grid2";
import { enumUtil } from "../../../../../common/utils/enum";
import { EstimationUomEnum } from "../../../../../entities/enums/EstimationUomEnum";

const MoveToTaskForm = ({
  backlogItemRef,
  boardRef,
  onCancel,
  isOnViewPage,
}: TMoveToTaskFormProps) => {
  const dispatch = useAppDispatch();
  const dispatchEvent = useDispatchEvent();
  const navigate = useNavigate();
  const { list: projectWithBoardResource, isLoading: isOrgInitLoading } =
    useAppSelector((state) => state.org.init);
  const userListService = useUserListService();
  const {
    dispatch: dispatchGetDetails,
    isLoading: isBacklogItemResourceLoading,
    data: itemDetailsResource,
  } = useGetBacklogItemDetailsService(false);
  const {
    isLoading: isCustomFieldsLoading,
    dispatch: dispatchFetchCustomFields,
    data: customFieldsResource,
  } = useFetchCustomFieldsByBoardRefService({ boardRef: boardRef });
  const { dispatch: dispatchConvertToTaskService, isLoading } =
    useConvertBacklogItemToEntityService({
      backlogItemId: itemDetailsResource?.backlogItem.id ?? 0,
    });
  const displayableCustomFields = customFieldsResource?.customFields.filter(
    (customField) => customField.isRequired
  );
  const projectRef = itemDetailsResource?.backlogItem?.projectRef;
  const projectBoards = projectRef
    ? projectWithBoardResource.find(
        (resource) => resource.project.ref === projectRef
      )?.boards
    : undefined;
  const defaultHolisticBoard = projectBoards?.find(
    (projectBoard) => projectBoard.type === BoardTypeEnum.HOLISTIC
  );
  const boardList = projectBoards
    ?.filter((board) => board.type !== BoardTypeEnum.BACKLOG)
    .map(
      (board) =>
        ({
          id: board.ref,
          label: board.name,
        } as TListItem<string>)
    );

  useEffect(() => {
    dispatchGetDetails({
      urlPath: `/${backlogItemRef}`,
    });
    dispatchFetchCustomFields();
  }, [backlogItemRef, boardRef]);

  const form = useForm<TMoveToTaskFormAttributes>({
    boardRef: undefined,
    assignedTo: [],
  });

  const isDefaultDataLoading =
    isOrgInitLoading ||
    isBacklogItemResourceLoading ||
    isCustomFieldsLoading ||
    !itemDetailsResource;

  useEffect(() => {
    if (isDefaultDataLoading || !itemDetailsResource) {
      return;
    }

    form.changeDefaultData({
      boardRef: defaultHolisticBoard
        ? ({
            id: defaultHolisticBoard.ref,
            label: defaultHolisticBoard.name,
          } as TListItem<string>)
        : undefined,
      assignedTo: form.get("assignedTo"),
      ...(!!itemDetailsResource.backlogItem.customFieldValues &&
      !!customFieldsResource?.customFields
        ? customFieldInputUtil.getFormAttributeValues(
            itemDetailsResource.backlogItem.customFieldValues,
            customFieldsResource?.customFields
          )
        : {}),
    });
  }, [isDefaultDataLoading]);

  const closeBacklogItemViewDialog = () => {
    // Closing backlog item view dialog
    dispatch(backlogItemViewActions.reset());

    // Updating backlog board view to reflect the changes
    dispatchEvent(EventEnum.ON_BACKLOG_ITEM_DELETED, {
      backlogItem: itemDetailsResource?.backlogItem!,
    } as TBacklogItemDeletedEvent);
  };

  const redirectToCreatedTask = (
    convertedEntityResource: IConvertBacklogItemToEntityResource
  ) => {
    navigate(convertedEntityResource.url);
  };

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();

    dispatchConvertToTaskService({
      body: form.getTransformed<TMoveToTaskFormAttributesTransformed>(
        (data) => {
          return {
            targetEntityType: EntityTypeEnum.TASK,
            boardRef: data.boardRef?.id,
            assignedTo: data.assignedTo?.length
              ? data.assignedTo.map((assignedToItem) => assignedToItem.id)
              : undefined,
            estimationUom: data.estimationUom?.id,
            estimationPoints: !!(data.estimationPoints ?? null)
              ? data.estimationPoints
              : undefined,
            ...customFieldInputUtil.getCustomFieldFormAttributesTransformed(
              customFieldsResource?.customFields ?? [],
              data
            ),
          };
        }
      ),
    })
      .then((resourceData: IConvertBacklogItemToEntityResource) => {
        dispatch(
          systemNotificationActions.open({
            message: {
              type: "withLinks",
              text: "Backlog item is converted to task {link} successfully",
              placeholders: {
                link: {
                  url: `/task/${resourceData.ref}`,
                  label: resourceData.ref,
                },
              },
            },
            variant: "success",
          })
        );

        // Redirect to the created task if it's done on a page, or close the backlog item view dialog and trigger
        // backlog item deleted event
        isOnViewPage
          ? redirectToCreatedTask(resourceData)
          : closeBacklogItemViewDialog();
      })
      .catch((error: THttpClientError) => {
        form.errorHandler(error);
      });
  };

  return (
    <Form
      onSubmit={submitHandler}
      actionElement={
        <>
          <Button onClick={onCancel} disabled={isLoading} variant="outlined">
            Cancel
          </Button>
          <Button
            disabled={isLoading || isDefaultDataLoading}
            variant="contained"
            type="submit"
            loading={isLoading || isDefaultDataLoading}
          >
            Add
          </Button>
        </>
      }
    >
      <Autocomplete
        form={form}
        name="assignedTo"
        label="Assigned To"
        multiple
        service={userListService}
        options={form.data?.assignedTo}
      />

      {isDefaultDataLoading && <Loading />}
      {!isDefaultDataLoading && (
        <>
          <Autocomplete
            form={form}
            name="boardRef"
            label="Board"
            options={boardList ?? []}
            showRequired
          />

          <Grid container spacing={2}>
            <Grid size={{ xs: 12, sm: 6 }}>
              <Autocomplete
                form={form}
                name="estimationUom"
                label="Estimation UOM"
                options={enumUtil.toList(EstimationUomEnum)}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <TextField
                form={form}
                name="estimationPoints"
                label="Estimation"
              />
            </Grid>
          </Grid>

          {displayableCustomFields?.map((customField) => (
            <CustomFieldInput
              key={customField.id}
              customField={customField}
              form={form}
              showLabel
            />
          ))}
        </>
      )}
    </Form>
  );
};

export default MoveToTaskForm;
