import { TSectionProps } from "./types/TSectionProps";
import { Card, CardContent, Typography, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Task from "../Task/Task";
import DropTargetContainer from "../DropTarget/DropTargetContainer";

const Section = (props: TSectionProps) => {
  const theme = useTheme();

  return (
    <Card
      sx={{
        backgroundColor:
          theme.palette.backgroundElevation.backgroundPaperElevation_1,
        position: "relative",
        marginRight: "10px",
        flexGrow: 0,
        flexShrink: 0,
        borderRadius: 3,
      }}
    >
      <DropTargetContainer {...props} />
      <CardContent
        style={{
          width: "316px",
          padding: "0",
        }}
      >
        <Grid container>
          <Grid
            size={12}
            sx={{
              bgcolor: theme.palette.background.paper,
              height: "50px",
              padding: "0 16px",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              borderBottom: `1px solid ${theme.palette.divider}`,
            }}
          >
            <Typography
              variant="subtitle2"
              color={"text.primary"}
            >{`${props.status.name} ${props.tasks.length}`}</Typography>
          </Grid>
          <Grid
            size={12}
            style={{
              padding: "8px",
            }}
          >
            {props?.tasks.map((task, index) => (
              <Task
                statusColumn={props.status}
                style={{ margin: "4px 0" }}
                key={`task-kv-${task.id}-${task.status}-${task.segmentId}`}
                task={task}
                users={props.users}
                isSegmentView={props.showSegments}
                statusName={props.status.name}
              />
            ))}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Section;
