import classes from "./TimeArrow.module.css";
import { TTimeArrowProps } from "./types/TTimeArrowProps";
import { Box, Theme, useTheme } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { TTimeHighlight } from "../../types/TTimeHighlight";

function timeArrowIcon(timeHighlight: TTimeHighlight, theme: Theme) {
  switch (timeHighlight) {
    case "now":
      return (
        <PlayArrowIcon
          sx={{
            position: "absolute",
            top: "-11px",
            left: "-7px",
            transform: "rotate(90deg)",
            fontSize: "15px",
            filter: `drop-shadow(0px 0px 8px ${theme.palette.warning.main})`,
          }}
          className={`${classes.arrow}`}
        />
      );
    case "checkpoint":
      return (
        <Box
          sx={{
            position: "absolute",
            top: "-5px",
            left: "-2.5px",
            transform: "rotate(135deg)",
            fontSize: "15px",
            width: "6px",
            height: "6px",
          }}
          className={`${classes.box} ${classes.shadow}`}
        />
      );
    default:
      return (
        <Box
          sx={{
            position: "absolute",
            top: "-6px",
            left: "-2.5px",
            transform: "rotate(135deg)",
            fontSize: "15px",
            width: "6px",
            height: "6px",
            borderRadius: "50%",
          }}
          className={`${classes.box} ${classes.shadow}`}
        />
      );
  }
}

const TimeArrow = (props: TTimeArrowProps) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: "absolute",
        left: `${props.position}px`,
        bottom: 0,
        height: `calc(${props.height ?? 100}% - 90px)`,
        width: "1px",
        zIndex: 20,
      }}
      className={`${classes[props.timeHighlight]} ${classes.shadow}`}
    >
      {timeArrowIcon(props.timeHighlight, theme)}
      <div
        className={`${
          props.timeHighlight === "now" ? classes.lineNow : classes.lineDashed
        }`}
      />
    </Box>
  );
};

export default TimeArrow;
