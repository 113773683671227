import GuesstimationViewContent from "../GuesstimationViewContent/GuesstimationViewContent";
import { TBoardViewProps } from "../../../../../board/types/TBoardViewProps";
import { StickyNote2 } from "@mui/icons-material";
import { useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/hooks/redux";
import NotesModal from "../../../../../objective/containers/View/Initiatives/ManageInitiative/NotesModal";
import CreateModal from "../../../../../guesstimation/modules/create/containers/CreateItem/CreateModal";
import GuesstimationItemViewModal from "../../../../../guesstimation/modules/view/containers/GuesstimationItemViewModal";
import BoardUsersFilter from "../Filter/BoardUsersFilter";
import ReportModal from "../../../../../guesstimation/modules/report/containers/ReportModal";
import { guesstimationItemReportActions } from "../../../../../guesstimation/modules/report/slices/guesstimationItemReportSlice";
import InsightsIcon from "@mui/icons-material/Insights";
import ButtonGroup from "../../../../../../common/modules/materialUI/components/ButtonGroup/ButtonGroup";
import Button from "../../../../../../common/modules/materialUI/components/Button/Button";
import { colorEnum } from "../../../../../../common/utils/color";
import { variantEnum } from "../../../../../../common/utils/variant";
import { ConfirmationDialog } from "../../../../../../common/modules/materialUI";
import { useArchiveBoardHandler } from "../../../../../board/hooks/useArchiveBoard";
import { useParams } from "react-router-dom";
import { TBoardParams } from "../../../../../board/containers/Board/types/TBoardParams";
import GuesstimationViewOptions from "./GuesstimationViewOptions";
import GuesstimationViewSettingsMenu from "./GuesstimationViewSettingsMenu";
import { Box } from "@mui/material";
import useMedia from "../../../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../../../common/hooks/useMedia/enums/QueryEnum";

const GuesstimationView = (props: TBoardViewProps) => {
  const { projectAbbr, boardRef } = useParams<TBoardParams>();
  const archiveBoard = useArchiveBoardHandler(boardRef!, projectAbbr!);
  const [noteModalOpen, setNoteModalOpen] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const matchesMax576Screen = useMedia(QueryEnum.MAX_WIDTH_576);

  const dispatch = useAppDispatch();
  const initiatives = useAppSelector((state) => state.board.initiatives);
  const { data, gridItems, showSkeleton } = useAppSelector(
    (state) => state.guesstimationView
  );

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        height={"40px"}
        marginBottom={"12px"}
      >
        <Box marginLeft={"10px"} width={"100%"}>
          <BoardUsersFilter
            projectAbbr={props.projectAbbr}
            boardRef={props.boardRef}
          />
        </Box>

        <Box display={"flex"} alignItems="center" gap={"12px"}>
          <Box>
            <ButtonGroup variant="outlined" sx={{ borderColor: "divider" }}>
              <Button
                onClick={() => {
                  setNoteModalOpen(true);
                }}
                size="small"
                color="inherit"
                variant="outlined"
                isBorderStyle
                startIcon={<StickyNote2 sx={{ fontSize: "18px" }} />}
                sx={{
                  p: "4px 10px",
                }}
              >
                {!matchesMax576Screen && "Notes"}
              </Button>
              <GuesstimationViewOptions />
            </ButtonGroup>
          </Box>
          <Box>
            <Button
              variant="outlined"
              size="small"
              color="inherit"
              startIcon={<InsightsIcon />}
              isBorderStyle
              onClick={() =>
                dispatch(guesstimationItemReportActions.openReportModal())
              }
              sx={{ p: "4px 10px" }}
            >
              {!matchesMax576Screen && "Collisions KPI"}
            </Button>
          </Box>
          <Box>
            <GuesstimationViewSettingsMenu
              boardRef={boardRef}
              setOpen={(value: boolean) => setOpen(value)}
              board={props.board}
              projectAbbr={projectAbbr}
            />
          </Box>
        </Box>
      </Box>

      <GuesstimationViewContent
        {...props}
        data={data}
        gridItems={gridItems}
        showSkeleton={showSkeleton}
      />

      <NotesModal
        show={noteModalOpen}
        boardId={props.board.id}
        initiatives={initiatives}
        isBoardView={true}
        closeHandler={() => {
          setNoteModalOpen(false);
        }}
      />

      <ReportModal boardRef={props.boardRef} />

      <GuesstimationItemViewModal />

      <CreateModal boardRef={props.boardRef} />

      <ConfirmationDialog
        open={open}
        title="Archive Board"
        content="Are you sure you want to archive board? You won't be able to unarchived it manually."
        closeHandler={() => setOpen(false)}
        successHandler={archiveBoard}
        disagreeText="Cancel"
        confirmText="Archive"
        confirmColor={colorEnum.ERROR}
        disagreeColor={colorEnum.INHERIT}
        disagreeVariant={variantEnum.OUTLINED}
      />
    </>
  );
};

export default GuesstimationView;
