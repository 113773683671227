import { TOccupiedSpaceProps } from "./types/TOccupiedSpaceProps";
import { TChildren } from "../../types/TChildren";
import { NavLink } from "react-router-dom";
import useLinkTarget from "../../hooks/useLinkTarget";

export default function Link(props: TOccupiedSpaceProps & TChildren) {
  const { _blank } = useLinkTarget();

  return (
    <>
      {props.link ? (
        <NavLink
          to={props.link}
          style={{
            color: "inherit",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
          target={_blank}
        >
          {props.children}
        </NavLink>
      ) : (
        props.children
      )}
    </>
  );
}
