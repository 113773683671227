import { IStripeSessionResource } from "../../../../modules/stripe/interfaces/IStripeSessionResource";
import { AbilityEnum } from "../../../modules/accessControl/enums/AbilityEnum";
import { useEffect } from "react";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useAccessControl } from "../../../modules/accessControl/hooks/useAccessControl";
import { Button } from "../../../modules/materialUI";
import { useFetchOrgInitDataService } from "../../../../modules/org/services/useFetchOrgInitDataService";
import { useCreateStripeManageSubscriptionService } from "../../../../modules/stripe/services/useCreateStripeManageSubscriptionService";
import { jwt } from "../../../utils/jwt";
import { useNavigate } from "react-router-dom";
import { useSignOutHandler } from "../../../../modules/user/hooks/useSignOutHandler";
import SignOutButton from "./SignOutButton";
import useWindow from "../../../hooks/useWindow";

const SubscriptionRequired = () => {
  const { openNewTab } = useWindow();
  const jwtPayload = jwt.parse();
  const navigate = useNavigate();
  const signOutHandler = useSignOutHandler();
  const { can, acLoaded } = useAccessControl();
  const { isLoading, dispatch: dispatchCreateStripePortalSessionService } =
    useCreateStripeManageSubscriptionService();
  const { dispatch: dispatchFetchOrgInitDataService } =
    useFetchOrgInitDataService(true);

  useEffect(() => {
    if (!acLoaded) {
      dispatchFetchOrgInitDataService().then(() => {
        // If we have successful response, then is not 402 anymore
        navigate("/");
      });
    }
  }, []);

  if (!jwtPayload) {
    return null;
  }

  const manageSubscriptionHandler = () => {
    dispatchCreateStripePortalSessionService().then(
      (resource: IStripeSessionResource) => {
        openNewTab(resource.url, true);
      }
    );
  };

  return (
    <Grid container sx={{ mt: 2 }} gap={5}>
      {can(AbilityEnum.supreme) ? (
        <Grid size={12}>
          <Typography pb={3}>
            Dear {jwtPayload.name}, Your subscription has expired. Please
            configure your subscription to continue using our product.{" "}
          </Typography>
          <Button
            sx={{ padding: "3px 15px" }}
            loading={isLoading}
            disabled={isLoading}
            variant="text"
            type="submit"
            size="large"
            onClick={manageSubscriptionHandler}
          >
            Manage Subscription
          </Button>
        </Grid>
      ) : (
        <Grid>
          <Typography>
            Dear {jwtPayload.name}, Your subscription has expired. Please
            contact your administration.
          </Typography>
        </Grid>
      )}
      <SignOutButton clickHandler={signOutHandler} />
    </Grid>
  );
};

export default SubscriptionRequired;
