import { Box, Grid, IconButton, Typography } from "@mui/material";
import { useEffect } from "react";
import { ContentCopy, Delete } from "@mui/icons-material";
import ReadOnlyLinkListSkeleton from "./ReadOnlyLinkListSkeleton";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../common/hooks/redux";
import { useReadOnlyAccessListService } from "../../../services/useReadOnlyAccessListService";
import { ReadOnlyAccessTypeEnum } from "../../../../../common/modules/accessControl/enums/ReadOnlyAccessTypeEnum";
import { useDisableReadOnlyAccessService } from "../../../services/useDisableReadOnlyAccessService";
import { IReadOnlyAccessToken } from "../../../../../entities/IReadOnlyAccessToken";
import { readOnlyAccessActions } from "../../../slices/readOnlyAccessSlice";
import { systemNotificationActions } from "../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { date } from "../../../../../common/utils/date";
import { Tooltip } from "../../../../../common/modules/materialUI";

type TProps = {
  boardRef: string;
};

const ReadOnlyLinkList = (props: TProps) => {
  const dispatch = useAppDispatch();
  const {
    isLoading: listIsLoading,
    dispatch: dispatchReadOnlyAccessListService,
  } = useReadOnlyAccessListService({
    accessTypeRef: props.boardRef,
    accessType: ReadOnlyAccessTypeEnum.BOARD_ACCESS,
  });
  const {
    isLoading: disableIsLoading,
    dispatch: dispatchDisableReadOnlyAccessService,
  } = useDisableReadOnlyAccessService();
  const list = useAppSelector((state) => state.readOnlyAccess.list);
  const isLoading = listIsLoading || disableIsLoading;

  useEffect(() => {
    dispatchReadOnlyAccessListService();
  }, []);

  const disableHandler = (id: number) => {
    dispatchDisableReadOnlyAccessService({ body: { id } })
      .then((response: IReadOnlyAccessToken) => {
        dispatch(
          readOnlyAccessActions.removeFromList({ id: response.id as number })
        );
        dispatch(
          systemNotificationActions.open({
            message: "You have successfully removed read-only link",
            variant: "success",
          })
        );
      })
      .catch(() => {
        dispatch(
          systemNotificationActions.open({
            message: "Failed to remove read-only link. Please try again.",
            variant: "error",
          })
        );
      });
  };

  const copyLinkHandler = (accessToken: string) => {
    navigator.clipboard.writeText(
      `${window.location.origin}/shared${window.location.pathname}?readOnlyToken=${accessToken}`
    );
    dispatch(
      systemNotificationActions.open({
        message: "Read-only URL is copied to clipboard.",
        variant: "success",
      })
    );
  };

  return (
    <Grid container>
      {listIsLoading && <ReadOnlyLinkListSkeleton />}
      {!listIsLoading && list.length === 0 && (
        <Grid item>
          <Typography variant="subtitle2">
            There are no active links.
          </Typography>
        </Grid>
      )}
      {!listIsLoading &&
        list.length > 0 &&
        list.map((link) => (
          <Grid item xs={12} key={link.id} sx={{ pb: 1 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: 2,
                borderRadius: 2,
                boxShadow: 1,
                backgroundColor: "background.paper",
              }}
            >
              <Box>
                <Typography variant="body1" fontWeight="bold">
                  {link.name}
                </Typography>
                <Typography variant="caption">
                  {link.hasExpired ? "Expired " : "Expires at "}
                  {date.format(link.expiresAt)}
                </Typography>
              </Box>

              <Box>
                <Tooltip title="Copy Board Read-only link">
                  <span>
                    <IconButton
                      disabled={isLoading || link.hasExpired}
                      onClick={() => copyLinkHandler(link.accessToken)}
                    >
                      <ContentCopy />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title={`Delete Board Read-only link`}>
                  <span>
                    <IconButton
                      disabled={isLoading}
                      onClick={() => disableHandler(link.id as number)}
                    >
                      <Delete />
                    </IconButton>
                  </span>
                </Tooltip>
              </Box>
            </Box>
          </Grid>
        ))}
    </Grid>
  );
};

export default ReadOnlyLinkList;
