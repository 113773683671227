import TaskInfoTabsContainer from "../../../../../../common/modules/taskView/containers/InfoTabs/InfoTabs";
import TaskViewDetails from "../TaskViewDetails/TaskViewDetails";
import CustomFieldSection from "../../../../../customField/containers/CustomFieldSection/CustomFiledSection";
import Splits from "../Splits/Splits";
import { useUpdateTaskService } from "../../../../services/useUpdateTaskService";
import { TTaskInfoTabsProps } from "./types/TTaskInfoTabsProps";
import { EntityTypeEnum } from "../../../../../../entities/enums/EntityTypeEnum";
import MainPanels from "../../../../../../common/modules/taskView/containers/InfoTabs/MainPanels";
import FinancialForecastDetails from "../FinancialForecastSection/FinancialForecastDetails";
import GitHubData from "../GitHubData/GitHubData";

export default function TaskInfoTabs({
  task,
  resource,
  isMobileView,
  isPageView,
}: TTaskInfoTabsProps) {
  const { dispatch: dispatchTaskPatchService } = useUpdateTaskService(
    task.ref!
  );

  return (
    <TaskInfoTabsContainer
      mainPanels={MainPanels({
        additionalPanels: [
          ...(isMobileView
            ? [
                {
                  tabIndex: "details-tab",
                  tabLabel: "Details",
                  tabComponent: (
                    <TaskViewDetails resource={resource} task={task} />
                  ),
                  tabComponentExtension: (
                    <CustomFieldSection
                      boardRef={task.boardRef}
                      dispatchUpdateService={dispatchTaskPatchService}
                      customFieldValues={task.customFieldValues}
                      isMobileView={isMobileView}
                    />
                  ),
                },
              ]
            : []),
          {
            tabIndex: "task-splits-tab",
            tabLabel: "Splits",
            tabComponent: (
              <Splits
                tabIndex="task-splits-tab"
                task={task}
                users={resource.users}
                isMobileView={isMobileView}
                isPageView={isPageView}
              />
            ),
          },
          ...(resource.isGithubIntegrated
            ? [
                {
                  tabIndex: "task-github-data-tab",
                  tabLabel: "GitHub",
                  tabComponent: (
                    <GitHubData
                      tabIndex="task-github-data-tab"
                      task={task}
                      isMobileView={isMobileView}
                      isPageView={isPageView}
                    />
                  ),
                },
              ]
            : []),
          ...(isMobileView && !!task.aggCosts?.length
            ? [
                {
                  tabIndex: "financial-forecast-tab",
                  tabLabel: "Financial Forecast",
                  tabComponent: (
                    <FinancialForecastDetails
                      aggCosts={task.aggCosts}
                      cost={task.segmentCount > 1 ? task.cost : undefined}
                    />
                  ),
                },
              ]
            : []),
        ],
        entityType: EntityTypeEnum.TASK,
        entityId: task.id,
        projectRef: task.projectRef,
        isMobileView,
      })}
      storageKey="unpinned-task-view-tabs"
      isMobileView={isMobileView}
    />
  );
}
