import { TColumnsProps } from "./types/TColumnsProps";
import { Typography, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid2";
import moment from "moment/moment";
import { FORMAT_YEAR_MONTH_DAY } from "../../../../../../common/utils/date";
import VerticalDivider from "../../../../../../common/components/Divider/VerticalDivider";

export default function DateColumn({
  initiative,
  color,
  fontSize,
}: TColumnsProps) {
  const theme = useTheme();
  const textColor = color || theme.palette.taskView.textMainColor;
  const textFontSize = fontSize || "13px";

  return (
    <Grid display="flex" alignItems="center" gap={1}>
      {initiative.startDate && initiative.endDate && (
        <>
          <Typography color={textColor} fontSize={textFontSize}>
            {moment(initiative.startDate).format(FORMAT_YEAR_MONTH_DAY)}
          </Typography>
          <VerticalDivider sx={{ margin: "8px 0" }} />
          <Typography color={textColor} fontSize={textFontSize}>
            {moment(initiative.endDate).format(FORMAT_YEAR_MONTH_DAY)}
          </Typography>
        </>
      )}
      {initiative.startDate && !initiative.endDate && (
        <Typography color={textColor} fontSize={textFontSize}>
          Starts at {moment(initiative.startDate).format(FORMAT_YEAR_MONTH_DAY)}
        </Typography>
      )}
      {initiative.endDate && !initiative.startDate && (
        <Typography color={textColor} fontSize={textFontSize}>
          Ends at {moment(initiative.endDate).format(FORMAT_YEAR_MONTH_DAY)}
        </Typography>
      )}
    </Grid>
  );
}
