import Dialog from "../../../../common/modules/materialUI/components/Dialog/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import ReportForm from "./ReportForm";
import DialogContent from "@mui/material/DialogContent";

type TProps = {
  openReportDialog: boolean;
  closeHandler: () => void;
};

const ReportDialog = ({ openReportDialog, closeHandler }: TProps) => {
  return (
    <Dialog
      open={openReportDialog}
      onClose={closeHandler}
      fullWidth
      maxWidth="xs"
      sx={{ zIndex: 1500 }}
    >
      <DialogTitle>Report Content</DialogTitle>
      <DialogContent>
        <ReportForm onCancel={closeHandler} />
      </DialogContent>
    </Dialog>
  );
};

export default ReportDialog;
