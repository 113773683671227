import { SvgIconProps, Typography, useTheme } from "@mui/material";
import { Tooltip } from "../../../../modules/materialUI";
import { CheckCircle, Timelapse } from "@mui/icons-material";
import { AggStatusEnum } from "../../../../../entities/columns/task/AggStatusEnum";
import DonutLargeIcon from "@mui/icons-material/DonutLarge";
import { TClassNameProp } from "../../../../types/TClassNameProp";
import { TStyleProp } from "../../../../types/TStyleProp";
import { string } from "../../../../utils/string";

type TProps = {
  statusType: AggStatusEnum;
  status: string;
  placement?: "left" | "right" | "top" | "bottom";
  hideTooltip?: boolean;
  hide?: boolean;
  iconProps?: SvgIconProps;
  iconSize?: number;
  iconColor?: string;
} & TClassNameProp &
  TStyleProp;

const StatusIcon = (props: TProps) => {
  const theme = useTheme();

  return (
    <>
      {!props.hide ? (
        <Tooltip
          when={!props.hideTooltip}
          placement={props.placement ?? "top"}
          title={
            <Typography variant="body2">
              {string.ucwords(props.status)}
            </Typography>
          }
          arrow
        >
          <div className={props.className} style={props.style}>
            {props.statusType === AggStatusEnum.IN_PROGRESS && (
              <Timelapse
                fontSize="small"
                {...props.iconProps}
                style={{
                  color: props.iconColor ?? theme.palette.info.main,
                  fontSize: props.iconSize ? props.iconSize + "px" : "inherit",
                }}
              />
            )}
            {props.statusType === AggStatusEnum.DONE && (
              <CheckCircle
                fontSize="small"
                {...props.iconProps}
                style={{
                  color: props.iconColor ?? theme.palette.successLight.main,
                  fontSize: props.iconSize ? props.iconSize + "px" : "inherit",
                }}
              />
            )}
            {props.statusType === AggStatusEnum.TO_DO && (
              <DonutLargeIcon
                fontSize="small"
                {...props.iconProps}
                style={{
                  color: props.iconColor ?? theme.palette.action.active,
                  fontSize: props.iconSize ? props.iconSize + "px" : "inherit",
                }}
              />
            )}
          </div>
        </Tooltip>
      ) : null}
    </>
  );
};

export default StatusIcon;
