import { Button } from "../../../../common/modules/materialUI";
import { Add } from "@mui/icons-material";

export default function AddNew({
  title,
  onClick,
}: {
  title: string;
  onClick?: () => void;
}) {
  return (
    <Button startIcon={<Add />} onClick={onClick}>
      {title}
    </Button>
  );
}
