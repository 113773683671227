import { useTheme } from "@mui/material";
import Grid from "@mui/material/Grid2";
import OrgSignUpForm from "./OrgSignUpForm";
import { TVerificationProps } from "../types/TVerificationProps";
import Title from "../../../../../common/components/Title/Title";
import OrSection from "../../../../user/containers/Auth/components/OrSection/OrSection";
import MicrosoftAuthButton from "../../../../integrations/modules/MSAuth/components/MicrosoftAuthButton/MicrosoftAuthButton";
import { useSearchParams } from "react-router-dom";
import { env } from "../../../../../common/utils/env";

const OrgSignUp = (props: TVerificationProps) => {
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const subscriptionPlan = searchParams.get("subscriptionPlan");

  const nextHandler = (uuid: string) => {
    props.onNextHandler && props.onNextHandler(uuid);
  };

  return (
    <>
      <Grid>
        <Title>Register your organization to continue.</Title>
        <OrgSignUpForm onNextHandler={nextHandler} />
      </Grid>
      <Grid>
        <OrSection />
      </Grid>
      <Grid
        style={{
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(2),
        }}
      >
        <MicrosoftAuthButton
          isLoading={false}
          login={false}
          onClick={() =>
            window.location.replace(
              `${env.msOrgRegistrationUrl}?subscriptionPlan=${subscriptionPlan}`
            )
          }
        />
      </Grid>
    </>
  );
};

export default OrgSignUp;
