import { menuItem } from "../utils/menuItem";
import { Box, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import { PeopleAlt } from "@mui/icons-material";
import useHeaderHeight from "../../Layout/App/Header/hooks/useHeaderHeight";
import { TMenuItemProps } from "../types/TMenuItemProps";

const Team = () => {
  const { isMobileView } = useHeaderHeight();

  return isMobileView
    ? menuItem.make({
        component: (
          <NavLink to={"/team"} style={{ width: "100%" }}>
            <Box display="flex" alignItems="center" gap="8px">
              <PeopleAlt sx={{ color: "action.active" }} />
              <Typography color="text.primary">Team</Typography>
            </Box>
          </NavLink>
        ),
        hasDivider: false,
      })
    : ({} as TMenuItemProps);
};

export default Team;
