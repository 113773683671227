import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type TViewPickerState = {
  displayNote: boolean;
};

const initialState: TViewPickerState = {
  displayNote: false,
};

const slice = createSlice({
  name: "viewPicker",
  initialState,
  reducers: {
    setDisplayNote: (state, action: PayloadAction<boolean>) => {
      state.displayNote = action.payload;
    },
  },
});

export const viewPickerActions = slice.actions;

export const viewPickerReducer = slice.reducer;
