import { TOccupiedSpaceProps } from "./types/TOccupiedSpaceProps";
import OccupiedSpaceSmall from "./OccupiedSpaceSmall";
import OccupiedSpaceBig from "./OccupiedSpaceBig";
import { pointConverter } from "../../utils/pointConverter";
import { CSSProperties } from "react";
import { useTheme } from "@mui/material";

export default function OccupiedSpace(props: TOccupiedSpaceProps) {
  const theme = useTheme();
  const positionStyle: CSSProperties =
    props.positionX || props.positionY
      ? {
          position: "absolute",
          top: props.positionY,
          left: props.positionX,
          borderRadius: 2,
          border: "1px solid" + theme.palette.divider,
        }
      : {};
  const time: string = pointConverter.WorkingHoursOrDayToPoints(props.tsPoints);

  return props.size > pointConverter.workingHoursToPixels(3) ? (
    <OccupiedSpaceBig style={positionStyle} {...props} time={time} />
  ) : (
    <OccupiedSpaceSmall style={positionStyle} {...props} time={time} />
  );
}
