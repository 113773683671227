import AvatarIcon from "../AvatarIcon/AvatarIcon";
import classes from "./AvatarGroup.module.css";
import { AvatarGroupProps, Box } from "@mui/material";
import { IUser } from "../../../../entities/IUser";
import AvatarMenu from "../../../boardView/containers/Filter/Avatar/menu/AvatarMenu";
import useSelectedUsers from "../../../boardView/containers/Filter/Avatar/hooks/useSelectedUsers";
import { useAppSelector } from "../../../../common/hooks/redux";
import { StatusEnum } from "../../../../entities/columns/user/StatusEnum";

type TProps = {
  users: IUser[];
  count?: number | null;
  avatarSize?: number;
  tooltipCallback?: (user: IUser) => string;
  isColorErrorCallback?: (user: IUser) => boolean;
  isColorWarningCallback?: (user: IUser) => boolean;
  isOpenCloseMenu?: boolean;
  setOpenCloseMenu?: (openClose: boolean) => void;
  openMenu?: boolean;
  options?: {
    hovered?: boolean;
    checked?: boolean;
    clicked?: boolean;
  };
  position?: "left" | "right";
  borderColor?: string;
  fontWeight?: number;
  fontSizeRatio?: number;
  textColor?: string;
} & AvatarGroupProps;

const AvatarGroup = ({
  users,
  count,
  avatarSize,
  tooltipCallback,
  isColorErrorCallback,
  isColorWarningCallback,
  isOpenCloseMenu,
  setOpenCloseMenu,
  openMenu = false,
  options,
  position = "right",
  borderColor,
  fontWeight,
  fontSizeRatio,
  textColor,
}: TProps) => {
  const numberToDisplay = count !== undefined ? count ?? 0 : 3;
  const selectedUsersId = useAppSelector((state) => state.user.selectedIds);
  const { userFilterHandler } = useSelectedUsers();

  const isChecked = (id: number): boolean => {
    return options?.checked
      ? selectedUsersId.some((userId) => userId === id)
      : false;
  };

  const avatars = users.slice(0, numberToDisplay).map((user, index) => (
    <AvatarIcon
      key={user.id}
      sx={{
        padding: 0,
        mr: index === users.length - 1 ? 0 : "-9px",
        transform: `translateY(${isChecked(user.id) ? "-4px" : "0px"})`,
        borderColor: borderColor ?? undefined,
      }}
      avatarStyles={{
        color: textColor ?? "_components.avatar.avatarText",
        fontWeight: fontWeight ?? 400,
        fontSize: avatarSize
          ? Math.floor(avatarSize / (fontSizeRatio ?? 2.2)) + "px"
          : "11px",
      }}
      className={classes.icon}
      userName={user.name}
      tooltip={tooltipCallback?.(user) ?? user.name}
      colorError={!!isColorErrorCallback?.(user)}
      colorWarning={!!isColorWarningCallback?.(user)}
      colorInactive={
        !!(user.inactiveDate && user.status === StatusEnum.INACTIVE)
      }
      position={position}
      size={avatarSize ?? 22}
      fontSize={10}
      isBold
      isHovered={options?.hovered}
      isUnassigned={user.id === 0}
      clickHandler={() => {
        options?.clicked && userFilterHandler(user.id);
      }}
      hasBorder
    />
  ));

  return (
    <Box display="flex" alignItems="center">
      {avatars}
      {users.length > numberToDisplay && (
        <AvatarMenu
          users={users}
          count={numberToDisplay}
          buttonCountSize={avatarSize}
          isOpenCloseMenu={isOpenCloseMenu}
          setOpenCloseMenu={setOpenCloseMenu}
          openMenu={openMenu}
        />
      )}
    </Box>
  );
};

export default AvatarGroup;
