import { Typography, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid2";
import moment from "moment";
import { TGuesstimationItemViewDetailsProps } from "./types/TGuesstimationItemViewDetailsProps";
import StatusIcon from "../../../../../../common/components/Task/components/Status/StatusIcon";
import { date, FORMAT_TWO } from "../../../../../../common/utils/date";
import AvatarGroup from "../../../../../user/containers/AvatarGroup/AvatarGroup";
import DetailsLabel from "../../../../../task/modules/view/containers/TaskViewDetails/DetailsLabel";
import EstimationForm from "../Estimation/EstimationForm";
import StartDateForm from "../StartDate/StartDateForm";
import DueDateForm from "../DueDate/DueDateForm";
import AddAssigneesButton from "../AddAssignees/AddAssigneesButton";
import EstimatedResourcesForm from "../EstimatedResources/EstimatedResourcesForm";
import useDynamicsAvatars from "../../../../../user/containers/AvatarGroup/hooks/useDynamicsAvatars";
import RepForm from "../Rep/RepForm";
import { string } from "../../../../../../common/utils/string";

const GuesstimationItemViewDetails = (
  props: TGuesstimationItemViewDetailsProps
) => {
  const { resource } = props;

  const theme = useTheme();

  const createdAt = moment(resource.guesstimationItem.createdAt);
  const updatedAt = moment(resource.guesstimationItem.updatedAt);

  const { avatarCount, containerRef } = useDynamicsAvatars({ maxCount: 6 });

  return (
    <Grid container spacing="20px" alignItems="center">
      <Grid size={4}>
        <DetailsLabel>Board</DetailsLabel>
      </Grid>
      <Grid size={8}>
        <Typography
          color={theme.palette.taskView.textSecondaryColor}
          fontSize="15px"
          fontWeight={500}
        >
          {resource.guesstimationItem.boardName}
        </Typography>
      </Grid>

      <Grid size={4}>
        <DetailsLabel>Assigned By</DetailsLabel>
      </Grid>
      <Grid size={8}>
        <Typography
          color={theme.palette.taskView.textSecondaryColor}
          fontSize="15px"
          fontWeight={500}
        >
          {resource.createdBy?.name}
        </Typography>
      </Grid>

      <Grid size={4}>
        <DetailsLabel>Assigned To</DetailsLabel>
      </Grid>

      <Grid ref={containerRef} size={8} display="flex" alignItems="center">
        <AvatarGroup
          users={resource.assignees}
          position={"left"}
          count={avatarCount}
        />
        <AddAssigneesButton guesstimationItem={resource.guesstimationItem} />
      </Grid>

      <EstimationForm guesstimationItem={resource.guesstimationItem} />

      <Grid size={4}>
        <DetailsLabel>State</DetailsLabel>
      </Grid>

      <Grid container size={8} alignItems="center" gap="8px">
        <StatusIcon
          placement="left"
          status={resource.guesstimationItem.status}
          statusType={resource.guesstimationItem.statusType}
          style={{ display: "flex" }}
        />
        <Typography
          color={theme.palette.taskView.textSecondaryColor}
          fontSize="15px"
          fontWeight={500}
          sx={{
            "::first-letter": {
              textTransform: "uppercase",
            },
          }}
        >
          {string.ucwords(resource.guesstimationItem.status)}
        </Typography>
      </Grid>

      <StartDateForm guesstimationItem={resource.guesstimationItem} />

      <DueDateForm guesstimationItem={resource.guesstimationItem} />

      <RepForm
        guesstimationItem={resource.guesstimationItem}
        representative={resource.representative}
      />

      {/*<Grid size={4}>*/}
      {/*  <DetailsLabel>Collisions</DetailsLabel>*/}
      {/*</Grid>*/}
      {/*<Grid size={8}>*/}
      {/*  {!!resource.guesstimationItem.collisions?.length*/}
      {/*    ? resource.guesstimationItem.collisions.map((collision) => {*/}
      {/*        const user = resource.assignees.find(*/}
      {/*          (assignee) => assignee.id === collision.userId*/}
      {/*        );*/}

      {/*        return (*/}
      {/*          <Box*/}
      {/*            key={`${collision.userId}-${collision.taskId}`}*/}
      {/*            color={theme.palette.taskView.textSecondaryColor}*/}
      {/*            fontSize="15px"*/}
      {/*            fontWeight={500}*/}
      {/*          >*/}
      {/*            Task id: {collision.taskId}, user:{" "}*/}
      {/*            <AvatarIcon*/}
      {/*              userName={user?.name}*/}
      {/*              tooltip={`${user?.name} (${collision.userRole})`}*/}
      {/*              size={17}*/}
      {/*            />*/}
      {/*          </Box>*/}
      {/*        );*/}
      {/*      })*/}
      {/*    : "None"}*/}
      {/*</Grid>*/}

      {/*<Grid size={4}>*/}
      {/*  <DetailsLabel>Cls Overview</DetailsLabel>*/}
      {/*</Grid>*/}
      {/*<Grid size={8}>*/}
      {/*  <Box*/}
      {/*    color={theme.palette.taskView.textSecondaryColor}*/}
      {/*    fontSize="15px"*/}
      {/*    fontWeight={500}*/}
      {/*  >*/}
      {/*    {!!resource.guesstimationItem.collisionsOverview?.length*/}
      {/*      ? resource.guesstimationItem.collisionsOverview.map(*/}
      {/*          (collisionOverview) => {*/}
      {/*            return (*/}
      {/*              <div key={collisionOverview.userRole}>*/}
      {/*                User Role: {collisionOverview.userRole}, Count:{" "}*/}
      {/*                {collisionOverview.count}*/}
      {/*              </div>*/}
      {/*            );*/}
      {/*          }*/}
      {/*        )*/}
      {/*      : "None"}*/}
      {/*  </Box>*/}
      {/*</Grid>*/}

      <EstimatedResourcesForm guesstimationItem={resource.guesstimationItem} />

      <Grid container mt="16px" gap="4px">
        <Grid size={12}>
          <Typography color={theme.palette.taskView.labelColor} fontSize="13px">
            Created {date.format(createdAt, FORMAT_TWO)}
          </Typography>
        </Grid>

        <Grid size={12}>
          <Typography color={theme.palette.taskView.labelColor} fontSize="13px">
            Updated {date.format(updatedAt, FORMAT_TWO)}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GuesstimationItemViewDetails;
