import { TTitleFormProps } from "./types/TTitleFormProps";
import { THttpClientError } from "../../../../../../common/modules/httpClient/types/THttpClientError";
import { EventEnum } from "../../../../../../common/modules/eventProvider/enums/EventEnum";
import { useDispatchEvent } from "../../../../../../common/modules/eventProvider";
import { systemNotificationActions } from "../../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { useAppDispatch } from "../../../../../../common/hooks/redux";
import { TEditableTitleFormProps } from "../../../../../../common/modules/taskView/containers/EditableTitleForm/types/TEditableTitleFormProps";
import { useUpdateGuesstimationItemService } from "../../services/useUpdateGuesstimationItemService";
import { TGuesstimationItemUpdatedEvent } from "../types/TGuesstimationItemUpdatedEvent";
import EditableTitleForm from "../../../../../../common/modules/taskView/containers/EditableTitleForm/EditableTitleForm";
import { IGuesstimationItemDetailsResource } from "../../interfaces/IGuesstimationItemDetailsResource";

const TitleForm = (props: TTitleFormProps) => {
  const dispatchEvent = useDispatchEvent();
  const dispatch = useAppDispatch();
  const { dispatch: dispatchUpdateGuesstimationItemService } =
    useUpdateGuesstimationItemService(props.guesstimationItem.id);

  const changeHandler: TEditableTitleFormProps["onChange"] = (
    name,
    onSuccess,
    onError
  ) => {
    dispatchUpdateGuesstimationItemService({
      body: {
        name,
      },
    })
      .then((payload: IGuesstimationItemDetailsResource) => {
        dispatchEvent(EventEnum.ON_GUESSTIMATION_ITEM_UPDATED, {
          initGuesstimationItem: props.guesstimationItem,
          guesstimationItem: payload.guesstimationItem,
        } as TGuesstimationItemUpdatedEvent);

        onSuccess();
      })
      .catch((error: THttpClientError) => {
        onError(error);

        if (error.status === 403) {
          dispatch(
            systemNotificationActions.open({
              message: error.data.message,
              variant: "warning",
            })
          );
          return;
        }

        error.status === 422 &&
          dispatch(
            systemNotificationActions.open({
              message: (error.data.errors as { name: string[] }).name[0],
              variant: "error",
            })
          );
        error.status !== 422 &&
          dispatch(
            systemNotificationActions.open({
              message:
                "There was a problem with updating the task name. Please try again.",
              variant: "error",
            })
          );
      });
  };

  return (
    <EditableTitleForm
      label="Item"
      name={props.guesstimationItem.name}
      onChange={changeHandler}
    />
  );
};

export default TitleForm;
