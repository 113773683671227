import { useEffect, useState } from "react";
import { httpClient } from "../httpClient";
import { TUseHttpClientCore } from "./types/TUseHttpClientCore";
import { TUseHttpClientResponse } from "./types/TUseHttpClientResponse";
import { TUseHttpClientCoreDispatch } from "./types/TUseHttpClientCoreDispatch";
import { THttpClientRequestProps } from "../types/THttpClientRequestProps";
import { useAppDispatch } from "../../../hooks/redux";
import { accessControlActions } from "../../accessControl/slices/accessControlSlice";
import { useSignOutHandler } from "../../../../modules/user/hooks/useSignOutHandler";
import { systemNotificationActions } from "../../systemNotification/slices/systemNotificationSlice";
import { useNavigate } from "react-router-dom";
import { THttpClientPayload } from "../types/THttpClientPayload";
import { IStripeError } from "../../../../modules/stripe/interfaces/IStripeError";
import { StripeInitEnum } from "../../../../modules/stripe/enums/StripeInitEnum";
import { READ_ONLY_TOKEN_EXPIRED_ROUTE } from "../../../containers/Layout/App/Routes/ReadOnly/AppRoutes";

export const useHttpClientCore: TUseHttpClientCore = (props) => {
  const { autoDispatch, updateState, url, method, query, urlPath, body } =
    props;

  const navigate = useNavigate();
  const signOutHandler = useSignOutHandler({ rememberState: true });
  const appDispatch = useAppDispatch();
  const [response, setResponse] = useState<TUseHttpClientResponse>({
    data: null,
    error: null,
    isLoading: autoDispatch,
  });

  const accessControlHandler = (payload: THttpClientPayload) => {
    if (payload.ac) {
      appDispatch(accessControlActions.setAbilities(payload.ac.abilities));
      appDispatch(
        accessControlActions.setAccessibleRoles(payload.ac.accessibleRoles)
      );
      appDispatch(accessControlActions.setPlan(payload.ac.plan));
    }
  };

  // Function query dispatcher
  const dispatch: TUseHttpClientCoreDispatch = (dispatchProps) => {
    updateState &&
      !response.isLoading &&
      setResponse({
        data: null,
        error: null,
        isLoading: true,
      });

    return new Promise<any>((resolve, reject) => {
      const requestProps: THttpClientRequestProps = {
        query:
          props.query || dispatchProps?.query
            ? { ...(props.query ?? {}), ...(dispatchProps?.query ?? {}) }
            : undefined,
        urlPath:
          props.urlPath || dispatchProps?.urlPath
            ? `${props.urlPath ?? ""}${dispatchProps?.urlPath ?? ""}`
            : undefined,
        body:
          props.body || dispatchProps?.body
            ? { ...(props.body ?? {}), ...(dispatchProps?.body ?? {}) }
            : undefined,
      };

      httpClient({ ...props, ...requestProps })
        .then((payload) => {
          const isSharedSession =
            window.location.pathname.startsWith("/shared");
          // This error can happen in case of Unauthorized
          if (payload.error?.status === 401) {
            // In this case we don't want to sign user out since the 401 related to
            // readonly token expiration not the real user access token
            if (isSharedSession) {
              navigate(READ_ONLY_TOKEN_EXPIRED_ROUTE);
              return;
            }

            // Otherwise we signout and stop processing
            signOutHandler();
            return;
          }

          if (payload.error?.status === 402) {
            const errors = payload.error.data.errors as IStripeError;
            // Requested to handle globally
            // Currently from auth page we are handling the errors there so no global handling
            if (errors.global) {
              if (errors.type !== StripeInitEnum.SUBSCRIPTION_EXPIRED) {
                signOutHandler();
                return;
              }

              accessControlHandler(payload);
              navigate("/subscriptionExpired");
              return;
            }
          }

          if (payload.error?.status === 429) {
            appDispatch(
              systemNotificationActions.open({
                variant: "error",
                message:
                  "Too many requests, please try again in a bit. If you think this is a mistake, please contact our support.",
              })
            );
            return;
          }

          if (payload.error?.status === 404 && isSharedSession) {
            reject(payload.error);
            updateState &&
              setResponse({
                data: payload.data,
                error: payload.error,
                isLoading: false,
              });
            setTimeout(
              () =>
                appDispatch(
                  systemNotificationActions.open({
                    variant: "warning",
                    message: "You cannot perform this action in view mode.",
                  })
                ),
              100
            );
            return;
          }

          // Handle dispatch promise
          if (payload.error) {
            reject(payload.error);
          } else {
            resolve(payload.data);
          }

          accessControlHandler(payload);

          updateState &&
            setResponse({
              data: payload.data,
              error: payload.error,
              isLoading: false,
            });
        })
        .catch((payload) => {
          // Reject promise with fatal error
          reject(payload);

          updateState &&
            setResponse({
              data: null,
              error: null,
              isLoading: false,
            });
          console.error(payload);
        });
    });
  };

  useEffect(() => {
    autoDispatch && dispatch({ query, urlPath, body });
    // eslint-disable-next-line
  }, [url, method, query?.toString(), urlPath, body?.toString()]);

  return { ...response, dispatch };
};
