import { ContentCut } from "@mui/icons-material";
import { Typography } from "@mui/material";
import QuickActionButton from "../../../../../../../common/modules/taskView/containers/QuickActions/QuickActionButton";
import { useAppDispatch } from "../../../../../../../common/hooks/redux";
import { guesstimationItemViewActions } from "../../../slices/viewSlice";
import { IGuesstimationItem } from "../../../../../../../entities/IGuesstimationItem";

type TProps = {
  guesstimationItem: IGuesstimationItem;
};

const CreatePhase = (props: TProps) => {
  const dispatch = useAppDispatch();

  return (
    <>
      <QuickActionButton
        onClick={() =>
          dispatch(
            guesstimationItemViewActions.openPhaseDialog({
              guesstimationItem: props.guesstimationItem,
            })
          )
        }
      >
        <ContentCut fontSize="small" />
        <Typography fontSize="13px" fontWeight={500}>
          Phase Item
        </Typography>
      </QuickActionButton>
    </>
  );
};

export default CreatePhase;
