import { TUserProfileNavigationContentProps } from "./types/TUserProfileNavigationContentProps";
import UserProfileSection from "./UserProfileSection";
import NavigationTitle from "../../../../../../components/navigationTitle/NavigationTitle";
import { useAppSelector } from "../../../../../../hooks/redux";
import List from "../../common/List/List";

const UserProfileNavigationContent = ({
  navigationRequestedHandler,
}: TUserProfileNavigationContentProps) => {
  const userJwt = useAppSelector((state) => state.user.userInfo);

  return (
    <>
      {!userJwt?.name ? (
        <List isDropdownList title="Loading..." loading={true} />
      ) : (
        <>
          <NavigationTitle title={"My Account"} />
          <UserProfileSection onClick={navigationRequestedHandler} />
        </>
      )}
    </>
  );
};

export default UserProfileNavigationContent;
