import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TBoardViewState } from "./types/TBoardViewState";
import { SideViewSectionEnum } from "../enums/SideViewSectionEnum";

export const STORAGE_GROUP_BY_SPRINT_KEY = "groupBySprint";
export const STORAGE_SPLITS_KEY = "displaySplits";

const groupBySprintStorage = localStorage.getItem(STORAGE_GROUP_BY_SPRINT_KEY);
const displaySplitsStorage = localStorage.getItem(STORAGE_SPLITS_KEY);

const initialState: TBoardViewState = {
  listView: {
    groupBySprint: !groupBySprintStorage
      ? false
      : groupBySprintStorage === "true",
    displaySplits: !displaySplitsStorage
      ? false
      : displaySplitsStorage === "true",
  },
  backlogView: {
    yearMonthGroup: {
      resource: null,
      resourceUpdatedCount: 0,
      expandedGroups: {},
    },
  },
};

const slice = createSlice({
  name: "boardView",
  initialState,
  reducers: {
    setGroupBySprint: (state, action: PayloadAction<boolean>) => {
      state.listView.groupBySprint = action.payload;
    },
    displaySideView: (
      state,
      action: PayloadAction<SideViewSectionEnum | undefined>
    ) => {
      state.displaySideView = action.payload;
    },
    displaySplits: (state, action: PayloadAction<boolean>) => {
      state.listView.displaySplits = action.payload;
    },

    setBacklogYearMonthGroupResource: (
      state,
      action: PayloadAction<
        TBoardViewState["backlogView"]["yearMonthGroup"]["resource"]
      >
    ) => {
      state.backlogView.yearMonthGroup.resource = action.payload;
      state.backlogView.yearMonthGroup.resourceUpdatedCount++;

      // Making the first group to be expanded by default on the first group fetch
      if (
        state.backlogView.yearMonthGroup.resourceUpdatedCount === 1 &&
        action.payload?.length
      ) {
        state.backlogView.yearMonthGroup.expandedGroups[
          action.payload[0].yearMonthGroup
        ] = true;
      }
    },
    setBacklogYearMonthGroupExpanded: (
      state,
      action: PayloadAction<{ groupKey: string; isExpanded: boolean }>
    ) => {
      state.backlogView.yearMonthGroup.expandedGroups[action.payload.groupKey] =
        action.payload.isExpanded;
    },
    resetBacklogView: (state) => {
      state.backlogView = initialState.backlogView;
    },
  },
});

export const boardViewReducer = slice.reducer;

export const boardViewActions = slice.actions;
