import CalendarViewWeekIcon from "@mui/icons-material/CalendarViewWeek";
import { Box, Typography } from "@mui/material";
import { boardViewActions } from "../../slices/boardViewSlice";
import { SideViewSectionEnum } from "../../enums/SideViewSectionEnum";
import { useAppDispatch } from "../../../../common/hooks/redux";
import { useAccessControl } from "../../../../common/modules/accessControl/hooks/useAccessControl";
import { AbilityEnum } from "../../../../common/modules/accessControl/enums/AbilityEnum";
import { menuItem } from "../../../../common/containers/Menu/utils/menuItem";
import { useIsFeatureAllowed } from "../../../../common/modules/accessControl/hooks/useIsFeatureAllowed";
import { FeatureEnum } from "../../../../common/modules/accessControl/enums/FeatureEnum";
import { featureUtil } from "../../../../common/modules/accessControl/utils/featureUtil";
import { propsForMenuItems } from "../../../../common/containers/Menu/common/propsForMenuItems";

const FinancialForecastMenuItem = () => {
  const { can } = useAccessControl();
  const dispatch = useAppDispatch();
  const isFinancialForecastAllowed = useIsFeatureAllowed(
    FeatureEnum.FINANCIAL_FORECAST
  );

  const clickHandler = () =>
    dispatch(boardViewActions.displaySideView(SideViewSectionEnum.BUDGETARY));

  return menuItem.make({
    component: (
      <Box
        display="flex"
        alignItems="center"
        gap={1}
        color="pickerPallet.secondary"
      >
        <CalendarViewWeekIcon
          sx={{
            color: isFinancialForecastAllowed
              ? "action.active"
              : "text.secondary",
          }}
        />
        <Typography
          variant="subtitle2"
          fontWeight="400"
          color={isFinancialForecastAllowed ? "text.primary" : "text.secondary"}
        >
          Financial Forecast
        </Typography>
      </Box>
    ),
    handler: clickHandler,
    when: can(AbilityEnum.financing),
    disabled: !isFinancialForecastAllowed,
    disabledTooltipMessage: !isFinancialForecastAllowed
      ? featureUtil.notAvailableText
      : undefined,
    menuItemsProps: propsForMenuItems,
    closeOnClick: true,
  });
};

export default FinancialForecastMenuItem;
