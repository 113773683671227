import { useAppDispatch } from "../../../../../../common/hooks/redux";
import useForm from "../../../../../../common/hooks/useForm/useForm";
import { usePhaseGuesstimationItemService } from "../../services/usePhaseGuesstimationItemService";
import { guesstimationItemViewActions } from "../../slices/viewSlice";
import { IGuesstimationItem } from "../../../../../../entities/IGuesstimationItem";
import { systemNotificationActions } from "../../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { THttpClientError } from "../../../../../../common/modules/httpClient/types/THttpClientError";
import Form from "../../../../../../common/components/Form/Form";
import Button from "../../../../../../common/modules/materialUI/components/Button/Button";
import { EventEnum } from "../../../../../../common/modules/eventProvider/enums/EventEnum";
import { TGuesstimationItemCreatedEvent } from "../../../create/containers/CreateItem/types/TGuesstimationItemCreatedEvent";
import { useDispatchEvent } from "../../../../../../common/modules/eventProvider";
import { TGuesstimationItemUpdatedEvent } from "../types/TGuesstimationItemUpdatedEvent";
import { IPhaseResource } from "../../interfaces/IPhaseResource";
import { DatePicker } from "../../../../../../common/modules/materialUI";
import { Moment } from "moment/moment";

type TPhaseFormAttributes = {
  startDate: Moment;
  endDate: Moment;
};

type TProps = {
  guesstimationItem: IGuesstimationItem;
  onCancel: () => void;
};

const PhaseForm = (props: TProps) => {
  const dispatch = useAppDispatch();
  const dispatchEvent = useDispatchEvent();
  const form = useForm<TPhaseFormAttributes>();
  const { isLoading, dispatch: dispatchPhaseGuesstimationItemService } =
    usePhaseGuesstimationItemService();

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();

    dispatchPhaseGuesstimationItemService({
      body: {
        startDate: form
          .data!.startDate?.startOf("day")
          .format("YYYY-MM-DD")
          .toString(),
        endDate:
          form.data!.endDate?.startOf("day").format("YYYY-MM-DD").toString() ??
          null,
        id: props.guesstimationItem.id,
      },
    })
      .then((response: IPhaseResource) => {
        dispatchEvent(EventEnum.ON_GUESSTIMATION_ITEM_CREATED, {
          item: response.guesstimationItemCreated,
        } as TGuesstimationItemCreatedEvent);

        dispatchEvent(EventEnum.ON_GUESSTIMATION_ITEM_UPDATED, {
          initGuesstimationItem: props.guesstimationItem,
          guesstimationItem: response.guesstimationItemUpdated,
        } as TGuesstimationItemUpdatedEvent);

        dispatch(guesstimationItemViewActions.closePhaseDialog());

        dispatch(
          systemNotificationActions.open({
            message: `Item phase successfully created.`,
            variant: "success",
          })
        );
      })
      .catch((error: THttpClientError) => {
        error.status === 422 &&
          (error.data.errors as { phaseError: string }).phaseError &&
          dispatch(
            systemNotificationActions.open({
              message: (error.data.errors as { phaseError: string }).phaseError,
              variant: "error",
            })
          );
        form.errorHandler(error);
      });
  };

  return (
    <Form
      onSubmit={submitHandler}
      actionElement={
        <>
          <Button
            onClick={props.onCancel}
            disabled={isLoading}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            disabled={isLoading || !form.isDirty()}
            variant="contained"
            type="submit"
            loading={isLoading}
          >
            Phase
          </Button>
        </>
      }
    >
      <DatePicker
        form={form}
        name="startDate"
        label="Start Date"
        allowClear
        showRequired
      />
      <DatePicker
        form={form}
        name="endDate"
        label="End Date"
        allowClear
        textFieldProps={{
          helperText:
            "Select an end date to create a phase for the specified interval, splitting the item into two tasks with the selected range removed. If no end date is selected, the item will still split into two tasks, but without removing the selected date range.",
        }}
      />
    </Form>
  );
};

export default PhaseForm;
