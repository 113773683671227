import { TTaskCardProps } from "./types/TTaskCardProps";
import {
  Box,
  CardActions,
  CardContent,
  Tooltip as MuiTooltip,
  Typography,
  useTheme,
} from "@mui/material";
import StatusIcon from "../Status/StatusIcon";
import { useTaskViewHandler } from "../../../../../modules/task/modules/view/hooks/useTaskViewHandler";
import { NavLink } from "react-router-dom";
import { taskUtil } from "../../../../utils/taskUtil";
import TaskMenu from "../Menu/TaskMenu";
import useGetTaskType from "../../../../../modules/taskType/hooks/useGetTaskType";
import { ForwardedRef, forwardRef, useState } from "react";
import useGetTaskPriority from "../../../../../modules/taskPriority/hooks/useGetTaskPriority";
import { link } from "../../../../utils/link";
import AvatarGroup from "../../../../../modules/user/containers/AvatarGroup/AvatarGroup";
import useSprintHelper from "../../../../../modules/boardView/hooks/sprintHelper/useSprintHelper";
import { BoardViewEnum } from "../../../../../modules/boardView/enums/BoardViewEnum";
import { InternalTypeEnum } from "../../../../../entities/columns/task/InternalTypeEnum";
import IconImage from "../../../Icon/IconImage/IconImage";
import { AggStatusEnum } from "../../../../../entities/columns/task/AggStatusEnum";
import TaskSplitLine from "../TaskHorizontal/TaskSplitLine";
import RiskStatusIcon from "../RiskStatus/RiskStatusIcon";
import SegmentCount from "../../../SegmentCount/SegmentCount";

const TaskCard = forwardRef(
  (
    {
      task,
      style,
      isSegmentView,
      users,
      mouseLeaveHandle,
      mouseEnterHandle,
      isHovered,
      statusName,
    }: TTaskCardProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const theme = useTheme();
    const [isOpenTaskMenu, setOpenCloseTaskMenu] = useState(false);

    const { openTaskViewHandler } = useTaskViewHandler({
      taskRef: task.ref,
      segmentNumber: task.number,
    });
    const sprintHelper = useSprintHelper({ view: BoardViewEnum.KANBAN_VIEW });
    const riskStatus = isSegmentView ? task.riskStatus : task.aggRiskStatus;
    const taskUrl = taskUtil.urlByRef(
      task.ref,
      isSegmentView ? task.number : undefined
    );
    const taskType = useGetTaskType({
      taskTypeId: task.taskTypeId,
    });
    const taskPriority = useGetTaskPriority({
      taskPriorityId: task.taskPriorityId,
    });
    const taskUsers =
      users?.filter((user) =>
        isSegmentView
          ? user.id === task.assignedTo
          : task.assignees.includes(user.id)
      ) ?? [];
    const showLeftBorder = !isSegmentView && task.segmentCountInGroup > 1;

    return (
      <Box
        onTouchStart={mouseEnterHandle}
        onTouchEnd={mouseLeaveHandle}
        onMouseEnter={mouseEnterHandle}
        onMouseLeave={mouseLeaveHandle}
        ref={ref}
        sx={{
          backgroundColor: "background.default",
          border: "1px solid " + theme.palette.divider,
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "2px",
          borderRadius: "12px",
          position: "relative",
          ...style,
        }}
      >
        <TaskSplitLine
          left={showLeftBorder}
          leftSx={{
            position: "absolute",
            top: "0",
            left: "0.2px",
            width: "15px",
            overflow: "hidden",
          }}
          imgHeight={"108px"}
        />
        <Box
          onClick={openTaskViewHandler}
          sx={{
            backgroundColor: "backgroundElevation.backgroundPaperElevation_0",
            color: "brightCardBackground.main",
            width: "100%",
            borderRadius: "10px",
            zIndex: 1,
            maxHeight: "104px",
            height: "104px",
          }}
        >
          <CardActions
            disableSpacing
            sx={{
              justifyContent: "space-between",
              display: "flex",
              paddingBottom: 0,
            }}
          >
            <Box display={"flex"} alignItems={"center"} gap={"4px"}>
              <NavLink
                to={taskUrl}
                onClick={link.anchorClickHandler}
                style={{
                  color: theme.palette.brightCardBackground.main,
                  gap: "4px",
                }}
              >
                {task.internalType === InternalTypeEnum.SPIKE && (
                  <MuiTooltip title={InternalTypeEnum.SPIKE}>
                    <Box display={"inline-flex"} alignItems={"center"}>
                      <IconImage
                        imgDark={"/app-v2/assets/spikeForKanbanLight.svg"}
                        img={"/app-v2/assets/spikeForKanbanDark.svg"}
                        size={16}
                      />
                    </Box>
                  </MuiTooltip>
                )}
                <Typography
                  variant={"caption"}
                  color={"text.primary"}
                  display={"flex"}
                  alignItems={"center"}
                  fontWeight={500}
                >
                  {task.ref}
                </Typography>
              </NavLink>
              {task.segmentCount > 1 && (
                <SegmentCount
                  task={{
                    segmentCount: task.segmentCount,
                    segmentCountInGroup: task.segmentCountInGroup,
                    number: task.number,
                  }}
                  statusName={statusName}
                  isSegmentView={isSegmentView}
                />
              )}
            </Box>
            {sprintHelper.chipLabel(!!isSegmentView, task)}
          </CardActions>
          <CardContent sx={{ p: 0.8 }}>
            <Typography
              variant={"body2"}
              sx={{
                color: "text.primary",
                lineHeight: "16px",
                paddingLeft: "4px",
                height: "32px",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
                overflow: "hidden",
                maxWidth:
                  riskStatus && task.statusType !== AggStatusEnum.DONE
                    ? "88%"
                    : "100%",
                wordBreak: "break-all",
              }}
            >
              {task.name}
            </Typography>
          </CardContent>
          <CardActions disableSpacing sx={{ p: "0 8px 4px" }}>
            <Box
              display={"flex"}
              alignItems="center"
              justifyContent="space-between"
              width={"100%"}
              height={"25px"}
              maxHeight={"25px"}
            >
              <Box display={"flex"} alignItems={"center"} gap={"4px"}>
                {taskPriority && (
                  <Box>
                    <MuiTooltip title={taskPriority.name}>
                      <img
                        src={`${process.env.CDN_URL}/${taskPriority.iconFilePath}`}
                        alt={taskPriority.name}
                        width={18}
                        height={18}
                        style={{ display: "flex" }}
                      />
                    </MuiTooltip>
                  </Box>
                )}

                {taskType && (
                  <Box>
                    <MuiTooltip title={taskType.name}>
                      <img
                        src={`${process.env.CDN_URL}/${taskType.iconFilePath}`}
                        alt={taskType.name}
                        width={18}
                        height={18}
                        style={{ display: "flex" }}
                      />
                    </MuiTooltip>
                  </Box>
                )}
              </Box>

              <Box display={"flex"} alignItems={"center"} gap={"4px"}>
                {taskUsers.length > 0 && (
                  <AvatarGroup
                    users={taskUsers}
                    avatarSize={24}
                    borderColor={
                      "backgroundElevation.backgroundPaperElevation_0"
                    }
                  />
                )}
                <Box display={"flex"} alignItems={"center"}>
                  <Box position={"relative"} display={"inline-flex"}>
                    <Box position={"absolute"} top={"-20px"}>
                      {riskStatus && task.statusType !== AggStatusEnum.DONE ? (
                        <RiskStatusIcon
                          riskStatus={riskStatus}
                          style={{ display: "inline-flex" }}
                          iconProps={{ fontSize: "small" }}
                          iconSize={20}
                        />
                      ) : null}
                    </Box>
                    <StatusIcon
                      statusType={task.statusType}
                      status={task.status}
                      style={{ display: "inline-flex" }}
                      iconProps={{ fontSize: "small" }}
                      iconSize={20}
                    />
                  </Box>

                  <TaskMenu
                    openHandler={(openClose: boolean) => {
                      setOpenCloseTaskMenu(openClose);
                      if (!openClose) {
                        mouseLeaveHandle?.();
                      }
                    }}
                    taskRef={task.ref!}
                    taskId={task.taskId ?? task.id}
                    taskInternalType={task.internalType}
                    projectRef={task.projectRef}
                    showButton={isHovered}
                    indentSubheader={false}
                    isOpenTaskMenu={isOpenTaskMenu}
                  />
                </Box>
              </Box>
            </Box>
          </CardActions>
        </Box>
      </Box>
    );
  }
);

export default TaskCard;
