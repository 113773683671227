import Form from "../../../../../common/components/Form/Form";
import {
  Button,
  DatePicker,
  TextField,
  Tooltip,
} from "../../../../../common/modules/materialUI";
import { THttpClientError } from "../../../../../common/modules/httpClient/types/THttpClientError";
import useForm from "../../../../../common/hooks/useForm/useForm";
import { TCreateFormAttributes } from "./types/TCreateFormAttributes";
import { useCreateReadOnlyAccessService } from "../../../services/useCreateReadOnlyAccessService";
import { ReadOnlyAccessTypeEnum } from "../../../../../common/modules/accessControl/enums/ReadOnlyAccessTypeEnum";
import { IReadOnlyAccessToken } from "../../../../../entities/IReadOnlyAccessToken";
import useMedia from "../../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../../common/hooks/useMedia/enums/QueryEnum";
import { useIsFeatureAllowed } from "../../../../../common/modules/accessControl/hooks/useIsFeatureAllowed";
import { FeatureEnum } from "../../../../../common/modules/accessControl/enums/FeatureEnum";
import { featureUtil } from "../../../../../common/modules/accessControl/utils/featureUtil";
import { systemNotificationActions } from "../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { useAppDispatch } from "../../../../../common/hooks/redux";

type TProps = {
  boardRef: string;
  onCancel: () => void;
  onSubmit: () => void;
};

const CreateForm = (props: TProps) => {
  const isTouchDevice = useMedia(QueryEnum.IS_TOUCH_DEVICE);
  const form = useForm<TCreateFormAttributes>();
  const isFeatureAllowed = useIsFeatureAllowed(FeatureEnum.SHARING_BOARDS);
  const dispatch = useAppDispatch();

  const { isLoading, dispatch: dispatchCreateReadOnlyAccessService } =
    useCreateReadOnlyAccessService();

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();

    if (!isFeatureAllowed) {
      dispatch(
        systemNotificationActions.open({
          message: featureUtil.notAvailableFullText,
          variant: "warning",
        })
      );
      return;
    }

    dispatchCreateReadOnlyAccessService({
      body: {
        accessType: ReadOnlyAccessTypeEnum.BOARD_ACCESS,
        accessTypeRef: props.boardRef,
        name: form.data!.name,
        expiresAt: form.data!.expiresAt,
      },
    })
      .then((payload: IReadOnlyAccessToken) => {
        props.onSubmit();
      })
      .catch((error: THttpClientError) => {
        form.errorHandler(error);
      });
  };

  return (
    <Form
      onSubmit={submitHandler}
      actionElement={
        <>
          <Button
            onClick={props.onCancel}
            disabled={isLoading}
            variant="outlined"
            sx={{ marginRight: "8px" }}
          >
            Cancel
          </Button>
          <Tooltip
            title={featureUtil.notAvailableText}
            when={!isFeatureAllowed}
            withSpanWrapper
          >
            <Button
              disabled={isLoading}
              variant="contained"
              type="submit"
              loading={isLoading}
            >
              Create
            </Button>
          </Tooltip>
        </>
      }
    >
      <TextField
        forceFocus={!isTouchDevice}
        form={form}
        name="name"
        label="Name Your Link"
        showRequired
        disabled={isLoading}
      />
      <DatePicker
        form={form}
        name="expiresAt"
        label="Active Time"
        showRequired
      />
    </Form>
  );
};

export default CreateForm;
