import { Box } from "@mui/material";
import { TLinkQuickRemoveProps } from "./types/TLinkQuickRemoveProps";
import { LinkOff } from "@mui/icons-material";
import Loading from "../../../../common/components/Icon/Loading/Loading";
import {
  ConfirmationButton,
  Tooltip,
} from "../../../../common/modules/materialUI";
import { useLinkDeleteService } from "../../services/useLinkDeleteService";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import { linkActions } from "../../slices/linkSlice";

const LinkQuickRemove = (props: TLinkQuickRemoveProps) => {
  const taskLabel = useAppSelector((state) => state.customs.taskLabel);
  const dispatch = useAppDispatch();
  const { isLoading, dispatch: dispatchLinkDeleteService } =
    useLinkDeleteService({
      id: props.linkId,
    });

  const deleteLinkHandler = () => {
    dispatchLinkDeleteService()
      .then(() => {
        dispatch(
          linkActions.removeLinkFromList({
            linkId: props.linkId,
          })
        );
      })
      .catch(() => {});
  };

  return (
    <Box gap={1}>
      <ConfirmationButton
        title="Unlink Task"
        content="Are you sure you want to unlink task?"
        button={
          !isLoading ? (
            <Tooltip title={`Remove ${taskLabel} link`} placement={"left"}>
              <LinkOff style={{ cursor: "pointer" }} fontSize="small" />
            </Tooltip>
          ) : (
            <Loading />
          )
        }
        confirmText="Unlink"
        disagreeText="Cancel"
        successHandler={deleteLinkHandler}
      />
    </Box>
  );
};

export default LinkQuickRemove;
