import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";

const fetchContainer = (tabIndex: string, section: "count" | "actions") =>
  document.getElementById(`${tabIndex}-${section}`);

export default function usePortalTabActions(tabIndex: string) {
  const [createCountsPortal, setCreateCountsPortal] = useState(false);
  const [createActionsPortal, setCreateActionsPortal] = useState(false);

  useEffect(() => {
    !!fetchContainer(tabIndex, "count") && setCreateCountsPortal(true);
    !!fetchContainer(tabIndex, "actions") && setCreateActionsPortal(true);
  }, [tabIndex]);

  return {
    CountsPortalComponent: (counts: ReactJSXElement) =>
      createCountsPortal
        ? ReactDOM.createPortal(counts, fetchContainer(tabIndex, "count")!)
        : null,
    ActionsPortalComponent: (actions: ReactJSXElement) =>
      createActionsPortal
        ? ReactDOM.createPortal(actions, fetchContainer(tabIndex, "actions")!)
        : null,
  };
}
