import { Box, Typography } from "@mui/material";
import { TSectionProps } from "./types/TSectionProps";

const Section = (props: TSectionProps) => {
  return (
    <Box display="flex" flexDirection="column" gap={1} onClick={props.onClick}>
      <Box
        gap={1}
        display="flex"
        style={{ opacity: !props.disabled ? undefined : 0.5 }}
      >
        {props.icon}
        <Typography>{props.name}</Typography>
      </Box>
      {!!props.helperText && (
        <Typography variant="caption" color="text.secondary">
          {props.helperText}
        </Typography>
      )}
    </Box>
  );
};

export default Section;
