import { TColumnsProps } from "./types/TColumnsProps";
import Grid from "@mui/material/Grid2";
import LinearProgressWithLabel from "../../../../../../common/modules/materialUI/components/LinearProgress/LinearProgressWithLabel";

export default function ProgressColumn({
  initiative,
  fontSize,
  sx,
  extraMessage,
}: TColumnsProps) {
  return (
    <>
      {(initiative.progress ?? 0) > 0 && (
        <Grid width={"170px"} sx={sx}>
          <LinearProgressWithLabel
            fontSize={fontSize}
            value={initiative.progress ?? 0}
            metadata={{
              doneCount: initiative.doneCount!,
              allCount: initiative.allCount!,
              extraMessage: extraMessage,
            }}
          />
        </Grid>
      )}
    </>
  );
}
