import Chip from "../../../../../../modules/materialUI/components/Chip/Chip";

const ReadOnlyLabel = () => {
  return (
    <Chip
      sx={{ color: "common.white", borderColor: "common.white" }}
      variant="outlined"
      label="View only"
    />
  );
};

export default ReadOnlyLabel;
