import { useUpdateTaskService } from "../../../../services/useUpdateTaskService";
import { TTitleFormProps } from "./types/TTitleFormProps";
import { THttpClientError } from "../../../../../../common/modules/httpClient/types/THttpClientError";
import { TTaskEvent } from "../types/TTaskEvent";
import { EventEnum } from "../../../../../../common/modules/eventProvider/enums/EventEnum";
import { useDispatchEvent } from "../../../../../../common/modules/eventProvider";
import { IShiftWithDataResource } from "../../../../interfaces/IShiftWithDataResource";
import { systemNotificationActions } from "../../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/hooks/redux";
import EditableTitleForm from "../../../../../../common/modules/taskView/containers/EditableTitleForm/EditableTitleForm";
import { TEditableTitleFormProps } from "../../../../../../common/modules/taskView/containers/EditableTitleForm/types/TEditableTitleFormProps";

const TitleForm = (props: TTitleFormProps) => {
  const dispatchEvent = useDispatchEvent();
  const dispatch = useAppDispatch();
  const { dispatch: dispatchUpdateTaskService } = useUpdateTaskService(
    props?.task?.ref!
  );
  const taskLabel = useAppSelector((state) => state.customs.taskLabel);

  const changeHandler: TEditableTitleFormProps["onChange"] = (
    name,
    onSuccess,
    onError
  ) => {
    dispatchUpdateTaskService({
      body: {
        name,
      },
    })
      .then((payload: IShiftWithDataResource) => {
        const taskEvent = {
          initTask: props.task,
          task: {
            ...props.task,
            name,
            updatedAt: payload.task.updatedAt,
          },
        } as TTaskEvent;
        dispatchEvent(EventEnum.ON_TASK_MAIN_DATA_UPDATED, taskEvent);

        onSuccess();
      })
      .catch((error: THttpClientError) => {
        error.status === 422 &&
          dispatch(
            systemNotificationActions.open({
              message: (error.data.errors as { name: string[] }).name[0],
              variant: "error",
            })
          );
        error.status !== 422 &&
          dispatch(
            systemNotificationActions.open({
              message:
                "There was a problem with updating the task name. Please try again.",
              variant: "error",
            })
          );

        onError(error);
      });
  };

  return (
    <EditableTitleForm
      label={taskLabel}
      name={props.task?.name}
      onChange={changeHandler}
    />
  );
};

export default TitleForm;
