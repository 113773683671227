import Chip from "../../../../common/modules/materialUI/components/Chip/Chip";
import { Tooltip } from "../../../../common/modules/materialUI";
import { Box, Typography } from "@mui/material";
import Progress from "./Progress";
import { IBoard } from "../../../../entities/IBoard";

const BoardInfoDisplay = ({
  projectAbbr,
  board,
  showProgress,
  tipIcon,
}: {
  projectAbbr: string;
  board: IBoard;
  showProgress?: boolean;
  tipIcon?: JSX.Element;
}) => {
  return (
    <Box display="flex" alignItems="center" gap="12px">
      <Chip
        label={
          <Tooltip title={projectAbbr}>
            <Typography
              noWrap
              color="text.primary"
              fontSize="12px"
              letterSpacing="0.16px"
              lineHeight="18px"
              maxWidth="6ch"
            >
              {projectAbbr}
            </Typography>
          </Tooltip>
        }
      />

      {showProgress && <Progress board={board} />}

      {tipIcon}
    </Box>
  );
};

export default BoardInfoDisplay;
