import TaskSensorLogo from "../../../../../../components/Logo/TaskSensorLogo";
import { Box } from "@mui/material";
import ReadOnlyLabel from "../../Header/Readonly/ReadOnlyLabel";

const Desktop = () => {
  return (
    <Box display="flex" alignItems="center" gap="12px">
      <TaskSensorLogo size={300} colorCombo={"white-green"} width={144} />
      <ReadOnlyLabel />
    </Box>
  );
};

export default Desktop;
