import { Box, Switch, Typography } from "@mui/material";
import { useState, useTransition } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../common/hooks/redux";
import {
  boardViewActions,
  STORAGE_SPLITS_KEY,
} from "../../../../../slices/boardViewSlice";
import { menuItem } from "../../../../../../../common/containers/Menu/utils/menuItem";
import { TFilterProps } from "../../../../../containers/Filter/Filter/types/TFilterProps";
import { propsForMenuItems } from "../../../../../../../common/containers/Menu/common/propsForMenuItems";

const DisplaySplitsOption = (props: TFilterProps) => {
  const dispatch = useAppDispatch();
  const [checked, setChecked] = useState<boolean>(
    useAppSelector((state) => state.boardView.listView.displaySplits)
  );
  const [, startTransition] = useTransition();

  const clickHandler = () => {
    setChecked((prevState) => {
      const checked = !prevState;
      startTransition(() => {
        localStorage.setItem(STORAGE_SPLITS_KEY, checked ? "true" : "false");
        dispatch(boardViewActions.displaySplits(checked));
      });
      return checked;
    });
  };

  return menuItem.make({
    component: (
      <Box
        display="flex"
        alignItems="center"
        gap={1}
        color={"pickerPallet.secondary"}
        sx={{ cursor: "pointer" }}
      >
        <Switch checked={checked} size="small" />
        <Typography
          variant="subtitle2"
          fontWeight="400"
          color={"text.secondary"}
        >
          Display Splits
        </Typography>
      </Box>
    ),
    handler: clickHandler,
    when: props.renderDisplaySplits,
    hasDivider: false,
    menuItemsProps: propsForMenuItems,
    closeOnClick: false,
  });
};

export default DisplaySplitsOption;
