import { aiSentinelActions } from "../slices/aiSentinelSlice";
import { IBreakdownTextRequest } from "../interfaces/IBreakdownTextRequest";
import { IBreakdownTextResource } from "../interfaces/IBreakdownTextResource";
import { THttpClientError } from "../../../common/modules/httpClient/types/THttpClientError";
import { useAppDispatch } from "../../../common/hooks/redux";
import { useBreakdownTextService } from "../services/useBreakdownTextService";
import useForm from "../../../common/hooks/useForm/useForm";
import { systemNotificationActions } from "../../../common/modules/systemNotification/slices/systemNotificationSlice";

const useAiHandleSendUserMessage = () => {
  const dispatch = useAppDispatch();
  const { dispatch: dispatchBreakdownTextService } = useBreakdownTextService();
  const { getValidationErrorAtIndex } = useForm();

  const handleSendUserMessage = (inputText: string) => {
    const inputTextTrimmed = inputText.trim();

    if (!inputTextTrimmed) {
      return;
    }

    dispatch(aiSentinelActions.setUserMessage(inputTextTrimmed));
    dispatch(aiSentinelActions.setAiTextMessage(null));

    dispatch(aiSentinelActions.setBreakdownMessageLoading());
    dispatchBreakdownTextService({
      body: { text: inputTextTrimmed } as IBreakdownTextRequest,
    })
      .then((resource: IBreakdownTextResource) => {
        if (resource.message) {
          dispatch(aiSentinelActions.setAiTextMessage(resource.message));
          dispatch(aiSentinelActions.resetBreakdownMessage());
        } else {
          dispatch(
            aiSentinelActions.setBreakdownActions(
              resource.items.map((item) => ({
                text: item.action,
                isSelected: true,
                isCreating: false,
              }))
            )
          );
        }
      })
      .catch((error: THttpClientError) => {
        if (error.status === 422) {
          const validationFirstError = getValidationErrorAtIndex(0, error);

          if (validationFirstError) {
            dispatch(aiSentinelActions.setAiTextMessage(validationFirstError));
          }
        } else {
          dispatch(
            systemNotificationActions.open({
              message: "Unable to send your message. Please try again.",
              variant: "error",
            })
          );
        }

        dispatch(aiSentinelActions.resetBreakdownMessage());
      });
  };

  return {
    handleSendUserMessage,
  };
};

export default useAiHandleSendUserMessage;
