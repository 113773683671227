import { device } from "../utils/device";
import { useAppDispatch } from "./redux";
import { systemActions } from "../store/systemSlice";
import usePlatformOS from "./useReactNativeBridge/usePlatformOS";
import useLinkTarget from "./useLinkTarget";

export default function useWindow() {
  const dispatch = useAppDispatch();
  const { isIOS } = usePlatformOS();
  const { _blank } = useLinkTarget();

  const openNewTab = (url: string) => {
    const a = document.createElement("a");
    a.target = _blank;
    a.href = url;
    a.click();
  };

  return {
    openNewTab: (url: string, async: boolean = false) => {
      (device.isIosSafari() || isIOS) && async
        ? dispatch(systemActions.openConfirmRedirectionDialog(url))
        : openNewTab(url);
    },
  };
}
