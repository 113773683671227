import { TBillingSettingsNavigationContentProps } from "./types/TBillingSettingsNavigationContentProps";
import BillingSettingsList from "./BillingSettingsList";
import { useAccessControl } from "../../../../../../../modules/accessControl/hooks/useAccessControl";
import { AbilityEnum } from "../../../../../../../modules/accessControl/enums/AbilityEnum";
import NavigationTitle from "../../../../../../../components/navigationTitle/NavigationTitle";
import { Box } from "@mui/material";

const BillingSettingsSection = ({
  onClick,
}: TBillingSettingsNavigationContentProps) => {
  const { can } = useAccessControl();

  return (
    <>
      {can(AbilityEnum.financing) && (
        <Box marginBottom={"8px"} marginTop={"8px"}>
          <NavigationTitle title={"Subscription"} />
          <BillingSettingsList onClick={onClick} />
        </Box>
      )}
    </>
  );
};

export default BillingSettingsSection;
