import { Box } from "@mui/material";
import { StickyElementsRef } from "../../../hooks/useStickyElements";
import Menu from "../../../../../../../common/containers/Menu/Menu";
import { IGuesstimationItem } from "../../../../../../../entities/IGuesstimationItem";
import { useState } from "react";
import Button from "../../../../../../../common/modules/materialUI/components/Button/Button";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import WorkdaysMenuItem from "./WorkdaysMenuItem";
import EffortUnitsMenuItem from "./EffortUnitsMenuItem";
import ResourcesMenuItem from "./ResourcesMenuItem";
import CollisionsMenuItem from "./CollisionsMenuItem";
import { PositionEnum } from "../LaneItemHorizontalLarge";

const KpisMenu = ({
  itemFooter,
  guesstimationItem,
  stickyOrStatic,
}: {
  itemFooter?: StickyElementsRef;
  guesstimationItem: IGuesstimationItem;
  stickyOrStatic: PositionEnum;
}) => {
  const [isOpen, setOpen] = useState<boolean>(false);

  return (
    <Box
      ref={(ref: HTMLDivElement | null) =>
        itemFooter && (itemFooter.endStickElement = ref)
      }
      display="flex"
      alignItems="center"
      position={stickyOrStatic}
      right="-20px"
      marginLeft={
        itemFooter && itemFooter.startStickElement
          ? `${itemFooter.startStickElement.offsetWidth + 20}px`
          : "0px"
      }
      gap="8px"
    >
      <Menu
        stretchOnMobile={false}
        openHandler={(openClose) => setOpen(openClose)}
        button={(onClick) => {
          return (
            <Button
              size="small"
              onClick={onClick}
              variant={"text"}
              color={"inherit"}
              endIcon={
                <ArrowDropDownIcon
                  sx={{ transform: `rotate(${isOpen ? "180" : "0"}deg)` }}
                />
              }
              sx={{ padding: "0 5px", gap: 0, "& span": { marginLeft: 0 } }}
            >
              KPI Metrics
            </Button>
          );
        }}
        menuItems={[
          WorkdaysMenuItem({
            workingDaysCount: guesstimationItem.workingDaysCount,
            tsPoints: guesstimationItem.tsPoints,
          }),
          ...(guesstimationItem.estimatedResources
            ? [
                EffortUnitsMenuItem({
                  allocatedResourceDays:
                    guesstimationItem.allocatedResourceDays,
                  estimatedResourceDays:
                    guesstimationItem.estimatedResourceDays,
                }),
              ]
            : []),
          ...(guesstimationItem.estimatedResources
            ? [
                ResourcesMenuItem({
                  assigneesLength: guesstimationItem.assignees?.length,
                  estimatedResources: guesstimationItem.estimatedResources,
                }),
              ]
            : []),
          ...(!!guesstimationItem.collisionsOverview?.length
            ? [
                CollisionsMenuItem({
                  collisionsOverview: guesstimationItem.collisionsOverview,
                  collisionsOverviewLength:
                    guesstimationItem.collisionsOverview.length,
                }),
              ]
            : []),
        ]}
      />
    </Box>
  );
};
export default KpisMenu;
