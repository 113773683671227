import NavigationIcon from "../common/NavigationIcon/NavigationIcon";
import TaskSensorLogo from "../../../../../components/Logo/TaskSensorLogo";
import UserMenu from "../../../../../../modules/user/containers/Menu/UserMenu";
import { NavLink } from "react-router-dom";
import ActivePlan from "../../../../../../modules/user/modules/freeTrial/containers/ActivePlan";
import { Box, LinearProgress, Typography } from "@mui/material";
import { PeopleAlt, Rocket } from "@mui/icons-material";
import { lightTheme } from "../../../../../modules/theme/lightTheme";
import InfoCenter from "../../../../../../modules/user/containers/Menu/InfoCenter";
import { Button } from "../../../../../modules/materialUI";
import useLandingPageHelper from "../../../../../modules/landingPage/hooks/useLandingPageHelper";
import { useAccessControl } from "../../../../../modules/accessControl/hooks/useAccessControl";
import { AbilityEnum } from "../../../../../modules/accessControl/enums/AbilityEnum";

const Desktop = () => {
  const {
    isLoading: quickStartLoading,
    needsQuickStart,
    progress,
  } = useLandingPageHelper();

  const { can } = useAccessControl();

  return (
    <>
      <Box display="flex" alignItems="center" gap="12px">
        <NavigationIcon />

        <NavLink to={"/"}>
          <TaskSensorLogo size={300} colorCombo={"white-green"} width={144} />
        </NavLink>

        <ActivePlan />
      </Box>

      <Box
        flex={1}
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        gap="12px"
      >
        {!quickStartLoading && needsQuickStart && (
          <Box
            sx={{
              borderRadius: 2,
              flex: 1,
              maxWidth: "216px",
              "&:active": {
                bgcolor: "text._states.hover",
              },
              "@media (hover: hover)": {
                "&:hover": {
                  bgcolor: "text._states.hover",
                },
              },
            }}
          >
            <NavLink
              to={"/"}
              style={{
                padding: "4px 8px",
                flexDirection: "column",
                justifyContent: "flex-end",
                gap: "4px",
              }}
            >
              <Box
                width="100%"
                color="common.white"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                gap="8px"
              >
                <Box display="flex" alignItems="center" gap="4px">
                  <Rocket sx={{ fontSize: "18px" }} />

                  <Typography variant="body2">Set up Progress</Typography>
                </Box>

                <Typography variant="body2">{progress}%</Typography>
              </Box>

              <Box width="100%">
                <LinearProgress
                  variant="determinate"
                  value={progress}
                  sx={{
                    bgcolor: "#B0D9CD",
                    ".MuiLinearProgress-bar": {
                      bgcolor: lightTheme.palette.primary.main,
                    },
                  }}
                />
              </Box>
            </NavLink>
          </Box>
        )}

        {can(AbilityEnum.administrate) && (
          <NavLink to={"/team"}>
            <Button
              size="small"
              startIcon={<PeopleAlt />}
              sx={{
                boxShadow: 0,
                bgcolor: lightTheme.palette.action.active,
                height: "32px",
              }}
            >
              Team
            </Button>
          </NavLink>
        )}

        <InfoCenter />
        <UserMenu />
      </Box>
    </>
  );
};

export default Desktop;
