import { Typography, useTheme } from "@mui/material";
import classes from "./EmptyView.module.scss";

const EmptyView = () => {
  const theme = useTheme();

  return (
    <div className={`text-center ${classes.container}`}>
      <img
        src={`${process.env.CDN_URL}/icons/welcoming/${
          theme.palette.mode === "dark"
            ? "welcome-priority-view-dark.png"
            : "welcome-priority-view-light.png"
        }`}
        alt=""
      />
      <Typography variant="body2" sx={{ color: "text.secondary" }}>
        No upcoming tasks found for you to build your priorities. Please check
        back later.
      </Typography>
    </div>
  );
};

export default EmptyView;
