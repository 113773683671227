import { cloneElement } from "react";
import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  useTheme,
} from "@mui/material";
import { ArrowDropDownRounded } from "@mui/icons-material";
import { TAccordionProps } from "./types/TAccordionProps";

export default function Accordion({
  title,
  icon,
  defaultExpended,
  mb,
  detailsSx,
  children,
}: TAccordionProps) {
  const theme = useTheme();

  const iconComponent = cloneElement(icon, {
    fontSize: "small",
    sx: {
      color: theme.palette.taskView.textMainColor,
      mr: "8px",
    },
  });

  return (
    <MuiAccordion
      defaultExpanded={defaultExpended ?? true}
      sx={{
        background: "transparent",
        borderRadius: "12px",
        boxShadow: "none",
        m: "0 !important",
        mb: mb ? "20px !important" : undefined,
        "::before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary
        sx={{
          minHeight: "auto !important",
          padding: "0 8px",
          "& .MuiAccordionSummary-content": {
            alignItems: "center",
            margin: "0 !important",
          },
        }}
        expandIcon={
          <ArrowDropDownRounded
            fontSize="small"
            sx={{ color: theme.palette.taskView.textMainColor }}
          />
        }
      >
        {iconComponent}
        <Typography
          color={theme.palette.taskView.textSecondaryColor}
          fontSize="15px"
          fontWeight={500}
        >
          {title}
        </Typography>
      </AccordionSummary>

      <AccordionDetails
        sx={{
          bgcolor: theme.palette.taskView.mainBackground,
          borderRadius: "12px",
          marginTop: "8px",
          p: "12px",
          ...detailsSx,
        }}
      >
        {children}
      </AccordionDetails>
    </MuiAccordion>
  );
}
