import usePlatformOS from "./useReactNativeBridge/usePlatformOS";

const useLinkTarget = () => {
  const { isMobileApp } = usePlatformOS();

  return {
    _blank: !isMobileApp ? "_blank" : "_self",
  };
};

export default useLinkTarget;
