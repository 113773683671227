import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReadOnlyAccessToken } from "../../../entities/IReadOnlyAccessToken";

type TReadOnlyAccessState = {
  displayModal: boolean;
  list: IReadOnlyAccessToken[];
};

const initialState: TReadOnlyAccessState = {
  displayModal: false,
  list: [],
};

const slice = createSlice({
  name: "readOnlyAccess",
  initialState,
  reducers: {
    setList: (state, action: PayloadAction<IReadOnlyAccessToken[]>) => {
      state.list = action.payload;
    },
    removeFromList: (state, action: PayloadAction<{ id: number }>) => {
      state.list = state.list.filter((item) => item.id !== action.payload.id);
    },
    openManageModal: (state) => {
      state.displayModal = true;
    },
    closeManageModal: (state) => {
      state.displayModal = false;
    },
  },
});

export const readOnlyAccessReducer = slice.reducer;

export const readOnlyAccessActions = slice.actions;
