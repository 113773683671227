import { guesstimationItemViewActions } from "../../slices/viewSlice";
import { Add } from "@mui/icons-material";
import { Button } from "../../../../../../common/modules/materialUI";
import { useAppDispatch } from "../../../../../../common/hooks/redux";
import { IGuesstimationItem } from "../../../../../../entities/IGuesstimationItem";

type TProps = {
  guesstimationItem: IGuesstimationItem;
};

export default function AddAssigneesButton({ guesstimationItem }: TProps) {
  const dispatch = useAppDispatch();

  return (
    <Button
      variant="text"
      onClick={() => {
        dispatch(
          guesstimationItemViewActions.openAddAssigneesDialog({
            item: guesstimationItem,
          })
        );
      }}
      size="small"
      sx={{
        maxWidth: "62px",
        gap: "2px",
        fontSize: "12px",
        textTransform: "capitalize",
      }}
      startIcon={<Add sx={{ fontSize: "13px !important" }} />}
    >
      Add
    </Button>
  );
}
