import { useAppDispatch, useAppSelector } from "../../../common/hooks/redux";
import { useDispatchEvent } from "../../../common/modules/eventProvider";
import { Backdrop, Box, Tooltip, Typography } from "@mui/material";
import { Button } from "../../../common/modules/materialUI";
import { EventEnum } from "../../../common/modules/eventProvider/enums/EventEnum";
import useMedia from "../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../common/hooks/useMedia/enums/QueryEnum";
import { useEffect, useState } from "react";
import { navigationActions } from "../../../common/store/navigationSlice";

const Tour = () => {
  const [, setShouldRetry] = useState<boolean>();
  const globalTip = useAppSelector((state) => state.tip.globalTip);
  const dispatchEvent = useDispatchEvent();
  const dispatch = useAppDispatch();

  let element = globalTip?.slug
    ? document.getElementById(globalTip.slug)
    : null;

  const matchesMax992Screen = useMedia(QueryEnum.MAX_WIDTH_992);
  const shouldForceOpenNavigation =
    matchesMax992Screen && globalTip?.slug === "tip:settings";

  useEffect(() => {
    // On mobile settings case
    shouldForceOpenNavigation && dispatch(navigationActions.show());
    if (!globalTip?.slug) {
      return;
    }

    !element &&
      setTimeout(() => {
        setShouldRetry((prevState) => !prevState);
      }, 200);
  }, [shouldForceOpenNavigation, element, globalTip?.slug]);

  if (!globalTip || !element) {
    return null;
  }

  return (
    <Backdrop
      open
      sx={(theme) => ({
        bgcolor: "#0000004D",
        zIndex: theme.zIndex.drawer + 1,
      })}
    >
      <Tooltip
        open
        arrow
        placement="bottom-end"
        slotProps={{
          tooltip: {
            sx: {
              p: "12px",
            },
          },
          popper: {
            anchorEl: element,
          },
        }}
        title={
          <>
            <Typography variant="body2" component="span" fontWeight={900}>
              {globalTip.title}
            </Typography>
            :&nbsp;
            <Typography variant="body2" component="span">
              {globalTip.description}
            </Typography>
            <Box display="flex" justifyContent="flex-end" mt="8px">
              <Button
                size="small"
                onClick={() => {
                  dispatchEvent(EventEnum.ON_SEEN_TIP_GLOBAL, globalTip);
                  shouldForceOpenNavigation &&
                    dispatch(navigationActions.hide());
                }}
              >
                Got It
              </Button>
            </Box>
          </>
        }
      >
        <span />
      </Tooltip>
    </Backdrop>
  );
};

export default Tour;
