import { IBoard } from "../../../../entities/IBoard";
import { IData } from "../../../../entities/columns/objective/IData";
import { useState } from "react";
import { useSubscribeEvent } from "../../../../common/modules/eventProvider";
import { EventEnum } from "../../../../common/modules/eventProvider/enums/EventEnum";
import { useFetchBoardDispatchableService } from "../../services/useFetchBoardDispatchableService";
import { Box, CircularProgress, Typography } from "@mui/material";

type TProps = {
  board: IBoard;
};

export default function Progress({ board }: TProps) {
  const [data, setData] = useState<IData>({
    progress: board.progress,
    allCount: board.task_count,
    doneCount: board.task_agg_done_count,
  } as IData);
  const { dispatch } = useFetchBoardDispatchableService({
    ref: board.ref!,
  });

  useSubscribeEvent(
    EventEnum.ON_TASK_STATUS_UPDATED,
    () => {
      // Setting timeout since progress calculation is done on background
      setTimeout(() => {
        dispatch()
          .then((payload: IBoard) => {
            setData({
              progress: payload.progress,
              allCount: payload.task_count,
              doneCount: payload.task_agg_done_count,
            } as IData);
          })
          .catch(() => {});
      }, 5000);
    },
    []
  );

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box position="relative" display="inline-flex">
        <Box position="relative" fontSize="0">
          <CircularProgress
            variant="determinate"
            sx={{
              color: "action.selected",
            }}
            size={36}
            value={100}
          />

          <CircularProgress
            size={36}
            sx={{
              position: "absolute",
              left: 0,
            }}
            thickness={4}
            variant="determinate"
            value={data.progress}
          />
        </Box>
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="caption" color="text.secondary">
            {`${Math.round(data.progress ?? 0)}%`}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
