import { useState } from "react";
import Org from "../../../components/Org/Org";
import Menu from "../Menu";
import { Typography } from "@mui/material";
import SwitchWorkspace from "./SwitchWorkspace";
import Settings from "./Settings";
import Integrations from "./Integrations";
import Subscription from "./Subscription";
import { useAccessControl } from "../../../modules/accessControl/hooks/useAccessControl";
import { AbilityEnum } from "../../../modules/accessControl/enums/AbilityEnum";
import { TMenuItemProps } from "../types/TMenuItemProps";
import AddWorkspaceButton from "./AddWorkspaceButton";
import { useAppSelector } from "../../../hooks/redux";
import { menuItem } from "../utils/menuItem";
import Team from "./Team";

type TOrgMenuProps = {
  onlyLogo?: boolean;
  mouseLeaveHandle?: () => void;
};

const OrgMenu = (props: TOrgMenuProps) => {
  const org = useAppSelector((state) => state.org.init.org);
  const [isOpenClose, setOpenClose] = useState<boolean>(false);
  const { can } = useAccessControl();

  return (
    <Menu
      openHandler={(openClose) => {
        setOpenClose(openClose);
        if (props.mouseLeaveHandle && !openClose) props.mouseLeaveHandle();
      }}
      button={(clickHandler) => (
        <Org
          clickHandler={clickHandler}
          isOpenClose={isOpenClose}
          onlyLogo={props.onlyLogo}
        />
      )}
      sx={{ width: "220px" }}
      menuItems={[
        {
          component: (
            <Typography
              padding="0px 16px 0 18px"
              color="text.primary"
              fontSize="12px"
              lineHeight="20px"
              letterSpacing="0.14px"
              fontWeight={500}
            >
              Switch Workspace
            </Typography>
          ),
          hasDivider: false,
          isHeaderItem: true,
          headerItemProps: {
            sx: { padding: "0" },
          },
        },
        ...SwitchWorkspace(org),
        can(AbilityEnum.manage)
          ? {
              component: (
                <Typography
                  noWrap
                  padding="0px 16px 0 18px"
                  color="text.primary"
                  fontSize="12px"
                  lineHeight="20px"
                  letterSpacing="0.14px"
                  fontWeight={500}
                >
                  {org?.name} Preferences
                </Typography>
              ),
              hasDivider: false,
              isHeaderItem: true,
              headerItemProps: {
                sx: { padding: "0" },
              },
            }
          : ({} as TMenuItemProps),
        ...menuItem.collect([
          { menuItem: Team(), can: can(AbilityEnum.administrate) },
          { menuItem: Settings(), can: can(AbilityEnum.administrate) },
          { menuItem: Subscription(), can: can(AbilityEnum.financing) },
          { menuItem: Integrations(), can: can(AbilityEnum.manage) },
        ]),
        AddWorkspaceButton(),
      ]}
    />
  );
};

export default OrgMenu;
