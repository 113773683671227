import { DataGrid as MuiDataGrid } from "@mui/x-data-grid";
import { TDataGridProps } from "./types/TDataGridProps";
import { useTheme } from "@mui/material";

const DataGrid = (props: TDataGridProps) => {
  const theme = useTheme();

  return (
    <MuiDataGrid
      {...props}
      sx={{
        ".MuiDataGrid-cell": {
          display: "flex",
          alignItems: "center",
          padding: "4px 10px",
          borderColor: "divider",
        },
        "& .MuiDataGrid-footerContainer p": {
          margin: 0,
        },
        "& .MuiDataGrid-container--top [role=row], & .MuiDataGrid-container--bottom [role=row]":
          {
            bgcolor: "backgroundElevation.backgroundPaperElevation_0",
          },
        "& .MuiDataGrid-columnHeader": {
          padding: "16px",
          border: "none !important",
          outline: "none !important",
        },
        "& .MuiDataGrid-columnHeaderDraggableContainer": {
          height: "min-content",
        },

        "& .MuiDataGrid-cell:focus": {
          outline: "none",
        },
        "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within":
          {
            outline: "none",
          },
        "& .MuiDataGrid-cell.Mui-selected": {
          border: "none",
        },
        ".MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator": {
          opacity: 1,
          transition: "opacity 0.2s ease",
          color: "divider",
        },
        "@media (hover: hover)": {
          ".MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator--resizable:hover":
            {
              color: "primary.main",
            },
        },
        "& .MuiDataGrid-columnHeader:focus .MuiDataGrid-columnSeparator, & .MuiDataGrid-columnHeader--withLeftBorder .MuiDataGrid-columnSeparator, & .MuiDataGrid-columnHeader--withRightBorder .MuiDataGrid-columnSeparator, & .MuiDataGrid-columnHeader--siblingFocused .MuiDataGrid-columnSeparator, & .MuiDataGrid-virtualScroller--hasScrollX .MuiDataGrid-columnHeader--lastUnpinned .MuiDataGrid-columnSeparator, & .MuiDataGrid-virtualScroller--hasScrollX .MuiDataGrid-columnHeader--last .MuiDataGrid-columnSeparator":
          {
            opacity: 1,
          },
        "& .MuiDataGrid-row--borderBottom .MuiDataGrid-columnHeader, & .MuiDataGrid-row--borderBottom .MuiDataGrid-filler, & .MuiDataGrid-row--borderBottom":
          {
            borderBottom: "none",
          },

        "& .MuiDataGrid-withBorderColor": {
          borderColor: theme.palette.divider + "!important",
        },
        "& .MuiDataGrid-row:hover #MyButton": {
          display: "block",
        },
        "& .MuiDataGrid-row #MyButton": {
          display: "none",
        },
        "@media (hover: none)": {
          "& .MuiDataGrid-row #MyButton": {
            display: "block",
          },
        },
        "&.MuiDataGrid-root": {
          border: "none",
          bgcolor: "backgroundElevation.backgroundPaperElevation_0",
          borderRadius: 3,
        },
        ...props.sx,
      }}
    />
  );
};

export default DataGrid;
