import Button from "../../../../../../common/modules/materialUI/components/Button/Button";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { menuItem } from "../../../../../../common/containers/Menu/utils/menuItem";
import { Box, Switch, Typography } from "@mui/material";
import Menu from "../../../../../../common/containers/Menu/Menu";
import { guesstimationViewActions } from "../../slices/guesstimationViewSlice";
import { useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/hooks/redux";
import useMedia from "../../../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../../../common/hooks/useMedia/enums/QueryEnum";

const GuesstimationViewOptions = () => {
  const dispatch = useAppDispatch();
  const matchesMax768Screen = useMedia(QueryEnum.MAX_WIDTH_768);

  const [isChecked, setIsChecked] = useState<boolean>(
    useAppSelector((state) => state.guesstimationView.filters.showDone)
  );

  const clickHandler = () => {
    setIsChecked(!isChecked);
    dispatch(guesstimationViewActions.toggleShowDone());
  };

  return (
    <Menu
      stretchOnMobile={false}
      closeOnSelection
      button={(clickHandler) => (
        <Button
          size="small"
          color="inherit"
          onClick={clickHandler}
          variant="outlined"
          isBorderStyle
          sx={{
            p: matchesMax768Screen ? "4px 9px" : "4px 10px",
          }}
          startIcon={<MoreVertIcon sx={{ fontSize: "18px" }} />}
        />
      )}
      menuItems={[
        menuItem.make({
          component: (
            <Box
              display="flex"
              alignItems="center"
              gap={1}
              color="pickerPallet.secondary"
              sx={{ cursor: "pointer" }}
            >
              <Switch checked={isChecked} size="small" />
              <Typography
                variant="subtitle2"
                fontWeight="400"
                color="text.secondary"
              >
                Display “Done”
              </Typography>
            </Box>
          ),
          closeOnClick: false,
          handler: () => clickHandler(),
        }),
      ]}
    />
  );
};

export default GuesstimationViewOptions;
