import { Box } from "@mui/material";
import { useFetchCustomFieldsService } from "../../services/useFetchCustomFieldsService";
import { useEffect } from "react";
import { DataGrid } from "../../../../common/modules/materialUI";
import Loader from "../../../../common/containers/Loader/Loader";
import { Columns } from "./Columns";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import { gridClasses } from "@mui/x-data-grid";
import UpdateModal from "../UpdateCustomField/UpdateModal";
import { customFieldActions } from "../../slices/customFieldSlice";
import DeleteConfirmationDialog from "../DeleteCustomField/DeleteConfirmationDialog";

const CustomFieldGrid = () => {
  const projects = useAppSelector((state) => state.project.projectList);
  const customFieldCreatedAt = useAppSelector(
    (state) => state.customField.create.createdAtTimestamp
  );
  const customFieldUpdatedAt = useAppSelector(
    (state) => state.customField.update.updatedAtTimestamp
  );
  const customFieldDeletedAt = useAppSelector(
    (state) => state.customField.delete.deletedAtTimestamp
  );
  const dispatch = useAppDispatch();
  const {
    data,
    isLoading,
    dispatch: dispatchFetchCustomFields,
  } = useFetchCustomFieldsService();

  // Fetch custom fields when page loads and when a new custom field is created or updated
  useEffect(() => {
    dispatchFetchCustomFields();
  }, [customFieldCreatedAt, customFieldUpdatedAt, customFieldDeletedAt]);

  return (
    <Box>
      <UpdateModal />
      <DeleteConfirmationDialog />
      <Box display="flex" flexDirection="column">
        <DataGrid
          columns={Columns({
            projects,
            onEditButtonClick: (customField) =>
              dispatch(customFieldActions.openUpdateModal(customField)),
            onDeleteButtonClick: (customField) =>
              dispatch(customFieldActions.openDeleteConfirmation(customField)),
          })}
          getRowHeight={() => "auto"}
          rows={data ?? []}
          disableRowSelectionOnClick
          checkboxSelection={false}
          loading={data === null || isLoading}
          sx={{
            [`& .${gridClasses.row}`]: {
              minHeight: `52px !important`,
            },
          }}
        />
      </Box>
      {isLoading && <Loader />}
    </Box>
  );
};

export default CustomFieldGrid;
