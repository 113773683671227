import { useAppSelector } from "../../../../common/hooks/redux";
import { BoardViewEnum } from "../../enums/BoardViewEnum";
import { Tooltip, Typography } from "@mui/material";
import { ITask } from "../../../../entities/ITask";
import Chip from "../../../../common/modules/materialUI/components/Chip/Chip";

const useSprintHelper = (props: { view: BoardViewEnum }) => {
  const listSprintData = useAppSelector(
    (state) => state.listView.list.sprintData
  );
  const kanbanSprintData = useAppSelector(
    (state) => state.kanbanView.list.sprintData
  );

  const listSprints = useAppSelector((state) => state.listView.list.sprints);
  const kanbanSprints = useAppSelector(
    (state) => state.kanbanView.list.sprints
  );

  const data =
    props.view === BoardViewEnum.LIST_VIEW ? listSprintData : kanbanSprintData;
  const sprints =
    props.view === BoardViewEnum.LIST_VIEW ? listSprints : kanbanSprints;

  const taskSprint = (taskId: number) => {
    const taskSprintRefs = data
      .filter((item) => item.taskId === taskId)
      .map((item) => item.sprintRef);
    if (!taskSprintRefs || taskSprintRefs.length === 0) {
      return null;
    }

    const sprintData = sprints.filter((item) =>
      taskSprintRefs.includes(item.ref!)
    );

    const sortedSprints = sprintData.sort((a, b) => {
      return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
    });

    return sortedSprints.length > 1
      ? [sortedSprints[0], sortedSprints[sortedSprints.length - 1]]
      : sortedSprints;
  };

  const segmentSprint = (taskSegmentId: number) => {
    if (!taskSegmentId) {
      return [];
    }

    const segmentSprintRef = data.find(
      (item) => item.taskSegmentId == taskSegmentId
    )?.sprintRef;

    if (!segmentSprintRef) {
      return null;
    }

    const sprint = sprints.find((item) => item.ref === segmentSprintRef);

    return sprint ? [sprint] : [];
  };

  return {
    chipLabel: (isSegmentView: boolean, task: ITask) => {
      const sprints = isSegmentView
        ? segmentSprint(task.segmentId)
        : taskSprint(task.id);

      if (!sprints || sprints.length === 0) {
        return null;
      }

      const label = sprints?.map((sprint) => sprint.name).join(" / ");

      return (
        <Chip
          sx={{
            backgroundColor: "info.main",
            color: "sprint.contrastText",
            borderRadius: 1,
            padding: "3px 4px",
            height: "20px",
          }}
          label={
            <Tooltip title={label}>
              <Typography
                noWrap
                color="info.contrastText"
                fontSize="12px"
                lineHeight="18px"
                letterSpacing="0.16px"
                fontWeight={400}
                maxWidth="120px"
              >
                {label}
              </Typography>
            </Tooltip>
          }
        />
      );
    },
  };
};

export default useSprintHelper;
