import TListItemProps from "../../../../../../components/Navigation/ListItem/types/TListItemProps";
import { AppRegistration } from "@mui/icons-material";
import { TSectionProps } from "../../types/TSectionProps";

const ProjectManagementListItem = (props: TSectionProps) =>
  ({
    id: "ProjectManagementListItem",
    name: "Project Management",
    icon: (
      <AppRegistration style={{ color: props.theme?.palette.action.active }} />
    ),
    path: "/settings/project",
    navigateToPath: true,
    onNavigate: props.onClick,
  } as TListItemProps);

export default ProjectManagementListItem;
