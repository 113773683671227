import { Typography, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { currencyUtil } from "../../../../../../common/utils/currencyUtil";
import { number } from "../../../../../../common/utils/number";
import { TFinancialForecastDetailsProps } from "./types/TFinancialForecastDetailsProps";

const FinancialForecastDetails = ({
  aggCosts,
  cost,
}: TFinancialForecastDetailsProps) => {
  const theme = useTheme();

  return (
    <Grid container spacing={1}>
      {!!cost && (
        <Grid container size={12} mb={3}>
          <Grid size={9}>
            <Typography
              variant="body2"
              component="span"
              color={theme.palette.text.secondary}
              paddingTop={1}
            >
              Cost of Split
            </Typography>
          </Grid>
          <Grid size={3}>
            <Typography
              variant="body2"
              component="span"
              color={theme.palette.text.secondary}
              paddingTop={1}
            >
              {currencyUtil.getSymbol(cost.costCurrency)}
              {number.formatAsPrice(cost.cost)}
            </Typography>
          </Grid>
        </Grid>
      )}
      {aggCosts.map((aggCurrencyTotalCost) => (
        <Grid key={aggCurrencyTotalCost.costCurrency} container size={12}>
          <Grid size={9}>
            <Typography
              variant="body2"
              component="span"
              color={theme.palette.text.secondary}
              paddingTop={1}
            >
              Total Cost ({aggCurrencyTotalCost.costCurrency})
              <br />
              <small style={{ fontStyle: "italic" }}>
                (for items priced in {aggCurrencyTotalCost.costCurrency})
              </small>
            </Typography>
          </Grid>
          <Grid size={3}>
            <Typography
              variant="body2"
              component="span"
              color={theme.palette.text.secondary}
              paddingTop={1}
            >
              {currencyUtil.getSymbol(aggCurrencyTotalCost.costCurrency)}
              {number.formatAsPrice(aggCurrencyTotalCost.cost)}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default FinancialForecastDetails;
