import { createTheme } from "@mui/material";
import { env } from "../../utils/env";

const theme = createTheme();

export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    dropTargetBackground: {
      default: "#39a1831f",
      paper: "#FFFFFF",
    },
    brightCardBackground: {
      highlightColor: "#EDEDED",
      default: "#353B44",
      paper: "#272e37",
      main: "#aeb7bb",
      contrastText: "#5e6a6d",
    },
    taskView: {
      mainBackground: "#323841",
      secondaryBackground: "#2A3138",
      mainContentBackground: "#2A2F38",
      headerTitleBackground: "rgba(255, 255, 255, 0.05)",
      aiButtonBackground: "#F6F7FB",
      dividerBackground: "rgba(255, 255, 255, 0.10)",
      attachmentDividerBackground: "rgba(51, 55, 68, 0.78)",
      headerBorderBottomBackground: "rgba(255, 255, 255, 0.10)",
      dropContainerBackground: "rgba(53, 59, 68, 0.7)",
      editorExpandGradient:
        "linear-gradient(180deg, rgba(42, 47, 56, 0.00) 0%, #2A2F38 72.56%)",
      textMainColor: "#F6F7FB",
      textSecondaryColor: "#F6F7FB",
      labelColor: "#B6B6B6",
      quickActionsLinkColor: "#2A3138",
      descriptionAiIcon: `${env.cdnUrl}/components/ai-sentinel/ai-sentinel-icon-dark.png`,
    },
    brightCardShadowColor: {
      primary: "inset 0 0 10px #21262C, 0 0 0 #21262C",
      secondary: "inset 0 0 4px #21262C, 0 0 0 #21262C",
    },
    mutedCardBackground: {
      default: "#313840",
      paper: "#39404A",
      main: "#aeb7bb",
    },
    cardsBackground: {
      default: "#353B44",
      paper: "#292f36",
      main: "#999999",
    },
    cardSplit: {
      color: "#ffffff82",
      border: "2px dashed #ffffff82",
    },
    inputPallet: {
      main: "#E5E1E1",
    },
    loginPallet: {
      main: "#282d34",
    },
    pickerPallet: {
      primary: "#FFFFFF",
      secondary: "#bdc2c3",
    },
    boardSideViewShadow: {
      main: "rgb(33, 38, 44) -47px -20px 20px -40px inset",
    },
    dataGrid: {
      borderColor: "#424850",
    },
    landingPageCard: {
      default: "#272E37",
      paper: "#353b44",
    },
    event: {
      background: "rgb(117 152 169 / 14%)",
      color: "#a1b7bb",
      backgroundImgSrc: `${env.cdnUrl}/components/event/event-lines-dark.png`,
      shadow: "rgb(27 33 40) 0px 0px 10px inset",
      icon: `${env.cdnUrl}/components/event/calendar-dark-theme.svg`,
    },
    offtime: {
      backgroundImgSrc: `${env.cdnUrl}/app-v2/assets/offTimeLine.svg`,
    },
    occupied: {
      shadow: "0px 0px 4px 0px rgba(0,0,0,0.2)",
    },
    spike: {
      backgroundColor: "#ffeca6",
      color: "#353c4e",
      border: "1px solid #f9c700",
      xsImg: `${env.cdnUrl}/components/spike/xsspike-icon-dark.png`,
      hover: "#f0e0a5",
    },
    floatingActions: {
      buttonBackgroundColor: "#353b44",
    },
    aiSentinel: {
      aiChatBackgroundColor: "#353b44",
      userChatBackgroundColor: "#282c32",
      chatGeneralBackgroundColor: "#30353d",
      headerBackgroundColor: "#292f35",
      headerIconBackgroundColor: "#f0f0ef",
      chatRequiredFieldsColor: "#AAABB0",
      chatRequiredFieldsBackgroundColor: "#30343B",
      regularIcon: `${env.cdnUrl}/components/ai-sentinel/ai-sentinel-icon-light.png`,
      headerIcon: `${env.cdnUrl}/components/ai-sentinel/ai-sentinel-icon-dark.png`,
    },
    helpCenter: {
      liveChatIcon: `${env.cdnUrl}/components/help-center/live-chat-icon-dark.png`,
    },
    statusTypeSchema: {
      toDo: { color: "#3a424a" },
      inProgress: { color: "#1b5054" },
      done: { color: "#3e876a" },
      textColor: "#fff",
      buttonBackground: "#2e3338",
    },
    nonWorkday: {
      timelineColor: "#51DBBC",
      overlay: "#1E232999",
    },
    sprint: {
      main: "#579085",
      contrastText: "#e7e4e4",
    },
    circularProgressBar: {
      strokeColor: "#90caf9",
      strokeBackgroundColor: "rgba(255, 255, 255, 0.3)",
    },
    divider: "#FFFFFF1F",
    text: {
      main: "#FFFFFF",
      primary: "#FFFFFF",
      secondary: "#FFFFFFB3",
      disabled: "#FFFFFF61",
      _states: {
        hover: "#FFFFFF14",
        selected: "#FFFFFF29",
        focus: "#FFFFFF1F",
        focusVisible: "#FFFFFF4D",
      },
    },
    primary: {
      main: "#57B298",
      dark: "#39A183",
      light: "#B4DDD1",
      contrastText: "#353B44DE",
    },
    primaryStates: {
      hover: "#84C8B414",
    },
    secondary: {
      main: "#F8C700",
      dark: "#F8BD00",
      light: "#FEEBA6",
      contrastText: "#353B44DE",
    },
    secondaryStates: {
      focus: "#FEEBA61F",
    },
    tertiary: {
      main: "#FF8A65",
      dark: "#FF5722",
      light: "#FFCCBC",
      contrastText: "#353B44DE",
    },
    action: {
      active: "#FFFFFF66",
      hover: "#FFFFFF14",
      selected: "#FFFFFF29",
      focus: "#FFFFFF1F",
      disabled: "#FFFFFF61",
      disabledBackground: "#FFFFFF1F",
    },
    error: {
      main: "#F44336",
      dark: "#D32F2F",
      light: "#E57373",
      contrastText: "#353B44",
    },
    warning: {
      main: "#FFC107",
      dark: "#FFA000",
      light: "#FFCA28",
      contrastText: "#353B44DE",
    },
    info: {
      main: "#4FC3F7",
      dark: "#039BE5",
      light: "#81D4FA",
      contrastText: "#353B44DE",
    },
    success: {
      main: "#8BC34A",
      dark: "#689F38",
      light: "#AED581",
      contrastText: "#353B44DE",
    },
    successLight: {
      main: "#50C878",
    },
    risk: {
      main: "#FFAF42",
    },
    common: {
      white: "#FFFFFF",
      black: "#353B44",
    },
    background: {
      default: "#22262B",
      paper: "#353B44",
    },
    backgroundElevation: {
      backgroundPaperElevation_0: "#353B44",
      backgroundPaperElevation_1: "#282E36",
      backgroundPaperElevation_2: "#454B53",
      backgroundPaperElevation_3: "#3A414C",
      backgroundPaperElevation_4: "#272727",
      backgroundPaperElevation_5: "#2A2A2A",
      backgroundPaperElevation_6: "#6A7C9314",
      backgroundPaperElevation_7: "#2C2C2C",
      backgroundPaperElevation_8: "#2E2E2E",
    },
    elevation: {
      outlined: "#353B441F",
    },
    _components: {
      avatar: {
        "1": "#84C8B4",
        "2": "#FFAB91",
        "3": "#B39DDB",
        "4": "#FFF59D",
        "5": "#90CAF9",
        "6": "#9FA8DA",
        "7": "#F48FB1",
        "8": "#81D4FA",
        avatarText: "#353B44CC",
      },
      input: {
        standard: {
          enabledBorder: "#FFFFFF6B",
          hoverBorder: "#FFFFFF",
        },
        filled: {
          enabledFill: "#FFFFFF17",
          hoverFill: "#FFFFFF1F",
        },
        outlined: {
          enabledBorder: "#FFFFFF3B",
          hoverBorder: "#FFFFFF",
        },
      },
      switch: {
        knobFillEnabled: "#84C8B4",
        slideFill: "#FFFFFF61",
        knowFillDisabled: "#5C6E82",
        knobDisabled: "#2A333E",
      },
      rating: {
        enabledBorder: "#FFFFFF3B",
        activeFill: "#FFB400",
      },
      snackbar: {
        fill: "#FFFFFF",
      },
      chip: {
        defaultCloseFill: "#FFFFFF",
        defaultHoverFill: "#FFFFFF1F",
        defaultEnabledBorder: "#4C5A6B",
        defaultFocusFill: "#FFFFFF33",
      },
      backdrop: {
        fill: "#00000080",
      },
      appBar: {
        defaultFill: "#353B44",
      },
      breadcrumbs: {
        collapseFill: "#8090A4",
      },
      alert: {
        error: {
          color: "#F4C7C7",
          background: "#160B0B",
        },
        warning: {
          color: "#FFE2B7",
          background: "#191207",
        },
        info: {
          color: "#B8E7FB",
          background: "#071318",
        },
        success: {
          color: "#CCE8CD",
          background: "#0C130D",
        },
      },
      stepper: {
        connector: "#5C6E82",
      },
      card: {
        CardMainFill: "#22262B",
      },
    },
    _native: {
      "scrollbar-bg": "#4C5A6B",
    },
    _common: {
      black_states: {
        outlinedBorder: "#FFFFFF40",
      },
    },
  },
  shape: {
    borderRadius: 4,
  },
  typography: {
    fontFamily: "Roboto, Helvetica",
    h1: {
      fontSize: "96px",
      fontWeight: 300,
      letterSpacing: "-1.5px",
      lineHeight: "116.7%",
    },
    h2: {
      fontSize: "60px",
      fontWeight: 300,
      letterSpacing: "-0.5px",
      lineHeight: "120%",
    },
    h3: {
      fontSize: "48px",
      fontWeight: 400,
      letterSpacing: "0px",
      lineHeight: "116.7%",
    },
    h4: {
      fontSize: "34px",
      fontWeight: 400,
      letterSpacing: "0.25px",
      lineHeight: "123.5%",
    },
    h5: {
      fontSize: "24px",
      fontWeight: 400,
      letterSpacing: "0px",
      lineHeight: "133.4%",
    },
    h6: {
      fontSize: "20px",
      fontWeight: 500,
      letterSpacing: "0.15px",
      lineHeight: "160%",
    },
    subtitle1: {
      fontSize: "15px",
      fontWeight: 500,
      letterSpacing: "0.15px",
      lineHeight: "175%",
    },
    subtitle2: {
      fontSize: "14px",
      fontWeight: 500,
      letterSpacing: "0.1px",
      lineHeight: "157%",
    },
    body1: {
      fontSize: "15px",
      fontWeight: 400,
      letterSpacing: "0.15px",
      lineHeight: "150%",
    },
    body2: {
      fontSize: "14px",
      fontWeight: 400,
      letterSpacing: "0.17px",
      lineHeight: "143%",
    },
    caption: {
      fontSize: "12px",
      fontWeight: 400,
      letterSpacing: "0.4px",
      lineHeight: "166%",
    },
    overline: {
      fontSize: "12px",
      fontWeight: 400,
      letterSpacing: "1px",
      lineHeight: "266%",
    },
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: () => ({
          backgroundImage: "none",
        }),
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundImage: "none",
        },
      },
    },
    MuiFormControlLabel: {
      defaultProps: {
        slotProps: {
          typography: {
            variant: "body2",
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#353B44E6",
          color: "#FFFFFF",
          boxShadow: theme.shadows[12],
          ...theme.typography.body2,
        },
        arrow: {
          color: "#353B44E6",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        popper: {
          // Required for showing Autocomplete options inside AI Sentinel when any Dialog is opened
          zIndex: 1500,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          // Required for showing date picker dialog inside AI Sentinel: above the chat and above any opened Dialog
          zIndex: 1500,
        },
      },
    },
    MuiPopper: {
      defaultProps: {
        style: {
          // Required for showing date picker dialog inside AI Sentinel: above the chat and above any opened Dialog
          zIndex: 1500,
        },
      },
    },
  },
});
