import ExpandableIcon from "../../../../common/components/Icon/Expandable/ExpandableIcon";
import { useEffect, useState } from "react";
import { Autocomplete } from "../../../../common/modules/materialUI";
import { useUserListService } from "../../../user/services/useUserListService";
import { Box } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useAppDispatch } from "../../../../common/hooks/redux";
import { offtimeActions } from "../../slices/offtimeSlice";
import useMedia from "../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../common/hooks/useMedia/enums/QueryEnum";

const UserPicker = () => {
  const isTouchDevice = useMedia(QueryEnum.IS_TOUCH_DEVICE);
  const [showUserPicker, setShowUserPicker] = useState<boolean>(false);
  const [manageFor, setManageFor] = useState<string | undefined>(undefined);
  const userListService = useUserListService({ roleLimited: true });
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(offtimeActions.setUserId(undefined));
    };
  }, []);

  const userChangeHandler = (userId: number | undefined) =>
    dispatch(offtimeActions.setUserId(userId));

  return (
    <Box pb={3} position="relative">
      <Box
        style={{ cursor: "pointer", fontSize: "0.9rem" }}
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        gap={1}
      >
        <Box
          display="flex"
          alignItems="center"
          onClick={() => setShowUserPicker((prevState) => !prevState)}
        >
          <span>
            Manage offtime for {manageFor ? <b>{manageFor}</b> : "others"}
          </span>
          <ExpandableIcon direction={!showUserPicker ? "down" : "up"} />
        </Box>
        {manageFor && (
          <Close
            style={{ fontSize: "1.1rem" }}
            onClick={() => {
              setManageFor(undefined);
              setShowUserPicker(false);
              userChangeHandler(undefined);
            }}
          />
        )}
      </Box>
      {showUserPicker && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          width="200px"
          position="absolute"
          bottom={"-20px"}
          left={0}
        >
          <Autocomplete
            name="userId"
            label="For user"
            service={userListService}
            onChange={(_, value) => {
              setManageFor(value.label);
              setShowUserPicker(false);
              userChangeHandler(value.id);
            }}
            textFieldProps={{
              forceFocus: !isTouchDevice,
            }}
            sx={{ width: "100%" }}
          />
        </Box>
      )}
    </Box>
  );
};

export default UserPicker;
