import { FocusEvent, MouseEvent, useEffect, useState } from "react";
import { Box, Menu, MenuItem, Typography } from "@mui/material";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../common/hooks/redux";
import { pointConverter } from "../../../../../common/utils/pointConverter";
import {
  buildLabel,
  getDayByCoords,
  lastContainerRef,
} from "./useVirtualizationHelper";
import { date, FORMAT_RAW_DATE_ONLY } from "../../../../../common/utils/date";
import moment from "moment";
import { FillTimeLineItemTypeEnum } from "../../../enums/FillTimeLineItemTypeEnum";
import { TBoardRefProp } from "../../../../board/types/TBoardRefProp";
import { useFillTimelineService } from "../services/useFillTimelineService";
import { IFillTimelineRequest } from "../../../interfaces/IFillTimelineRequest";
import { holisticViewActions } from "../slices/holisticViewSlice";
import { useUpdateUserLanes } from "./useUpdateUserLanes";
import { systemNotificationActions } from "../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { THttpClientError } from "../../../../../common/modules/httpClient/types/THttpClientError";
import { IUser } from "../../../../../entities/IUser";
import { user as userUtil } from "../../../../../common/utils/user";
import { useIsFeatureAllowed } from "../../../../../common/modules/accessControl/hooks/useIsFeatureAllowed";
import { FeatureEnum } from "../../../../../common/modules/accessControl/enums/FeatureEnum";
import { featureUtil } from "../../../../../common/modules/accessControl/utils/featureUtil";

type TProps = {
  projectAbbr: string;
  user: IUser;
} & TBoardRefProp;

export const useTimelineMenu = ({ boardRef, projectAbbr, user }: TProps) => {
  const isHolisticShiftAllowed = useIsFeatureAllowed(
    FeatureEnum.HOLISTIC_SHIFT
  );
  const [menuPosition, setMenuPosition] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);
  const [coordData, setCoordData] = useState<{
    label: string;
    date: string;
  } | null>(null);
  const { dispatch: dispatchFillTimeline } = useFillTimelineService({
    boardRef,
  });
  const dispatch = useAppDispatch();
  const { dispatch: dispatchUpdateUserLane } = useUpdateUserLanes({
    projectAbbr: projectAbbr,
    boardRef: boardRef,
  });

  const sprintLabel = useAppSelector((state) => state.customs.sprintLabel);
  const userLaneOverlay = useAppSelector(
    (state) => state.holisticView.userLaneOverlay
  );
  const workingHours = useAppSelector(
    (state) => state.holisticView.workingHours
  );
  const stepInPx = pointConverter.oneWorkingHourInPixels(workingHours);

  useEffect(() => {
    // Calculate the current position inside the lane based on the scroll left of the container,
    // so we know what is the container left offset and right offset, mouse drag coordinates
    // are current mouse coordinates and to achieve this we can sum to the scroll left the
    // diff of current x and container offset left to receive correct internal coordinates
    if (!menuPosition?.mouseX || !lastContainerRef) {
      return;
    }

    const scrollLeft = lastContainerRef.scrollLeft;
    const shiftInWindow = menuPosition?.mouseX - lastContainerRef.offsetLeft;
    const currentX = scrollLeft + shiftInWindow;

    const coordData = getDayByCoords(currentX);
    if (!coordData) {
      return;
    }

    const { day, coord } = coordData;
    const hours = Math.ceil((currentX - coord) / stepInPx);
    const dateToShift = `${moment(day)!.format(
      FORMAT_RAW_DATE_ONLY
    )} ${date.getRealWorldTime(hours, workingHours)}`;
    const label = buildLabel(day, hours);

    setCoordData({ label, date: dateToShift });
  }, [menuPosition?.mouseX]);

  const timelineClickHandler = (event: MouseEvent<HTMLDivElement>) => {
    if (event.target !== event.currentTarget || userLaneOverlay?.display) {
      return;
    }

    setMenuPosition({
      mouseX: event.clientX,
      mouseY: event.clientY,
    });
  };

  const closeHandler = () => {
    setMenuPosition(null);
  };

  const blurHandler = (_: FocusEvent<HTMLElement>) => {
    closeHandler();
  };

  const autoShiftHandler = (itemType: FillTimeLineItemTypeEnum) => {
    if (!isHolisticShiftAllowed) {
      dispatch(
        systemNotificationActions.open({
          message: featureUtil.notAvailableText,
          variant: "warning",
        })
      );
      return;
    }

    showTimelineProgressHandler(true);
    dispatchFillTimeline({
      body: {
        itemType,
        date: coordData?.date,
        assignedTo: user.id,
      } as IFillTimelineRequest,
    })
      .then(() => {
        dispatchUpdateUserLane().finally(() => {
          showTimelineProgressHandler(false);
        });
      })
      .catch((error: THttpClientError) => {
        showTimelineProgressHandler(false);

        if ([406, 403].includes(error.status)) {
          dispatch(
            systemNotificationActions.open({
              message: error.data.message,
              variant: "warning",
            })
          );
          return;
        }

        dispatch(
          systemNotificationActions.open({
            message: "Something went wrong, please try again.",
            variant: "error",
          })
        );
      });
  };

  const showTimelineProgressHandler = (showShiftingProgress: boolean) => {
    showShiftingProgress
      ? setTimeout(
          () => dispatch(holisticViewActions.animateUserLaneOverlay(true)),
          10
        )
      : dispatch(holisticViewActions.displayUserLaneOverlay(false));
  };

  return {
    menuComponent:
      user.id !== 0 ? (
        <Menu
          open={!!menuPosition}
          onClose={closeHandler}
          onBlur={blurHandler}
          anchorReference="anchorPosition"
          anchorPosition={
            menuPosition !== null
              ? { top: menuPosition.mouseY - 6, left: menuPosition.mouseX + 2 }
              : undefined
          }
          slotProps={{
            paper: {
              sx: {
                maxWidth: "250px", // Set maximum width for the menu
                backgroundColor: "background.paper",
                backgroundImage: "none",
              },
            },
          }}
        >
          <Box sx={{ padding: "0 12px 8px" }}>
            <Typography variant="subtitle2" color="text.secondary">
              Auto shift tasks of {userUtil.shortName(user)} to: <br />
              <b>{coordData?.label}</b>
            </Typography>
          </Box>
          <MenuItem
            onClick={() => autoShiftHandler(FillTimeLineItemTypeEnum.SPRINT)}
          >
            {sprintLabel} tasks
          </MenuItem>
          <MenuItem
            onClick={() => autoShiftHandler(FillTimeLineItemTypeEnum.ALL)}
          >
            All tasks
          </MenuItem>
        </Menu>
      ) : null,
    timelineClickHandler,
  };
};
