import { IconButton, keyframes } from "@mui/material";
import { Lightbulb } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/redux";
import { tipsSliderActions } from "../slices/tipsSliderSlice";
import useTipHelper from "../hooks/useTipHelper";
import { useEffect } from "react";
import TipsSlider from "./TipsSlider/TipsSlider";

const fadeInOut = keyframes`
  0%, 100% {
    opacity: 1; 
  }
  50% {
    opacity: 0.8;      
  }
`;

type TProps = {
  slugPrefix: string;
  name: string;
};

const TipIcon = ({ slugPrefix, name }: TProps) => {
  const dispatch = useAppDispatch();
  const { fetchFeatures } = useTipHelper();
  const { data: tips, allSeen } = useAppSelector(
    (state) => state.tipsSlider.featureData
  );

  useEffect(() => {
    fetchFeatures(slugPrefix, name).catch(() => {});

    return () => {
      dispatch(tipsSliderActions.clearFeatureData());
    };
  }, [slugPrefix, name]);

  return (
    <>
      {allSeen === false && (
        <IconButton
          onClick={() => dispatch(tipsSliderActions.openTipsSlider())}
          color="secondary"
          sx={{
            border: "1px solid",
            width: "36px",
            height: "36px",
            "&:hover": {
              ".MuiSvgIcon-root": {
                animation: "none",
              },
            },
          }}
        >
          <Lightbulb
            sx={{
              animation: `${fadeInOut} 1.2s ease-in-out infinite`,
            }}
          />
        </IconButton>
      )}

      {tips.length > 0 && (
        <TipsSlider
          tips={tips}
          slugPrefix={slugPrefix}
          learningLater={allSeen ?? false}
        />
      )}
    </>
  );
};

export default TipIcon;
