import { Box, SxProps, useTheme } from "@mui/material";
import { env } from "../../../../utils/env";

const TaskSplitLine = ({
  left,
  right,
  leftSx,
  rightSx,
  imgHeight,
  isSpike,
}: {
  left?: Boolean;
  right?: Boolean;
  leftSx?: SxProps;
  rightSx?: SxProps;
  imgHeight?: string;
  isSpike?: boolean;
}) => {
  const theme = useTheme();

  return (
    <>
      {left && (
        <Box
          sx={{
            ...leftSx,
          }}
        >
          <img
            height={imgHeight}
            src={
              env.cdnUrl +
              (theme.palette.mode === "light"
                ? "/app-v2/assets/LeftSplitDark.svg"
                : isSpike
                ? "/app-v2/assets/LeftSplitLightSpike.svg"
                : "/app-v2/assets/LeftSplitLight.svg")
            }
            alt=""
          />
        </Box>
      )}
      {right && (
        <Box
          sx={{
            ...rightSx,
          }}
        >
          <img
            height={imgHeight}
            src={
              env.cdnUrl +
              (theme.palette.mode === "light"
                ? "/app-v2/assets/RightSplitDark.svg"
                : isSpike
                ? "/app-v2/assets/RightSplitLightSpike.svg"
                : "/app-v2/assets/RightSplitLight.svg")
            }
            alt=""
          />
        </Box>
      )}
    </>
  );
};

export default TaskSplitLine;
