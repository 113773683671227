import useForm from "../../../../../common/hooks/useForm/useForm";
import Form from "../../../../../common/components/Form/Form";
import { THttpClientError } from "../../../../../common/modules/httpClient/types/THttpClientError";
import { IOAuthResource } from "../../../interfaces/IOAuthResource";
import { TSigninFormAttributes } from "./types/TSigninFormAttributes";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthenticateService } from "../../../services/useAuthenticateService";
import { Button, TextField } from "../../../../../common/modules/materialUI";
import { jwt } from "../../../../../common/utils/jwt";
import PasswordField from "../containers/Password/PasswordField";
import useMedia from "../../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../../common/hooks/useMedia/enums/QueryEnum";
import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { useCreateStripeSessionService } from "../../../../stripe/services/useCreateStripeSessionService";
import { IStripeSessionResource } from "../../../../stripe/interfaces/IStripeSessionResource";
import { systemNotificationActions } from "../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { useAppDispatch } from "../../../../../common/hooks/redux";
import { IStripeError } from "../../../../stripe/interfaces/IStripeError";
import useWindow from "../../../../../common/hooks/useWindow";

export const ROUTE_ON_SIGNIN = "/";
export const ROUTE_TO_AUTH = "/auth";
export const ROUTE_TO_FORGOT_PASSWORD = "/forgotPassword";

const TSSigninForm = () => {
  const { openNewTab } = useWindow();
  const isTouchDevice = useMedia(QueryEnum.IS_TOUCH_DEVICE);
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const form = useForm<TSigninFormAttributes>();
  const { getValidationErrorAtIndex } = useForm();
  const [paymentRequiredError, setPaymentRequiredError] = useState<
    string | null
  >(null);
  const [validationError, setValidationError] = useState<string | null>(null);
  const [subscriptionPlan, setSubscriptionPlan] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const { isLoading, dispatch: dispatchAuthenticate } =
    useAuthenticateService();

  const {
    isLoading: isLoadingStripeSession,
    dispatch: dispatchCreateStripeSessionService,
  } = useCreateStripeSessionService();

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();

    dispatchAuthenticate({ body: form.data })
      .then((payload: IOAuthResource) => {
        jwt.store(payload.accessToken);
        navigate(location.state?.from ?? ROUTE_ON_SIGNIN, { state: null });
      })
      .catch((error: THttpClientError) => {
        if (error.status === 402) {
          const errors = error.data.errors as IStripeError;
          setPaymentRequiredError(error.data.message);
          setSubscriptionPlan(errors.subscriptionPlan!);
          setEmail(errors.email!);
        }
        if (error.status === 422) {
          const errors = error.data.errors as any[];
          setValidationError(errors[0]);
        }
        form.errorHandler(error);
      });
  };

  const createStripeSessionHandler = () => {
    dispatchCreateStripeSessionService({
      body: {
        subscriptionPlan: subscriptionPlan,
        email: email,
      },
    })
      .then((resource: IStripeSessionResource) => {
        openNewTab(resource.url, true);
      })
      .catch((error: THttpClientError) => {
        const validationFirstError = getValidationErrorAtIndex(0, error);
        if (validationFirstError) {
          dispatch(
            systemNotificationActions.open({
              variant: "error",
              message: validationFirstError,
            })
          );
        }
      });
  };

  const forgotPasswordHandler = () => {
    navigate(ROUTE_TO_FORGOT_PASSWORD);
  };

  return (
    <Form
      onSubmit={submitHandler}
      actionElement={
        <Button
          disabled={isLoading || isLoadingStripeSession}
          loading={isLoading}
          variant="text"
          type="submit"
          fullWidth
          size="large"
        >
          Sign In
        </Button>
      }
    >
      <TextField
        forceFocus={!isTouchDevice}
        form={form}
        name="username"
        label="Email address"
        // autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
      />

      <PasswordField form={form} showRequired={false} />
      <Box display="flex" justifyContent="flex-end" style={{ marginBottom: 0 }}>
        <Button
          tabIndex={-1}
          disabled={isLoading}
          variant="text"
          onClick={forgotPasswordHandler}
          sx={{ fontSize: "0.5625rem", color: "text.secondary" }}
        >
          Forgot password?
        </Button>
      </Box>

      {paymentRequiredError && (
        <Typography textAlign="left" sx={{ fontSize: "14px" }}>
          {paymentRequiredError}
          <Button
            variant="text"
            type="submit"
            size="medium"
            onClick={createStripeSessionHandler}
            disabled={isLoading || isLoadingStripeSession}
            loading={isLoadingStripeSession}
          >
            Go to checkout
          </Button>
        </Typography>
      )}

      {validationError && (
        <Typography textAlign="left">{validationError}</Typography>
      )}
    </Form>
  );
};

export default TSSigninForm;
