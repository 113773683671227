import { Box, IconButton, useTheme } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { TAvatarIconProps } from "./types/TAvatarIconProps";
import { jwt } from "../../../../common/utils/jwt";
import { string } from "../../../../common/utils/string";
import { Tooltip } from "../../../../common/modules/materialUI";
import PersonIcon from "@mui/icons-material/Person";

const AvatarIcon = (props: TAvatarIconProps) => {
  const userJwt = jwt.parse()!;
  const theme = useTheme();
  const sxProps = {
    ml: props.position === "right" ? "auto" : "",
    p: props.noPadding ? 0 : "",
    border: props.hasBorder
      ? "1px solid " + theme.palette.background.default
      : undefined,
    ...(props.isHovered && {
      "&:active": {
        borderWidth: "2px",
      },
      "@media (hover: hover)": {
        "&:hover": {
          borderWidth: "2px",
        },
      },
    }),
    ...props.sx,
  };

  const bgcolor = props.colorInactive
    ? "grey.500"
    : props.colorError
    ? "error.main"
    : props.colorWarning
    ? "warning.main"
    : theme.palette._components.avatar[8];

  return (
    <Tooltip when={!!props.tooltip} title={props.tooltip} arrow>
      <IconButton
        className={props.className}
        onClick={(event) => {
          props.clickHandler?.(event);
        }}
        size="small"
        sx={sxProps}
        disableRipple={!!props.disableRipple}
        {...props.iconButtonProps}
      >
        <Avatar
          sx={{
            bgcolor,
            width: props.size ?? 30,
            height: props.size ?? 30,
            fontSize: props.fontSize ?? (props.size ? props.size / 2 : 15),
            fontWeight: props.isBold ? "bold" : "normal",
            position: "relative",
            ...props.avatarStyles,
          }}
        >
          {props.isUnassigned && (
            <PersonIcon
              sx={{
                fontSize: "15px",
              }}
            />
          )}
          {!props.isUnassigned &&
            string.acronym(props.userName ?? userJwt.name)}

          {props.isHovered && (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                opacity: 0,
                transition: "opacity 0.1s",
                "&:active": {
                  bgcolor: "_components.avatar.avatarText",
                  opacity: 0.5,
                },
                "@media (hover: hover)": {
                  "&:hover": {
                    bgcolor: "_components.avatar.avatarText",
                    opacity: 0.5,
                  },
                },
              }}
            />
          )}
        </Avatar>
      </IconButton>
    </Tooltip>
  );
};

export default AvatarIcon;
