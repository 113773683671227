import { TTaskRowProps } from "./types/TTaskRowProps";
import classes from "./LeftContainer.module.scss";
import { Box, Typography } from "@mui/material";
import StatusIcon from "../Status/StatusIcon";
import { useTaskViewHandler } from "../../../../../modules/task/modules/view/hooks/useTaskViewHandler";
import { NavLink } from "react-router-dom";
import { taskUtil } from "../../../../utils/taskUtil";
import { link } from "../../../../utils/link";
import IconImage from "../../../Icon/IconImage/IconImage";
import SegmentCount from "../../../SegmentCount/SegmentCount";
import { InternalTypeEnum } from "../../../../../entities/columns/task/InternalTypeEnum";

const LeftContainer = (props: TTaskRowProps) => {
  const { openTaskViewHandler } = useTaskViewHandler({
    taskRef: props.task.ref,
    segmentNumber: props.task.number,
  });

  const taskHandler = () => {
    openTaskViewHandler();
  };

  const anchorHandler = (e: React.MouseEvent<HTMLAnchorElement>) => {
    link.anchorClickHandler(e);
  };

  const statusType = props.isSegmentView
    ? props.task.statusType
    : props.task.aggStatus;
  const taskUrl = taskUtil.urlByRef(
    props.task.ref,
    props.isSegmentView ? props.task.number : undefined
  );

  return (
    <div className={classes.container}>
      <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <StatusIcon
          className={classes["state-icon"]}
          style={{ display: "inline-flex" }}
          iconSize={20}
          statusType={statusType}
          status={
            props.isSegmentView || props.task.segmentCount === 1
              ? props.task.status
              : props.task.aggStatus
          }
        />
        {props.task.internalType === InternalTypeEnum.SPIKE && (
          <IconImage
            imgDark={"/app-v2/assets/spikeForKanbanLight.svg"}
            img={"/app-v2/assets/spikeForKanbanDark.svg"}
            size={16}
          />
        )}
      </Box>

      <Box sx={{ whiteSpace: "nowrap", gap: "4px" }} onClick={taskHandler}>
        <NavLink
          to={taskUrl}
          onClick={anchorHandler}
          style={{ color: props.color }}
        >
          <Typography
            variant={"caption"}
            fontWeight="bold"
            sx={{ cursor: "pointer" }}
          >
            {props.task.ref}
          </Typography>
        </NavLink>

        {props.task.segmentCount > 1 ? (
          <SegmentCount
            task={{
              segmentCount: props.task.segmentCount,
              segmentCountInGroup: props.task.segmentCountInGroup,
              number: props.task.number,
            }}
            statusName={props.task.aggStatus.toLocaleLowerCase()}
            isSegmentView={props.isSegmentView}
          />
        ) : (
          ""
        )}
      </Box>

      <Box
        sx={{
          lineHeight: "1.3rem",
          height: "1.3rem",
          width: "90%",
          alignItems: "start !important",
          cursor: "pointer",
          overflow: "hidden",
        }}
        onClick={taskHandler}
      >
        <Typography variant="body2">{props.task.name}</Typography>
      </Box>
    </div>
  );
};

export default LeftContainer;
