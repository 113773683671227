import { useState } from "react";
import TListProps from "./types/TListProps";
import classes from "./List.module.css";
import Loading from "../../Icon/Loading/Loading";
import {
  Box,
  Collapse,
  ListItemButton,
  Typography,
  useTheme,
} from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useLocation } from "react-router-dom";

const List = (props: TListProps) => {
  const theme = useTheme();
  const location = useLocation();

  const [open, setOpen] = useState<boolean>(() => {
    if (props?.projectAbbr) {
      const storedState = localStorage.getItem(props.projectAbbr);
      return storedState === "true";
    }
    return true;
  });

  const handleClick = () => {
    setOpen(!open);
    if (props?.projectAbbr) {
      localStorage.setItem(props.projectAbbr, String(!open));
    }
  };

  return (
    <>
      {props.isDropdownList && (
        <ListItemButton
          onClick={handleClick}
          sx={{
            padding: "0",
            paddingRight: "12px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingLeft: props.onlyIconList ? "13px" : "11px",
            background:
              !open &&
              props?.projectAbbr === location.pathname.split("/")[1] &&
              !props.onlyIconList
                ? theme.palette.action.focus
                : "none",
          }}
        >
          {props.title && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginRight: props.onlyIconList ? "0" : "18px",
                width: "100%",
                position: "relative",
                height: "36px",
              }}
            >
              {!props.loading && !props.onlyIconList ? (
                <ExpandMore
                  style={{
                    color: theme.palette.action.active,
                    transform: open ? "rotate(0deg)" : "rotate(-90deg)",
                    transition: "transform 0.2s",
                  }}
                />
              ) : null}
              {props.title}
            </Box>
          )}
          {props.loading && (
            <Loading
              style={{ marginTop: props.onlyIconList ? "12px" : "0" }}
              className={classes["loading-icon"]}
            />
          )}
          <Typography
            component={"span"}
            sx={{ position: "absolute", right: "12px" }}
          >
            {!props.loading && props.action1Element && props.action1Element}
          </Typography>
        </ListItemButton>
      )}
      <Collapse in={open} timeout="auto" unmountOnExit>
        {props.children && <ul className={classes.ul}>{props.children}</ul>}
      </Collapse>
    </>
  );
};

export default List;
