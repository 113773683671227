import { Box, Typography, useTheme } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Tooltip } from "../../../../../../common/modules/materialUI";
import AvatarIcon from "../../../../../user/containers/AvatarIcon/AvatarIcon";
import classes from "../Avatar.module.css";
import Menu from "../../../../../../common/containers/Menu/Menu";
import { menuItem } from "../../../../../../common/containers/Menu/utils/menuItem";
import { IUser } from "../../../../../../entities/IUser";
import HeaderItem from "../../../../../../common/containers/Menu/common/HeaderItem";
import { useAppSelector } from "../../../../../../common/hooks/redux";
import useSelectedUsers from "../hooks/useSelectedUsers";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";

type TpropsType = {
  users: IUser[];
  isArrowButton?: boolean;
  count?: number;
  buttonCountSize?: number;
  isOpenCloseMenu?: boolean;
  setOpenCloseMenu?: (openClose: boolean) => void;
  openMenu?: boolean;
};

const AvatarMenu = ({
  users,
  isArrowButton,
  count,
  buttonCountSize,
  isOpenCloseMenu,
  setOpenCloseMenu,
  openMenu,
}: TpropsType) => {
  const theme = useTheme();
  const selectedUsersId = useAppSelector((state) => state.user.selectedIds);

  const { userFilterHandler } = useSelectedUsers();

  return (
    <Menu
      openHandler={(openClose) => setOpenCloseMenu?.(openClose)}
      stretchOnMobile={false}
      closeOnSelection={false}
      sx={{ overflowY: "auto", width: "240px", maxHeight: "620px" }}
      padding={"0 0 8px 0"}
      button={(clickHandler) => (
        <>
          {isArrowButton ? (
            <Box
              onClick={clickHandler}
              display="flex"
              alignItems="center"
              sx={{ cursor: "pointer" }}
            >
              <ArrowDropDownIcon
                fontSize="small"
                sx={{
                  transform: isOpenCloseMenu
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                  color: "action.active",
                }}
              />
            </Box>
          ) : (
            <Tooltip
              title={`+${users.length - (count ?? 0)} Assignees`}
              when={users.length > (count ?? 0)}
              arrow
            >
              <Box
                onClick={(e) => openMenu && clickHandler(e)}
                bgcolor="_components.avatar.8"
                width={buttonCountSize ? buttonCountSize + 2 + "px" : "24px"}
                height={buttonCountSize ? buttonCountSize + 2 + "px" : "24px"}
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius="100%"
                zIndex={1}
                border={`1px solid ${theme.palette.background.default}`}
                sx={{
                  cursor: "pointer",
                }}
              >
                <Typography
                  variant="body2"
                  color="_components.avatar.avatarText"
                  fontSize={
                    buttonCountSize
                      ? Math.floor(buttonCountSize / 2.5) + "px"
                      : "11px"
                  }
                >{`+${users.length - (count ?? 0)}`}</Typography>
              </Box>
            </Tooltip>
          )}
        </>
      )}
      menuItems={[
        HeaderItem("Users"),
        ...users.map((user, index) =>
          menuItem.make({
            component: (
              <Box
                display="flex"
                alignItems="center"
                width="100%"
                justifyContent="space-between"
              >
                <Tooltip title={user.name}>
                  <Box display="flex" alignItems="center">
                    <AvatarIcon
                      className={classes.icon}
                      key={index}
                      userName={user.name}
                      size={25}
                      fontSize={10}
                      isUnassigned={user.id === 0}
                      avatarStyles={{ color: "_components.avatar.avatarText" }}
                      noPadding
                    />
                    <Typography
                      noWrap
                      maxWidth="109px"
                      ml="22px"
                      color="text.primary"
                    >
                      {user.name}
                    </Typography>
                  </Box>
                </Tooltip>
                <Tooltip title="Highlight">
                  {selectedUsersId.findIndex(
                    (selectedUserId: number) => selectedUserId === user.id
                  ) !== -1 ? (
                    <BookmarkIcon
                      sx={{ color: "secondary.main", fontSize: "20px" }}
                    />
                  ) : (
                    <BookmarkBorderIcon
                      sx={{ color: "action.active", fontSize: "20px" }}
                    />
                  )}
                </Tooltip>
              </Box>
            ),
            handler: () => userFilterHandler(user.id),
            hasDivider: false,
            menuItemsProps: {
              sx: {
                margin: 0,
                py: "4px",
              },
            },
          })
        ),
      ]}
    />
  );
};
export default AvatarMenu;
