import { Box, IconButton, Paper, useTheme } from "@mui/material";
import { TextField } from "../../../../common/modules/materialUI";
import { Send } from "@mui/icons-material";
import { useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../../../common/hooks/redux";
import { keyboard } from "../../../../common/utils/keyboard";
import SimpleTextMessage from "./SimpleTextMessage";
import BreakdownMessage from "./BreakdownMessage";
import useAiHandleSendUserMessage from "../../hooks/useAiHandleSendUserMessage";
import InputAdornment from "@mui/material/InputAdornment";
import ChatHeader from "./ChatHeader";
import useMedia from "../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../common/hooks/useMedia/enums/QueryEnum";

const ChatBox = () => {
  const aiSentinelConfig = useAppSelector((state) => state.org.init.aiSentinel);
  const isTouchDevice = useMedia(QueryEnum.IS_TOUCH_DEVICE);
  // Ref to the end of the chat container
  const messagesBoxRef = useRef<HTMLDivElement>(null);
  const { handleSendUserMessage } = useAiHandleSendUserMessage();
  const [inputText, setInputText] = useState("");
  const userMessage = useAppSelector((state) => state.aiSentinel.userMessage);
  const aiTextMessage = useAppSelector(
    (state) => state.aiSentinel.aiTextMessage
  );
  const showBreakdownMessage = useAppSelector(
    (state) => state.aiSentinel.breakdownMessage.show
  );
  const theme = useTheme();
  const maxWidth768 = useMedia(QueryEnum.MAX_WIDTH_768);

  // Scrolling to the bottom of the chat
  useEffect(() => {
    const messagesBox = messagesBoxRef.current;

    const resizeObserver = new MutationObserver(() => {
      messagesBox?.scrollTo({
        top: messagesBox?.scrollHeight,
        behavior: "smooth",
      });
    });

    if (messagesBox) {
      resizeObserver.observe(messagesBox, { childList: true, subtree: true });
    }

    return () => {
      if (messagesBox) {
        resizeObserver.disconnect();
      }
    };
  }, []);

  const handleSendMessage = () => {
    handleSendUserMessage(inputText);

    setInputText("");
  };

  return (
    <Paper
      sx={{
        width: "100%",
        height: maxWidth768 ? "100vh" : "600px",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        backgroundColor: theme.palette.aiSentinel.chatGeneralBackgroundColor,
        backgroundImage: "none",
        borderRadius: maxWidth768 ? 0 : 5,
      }}
    >
      <ChatHeader />

      {/* Messages box */}
      <Box
        ref={messagesBoxRef}
        sx={{
          overflow: "auto",
          padding: "10px",
          flex: 1,
          overscrollBehavior: "contain",
        }}
      >
        <SimpleTextMessage
          message={{
            type: "ai",
            text: "Hello, I'm AI Sentinel. I can break down your text into tasks and even join your Teams or Zoom meeting to assist you once you send me the link.",
          }}
          aiSentinel={aiSentinelConfig}
        />

        {userMessage && (
          <SimpleTextMessage message={{ type: "user", text: userMessage }} />
        )}

        {aiTextMessage && (
          <SimpleTextMessage message={{ type: "ai", text: aiTextMessage }} />
        )}

        {showBreakdownMessage && <BreakdownMessage />}
      </Box>

      {/* Input box */}
      <TextField
        autoFocus={!isTouchDevice}
        variant="outlined"
        multiline
        maxRows={4}
        fullWidth
        value={inputText}
        onChange={(event) => setInputText(event.target.value)}
        placeholder="Type your message here..."
        onKeyDown={(event) => {
          if (
            keyboard.isEnterPressed(event) &&
            !keyboard.isShiftPressed(event)
          ) {
            event.preventDefault();
            handleSendMessage();
          }
        }}
        sx={{
          padding: 1,
          display: "flex",
          alignItems: "center",
          mr: 1,
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderRadius: "16px",
            },
          },
        }}
        slotProps={{
          input: {
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleSendMessage}
                  color="primary"
                  disabled={!inputText.trim()}
                >
                  <Send />
                </IconButton>
              </InputAdornment>
            ),
          },
        }}
      />
    </Paper>
  );
};

export default ChatBox;
