import { IconButton, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useAppDispatch } from "../../../../common/hooks/redux";
import { number } from "../../../../common/utils/number";
import useForm from "../../../../common/hooks/useForm/useForm";
import { TFinancialForecastFilterFormAttributes } from "./types/TFinancialForecastFilterFormAttributes";
import { DatePicker } from "../../../../common/modules/materialUI";
import { Check } from "@mui/icons-material";
import { TFinancialForecastSectionProps } from "./types/TFinancialForecastSectionProps";
import { useFetchTimelineCostTotalsService } from "../../modules/holisticView/services/useFetchTimelineCostTotalsService";
import { date, FORMAT_RAW_DATE_ONLY } from "../../../../common/utils/date";
import { THttpClientError } from "../../../../common/modules/httpClient/types/THttpClientError";
import Loading from "../../../../common/components/Icon/Loading/Loading";
import { UserTimelineTypeEnum } from "../../../../entities/columns/userTimeline/UserTimelineTypeEnum";
import { currencyUtil } from "../../../../common/utils/currencyUtil";
import { ITimelineCostTotalsRequest } from "../../interfaces/ITimelineCostTotalsRequest";
import { systemNotificationActions } from "../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { boardViewActions } from "../../slices/boardViewSlice";
import { SideViewSectionEnum } from "../../enums/SideViewSectionEnum";
import Dialog from "../../../../common/modules/materialUI/components/Dialog/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { ITimelineTypeTotalCostResource } from "../../interfaces/ITimelineTypeTotalCostResource";

const costTimelineTypeToLabelMap = {
  [UserTimelineTypeEnum.TASK as string]: "Tasks",
  [UserTimelineTypeEnum.SPIKE as string]: "Spikes",
  [UserTimelineTypeEnum.EVENT as string]: "Events",
};

const FinancialForecastDialog = ({
  boardRef,
  displaySideView,
}: TFinancialForecastSectionProps) => {
  const dispatch = useAppDispatch();

  const {
    isLoading: isFetchingCostTotals,
    dispatch: dispatchFetchTotalCosts,
    data: currencyTotalCosts,
  } = useFetchTimelineCostTotalsService(boardRef);

  const form = useForm<TFinancialForecastFilterFormAttributes>({
    fromDate: null,
    toDate: null,
  });

  const submitHandler = () => {
    dispatchFetchTotalCosts({
      query: form.getTransformed(
        (data) =>
          ({
            fromDate: data.fromDate
              ? date.format(data.fromDate, FORMAT_RAW_DATE_ONLY)
              : null,
            toDate: data.toDate
              ? date.format(data.toDate, FORMAT_RAW_DATE_ONLY)
              : null,
          } as ITimelineCostTotalsRequest)
      ),
    })
      .then(() => {
        form.notDirty();
      })
      .catch((error: THttpClientError) => {
        if (error.status === 403) {
          dispatch(
            systemNotificationActions.open({
              message: error.data.message,
              variant: "warning",
            })
          );
          return;
        }

        form.errorHandler(error);
      });
  };

  const closeHandler = () => {
    dispatch(boardViewActions.displaySideView(undefined));
  };

  return (
    <Dialog
      closeButtonHandler={closeHandler}
      renderCloseButton
      open={displaySideView === SideViewSectionEnum.BUDGETARY}
      onClose={closeHandler}
      maxWidth="lg"
    >
      <DialogTitle
        variant="body2"
        color="text.secondary"
        fontWeight={500}
        fontSize={16}
      >
        Financial Forecast
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={1}>
          <Grid size={6}>
            <DatePicker form={form} name="fromDate" showRequired label="From" />
          </Grid>
          <Grid size={6}>
            <DatePicker form={form} name="toDate" showRequired label="To" />
          </Grid>

          <Grid size={12} display="flex" justifyContent="flex-end">
            <IconButton
              type="submit"
              disabled={
                isFetchingCostTotals ||
                !form.get("fromDate") ||
                !form.get("toDate")
              }
              onClick={submitHandler}
            >
              <Check fontSize="small" sx={{ fontSize: "16px" }} />
            </IconButton>
          </Grid>
        </Grid>

        {isFetchingCostTotals && <Loading />}
        {!isFetchingCostTotals &&
          currencyTotalCosts !== null &&
          !currencyTotalCosts.length && (
            <Typography variant="body2" color="text.secondary">
              Costs have not been found
            </Typography>
          )}
        {!isFetchingCostTotals && !!currencyTotalCosts?.length && (
          <Grid container direction="column" spacing={2} mb={2}>
            {currencyTotalCosts.map((currencyTotalCost) => (
              <Grid
                key={currencyTotalCost.costCurrency}
                container
                direction="column"
                spacing={0.5}
                bgcolor="dataGrid.borderColor"
                sx={{ mt: 2, ml: 0, pb: 1, pr: 1 }}
              >
                {/* Total Currency Cost */}
                <Grid container direction="column" spacing={0.1}>
                  <Grid>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      fontWeight={500}
                    >
                      Total Cost ({currencyTotalCost.costCurrency})
                    </Typography>
                  </Grid>
                  <Grid>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      fontWeight={500}
                    >
                      {currencyUtil.getSymbol(currencyTotalCost.costCurrency)}
                      {number.formatAsPrice(
                        currencyTotalCost.currencyTotalCost
                      )}
                    </Typography>
                  </Grid>
                </Grid>

                {/* Type Cost */}
                <Grid container direction="column" spacing={1}>
                  {currencyTotalCost.typeTotalCosts.map(
                    (typeTotalCost: ITimelineTypeTotalCostResource) => (
                      <Grid key={typeTotalCost.type}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              variant="body2"
                              component="span"
                              color="text.secondary"
                              style={{
                                fontSize: "3rem",
                                fontWeight: "lighter",
                                lineHeight: 0.5,
                                paddingRight: "2px",
                                paddingTop: "10px",
                              }}
                            >
                              •
                            </Typography>
                            <Typography
                              variant="body2"
                              component="span"
                              color="text.secondary"
                              paddingTop={1}
                            >
                              {costTimelineTypeToLabelMap[typeTotalCost.type]}
                            </Typography>
                          </div>
                          <Typography
                            variant="body2"
                            component="span"
                            color="text.secondary"
                            paddingTop={1}
                          >
                            {currencyUtil.getSymbol(
                              currencyTotalCost.costCurrency
                            )}
                            {number.formatAsPrice(typeTotalCost.totalCost)}
                          </Typography>
                        </div>
                      </Grid>
                    )
                  )}
                </Grid>
              </Grid>
            ))}
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default FinancialForecastDialog;
