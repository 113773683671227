import { Box, Typography } from "@mui/material";
import { Tooltip } from "../../../../../../common/modules/materialUI";
import Chip from "../../../../../../common/modules/materialUI/components/Chip/Chip";
import { Fragment } from "react";
import { StickyElementsRef } from "../../hooks/useStickyElements";
import { IGuesstimationItem } from "../../../../../../entities/IGuesstimationItem";
import { useRoleMapHelper } from "../../hooks/useRoleMapHelper";
import CollisionsItem from "./Items/CollisionsItem";
import ResourcesItem from "./Items/ResourcesItem";
import EffortUnitsItem from "./Items/EffortUnitsItem";
import WorkDaysItem from "./Items/WorkDaysItem";
import { PositionEnum } from "./LaneItemHorizontalLarge";

export const numberOfCollisionsToShow = 2;

const KpiSection = ({
  itemFooter,
  guesstimationItem,
  stickyOrStatic,
}: {
  itemFooter?: StickyElementsRef;
  guesstimationItem: IGuesstimationItem;
  stickyOrStatic: PositionEnum;
}) => {
  const roleMapHelper = useRoleMapHelper();

  return (
    <Box
      ref={(ref: HTMLDivElement | null) =>
        itemFooter && (itemFooter.endStickElement = ref)
      }
      display="flex"
      alignItems="center"
      position={stickyOrStatic}
      right="-20px"
      marginLeft={
        itemFooter && itemFooter.startStickElement
          ? `${itemFooter.startStickElement.offsetWidth + 20}px`
          : "0px"
      }
      gap="8px"
    >
      <Tooltip
        title={
          <Typography variant="caption">
            Number of working days available in the interval / Total days in the
            interval
          </Typography>
        }
        withSpanWrapper
      >
        <Chip
          sx={{
            bgcolor: "backgroundElevation.backgroundPaperElevation_1",
            p: "3px 4px",
            lineHeight: "18px",
            fontSize: "12px",
          }}
          label={
            <WorkDaysItem
              workingDaysCount={guesstimationItem.workingDaysCount}
              tsPoints={guesstimationItem.tsPoints}
            />
          }
        />
      </Tooltip>

      {guesstimationItem.estimatedResources && (
        <Tooltip
          title={
            <Typography variant="caption">
              Total Per-Person Effort / Estimated Resource Units
              <br />
              <br />
              Total Per-Person Effort: Assigned Resources x Working Days -
              Person Off Days
              <br />
              <br />
              Estimated Resource Units: Estimated Resources Count x Working Days
            </Typography>
          }
          withSpanWrapper
        >
          <Chip
            sx={{
              bgcolor: "backgroundElevation.backgroundPaperElevation_1",
              p: "3px 4px",
              lineHeight: "18px",
              fontSize: "12px",
            }}
            label={
              <EffortUnitsItem
                allocatedResourceDays={guesstimationItem.allocatedResourceDays}
                estimatedResourceDays={guesstimationItem.estimatedResourceDays}
              />
            }
          />
        </Tooltip>
      )}

      {guesstimationItem.estimatedResources && (
        <Tooltip
          title={
            <Typography variant="caption">
              Assignees/ Estimated Resource Units
            </Typography>
          }
          arrow
          withSpanWrapper
        >
          <Chip
            sx={{
              bgcolor: "backgroundElevation.backgroundPaperElevation_1",
              p: "3px 4px",
              lineHeight: "18px",
              fontSize: "12px",
            }}
            label={
              <ResourcesItem
                assigneesLength={guesstimationItem.assignees?.length}
                estimatedResources={guesstimationItem.estimatedResources}
              />
            }
          />
        </Tooltip>
      )}
      {!!guesstimationItem.collisionsOverview?.length && (
        <>
          <Tooltip
            when={
              guesstimationItem.collisionsOverview.length >
              numberOfCollisionsToShow
            }
            withSpanWrapper
            title={
              <Typography>
                {guesstimationItem.collisionsOverview.map(
                  (collisionOverview, index) => {
                    return (
                      <Fragment key={index}>
                        <span>
                          {roleMapHelper.getRoleMapLabel(
                            collisionOverview.userRole
                          )}{" "}
                          -{collisionOverview.count}
                        </span>
                        <br />
                      </Fragment>
                    );
                  }
                )}
              </Typography>
            }
          >
            <Chip
              sx={{
                bgcolor: "backgroundElevation.backgroundPaperElevation_1",
                p: "3px 4px",
                lineHeight: "18px",
                fontSize: "12px",
              }}
              label={
                <CollisionsItem
                  collisionsOverview={guesstimationItem.collisionsOverview}
                  collisionsOverviewLength={
                    guesstimationItem.collisionsOverview.length
                  }
                />
              }
            />
          </Tooltip>
        </>
      )}
    </Box>
  );
};
export default KpiSection;
