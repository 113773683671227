import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TUserState } from "./types/TUserState";
import { IBoardUsersResource } from "../../boardView/interfaces/IBoardUsersResource";
import { IJWTPayload } from "../../../interfaces/IJWTPayload";
import { array } from "../../../common/utils/array";
import { IUser } from "../../../entities/IUser";
import { user } from "../../../common/utils/user";

const initialState: TUserState = {
  userList: [],
  userFilteredList: [],
  hasUnassigned: false,
  userInfo: null,
  selectedIds: [],
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setSelectedIds: (state, action: PayloadAction<number[]>) => {
      // This will be used later when the list changed, so we can sort the filtered list accordingly
      state.selectedIds = action.payload;
    },
    deleteSelectedIds: (state) => {
      state.selectedIds = [];
    },
    setList: (state, action: PayloadAction<IBoardUsersResource>) => {
      const userList = [
        ...array.sortByColumn<IUser>(action.payload.users, "name"),
        ...(action.payload.hasUnassigned ? [user.unassignedEntity] : []),
      ];

      state.userList = userList;
      state.userFilteredList = [...userList].sort((a, b) =>
        state.selectedIds.includes(a.id) ? -1 : 1
      );
      state.hasUnassigned = action.payload.hasUnassigned;
    },
    setFilteredList: (state, action: PayloadAction<number[]>) => {
      // Selected ones will be on the beginning of the list
      state.userFilteredList = [...state.userList].sort((a, b) =>
        action.payload.includes(a.id) ? -1 : 1
      );
    },
    reset: (state) => {
      state.userList = initialState.userList;
      state.hasUnassigned = initialState.hasUnassigned;
    },
    setUserInfo: (state, action: PayloadAction<IJWTPayload | null>) => {
      if (action.payload) {
        state.userInfo = action.payload;
      }
    },
    updateUserJwtInfo: (state, action: PayloadAction<IJWTPayload>) => {
      state.userInfo = action.payload;
    },
  },
});

export const userReducer = slice.reducer;

export const userActions = slice.actions;
