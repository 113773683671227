export const string = {
  /**
   * Create and return a random string
   */
  random: (length: number = 5): string =>
    Math.random().toString(16).slice(-length),

  acronym: (text: string): string => {
    const nameParts = text.split(" ");

    return `${nameParts[0][0]}${
      nameParts[1] ? nameParts[1][0] : ""
    }`.toUpperCase();
  },

  // Converts the 1, 2, 3, 4, ... to 1st, 2nd, 3rd, 4th, ...
  // "Ordinal" refers to the form of a number that shows its order in a series or sequence. Ordinal numbers are
  // generally used for indicating position or place, as opposed to cardinal numbers, which indicate quantity.
  // For example, in a race, you might finish in "1st" place, not "one" place. Here, "1st" is an ordinal number,
  // while "one" is a cardinal number.
  getOrdinal: (n: number) => {
    const s = ["th", "st", "nd", "rd"],
      v = n % 100;

    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  },

  capitalize: (text: string): string => {
    const lowerText = text.toLowerCase();

    return lowerText.charAt(0).toUpperCase() + lowerText.slice(1);
  },

  ucwords: (text: string): string => {
    const lowerText = text.toLowerCase();

    // Uppercase each word
    return lowerText.replace(/(^| )(\w)/g, (x) => x.toUpperCase());
  },

  styleStringToObject: (styleString?: string) => {
    let styleObject: { [key: string]: string } = {};
    (styleString ?? "").split(";").forEach((pair) => {
      let [key, value] = pair.split(":");
      if (key && value) {
        styleObject[key.trim()] = value.trim();
      }
    });
    return styleObject;
  },

  fromHtml: (html: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");

    return doc.body.textContent || "";
  },
  pathsMatching: (pathToMatch: string, patchToCompareAgainst: string) => {
    pathToMatch = "/" + pathToMatch.replace(/^\//, "");
    patchToCompareAgainst = "/" + patchToCompareAgainst.replace(/^\//, "");

    return (
      pathToMatch.length > 1 &&
      (patchToCompareAgainst === pathToMatch ||
        patchToCompareAgainst.startsWith(pathToMatch + "/"))
    );
  },
};
