import { useHttpClientDispatchable } from "../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";
import { IReadOnlyAccessToken } from "../../../entities/IReadOnlyAccessToken";

export const useCreateReadOnlyAccessService = () => {
  const response = useHttpClientDispatchable({
    url: "/readOnlyAccessToken",
    method: RequestMethodEnum.POST,
  });

  return {
    ...response,
    data: response.data as IReadOnlyAccessToken,
  };
};
