import { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useAppDispatch } from "../../../../../../common/hooks/redux";
import { systemNotificationActions } from "../../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import usePortalTabActions from "../../hooks/usePortalTabActions";
import { ITask } from "../../../../../../entities/ITask";
import { TIsMobileViewProp } from "../../../../../../common/modules/taskView/containers/types/TIsMobileViewProp";
import { TIsPageViewProp } from "../../../../../../common/modules/taskView/containers/types/TIsPageViewProp";
import { useGetTaskGitHubDataService } from "../../services/useGetTaskGitHubDataService";
import Loading from "../../../../../../common/components/Icon/Loading/Loading";
import useLinkTarget from "../../../../../../common/hooks/useLinkTarget";

type TProps = {
  task: ITask;
  tabIndex: string;
} & TIsMobileViewProp &
  TIsPageViewProp;

const GitHubData = ({ task, isMobileView, tabIndex, isPageView }: TProps) => {
  const { CountsPortalComponent } = usePortalTabActions(tabIndex);
  const dispatch = useAppDispatch();
  const { _blank } = useLinkTarget();
  const {
    dispatch: dispatchGetGithubData,
    data: gitHubDataResource,
    isLoading: isLoadingGitHubData,
  } = useGetTaskGitHubDataService({
    taskId: task.taskId,
  });

  useEffect(() => {
    dispatchGetGithubData().catch(() => {
      dispatch(
        systemNotificationActions.open({
          message: "Failed to fetch github data. Please try again.",
          variant: "error",
        })
      );
    });
  }, [task.taskId]);

  return (
    <>
      {CountsPortalComponent(
        <>
          {isLoadingGitHubData && <Loading />}
          {!isLoadingGitHubData && (
            <>
              {gitHubDataResource?.pullRequests.length ?? 0} Pull Request(s),{" "}
              {gitHubDataResource?.commits.length ?? 0} Commit(s).
            </>
          )}
        </>
      )}

      <Grid container direction="column" spacing={3}>
        {gitHubDataResource?.pullRequests.length > 0 && (
          <Grid>
            <Box>
              <Typography variant="h6">Pull Requests:</Typography>
            </Box>
            <Grid container spacing={1} direction="column" pt={1}>
              {gitHubDataResource.pullRequests.map((pr) => (
                <Grid key={pr.id}>
                  <Box>
                    <Typography>
                      <a href={pr.url} target={_blank} rel="noreferrer">
                        {pr.title}
                      </a>
                    </Typography>
                  </Box>
                  <Box>number: #{pr.data.number ?? ""}</Box>
                  <Box>Is Draft: {pr.data.draft ? "Yes" : "No"}</Box>
                  <Box>State: {pr.data.state}</Box>
                  <Box>Is Locked: {pr.data.locked ? "Yes" : "No"}</Box>
                  <Box>
                    Is Merged:{" "}
                    {pr.data.merged === null
                      ? "N/A"
                      : pr.data.merged
                      ? "Yes"
                      : "No"}
                  </Box>
                  <Box>
                    Is Mergeable:{" "}
                    {pr.data.mergeable === null
                      ? "N/A"
                      : pr.data.mergeable
                      ? "Yes"
                      : "No"}
                  </Box>
                  <Box>Mergeable State: {pr.data.mergeable_state}</Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}

        {gitHubDataResource?.commits.length > 0 && (
          <Grid>
            <Box>
              <Typography variant="h6">Commits:</Typography>
            </Box>
            <Grid container spacing={1} direction="column" pt={1}>
              {gitHubDataResource.commits.map((commit) => (
                <Grid key={commit.id}>
                  <Box>
                    <Typography>
                      <a href={commit.url} target={_blank} rel="noreferrer">
                        {commit.title}
                      </a>
                    </Typography>
                  </Box>
                  <Box>id: {commit.githubId}</Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default GitHubData;
