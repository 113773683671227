import { useAppDispatch } from "../../../common/hooks/redux";
import { taskTypeActions } from "../slices/taskTypeSlice";
import CreateModal from "./CreateTaskType/CreateModal";
import TaskTypeGrid from "./TaskTypeGrid/TaskTypeGrid";
import { orgActions } from "../../org/slices/orgSlice";
import { useSubscribeEvent } from "../../../common/modules/eventProvider";
import { EventEnum } from "../../../common/modules/eventProvider/enums/EventEnum";
import { ITaskType } from "../../../entities/ITaskType";
import TitleAndActionButton from "../../../common/components/Title/TitleAndActionButton";

const TaskTypesPage = () => {
  const dispatch = useAppDispatch();

  useSubscribeEvent(EventEnum.ON_TASK_TYPE_CREATED, (taskType: ITaskType) => {
    dispatch(orgActions.addTaskType(taskType));
  });
  useSubscribeEvent(EventEnum.ON_TASK_TYPE_UPDATED, (taskType: ITaskType) => {
    dispatch(orgActions.updateTaskType(taskType));
  });
  useSubscribeEvent(EventEnum.ON_TASK_TYPE_DELETED, (taskTypeId: number) => {
    dispatch(orgActions.deleteTaskType({ id: taskTypeId }));
  });

  return (
    <>
      <TitleAndActionButton
        title="Task Types"
        buttonText="Add Task Type"
        onClick={() => dispatch(taskTypeActions.openCreateModal())}
      />

      <TaskTypeGrid />
      <CreateModal />
    </>
  );
};

export default TaskTypesPage;
