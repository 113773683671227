import { useTheme } from "@mui/material";
import Badge from "../../modules/materialUI/components/Badge/Badge";

const NavigationTitle = ({
  title,
  textInCenter,
}: {
  title: string;
  textInCenter?: boolean;
}) => {
  const theme = useTheme();
  return (
    <Badge
      variant={"outlined"}
      color={theme.palette.text.primary}
      sx={{
        fontWeight: 500,
        letterSpacing: "0.14px",
        fontSize: "12px",
        lineHeight: "20px",
        textAlign: textInCenter ? "center" : "left",
      }}
    >
      {title}
    </Badge>
  );
};
export default NavigationTitle;
