import { Tab, Tabs as MuiTabs, Typography, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { TTabsProps } from "./types/TTabsProps";
import useMedia from "../../../../hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../hooks/useMedia/enums/QueryEnum";

const a11yProps = (tabIndex: string) => {
  return {
    id: `taskInfo-tab-${tabIndex}`,
    "aria-controls": `taskInfo-tabpanel-${tabIndex}`,
  };
};

export default function Tabs({
  activeTabIndex,
  tabChangeHandler,
  tabs,
}: TTabsProps) {
  const theme = useTheme();
  const matchesMax576Screen = useMedia(QueryEnum.MAX_WIDTH_576);

  return (
    <Grid>
      <MuiTabs
        aria-label="task-info-tabpanel"
        value={activeTabIndex}
        sx={{
          minHeight: "auto",
          "& .MuiTabs-flexContainer": {
            ...(matchesMax576Screen
              ? { width: "calc(100vw - 65px)", overflowX: "auto" }
              : {}),
          },
        }}
        onChange={tabChangeHandler}
      >
        {tabs.map(
          ({ tabIndex: currentTabIndex, tabLabel, isActive }, index) => (
            <Tab
              key={currentTabIndex}
              sx={{
                minHeight: "auto",
                minWidth: "auto",
                mr: "12px",
                p: 0,
                textTransform: "none",
              }}
              label={
                <Typography
                  color={
                    isActive
                      ? theme.palette.primary.main
                      : theme.palette.taskView.textMainColor
                  }
                  fontSize="15px"
                  fontWeight={500}
                >
                  {tabLabel}
                </Typography>
              }
              {...a11yProps(currentTabIndex)}
            />
          )
        )}
        ;
      </MuiTabs>
    </Grid>
  );
}
