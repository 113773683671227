import { GridColDef } from "@mui/x-data-grid";
import { IconButton, Tooltip, Typography } from "@mui/material";
import { Delete, ModeEdit } from "@mui/icons-material";
import { useAppSelector } from "../../../../common/hooks/redux";
import { TColumnsProps } from "./types/TColumnsProps";
import { ISprint } from "../../../../entities/ISprint";
import moment from "moment/moment";
import { TListItem } from "../../../../common/types/TListItem";
import { ISprintResource } from "../../interfaces/ISprintResource";

export const Columns: (props: TColumnsProps) => GridColDef<ISprint>[] = ({
  onEditButtonClick,
  onDeleteButtonClick,
}) => {
  const sprintLabel = useAppSelector((state) => state.customs.sprintLabel);
  const representatives = useAppSelector(
    (state) => state.sprint.representatives
  );

  return [
    {
      field: "actions",
      headerName: "Actions",
      width: 90,
      hideable: false,
      sortable: false,
      renderCell: (params) => {
        const sprint = params.row;
        const representative = representatives.find(
          (rep) => rep.id === params.row.rep
        )?.name;

        const sprintResource = {
          sprint: sprint,
          representative: {
            id: sprint.rep,
            label: representative,
          } as TListItem<number>,
        } as ISprintResource;

        return (
          <>
            <Tooltip title={`Edit ${sprintLabel}`}>
              <IconButton
                onClick={() => onEditButtonClick(sprintResource)}
                color="primary"
              >
                <ModeEdit color="primary" fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title={`Delete ${sprintLabel}`}>
              <IconButton
                onClick={() => onDeleteButtonClick(params.row)}
                color="error"
              >
                <Delete color="error" fontSize="small" />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 200,
      maxWidth: 300,
      hideable: false,
    },
    // {
    //   field: "status",
    //   headerName: `Status`,
    //   flex: 1,
    //   minWidth: 200,
    //   maxWidth: 300,
    //   hideable: false,
    // },
    {
      field: "rep",
      headerName: "Representative",
      flex: 1,
      minWidth: 300,
      maxWidth: 400,
      hideable: false,
      renderCell: (params) => {
        const representative = representatives.find(
          (rep) => rep.id === params.row.rep
        )?.name;

        return <Typography variant="body2">{representative}</Typography>;
      },
    },
    {
      field: "startDate",
      headerName: "Start Date",
      width: 170,
      renderCell: (params) => {
        return (
          <Typography variant="body2">
            {moment(params.row.startDate).format("MM/DD/YYYY")}
          </Typography>
        );
      },
    },
    {
      field: "dueDate",
      headerName: "Due Date",
      width: 170,
      renderCell: (params) => {
        return (
          <Typography variant="body2">
            {moment(params.row.dueDate).format("MM/DD/YYYY")}
          </Typography>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Date Added",
      width: 170,
    },
    {
      field: "updatedAt",
      headerName: "Date Updated",
      width: 170,
    },
  ];
};
