import { TMenuItemProps } from "../types/TMenuItemProps";

export const menuItem = {
  make: ({
    component,
    handler,
    when,
    disabled,
    disabledTooltipMessage,
    hasDivider,
    dividerSx,
    isHeaderItem,
    closeOnClick,
    headerItemProps,
    menuItemsProps,
    closeOnClickInHeader,
  }: TMenuItemProps) =>
    ({
      component,
      handler,
      when,
      disabled,
      disabledTooltipMessage,
      hasDivider,
      dividerSx,
      isHeaderItem,
      closeOnClick,
      headerItemProps,
      menuItemsProps,
      closeOnClickInHeader,
    } as TMenuItemProps),

  collect: (
    items: {
      menuItem: TMenuItemProps;
      can?: boolean;
    }[],
    params?: {
      skipDividerOnLastElement?: boolean;
    }
  ) => {
    const collectedList: TMenuItemProps[] = [];
    items.forEach((item) => {
      item.can && collectedList.push(item.menuItem);
    });
    // Add divider to the last element if needed
    if (params?.skipDividerOnLastElement !== false && collectedList.at(-1)) {
      collectedList.at(-1)!.hasDivider = true;
    }

    return collectedList;
  },
};
