import { Skeleton } from "../../../../../../common/modules/materialUI";
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Section from "../../../../../../common/components/Section/Section";

const EntityFieldValuesFormSkeleton = () => {
  return (
    <>
      <Box display="flex" justifyContent="flex-end">
        <Skeleton variant="text" width={220} />
      </Box>
      <Grid pt={3} container spacing={2} direction="row">
        <Grid
          size={{
            xl: 4,
            lg: 6,
            xs: 12,
          }}
        >
          <Section
            title={<Skeleton variant="text" width={200} />}
            subtitle={<Skeleton variant="text" width={300} />}
          >
            <Skeleton variant="rounded" height={200} />
          </Section>
        </Grid>
        <Grid
          size={{
            xl: 4,
            lg: 6,
            xs: 12,
          }}
        >
          <Section
            title={<Skeleton variant="text" width={200} />}
            subtitle={<Skeleton variant="text" width={300} />}
          >
            <Skeleton variant="rounded" height={200} />
          </Section>
        </Grid>
        <Grid
          size={{
            xl: 4,
            lg: 6,
            xs: 12,
          }}
        >
          <Section
            title={<Skeleton variant="text" width={200} />}
            subtitle={<Skeleton variant="text" width={300} />}
          >
            <Skeleton variant="rounded" height={200} />
          </Section>
        </Grid>
      </Grid>
    </>
  );
};

export default EntityFieldValuesFormSkeleton;
