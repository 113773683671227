import { TColumnsProps } from "./types/TColumnsProps";
import { Typography } from "@mui/material";
import { useOrgProjectData } from "../../../../../org/hooks/useOrgProjectData";
import useLinkTarget from "../../../../../../common/hooks/useLinkTarget";

export default function MainData({ initiative }: TColumnsProps) {
  const { findProjectByRef } = useOrgProjectData();
  const { _blank } = useLinkTarget();
  const hasBoard = initiative.boardRef;
  const boardUrl = hasBoard
    ? `/${findProjectByRef(initiative.projectRef!)?.project.abbr}/board/${
        initiative.boardRef
      }/holistic`
    : "";

  return (
    <>
      <Typography noWrap fontSize="13px" fontWeight={500}>
        {hasBoard ? (
          <a href={boardUrl} target={_blank} rel="noreferrer">
            {initiative.name}
          </a>
        ) : (
          initiative.name
        )}
      </Typography>

      {hasBoard && (
        <Typography noWrap fontSize="13px">
          <a href={boardUrl} target={_blank} rel="noreferrer">
            {initiative.boardRef}
          </a>
        </Typography>
      )}
    </>
  );
}
