import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import "./index.scss";
import { store } from "./common/store/store";
import Layout from "./common/containers/Layout/App/Layout/Layout";
import AuthLayout from "./common/containers/Layout/Auth/Layout/Layout";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { createRoot } from "react-dom/client";
import moment from "moment-timezone";
import ThemeProvider from "./common/modules/theme/containers/ThemeProvider/ThemeProvider";
import SignIn from "./modules/user/containers/Auth/Signin/SignIn";
import OrgRegistration from "./modules/org/containers/OrgRegistration/OrgRegistration";
import SignUp from "./modules/user/containers/Auth/Signup/SignUp";
import ResetPassword from "./modules/user/containers/Auth/ResetPassword/ResetPassword";
import RegistrationSuccess from "./modules/stripe/containers/Checkout/Success/RegistrationSuccess";
import SubscriptionRequired from "./common/components/Page/SubscriptionRequired/SubscriptionRequired";
import LayoutNoRedirect from "./common/containers/Layout/Auth/Layout/LayoutNoRedirect";
import MSOrgRegistration from "./modules/integrations/modules/MSAuth/containers/MSOrgRegistration";
import { ReactNativeBridge } from "./common/hooks/useReactNativeBridge/containers/ReactNativeBridge";
import ReadonlyLayout from "./common/containers/Layout/App/Layout/ReadOnly/Layout";

// Set the app default timezone, this will be used by the date pickers as well by default moment() object
moment.tz.setDefault("UTC");
const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <ThemeProvider>
            <ReactNativeBridge />
            <Routes>
              <Route
                path="/auth"
                element={
                  <AuthLayout>
                    <SignIn />
                  </AuthLayout>
                }
              />
              <Route
                path="/signUp"
                element={
                  <AuthLayout>
                    <SignUp />
                  </AuthLayout>
                }
              />
              <Route
                path="/orgRegistration"
                element={
                  <AuthLayout>
                    <OrgRegistration />
                  </AuthLayout>
                }
              />
              <Route
                path="/msOrgRegistration"
                element={
                  <AuthLayout>
                    <MSOrgRegistration />
                  </AuthLayout>
                }
              />
              <Route
                path="/orgRegistration/success"
                element={
                  <AuthLayout>
                    <RegistrationSuccess />
                  </AuthLayout>
                }
              />
              <Route
                path="/forgotPassword"
                element={
                  <AuthLayout>
                    <ResetPassword />
                  </AuthLayout>
                }
              />
              <Route
                path={"subscriptionExpired"}
                element={
                  <LayoutNoRedirect>
                    <SubscriptionRequired />
                  </LayoutNoRedirect>
                }
              />
              <Route path="/shared/*" element={<ReadonlyLayout />} />
              <Route path="/*" element={<Layout />} />
            </Routes>
          </ThemeProvider>
        </LocalizationProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);
