import { useEffect } from "react";

export default function useListenMessage(
  listenToParam: string,
  callback: (param: string) => void
) {
  useEffect(() => {
    const webViewEventHandler = (event: any) => {
      if (!event.data) {
        return;
      }

      try {
        const data = JSON.parse(event.data);
        data?.hasOwnProperty(listenToParam) && callback(data[listenToParam]);
      } catch (_) {}
    };

    document.addEventListener("message", webViewEventHandler); // For Android
    window.addEventListener("message", webViewEventHandler); // For iOS

    return () => {
      document.removeEventListener("message", webViewEventHandler); // For Android
      window.removeEventListener("message", webViewEventHandler); // For iOS
    };
  }, []);
}
