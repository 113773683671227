import { TSliderProps } from "./types/TSliderProps";
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  Slider as MuiSlider,
  TextField as MuiTextField,
} from "@mui/material";
import Grid from "@mui/material/Grid2";

const Slider = <TFormAttributes extends unknown>(
  props: TSliderProps<TFormAttributes>
) => {
  const { form, name, label, showRequired, ...sliderProps } = props;
  const sliderLabel = label ? `${label}${showRequired ? " *" : ""}` : undefined;
  const sliderValue = +(form.get(name) ?? sliderProps?.min ?? 0);

  return (
    <Box>
      {sliderLabel && <InputLabel sx={{ mb: 1 }}>{sliderLabel}</InputLabel>}
      <FormControl fullWidth>
        <Grid container spacing={2} alignItems="center">
          <Grid size="grow">
            <MuiSlider
              aria-label={sliderLabel}
              value={sliderValue}
              onChange={(e, value, activeThumb) => {
                form.changeHandler(name as string, value);
                sliderProps.onChange?.(e, value, activeThumb);
              }}
              {...sliderProps}
            />
          </Grid>
          <Grid>
            <MuiTextField
              name={name}
              value={sliderValue}
              variant="standard"
              onChange={(e) => {
                form.changeHandler(name as string, e.target.value);
              }}
              slotProps={{
                htmlInput: {
                  min: sliderProps?.min ?? 0,
                  max: sliderProps?.max ?? 100,
                  type: "number",
                  sx: {
                    textAlign: "center",
                  },
                },
              }}
            />
          </Grid>
        </Grid>

        <FormHelperText error={form.hasError(name)}>
          {form.validationErrors?.[name]}
        </FormHelperText>
      </FormControl>
    </Box>
  );
};

export default Slider;
