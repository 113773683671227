import { CSSProperties, useCallback } from "react";
import { DragPreviewImage } from "react-dnd";
import {
  TaskHorizontalLarge,
  TaskHorizontalSmall,
} from "../../../../../../common/components/Task";
import { TTaskProps } from "./types/TTaskProps";
import { getEmptyImage } from "react-dnd-html5-backend";
import { useResizeHelper } from "../../hooks/useResizeHelper";
import { useDragAndDropHelper } from "../../hooks/useDragAndDropHelper";
import { useTheme } from "@mui/material";
import { useAppSelector } from "../../../../../../common/hooks/redux";

export const DRAG_TYPE_TASK_HORIZONTAL = "TaskHorizontal";

function getStyles(shouldDisplay: boolean): CSSProperties {
  return {
    // IE fallback: hide the real node using CSS when dragging
    // because IE will ignore our custom "empty image" drag preview.
    opacity: shouldDisplay ? 1 : 0,
    height: shouldDisplay ? undefined : 0,
  };
}

const Task = (props: TTaskProps) => {
  const theme = useTheme();
  const displayCostPerItem = useAppSelector(
    (state) => state.holisticView.displayCostPerItem
  );

  const {
    drag,
    dragPreview,
    isDragging,
    dragStartHandler,
    showShiftingProgress,
  } = useDragAndDropHelper(props);

  const {
    hours,
    xPositive,
    widthDiff,
    remainingPixelsToNextCheckpoint,
    metrics: resizeMetrics,
    resizeStartHandler,
    resizeHandler,
    resizeCompleteHandler,
    resizeInterruptHandler,
  } = useResizeHelper(props);

  const resizerMetricsWidth = 41;
  const calculateResizeTargetMetricsPosition = useCallback(
    () =>
      props.positionX +
      props.size +
      widthDiff -
      resizerMetricsWidth +
      (xPositive
        ? remainingPixelsToNextCheckpoint
        : -remainingPixelsToNextCheckpoint),
    [hours]
  );

  const shouldDisplay = !isDragging && !showShiftingProgress;

  return (
    <>
      <DragPreviewImage connect={dragPreview} src={getEmptyImage().src} />
      {props.variant === "large" ? (
        <TaskHorizontalLarge
          {...props}
          className="no-select"
          style={{
            ...props.style,
            ...getStyles(shouldDisplay),
          }}
          ref={drag}
          onDragStart={dragStartHandler}
          resizable
          resizerConfig={{
            metrics: resizeMetrics,
            onResizeStart: resizeStartHandler,
            onResize: resizeHandler,
            onResizeComplete: resizeCompleteHandler,
            onResizeInterrupt: resizeInterruptHandler,
          }}
          displayCostPerItem={displayCostPerItem}
        />
      ) : (
        <TaskHorizontalSmall
          {...props}
          className="no-select"
          style={{
            ...props.style,
            ...getStyles(shouldDisplay),
          }}
          ref={drag}
          onDragStart={dragStartHandler}
          resizable
          resizerConfig={{
            metrics: resizeMetrics,
            onResizeStart: resizeStartHandler,
            onResize: resizeHandler,
            onResizeComplete: resizeCompleteHandler,
            onResizeInterrupt: resizeInterruptHandler,
          }}
          displayCostPerItem={displayCostPerItem}
        />
      )}
      {resizeMetrics !== undefined && (
        <div
          style={{
            zIndex: 25,
            width: `${resizerMetricsWidth}px`,
            height: "90%",
            borderRadius: "29px",
            borderRight: `1px solid ${theme.palette.brightCardBackground.main}`,
            position: "absolute",
            left: `${calculateResizeTargetMetricsPosition()}px`,
          }}
        />
      )}
    </>
  );
};

export default Task;
