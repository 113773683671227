import { menuItem } from "../../../../common/containers/Menu/utils/menuItem";
import { Box, Typography } from "@mui/material";
import { propsForMenuItems } from "../../../../common/containers/Menu/common/propsForMenuItems";
import { StickyNote2 } from "@mui/icons-material";

const NotesMenuItem = ({
  clickHandler,
  matchesMax400Screen,
}: {
  clickHandler: () => void;
  matchesMax400Screen: boolean;
}) => {
  return menuItem.make({
    component: (
      <Box
        display="flex"
        alignItems="center"
        gap={1}
        color="pickerPallet.secondary"
      >
        <StickyNote2
          sx={{
            color: "action.active",
            width: "24px",
          }}
        />
        <Typography variant="subtitle2" fontWeight="400" color="text.primary">
          Notes
        </Typography>
      </Box>
    ),
    handler: clickHandler,
    when: matchesMax400Screen,
    menuItemsProps: propsForMenuItems,
  });
};

export default NotesMenuItem;
