import { Switch } from "@mui/material";

const OfftimeSwitch = ({
  checked,
  disabled,
  onChange,
}: {
  checked: boolean;
  disabled: boolean;
  onChange:
    | ((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void)
    | undefined;
}) => {
  return <Switch checked={checked} disabled={disabled} onChange={onChange} />;
};
export default OfftimeSwitch;
