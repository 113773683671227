import IconImage from "./IconImage";

const BacklogIcon = ({ size = 16 }: { size?: number }) => {
  return (
    <IconImage
      imgDark="/app-v2/assets/Backlog-Light.svg"
      img="/app-v2/assets/Backlog-Dark.svg"
      size={size}
    />
  );
};

export default BacklogIcon;
