import { Box, Typography } from "@mui/material";
import { menuItem } from "../../../../../../common/containers/Menu/utils/menuItem";
import { taskViewActions } from "../../slices/viewSlice";
import { AddTask } from "@mui/icons-material";
import { InternalTypeEnum } from "../../../../../../entities/columns/task/InternalTypeEnum";
import { TTaskIdProp } from "../../../../../../common/modules/taskView/containers/types/TTaskIdProp";
import { useIsFeatureAllowed } from "../../../../../../common/modules/accessControl/hooks/useIsFeatureAllowed";
import { FeatureEnum } from "../../../../../../common/modules/accessControl/enums/FeatureEnum";
import { systemNotificationActions } from "../../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { featureUtil } from "../../../../../../common/modules/accessControl/utils/featureUtil";
import { AppDispatch } from "../../../../../../common/store/store";
import IconImage from "../../../../../../common/components/Icon/IconImage/IconImage";

type TProps = {
  taskInternalType: InternalTypeEnum;
  dispatch: AppDispatch;
  shouldRender: boolean;
} & TTaskIdProp;

const ConvertInternalTypeMenuItem = ({
  taskId,
  taskInternalType,
  shouldRender,
  dispatch,
}: TProps) => {
  const isSpikeCreationAllowed = useIsFeatureAllowed(FeatureEnum.SPIKE);

  const targetInternalType =
    taskInternalType === InternalTypeEnum.TASK
      ? InternalTypeEnum.SPIKE
      : InternalTypeEnum.TASK;

  if (!shouldRender) {
    return [];
  }

  return menuItem.make({
    component: (
      <Box display="flex" alignItems="center" gap={1}>
        {targetInternalType === InternalTypeEnum.SPIKE ? (
          <IconImage
            img={"/app-v2/assets/spikeDark.svg"}
            imgDark={"/app-v2/assets/spikeLight.svg"}
            size={24}
          />
        ) : (
          <AddTask fontWeight="400" sx={{ color: "action.active" }} />
        )}
        <Typography variant="subtitle2" fontWeight="400">
          Convert to a {targetInternalType}
        </Typography>
      </Box>
    ),
    handler: () => {
      if (
        targetInternalType === InternalTypeEnum.SPIKE &&
        !isSpikeCreationAllowed
      ) {
        dispatch(
          systemNotificationActions.open({
            message: featureUtil.notAvailableFullText,
            variant: "warning",
          })
        );
        return;
      }

      dispatch(
        taskViewActions.openConvertInternalTypeConfirmation({
          taskId,
          taskInternalType,
          targetInternalType,
        })
      );
    },
  });
};

export default ConvertInternalTypeMenuItem;
