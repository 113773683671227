import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Tab,
  Tabs as MuiTabs,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { AutoAwesome, Link } from "@mui/icons-material";
import CreateForm from "./CreateLink/CreateForm";
import ReadOnlyLinkList from "./LinkList/ReadOnlyLinkList";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import { readOnlyAccessActions } from "../../slices/readOnlyAccessSlice";
import { useIsFeatureAllowed } from "../../../../common/modules/accessControl/hooks/useIsFeatureAllowed";
import { FeatureEnum } from "../../../../common/modules/accessControl/enums/FeatureEnum";
import { systemNotificationActions } from "../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { featureUtil } from "../../../../common/modules/accessControl/utils/featureUtil";

type TProps = {
  boardRef: string;
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ marginTop: "10px" }}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

const ManageModal = (props: TProps) => {
  const displayModal = useAppSelector(
    (state) => state.readOnlyAccess.displayModal
  );
  const [tabIndex, setTabIndex] = useState<number>(1);
  const dispatch = useAppDispatch();
  const isFeatureAllowed = useIsFeatureAllowed(FeatureEnum.SHARING_BOARDS);

  const closeHandler = () => {
    dispatch(readOnlyAccessActions.closeManageModal());
  };

  const changeHandler = (event: React.SyntheticEvent, newValue: number) => {
    if (!isFeatureAllowed) {
      dispatch(
        systemNotificationActions.open({
          message: featureUtil.notAvailableFullText,
          variant: "warning",
        })
      );
      return;
    }

    setTabIndex(newValue);
  };

  const submitHandler = () => {
    setTabIndex(2);
  };

  return (
    <Dialog open={displayModal} onClose={closeHandler} fullWidth maxWidth="xs">
      <DialogTitle>Share Board</DialogTitle>
      <DialogContent>
        <MuiTabs
          value={tabIndex}
          onChange={changeHandler}
          aria-label="read-only access tabs"
        >
          <Tab
            value={1}
            icon={<AutoAwesome />}
            iconPosition="start"
            label="Generate New"
            aria-controls="generate-new"
          />
          <Tab
            value={2}
            icon={<Link />}
            iconPosition="start"
            label="Existing Links"
            aria-controls="existing-links"
          />
        </MuiTabs>

        <CustomTabPanel value={tabIndex} index={1}>
          <Grid container sx={{ pb: 2 }}>
            <Grid item>
              <Typography variant="subtitle2">
                Important: The link provides "Read Only" access and enables
                external users to view your internal board. Be careful about who
                you share it with.
              </Typography>
            </Grid>
          </Grid>
          <CreateForm
            boardRef={props.boardRef}
            onCancel={closeHandler}
            onSubmit={submitHandler}
          />
        </CustomTabPanel>
        <CustomTabPanel value={tabIndex} index={2}>
          <ReadOnlyLinkList boardRef={props.boardRef} />
        </CustomTabPanel>
      </DialogContent>
    </Dialog>
  );
};

export default ManageModal;
