import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import EditableColumn from "./EditableColumn";
import { enumUtil } from "../../../../../common/utils/enum";
import { StatusEnum } from "../../../../../entities/columns/user/StatusEnum";
import { Box, IconButton } from "@mui/material";
import AvatarIcon from "../../../../user/containers/AvatarIcon/AvatarIcon";
import { AbilityEnum } from "../../../../../common/modules/accessControl/enums/AbilityEnum";
import {
  ConfirmationButton,
  Tooltip,
} from "../../../../../common/modules/materialUI";
import { AttachMoney, Delete, GroupAdd } from "@mui/icons-material";
import { TColumnsProps } from "./types/TColumnsProps";
import { IUserResource } from "../../../interfaces/IUserResource";
import { ExtraStatusEnum } from "../../../../../entities/columns/user/ExtraStatusEnum";

export const Columns: (props: TColumnsProps) => GridColDef<IUserResource>[] = (
  props
) => [
  {
    field: "actions",
    headerName: "Actions",
    width: 90,
    hideable: false,
    sortable: false,
    renderCell: (params) => {
      // @todo <ConfirmationButton /> must not be used in the loop since it will render unnecessarily
      // lots of modals
      return (
        <>
          {params.row?.status !== ExtraStatusEnum.PENDING &&
            props.can(AbilityEnum.financing) && (
              <Tooltip title="User Financial Data">
                <IconButton
                  onClick={() =>
                    props.onFinancialDataButtonClick(params.row.id!)
                  }
                  color="primary"
                >
                  <AttachMoney color="primary" fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          {params.row?.status === ExtraStatusEnum.PENDING && (
            <ConfirmationButton
              title="Drop User Invitation"
              content={`Are you sure you want to drop invitation for "${params.row?.email}"?`}
              button={
                <IconButton color="error">
                  <Delete color="error" fontSize="small" />
                </IconButton>
              }
              successHandler={() =>
                props.onDeleteButtonClick(params.row as IUserResource)
              }
            />
          )}

          {params.row?.isVirtual && (
            <Tooltip title="Invite to Your Team">
              <IconButton
                color="primary"
                onClick={() =>
                  props.onSendInvitation(params.row as IUserResource)
                }
              >
                <GroupAdd color="primary" fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </>
      );
    },
  },
  {
    field: "name",
    headerName: "Full Name",
    flex: 1,
    minWidth: 200,
    maxWidth: 300,
    hideable: false,
    renderCell: (params: GridRenderCellParams) => {
      if (!params.value) {
        return null;
      }

      return (
        <Box display="flex" gap={1} alignItems={"center"}>
          <AvatarIcon userName={params.value} size={25} fontSize={10} />
          <span>{params.value}</span>
        </Box>
      );
    },
  },
  {
    field: "email",
    headerName: "Email",
    flex: 1,
    minWidth: 300,
    maxWidth: 400,
    hideable: false,
  },
  {
    field: "role",
    headerName: "Role",
    width: 200,
    hideable: false,
    renderCell: (params: GridRenderCellParams) => {
      if (!params.value) {
        return null;
      }

      return (
        <EditableColumn
          patchServiceName="changeRole"
          gridRenderCellParams={params}
          name="role"
          list={props.accessibleRoles!.map((value) => ({
            id: value,
            label: value,
          }))}
        />
      );
    },
  },
  {
    field: "status",
    headerName: "Status",
    width: 200,
    hideable: false,
    renderCell: (params: GridRenderCellParams) => {
      if (params.value === ExtraStatusEnum.PENDING) {
        return <span>{params.value}</span>;
      }

      return (
        <EditableColumn
          patchServiceName="changeStatus"
          gridRenderCellParams={params}
          name="status"
          list={enumUtil.toList(StatusEnum)}
        />
      );
    },
  },
  {
    field: "isVirtual",
    headerName: "Virtual User",
    hideable: false,
    renderCell: (params: GridRenderCellParams) => {
      return <span>{params.value ? "Yes" : "No"}</span>;
    },
  },
  {
    field: "createdAt",
    headerName: "Date Added",
    width: 200,
    hideable: false,
  },
];
