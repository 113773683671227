import { Box, MenuItem, Typography, useTheme } from "@mui/material";
import useCustomField from "./useCustomField";
import { Tooltip } from "../../materialUI";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import useMedia from "../../../hooks/useMedia/useMedia";
import { QueryEnum } from "../../../hooks/useMedia/enums/QueryEnum";
import { TListResource } from "../../../types/TListResource";
import Badge from "../../materialUI/components/Badge/Badge";

const useFilterTitle = ({
  filters,
  usersFilteredCount,
  showUser,
  showSprint,
}: {
  filters?: {
    taskTypeIds: TListResource<number>;
    taskPriorityIds: TListResource<number>;
    sprintRefs?: TListResource<number>;
    customFields: TListResource<string | number>;
  };
  usersFilteredCount?: number;
  showUser?: boolean;
  showSprint?: boolean;
} = {}) => {
  const customFieldComponent = useCustomField({
    isBacklog: false,
  }).listFilters();
  const theme = useTheme();
  const matchesMax768Screen = useMedia(QueryEnum.MAX_WIDTH_768);

  const titleData: { label: string; filtersCount?: number; show?: boolean }[] =
    [
      {
        label: "Type",
        filtersCount: filters?.taskTypeIds?.length,
      },
      {
        label: "Priority",
        filtersCount: filters?.taskPriorityIds?.length,
      },
      {
        label: "Sprint",
        show: showSprint,
        filtersCount: filters?.sprintRefs?.length,
      },
      {
        label: "Users",
        show: showUser && matchesMax768Screen,
        filtersCount: usersFilteredCount,
      },
      ...(customFieldComponent?.map((item, index) => ({
        label: item.label,
        filtersCount: filters?.customFields?.filter(
          (field) => field.extraId === item.id
        ).length,
      })) || []),
    ];

  return {
    titleList: (
      activeItem: string,
      changeHandler: (filtredItem: string) => void
    ) => {
      return (
        <Box>
          {titleData.map(
            (item: {
              label: string;
              filtersCount?: number;
              show?: boolean;
            }) => {
              if (item.show === false) return null;
              return (
                <MenuItem
                  key={item.label}
                  onClick={() => {
                    changeHandler(item.label);
                  }}
                  sx={{
                    height: "32px",
                    minHeight: "32px",
                    p: "4px 16px",
                    display: "flex",
                    alignItems: "center",
                    background:
                      item.label === activeItem
                        ? `${theme.palette.primaryStates.hover} !important`
                        : "",
                  }}
                >
                  <Tooltip title={item.label}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        gap="8px"
                        overflow="hidden"
                      >
                        <Typography noWrap maxWidth="100%">
                          {item.label}
                        </Typography>
                        {item.filtersCount !== undefined &&
                          item.filtersCount > 0 && (
                            <Badge>{item.filtersCount}</Badge>
                          )}
                      </Box>
                      <NavigateNextIcon
                        sx={{
                          color: "action.active",
                          height: "24px",
                        }}
                      />
                    </Box>
                  </Tooltip>
                </MenuItem>
              );
            }
          )}
        </Box>
      );
    },
  };
};

export default useFilterTitle;
