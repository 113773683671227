import { createTheme } from "@mui/material";
import { env } from "../../utils/env";

const theme = createTheme();

export const lightTheme = createTheme({
  palette: {
    mode: "light",
    dropTargetBackground: {
      default: "#89ccb954",
      paper: "#353c4e",
    },
    brightCardBackground: {
      highlightColor: "#353B44",
      default: "#F2F5F6",
      paper: "#ffffff",
      main: "#353c4e",
      contrastText: "#AEBDC1",
    },
    taskView: {
      mainBackground: "#F6F7FB",
      secondaryBackground: "#FDFDFE",
      mainContentBackground: "#EEF0F7",
      headerTitleBackground: "rgba(0, 0, 0, 0.05)",
      aiButtonBackground: "#ffffff",
      dividerBackground: "rgba(0, 0, 0, 0.10)",
      attachmentDividerBackground: "rgba(162, 162, 162, 0.10)",
      headerBorderBottomBackground: "rgba(0, 0, 0, 0.10)",
      dropContainerBackground: "rgba(226, 226, 226, 0.7)",
      editorExpandGradient:
        "linear-gradient(180deg, rgba(246, 247, 251, 0.00) 0%, #EEF0F7 72.56%)",
      textMainColor: "#353C4E",
      textSecondaryColor: "#4C5363",
      labelColor: "#676B77",
      quickActionsLinkColor: "#676B77",
      descriptionAiIcon: `${env.cdnUrl}/components/ai-sentinel/ai-sentinel-icon-dark.png`,
    },
    brightCardShadowColor: {
      primary: "inset 0 0 10px #fff, 0 0 0 #fff",
      secondary: "inset 0 0 4px #fff, 0 0 0 #fff",
    },
    mutedCardBackground: {
      default: "#ebebeb",
      paper: "#f3f3f3",
      main: "#353c4e",
    },
    cardsBackground: {
      default: "#eff1f5",
      paper: "#ffffff",
      main: "#717173",
    },
    cardSplit: {
      color: "#000000A6",
      border: "2px dashed #000000A6",
    },
    inputPallet: {
      main: "#312312",
    },
    loginPallet: {
      main: "#ffffff",
    },
    pickerPallet: {
      primary: "#353B44",
      secondary: "#5b6272",
    },
    boardSideViewShadow: {
      main: "#d6d7db -47px -20px 20px -40px inset",
    },
    dataGrid: {
      borderColor: "#e2e7ed",
    },
    landingPageCard: {
      default: "#EDEEF2",
      paper: "#ffffff",
    },
    event: {
      background: "rgb(208 207 249 / 8%)",
      color: "#5b6272",
      backgroundImgSrc: `${env.cdnUrl}/components/event/event-lines-light.png`,
      shadow: "#ffffff 0px 0px 20px inset",
      icon: `${env.cdnUrl}/components/event/calendar-light-theme.svg`,
    },
    offtime: {
      backgroundImgSrc: `${env.cdnUrl}/app-v2/assets/offTimeLine.svg`,
    },
    occupied: {
      shadow: "0px 0px 4px 0px rgba(255,255,255,1)",
    },
    spike: {
      backgroundColor: "#ffeca6",
      color: "#353c4e",
      border: "1px solid #f8bd01",
      xsImg: `${env.cdnUrl}/components/spike/xsspike-icon-dark.png`,
      hover: "#f0e0a5",
    },
    floatingActions: {
      buttonBackgroundColor: "#ffffff",
    },
    aiSentinel: {
      aiChatBackgroundColor: "#f0f3f5",
      userChatBackgroundColor: "#d4d7e0",
      chatGeneralBackgroundColor: "#e6e8ed",
      headerBackgroundColor: "#f5f7f8",
      headerIconBackgroundColor: "#353b44",
      chatRequiredFieldsColor: "#73797F",
      chatRequiredFieldsBackgroundColor: "#E9EAEE",
      regularIcon: `${env.cdnUrl}/components/ai-sentinel/ai-sentinel-icon-dark.png`,
      headerIcon: `${env.cdnUrl}/components/ai-sentinel/ai-sentinel-icon-light.png`,
    },
    helpCenter: {
      liveChatIcon: `${env.cdnUrl}/components/help-center/live-chat-icon-light.png`,
    },
    statusTypeSchema: {
      toDo: { color: "#b6c7d9" },
      inProgress: { color: "#82cdd3" },
      done: { color: "#80d3b3" },
      textColor: "#353c4e",
      buttonBackground: "#e2e6ea",
    },
    nonWorkday: {
      timelineColor: "#17C29C",
      timelineFontWeight: 500,
      overlay: "#6A7C931F",
    },
    sprint: {
      main: "#ACDFD3",
    },
    circularProgressBar: {
      strokeColor: "#1976d2",
      strokeBackgroundColor: "#0000004C",
    },
    divider: "#353B441F",
    text: {
      main: "#353B44DE",
      primary: "#353B44DE",
      secondary: "#353B4499",
      disabled: "#353B4461",
      _states: {
        hover: "#353B440A",
        selected: "#353B4414",
        focus: "#353B441F",
        focusVisible: "#353B444D",
      },
    },
    primary: {
      main: "#39A183",
      dark: "#228465",
      light: "#84C8B4",
      contrastText: "#FFFFFF",
    },
    primaryStates: {
      hover: "#39A1830A",
    },
    secondary: {
      main: "#F8BD00",
      dark: "#FB8A00",
      light: "#FEEBA6",
      contrastText: "#FFFFFF",
    },
    secondaryStates: {
      focus: "#FEEBA61F",
    },
    tertiary: {
      main: "#FF8A65",
      dark: "#F4511E",
      light: "#FFCCBC",
      contrastText: "#FFFFFF",
    },
    action: {
      active: "#6A7C938F",
      hover: "#6A7C930A",
      selected: "#6A7C9314",
      focus: "#6A7C931F",
      disabled: "#6A7C9333",
      disabledBackground: "#6A7C931F",
    },
    error: {
      main: "#D32F2F",
      dark: "#C62828",
      light: "#EF5350",
      contrastText: "#FFFFFF",
    },
    warning: {
      main: "#FF8F00",
      dark: "#FF6F00",
      light: "#FFA000",
      contrastText: "#FFFFFF",
    },
    info: {
      main: "#03A9F4",
      dark: "#01579B",
      light: "#4FC3F7",
      contrastText: "#FFFFFF",
    },
    success: {
      main: "#689F38",
      dark: "#33691E",
      light: "#8BC34A",
      contrastText: "#FFFFFF",
    },
    successLight: {
      main: "#50C878",
    },
    risk: {
      main: "#FFAF42",
    },
    common: {
      white: "#FFFFFF",
      black: "#353B44",
    },
    background: {
      default: "#ECEFF5",
      paper: "#FFFFFF",
    },
    backgroundElevation: {
      backgroundPaperElevation_0: "#FFFFFF",
      backgroundPaperElevation_1: "#F4F6FA",
      backgroundPaperElevation_2: "#F7F7F8",
      backgroundPaperElevation_3: "#F0F2F4",
      backgroundPaperElevation_4: "#FFFFFF",
      backgroundPaperElevation_5: "#FFFFFF",
      backgroundPaperElevation_6: "#6A7C9314",
      backgroundPaperElevation_7: "#FFFFFF",
      backgroundPaperElevation_8: "#FFFFFF",
    },
    elevation: {
      outlined: "#D3D7D6",
    },
    _components: {
      avatar: {
        "1": "#84C8B4",
        "2": "#FFCCBC",
        "3": "#D1C4E9",
        "4": "#FFF59D",
        "5": "#90CAF9",
        "6": "#9FA8DA",
        "7": "#F48FB1",
        "8": "#81D4FA",
        avatarText: "#353B44CC",
      },
      input: {
        standard: {
          enabledBorder: "#353B446B",
          hoverBorder: "#353B44",
        },
        filled: {
          enabledFill: "#353B440F",
          hoverFill: "#353B4417",
        },
        outlined: {
          enabledBorder: "#353B443B",
          hoverBorder: "#353B44",
        },
      },
      switch: {
        knobFillEnabled: "#39A183",
        slideFill: "#353B44",
        knowFillDisabled: "#B4BECB",
        knobDisabled: "#ECEFF5",
      },
      rating: {
        enabledBorder: "#353B443B",
        activeFill: "#FFB400",
      },
      snackbar: {
        fill: "#323232",
      },
      chip: {
        defaultCloseFill: "#353B44",
        defaultHoverFill: "#353B441F",
        defaultEnabledBorder: "#8090A4",
        defaultFocusFill: "#353B4433",
      },
      backdrop: {
        fill: "#353B4480",
      },
      appBar: {
        defaultFill: "#353B44",
      },
      breadcrumbs: {
        collapseFill: "#B4BECB",
      },
      alert: {
        error: {
          color: "#5F2120",
          background: "#FDEDED",
        },
        warning: {
          color: "#663C00",
          background: "#FFF4E5",
        },
        info: {
          color: "#014361",
          background: "#E5F6FD",
        },
        success: {
          color: "#1E4620",
          background: "#EDF7ED",
        },
      },
      stepper: {
        connector: "#96A4B5",
      },
      card: {
        CardMainFill: "#ECEFF5",
      },
    },
    _native: {
      "scrollbar-bg": "#B4BECB",
    },
    _common: {
      black_states: {
        outlinedBorder: "#353B4440",
      },
    },
  },
  shape: {
    borderRadius: 4,
  },
  typography: {
    fontFamily: "Roboto, Helvetica",
    h1: {
      fontSize: "96px",
      fontWeight: 300,
      letterSpacing: "-1.5px",
      lineHeight: "116.7%",
    },
    h2: {
      fontSize: "60px",
      fontWeight: 300,
      letterSpacing: "-0.5px",
      lineHeight: "120%",
    },
    h3: {
      fontSize: "48px",
      fontWeight: 400,
      letterSpacing: "0px",
      lineHeight: "116.7%",
    },
    h4: {
      fontSize: "34px",
      fontWeight: 400,
      letterSpacing: "0.25px",
      lineHeight: "123.5%",
    },
    h5: {
      fontSize: "24px",
      fontWeight: 400,
      letterSpacing: "0px",
      lineHeight: "133.4%",
    },
    h6: {
      fontSize: "20px",
      fontWeight: 500,
      letterSpacing: "0.15px",
      lineHeight: "160%",
    },
    subtitle1: {
      fontSize: "15px",
      fontWeight: 500,
      letterSpacing: "0.15px",
      lineHeight: "175%",
    },
    subtitle2: {
      fontSize: "14px",
      fontWeight: 500,
      letterSpacing: "0.1px",
      lineHeight: "157%",
    },
    body1: {
      fontSize: "15px",
      fontWeight: 400,
      letterSpacing: "0.15px",
      lineHeight: "150%",
    },
    body2: {
      fontSize: "14px",
      fontWeight: 400,
      letterSpacing: "0.17px",
      lineHeight: "143%",
    },
    caption: {
      fontSize: "12px",
      fontWeight: 400,
      letterSpacing: "0.4px",
      lineHeight: "166%",
    },
    overline: {
      fontSize: "12px",
      fontWeight: 400,
      letterSpacing: "1px",
      lineHeight: "266%",
    },
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: () => ({
          backgroundImage: "none",
        }),
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundImage: "none",
        },
      },
    },
    MuiFormControlLabel: {
      defaultProps: {
        slotProps: {
          typography: {
            variant: "body2",
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#353B44E6",
          color: "#FFFFFF",
          boxShadow: theme.shadows[12],
          ...theme.typography.body2,
        },
        arrow: {
          color: "#353B44E6",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        popper: {
          // Required for showing Autocomplete options inside AI Sentinel when any Dialog is opened
          zIndex: 1500,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          // Required for showing date picker dialog inside AI Sentinel: above the chat and above any opened Dialog
          zIndex: 1500,
        },
      },
    },
    MuiPopper: {
      defaultProps: {
        style: {
          // Required for showing date picker dialog inside AI Sentinel: above the chat and above any opened Dialog
          zIndex: 1500,
        },
      },
    },
  },
});
