import IntegrationSettingsList from "./IntegrationSettingsList";
import { useAccessControl } from "../../../../../../../modules/accessControl/hooks/useAccessControl";
import { AbilityEnum } from "../../../../../../../modules/accessControl/enums/AbilityEnum";
import NavigationTitle from "../../../../../../../components/navigationTitle/NavigationTitle";
import { TBillingSettingsNavigationContentProps } from "../BillingSettings/types/TBillingSettingsNavigationContentProps";

const IntegrationSettingsSection = ({
  onClick,
}: TBillingSettingsNavigationContentProps) => {
  const { can } = useAccessControl();

  return (
    <>
      {can(AbilityEnum.manage) && (
        <>
          <NavigationTitle title={"Integrations"} />
          <IntegrationSettingsList onClick={onClick} />
        </>
      )}
    </>
  );
};

export default IntegrationSettingsSection;
