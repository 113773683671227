import { Box, Grid, IconButton, Typography } from "@mui/material";
import { Skeleton, Tooltip } from "../../../../../common/modules/materialUI";

const ReadOnlyLinkList = () => {
  return (
    <>
      {Array.from({ length: 3 }).map((value, index) => (
        <Grid item xs={12} key={index} sx={{ pb: 1 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 2,
              borderRadius: 2,
              boxShadow: 1,
              backgroundColor: "background.paper",
            }}
          >
            <Box>
              <Typography variant="body1" fontWeight="bold">
                <Skeleton variant="text" width={60} />
              </Typography>
              <Typography variant="caption">
                <Skeleton variant="text" width={180} />
              </Typography>
            </Box>

            <Box>
              <Tooltip title="Copy Board Read-only link">
                <span>
                  <IconButton>
                    <Skeleton variant="text" width={20} />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title={`Delete Board Read-only link`}>
                <span>
                  <IconButton>
                    <Skeleton variant="text" width={20} />
                  </IconButton>
                </span>
              </Tooltip>
            </Box>
          </Box>
        </Grid>
      ))}
    </>
  );
};

export default ReadOnlyLinkList;
