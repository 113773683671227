import useForm from "../../../../common/hooks/useForm/useForm";
import Form from "../../../../common/components/Form/Form";
import {
  Autocomplete,
  Button,
  DatePicker,
  TextField,
} from "../../../../common/modules/materialUI";
import { THttpClientError } from "../../../../common/modules/httpClient/types/THttpClientError";
import Grid from "@mui/material/Grid2";
import { useUserListService } from "../../../user/services/useUserListService";
import { TUpdateFormProps } from "./types/TUpdateFormProps";
import { TUpdateFormAttributes } from "./types/TUpdateFormAttributes";
import { TUpdateFormAttributesTransformed } from "./types/TUpdateFormAttributesTransformed";
import { useUpdateSprintService } from "../../services/useUpdateSprintService";
import { sprintActions } from "../../slices/sprintSlice";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import { systemNotificationActions } from "../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import moment from "moment";
import { FORMAT_RAW_DATE_ONLY } from "../../../../common/utils/date";
import { IUser } from "../../../../entities/IUser";
import { useOrgProjectData } from "../../../org/hooks/useOrgProjectData";

const UpdateForm = ({
  onCancel,
  sprintResource,
  projectAbbr,
}: TUpdateFormProps) => {
  const dispatch = useAppDispatch();
  const sprintLabel = useAppSelector((state) => state.customs.sprintLabel);
  const projectData = useOrgProjectData();
  const projectId = projectData.findProjectByAbbr(projectAbbr)?.project.id!;
  const form = useForm<TUpdateFormAttributes>({
    name: sprintResource.sprint.name,
    dueDate: sprintResource.sprint.dueDate,
    startDate: sprintResource.sprint.startDate,
    rep: sprintResource.representative,
    projectId: projectId,
  });
  const { isLoading, dispatch: dispatchCreateSprint } = useUpdateSprintService({
    sprintId: +sprintResource.sprint.id!,
  });
  const userListService = useUserListService();

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();

    dispatchCreateSprint({
      body: form.getTransformed<TUpdateFormAttributesTransformed>((data) => ({
        ...data,
        rep: data.rep?.id,
      })),
    })
      .then(() => {
        dispatch(
          sprintActions.addRepresentative({
            id: +form.data?.rep?.id!,
            name: form.data?.rep?.label!,
          } as IUser)
        );
        dispatch(
          sprintActions.updateList({
            id: +sprintResource.sprint.id!,
            name: form.data?.name!,
            startDate: moment(form.data?.startDate!)!.format(
              FORMAT_RAW_DATE_ONLY
            ),
            dueDate: moment(form.data?.dueDate!)!.format(FORMAT_RAW_DATE_ONLY),
            rep: +form.data?.rep!.id!,
          })
        );
        dispatch(sprintActions.closeUpdateModal());
        dispatch(
          systemNotificationActions.open({
            message: `${sprintLabel} updated successfully`,
            variant: "success",
          })
        );
      })
      .catch((error: THttpClientError) => {
        form.errorHandler(error);
      });
  };

  return (
    <Form
      onSubmit={submitHandler}
      actionElement={
        <>
          <Button onClick={onCancel} disabled={isLoading} variant="outlined">
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            variant="contained"
            type="submit"
            loading={isLoading}
          >
            Update
          </Button>
        </>
      }
    >
      <TextField form={form} name="name" label="Name" showRequired />

      <Autocomplete
        form={form}
        name="rep"
        label="Representative"
        showRequired
        service={userListService}
        options={
          form.data?.rep
            ? [
                {
                  id: form.data.rep.id,
                  label: form.data.rep.label,
                },
              ]
            : []
        }
      />

      <Grid container spacing={2}>
        <Grid
          size={{
            xs: 12,
            sm: 6,
          }}
        >
          <DatePicker
            form={form}
            name="startDate"
            label="Start Date"
            showRequired
          />
        </Grid>
        <Grid
          size={{
            xs: 12,
            sm: 6,
          }}
        >
          <DatePicker
            form={form}
            name="dueDate"
            label="Due Date"
            showRequired
          />
        </Grid>
      </Grid>
    </Form>
  );
};

export default UpdateForm;
