import classes from "./Lane.module.scss";
import moment from "moment/moment";
import { useTheme } from "@mui/material";
import { pointConverter } from "../../../../../../common/utils/pointConverter";
import LaneItem from "../LaneItem/LaneItem";
import Overlay from "./Overlay";
import { TLaneProps } from "./types/TLaneProps";
import DropTargetContainer from "../DropTarget/DropTargetContainer";

const POSITION_SHIFT = 5;
export const GUESSTIMATION_ITEM_LANE_HEIGHT = 112;

const Lane = (props: TLaneProps) => {
  const theme = useTheme();

  return (
    <div
      className={classes.container}
      style={{
        width: props.width,
        minHeight: `${GUESSTIMATION_ITEM_LANE_HEIGHT}px`,
      }}
    >
      <DropTargetContainer
        guesstimationItem={props.guesstimationItems[0]}
        users={props.users}
        width={props.width}
        minDate={props.minDate}
        maxDate={props.maxDate}
      />
      <div
        className={classes["task-lane"]}
        style={{
          backgroundColor:
            theme.palette.backgroundElevation.backgroundPaperElevation_1,
        }}
      >
        <Overlay />
        {props.guesstimationItems.map((guesstimationItem, index) => {
          const xSize = pointConverter.pointToXSizeInPixels(
            guesstimationItem.tsPoints
          );
          // This will force tasks to re-render on user lane update, even if service sent same data
          // const suffix = laneData.keySuffix ? "-" + laneData.keySuffix : "";
          // const key = `task-${task.segmentId}${suffix}`;
          return (
            <LaneItem
              key={guesstimationItem.ref}
              itemLaneIndex={guesstimationItem.id}
              phaseIndex={index + 1}
              guesstimationItem={guesstimationItem}
              size={xSize - POSITION_SHIFT}
              positionX={pointConverter.intervalToXPositionInPixels(
                props.minDate,
                moment(guesstimationItem.startDate)
              )}
              users={props.users}
              groupSize={props.guesstimationItems.length}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Lane;
