import { Box, Switch, Typography } from "@mui/material";
import { useState, useTransition } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../common/hooks/redux";
import {
  boardViewActions,
  STORAGE_GROUP_BY_SPRINT_KEY,
} from "../../../../../slices/boardViewSlice";
import { menuItem } from "../../../../../../../common/containers/Menu/utils/menuItem";
import { TFilterProps } from "../../../../../containers/Filter/Filter/types/TFilterProps";
import { propsForMenuItems } from "../../../../../../../common/containers/Menu/common/propsForMenuItems";

const GroupBySprintOption = (props: TFilterProps) => {
  const customs = useAppSelector((state) => state.customs);
  const [checked, setChecked] = useState<boolean>(
    useAppSelector((state) => state.boardView.listView.groupBySprint)
  );
  const [, startTransition] = useTransition();
  const dispatch = useAppDispatch();

  const clickHandler = () => {
    setChecked((prev) => {
      const checked = !prev;
      localStorage.setItem(
        STORAGE_GROUP_BY_SPRINT_KEY,
        checked ? "true" : "false"
      );
      startTransition(() => {
        dispatch(boardViewActions.setGroupBySprint(checked));
      });
      return checked;
    });
  };

  return menuItem.make({
    component: (
      <Box
        display="flex"
        alignItems="center"
        gap={1}
        color={"pickerPallet.secondary"}
        sx={{ cursor: "pointer" }}
      >
        <Switch checked={checked} size="small" />
        <Typography
          variant="subtitle2"
          fontWeight="400"
          color={"text.secondary"}
        >
          Group by {customs.sprintLabel}
        </Typography>
      </Box>
    ),
    handler: clickHandler,
    when: props.renderGroupBySprint,
    menuItemsProps: propsForMenuItems,
    closeOnClick: false,
  });
};

export default GroupBySprintOption;
