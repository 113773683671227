import { Box, Typography } from "@mui/material";
import classes from "./GuesstimationItemIsDeletedContent.module.scss";

const GuesstimationItemIsDeletedContent = () => {
  return (
    <Box className={`text-center ${classes.container}`} sx={{ pt: 25 }}>
      <Typography variant="body2" sx={{ color: "text.secondary", mb: 2 }}>
        This item has been deleted successfully. You can close this page
      </Typography>
    </Box>
  );
};

export default GuesstimationItemIsDeletedContent;
