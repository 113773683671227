import { Button } from "../../../modules/materialUI";
import { Logout as LogoutIcon } from "@mui/icons-material";
import Grid from "@mui/material/Grid2";

type TProps = {
  clickHandler: () => void;
};

export default function SignOutButton({ clickHandler }: TProps) {
  return (
    <Grid size={12}>
      <Button
        variant="text"
        color="inherit"
        startIcon={<LogoutIcon />}
        onClick={clickHandler}
      >
        Sign Out
      </Button>
    </Grid>
  );
}
