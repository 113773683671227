import { Chip as MuiChip, ChipProps } from "@mui/material";

const Chip = ({ size = "medium", variant = "filled", ...props }: ChipProps) => {
  const isFilled = variant === "filled";

  return (
    <MuiChip
      {...props}
      variant={variant}
      sx={{
        borderRadius: 2,
        padding:
          size === "medium"
            ? `${isFilled ? 4 : 3}px`
            : `${isFilled ? "1px 4px" : "0px 3px"}`,
        fontSize: size === "medium" ? "14px" : "12px",
        fontWeight: 400,
        lineHeight: "18px",
        letterSpacing: "0.16px",
        height: "auto",
        "& .MuiChip-label": {
          px: "6px",
        },
        ...props.sx,
      }}
    />
  );
};

export default Chip;
