import { TCreateFormProps } from "./types/TCreateFormProps";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import useForm from "../../../../common/hooks/useForm/useForm";
import { TCreateFormAttributes } from "./types/TCreateFormAttributes";
import Form from "../../../../common/components/Form/Form";
import {
  Autocomplete,
  Button,
  Checkbox,
  TextField,
} from "../../../../common/modules/materialUI";
import { THttpClientError } from "../../../../common/modules/httpClient/types/THttpClientError";
import { systemNotificationActions } from "../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { useCreateLinkListService } from "../../services/useCreateLinkListService";
import { linkListActions } from "../../slices/linkListSlice";
import { projectUtil } from "../../../../common/utils/projectUtil";
import { TCreateFormAttributesTransformed } from "./types/TCreateFormAttributesTransformed";
import { ILinkList } from "../../../../entities/ILinkList";
import { Box, FormControlLabel, Typography } from "@mui/material";

const CreateForm = (props: TCreateFormProps) => {
  const dispatch = useAppDispatch();
  const taskLabel = useAppSelector((state) => state.customs.taskLabel);
  const form = useForm<TCreateFormAttributes>({
    description: "",
    timeSensitive: false,
    linkedDescription: null,
  });
  const projects = useAppSelector((state) => state.project.projectList);
  const { isLoading, dispatch: dispatchCreateLinkListService } =
    useCreateLinkListService();

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();

    dispatchCreateLinkListService({
      body: form.getTransformed<TCreateFormAttributesTransformed>((data) => ({
        ...data,
        projectIds: data.projectIds?.length
          ? data.projectIds.map((item) => item.id)
          : null,
      })),
    })
      .then((payload: ILinkList[]) => {
        dispatch(linkListActions.closeCreateModal());
        dispatch(
          systemNotificationActions.open({
            message: "Link created successfully",
            variant: "success",
          })
        );
      })
      .catch((error: THttpClientError) => {
        form.errorHandler(error);
      });
  };

  return (
    <Form
      onSubmit={submitHandler}
      actionElement={
        <>
          <Button
            onClick={props.onCancel}
            disabled={isLoading}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            variant="contained"
            type="submit"
            loading={isLoading}
          >
            Add
          </Button>
        </>
      }
    >
      <TextField
        form={form}
        name="description"
        label="Description"
        placeholder="e.g. Blocks"
        showRequired
      />
      <TextField
        form={form}
        name="linkedDescription"
        label={`Linked ${taskLabel} Description`}
        placeholder="e.g. Is blocked by"
      />
      <Box
        display="flex"
        alignItems="center"
        sx={{ marginBottom: "0 !important" }}
      >
        <FormControlLabel
          control={<Checkbox form={form} name="timeSensitive" />}
          label="Time sensitive"
        />
      </Box>
      {form.get("timeSensitive") && (
        <Typography variant="caption">
          Warning: This flag identifies hard dependency between linked tasks.
          The linked one will be automatically forced to follow the end date of
          originating one.
        </Typography>
      )}
      <Autocomplete
        form={form}
        name="projectIds"
        label={!form.data?.projectIds?.length ? "All projects" : "Projects"}
        multiple
        disabled={!projects.length}
        options={projectUtil.list(projects)}
      />
    </Form>
  );
};

export default CreateForm;
