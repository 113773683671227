import Section from "./Section/Section";
import { TBoardViewProps } from "../../../../board/types/TBoardViewProps";
import { useEffect } from "react";
import KanbanViewSkeleton from "./KanbanViewSkeleton";
import EmptyView from "../../../../board/containers/EmptyView/EmptyView";
import ViewPicker from "../../../containers/ViewPicker/ViewPicker";
import Filter from "../../../containers/Filter/Filter/Filter";
import ViewContainer from "../../../containers/ViewContainer/ViewContainer";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../common/hooks/redux";
import { useSubscribeEvent } from "../../../../../common/modules/eventProvider";
import { EventEnum } from "../../../../../common/modules/eventProvider/enums/EventEnum";
import { TTaskEvent } from "../../../../task/modules/view/containers/types/TTaskEvent";
import { ITask } from "../../../../../entities/ITask";
import { kanbanViewActions } from "../slices/kanbanViewSlice";
import { BoardViewEnum } from "../../../enums/BoardViewEnum";
import { useKanbanViewQueryParams } from "../hooks/useKanbanViewQueryParams";
import { useFetchKanbanViewDataService } from "../services/useKanbanViewDispatchService";
import { useOrgStatusFlowData } from "../../../../org/hooks/useOrgStatusFlowData";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { FirebaseEventEnum } from "../../../../../common/modules/firebase/hooks/types/FirebaseEventEnum";
import { FeatureEnum } from "../../../../../common/modules/accessControl/enums/FeatureEnum";
import { useFirebaseEventHelper } from "../../../../../common/modules/firebase/hooks/useFirebaseEventHelper";
import { Box } from "@mui/material";
import NotesModal from "../../../../objective/containers/View/Initiatives/ManageInitiative/NotesModal";
import { viewPickerActions } from "../../../slices/viewPickerSlice";

const KanbanViewContent = (props: TBoardViewProps) => {
  const { addToFirebaseQueue } = useFirebaseEventHelper();
  const dispatch = useAppDispatch();
  const initiatives = useAppSelector((state) => state.board.initiatives);
  const showNotesModal = useAppSelector(
    (state) => state.viewPicker.displayNote
  );
  const isDragging = useAppSelector((state) => state.kanbanViewTask.isDragging);
  const { displaySplits: showSegments } = useAppSelector(
    (state) => state.boardView.listView
  );
  const {
    list: data,
    showSkeleton,
    isSegmentView,
  } = useAppSelector((state) => state.kanbanView);
  const queryParams = useKanbanViewQueryParams({
    projectAbbr: props.projectAbbr,
    showSegments,
  });
  const queryParamsString = JSON.stringify(queryParams);
  const { statuses } = useOrgStatusFlowData(props.board.projectRef);

  const { dispatch: dispatchFetchKanbanViewDataService } =
    useFetchKanbanViewDataService({
      boardRef: props.boardRef,
      query: queryParams,
    });
  const hasData = !!data?.tasks.length;

  useSubscribeEvent(
    EventEnum.ON_TASK_CREATED,
    (_: ITask) => {
      dispatchFetchKanbanViewDataService({
        query: queryParams,
      });
    },
    [queryParamsString]
  );

  useSubscribeEvent(
    EventEnum.ON_TASK_MAIN_DATA_UPDATED,
    (_: TTaskEvent, callback) => {
      dispatchFetchKanbanViewDataService({
        query: queryParams,
      }).finally(() => callback?.());
    },
    [queryParamsString]
  );

  useEffect(() => {
    // Do not fetch data on first render if this component wasn't yet mounted. Default data will be fetched in another useEffect.
    dispatchFetchKanbanViewDataService({
      query: queryParams,
    });
  }, [queryParamsString]);

  useEffect(() => {
    addToFirebaseQueue(FirebaseEventEnum.FEATURE_ACCESS, {
      feature: FeatureEnum.KANBAN_VIEW,
    });

    return () => {
      dispatch(kanbanViewActions.reset());
    };
  }, []);

  return (
    <>
      <ViewPicker
        board={props.board}
        projectAbbr={props.projectAbbr}
        boardRef={props.boardRef}
        boardType={props.board.type}
        havingBoardUsers={data.havingBoardUsers}
        filterElement={
          <Filter
            boardType={props.board.type}
            board={props.board}
            boardView={BoardViewEnum.KANBAN_VIEW}
            projectAbbr={props.projectAbbr}
            boardRef={props.boardRef}
            renderDisplaySplits={true}
            renderGroupBySprint={false}
            havingBoardUsers={data.havingBoardUsers}
          />
        }
      />
      {showSkeleton && <KanbanViewSkeleton />}

      {!showSkeleton && !hasData && <EmptyView {...props} />}

      {!showSkeleton && hasData && (
        <>
          <ViewContainer boardRef={props.boardRef}>
            <Box
              sx={{
                // its for slider effect
                overflowX: "auto",
                display: "flex",
                scrollSnapType: isDragging ? "none" : "x mandatory",
                "& > *": {
                  scrollSnapAlign: "start",
                },
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                scrollbarWidth: "none", // For Firefox
                paddingBottom: "1px",
              }}
            >
              <DndProvider backend={HTML5Backend}>
                {statuses.map((status, index) => {
                  const tasks = data.tasks.filter(
                    (item) => item.status === status.name
                  );
                  return (
                    <Section
                      key={index}
                      status={status}
                      tasks={tasks}
                      users={data.users}
                      showSegments={isSegmentView}
                    />
                  );
                })}
              </DndProvider>
            </Box>
          </ViewContainer>
        </>
      )}

      <NotesModal
        show={showNotesModal}
        boardId={props.board.id}
        initiatives={initiatives}
        isBoardView={true}
        closeHandler={function () {
          dispatch(viewPickerActions.setDisplayNote(false));
        }}
      />
    </>
  );
};

export default KanbanViewContent;
