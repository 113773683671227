import { Children, cloneElement, isValidElement, ReactElement } from "react";
import {
  ButtonGroupProps,
  ButtonProps,
  ButtonGroup as MuiButtonGroup,
} from "@mui/material";

const ButtonGroup = ({
  size = "medium",
  variant = "contained",
  sx,
  ...props
}: ButtonGroupProps) => {
  const childrenWithVariant = Children.map(props.children, (child) =>
    isValidElement(child)
      ? cloneElement(child as ReactElement<ButtonProps>, {
          variant,
        })
      : child
  );

  return (
    <MuiButtonGroup
      {...props}
      variant={variant}
      sx={{
        borderRadius: 2,
        boxShadow: variant === "contained" ? 2 : 0,
        ...sx,
      }}
    >
      {childrenWithVariant}
    </MuiButtonGroup>
  );
};

export default ButtonGroup;
