import { Box, Typography, useTheme } from "@mui/material";
import AiSentinelIcon from "../AiSentinel/AiSentinelIcon";
import { TRawMessageProps } from "./types/TRawMessageProps";
import AvatarIcon from "../../../user/containers/AvatarIcon/AvatarIcon";
import useWindow from "../../../../common/hooks/useWindow";
import useAiHandleSendUserMessage from "../../hooks/useAiHandleSendUserMessage";

const RawMessage = ({ type, children, aiSentinel }: TRawMessageProps) => {
  const isUserMessage = type === "user";
  const theme = useTheme();
  const { openNewTab } = useWindow();
  const { handleSendUserMessage } = useAiHandleSendUserMessage();

  return (
    <Box
      {...(isUserMessage && {
        sx: {
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
        },
      })}
    >
      <Box
        p={1.5}
        my={2}
        mx={1}
        sx={{
          backgroundColor: isUserMessage
            ? theme.palette.aiSentinel.userChatBackgroundColor
            : theme.palette.aiSentinel.aiChatBackgroundColor,
          borderRadius: 5,
          width: "85%",
        }}
      >
        <Box
          sx={{
            width: "100%",
          }}
        >
          {children}
        </Box>
      </Box>
      {isUserMessage ? (
        <AvatarIcon noPadding disableRipple sx={{ cursor: "default" }} />
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap={2}
        >
          <AiSentinelIcon size={30} displayText />
          {aiSentinel?.meetingAssistant?.zoomMeetingLink && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              gap={0.5}
              sx={{ cursor: "pointer" }}
              onClick={() => {
                const link = aiSentinel.meetingAssistant.zoomMeetingLink!;
                handleSendUserMessage(link);
                setTimeout(() => {
                  openNewTab(link, true);
                }, 1300);
              }}
            >
              <img
                width={18}
                height={18}
                src="https://cdn.tasksensor.com/integrations/zoom/zoom-logo-150x150.png"
              />
              <Typography fontWeight={100} fontSize={13}>
                Join Zoom
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default RawMessage;
