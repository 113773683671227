import { menuItem } from "../../../../../../../common/containers/Menu/utils/menuItem";
import { ICollisionOverview } from "../../../../../../../entities/columns/guesstimationItem/ICollisionOverview";
import CollisionsItem from "../Items/CollisionsItem";

const CollisionsMenuItem = ({
  collisionsOverview,
  collisionsOverviewLength,
}: {
  collisionsOverview: ICollisionOverview[];
  collisionsOverviewLength?: number;
}) => {
  return menuItem.make({
    component: (
      <CollisionsItem
        collisionsOverview={collisionsOverview}
        collisionsOverviewLength={collisionsOverviewLength}
      />
    ),
    isHeaderItem: true,
  });
};
export default CollisionsMenuItem;
