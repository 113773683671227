import { useTheme } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { MAIN_CONTENT_PADDING_BOTTOM } from "../../../../../common/containers/Layout/App/Content/Content";
import { EntityTypeEnum } from "../../../../../entities/enums/EntityTypeEnum";
import { TBacklogItemContentProps } from "./types/TBacklogItemContentProps";
import AddAttachment from "../../../../../common/modules/taskView/containers/QuickActions/containers/AddAttachment";
import AddLink from "../../../../../common/modules/taskView/containers/QuickActions/containers/AddLink";
import AddToTimeline from "../../../../../common/modules/taskView/containers/QuickActions/containers/AddToTimeline";
import QuickActions from "../../../../../common/modules/taskView/containers/QuickActions/QuickActions";
import BacklogItemViewHeader from "./Header/BacklogItemViewHeader";
import BacklogItemViewMainContent from "./TaskViewMainContent/BacklogItemViewMainContent";

const BacklogItemViewMobile = ({
  detailsResource,
  contentHeight,
  isMobileView,
}: TBacklogItemContentProps) => {
  const { backlogItem } = detailsResource;
  const entityType = EntityTypeEnum.BACKLOG_ITEM;
  const id = backlogItem.id;

  const theme = useTheme();

  return (
    <Grid
      container
      minHeight={contentHeight}
      flexDirection="column"
      wrap="nowrap"
    >
      <Grid>
        <BacklogItemViewHeader
          backlogItem={backlogItem}
          isMobileView={isMobileView}
        />
      </Grid>

      <Grid
        container
        bgcolor={theme.palette.taskView.mainBackground}
        p="20px 16px"
        pb={`${MAIN_CONTENT_PADDING_BOTTOM}px`}
        flex={1}
        flexDirection="column"
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          mb="20px"
        >
          <Grid />
          <Grid>
            <QuickActions isMobileView={isMobileView}>
              <AddAttachment
                isMobileView={isMobileView}
                entityType={entityType}
                entityId={id}
              />
              <AddLink
                entityType={entityType}
                entityId={id}
                projectRef={backlogItem.projectRef}
              />
              <AddToTimeline
                backlogItemRef={backlogItem.ref}
                boardRef={backlogItem.boardRef}
              />
            </QuickActions>
          </Grid>
        </Grid>

        <BacklogItemViewMainContent
          backlogItem={backlogItem}
          isMobileView={isMobileView}
        />
      </Grid>
    </Grid>
  );
};

export default BacklogItemViewMobile;
