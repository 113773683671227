import useMedia from "../../../../../../hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../../../hooks/useMedia/enums/QueryEnum";
import Desktop from "../../Desktop/ReadOnly/Desktop";
import Mobile from "../../Mobile/ReadOnly/Mobile";
import { Box } from "@mui/material";
import useHeaderHeight from "../../hooks/useHeaderHeight";

const Header = () => {
  const matchesMax992Screen = useMedia(QueryEnum.MAX_WIDTH_992);
  const { HEADER_HEIGHT_PX } = useHeaderHeight();

  return (
    <Box
      height={HEADER_HEIGHT_PX}
      bgcolor="_components.appBar.defaultFill"
      px={matchesMax992Screen ? "8px" : "12px"}
      width="100%"
      boxShadow={4}
      position="fixed"
      left={0}
      top={0}
      zIndex={21}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      gap="12px"
    >
      {matchesMax992Screen ? <Mobile /> : <Desktop />}
    </Box>
  );
};

export default Header;
