import { Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useState } from "react";
import { useResendOrgVerificationService } from "../../../user/services/useResendOrgVerificationService";
import { useSearchParams } from "react-router-dom";
import RegisterStepper from "./Stepper/RegisterStepper";
import { useSubscribeEvent } from "../../../../common/modules/eventProvider";
import { EventEnum } from "../../../../common/modules/eventProvider/enums/EventEnum";
import { env } from "../../../../common/utils/env";

const OrgRegistration = () => {
  const [searchParams] = useSearchParams();
  const subscriptionPlan = searchParams.get("subscriptionPlan");
  const [step, setStep] = useState(1);
  const [inquiryUuid, setInquiryUuid] = useState<string | undefined>(undefined);
  const [orgEmail, setOrgEmail] = useState<string>("");
  const resendService = useResendOrgVerificationService();

  const nextHandler = (uuid?: string) => {
    !inquiryUuid && setInquiryUuid(uuid);
    setStep(step + 1);
  };

  const afterOrgCreationHandler = (email: string) => {
    setOrgEmail(email);
    setStep(step + 1);
  };

  // Rewardful affiliate service is always linked, and we need to wait to ensure the
  // vendor referral is stored before we redirect
  // Even if no affiliate is passed through url, it is still fine to redirect when that script
  // is loaded
  useSubscribeEvent(EventEnum.ON_REWARDFUL_READY, () => {
    !subscriptionPlan && window.location.replace(`${env.siteUrl}/pricing/`);
  });

  return !subscriptionPlan ? (
    <Box pt={5}>Redirecting you to pricing page...</Box>
  ) : (
    <Grid>
      <RegisterStepper
        activeStep={step - 1}
        orgSignUpProps={{
          onNextHandler: nextHandler,
        }}
        verifyMailProps={{
          onNextHandler: nextHandler,
          inquiryUuid: inquiryUuid!,
          resendService: resendService,
        }}
        verifyPhoneProps={{
          onNextHandler: nextHandler,
          inquiryUuid: inquiryUuid!,
          resendService: resendService,
        }}
        orgPasswordProps={{
          onNextHandler: afterOrgCreationHandler,
          inquiryUuid: inquiryUuid!,
          subscriptionPlan: subscriptionPlan,
        }}
        checkoutProps={{
          subscriptionPlan: subscriptionPlan,
          orgEmail: orgEmail,
        }}
      />
    </Grid>
  );
};

export default OrgRegistration;
