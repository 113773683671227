import { Button } from "../../../modules/materialUI";
import { menuItem } from "../utils/menuItem";
import { Box, Tooltip } from "@mui/material";

const AddWorkspaceButton = () => {
  return menuItem.make({
    component: (
      <Tooltip
        title={
          "Just Around the Corner: Get Ready, This Exciting Feature is Coming Soon!"
        }
        arrow
      >
        <Box>
          <Button variant={"text"} disabled>
            Add Workspace
          </Button>
        </Box>
      </Tooltip>
    ),
    isHeaderItem: true,
    headerItemProps: {
      sx: { py: 0 },
    },
  });
};

export default AddWorkspaceButton;
