import { Box, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Check, Link } from "@mui/icons-material";
import { NavLink } from "react-router-dom";
import { Tooltip } from "../../../../../../common/modules/materialUI";
import { TBacklogItemViewHeaderProps } from "./types/TBacklogItemViewHeaderProps";
import useCopyLink from "../../../../../../common/modules/taskView/hooks/useCopyLink";
import TaskTypeForm from "../TaskTypeForm/TaskTypeForm";
import TaskPriorityForm from "../TaskPriorityForm/TaskPriorityForm";
import { backlogItemUtil } from "../../../../../../common/utils/backlogItemUtil";

export const TASK_VIEW_MAIN_CONTENT_HEADER_HEIGHT = 60;

const BacklogItemViewHeader = ({
  backlogItem,
  isMobileView,
}: TBacklogItemViewHeaderProps) => {
  const url = backlogItemUtil.urlByRef(backlogItem.ref);

  const { linkCopied, copyLinkHandler } = useCopyLink(url);
  const theme = useTheme();

  return (
    <>
      <Grid
        container
        bgcolor={theme.palette.taskView.secondaryBackground}
        borderBottom={`1px solid ${theme.palette.taskView.headerBorderBottomBackground}`}
        height={TASK_VIEW_MAIN_CONTENT_HEADER_HEIGHT}
        p={`16px ${isMobileView ? "40px 16px 16px" : "26px"}`}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid container width="auto" alignItems="center">
          <Grid
            bgcolor={theme.palette.taskView.headerTitleBackground}
            borderRadius="4px"
            fontSize="12px"
            fontWeight={500}
            p="7px 8px"
          >
            <NavLink
              to={url}
              style={{ color: theme.palette.taskView.textMainColor }}
            >
              {backlogItem.ref}
            </NavLink>
          </Grid>

          <Tooltip
            placement="right"
            title={!linkCopied ? "Copy link" : "Copied!"}
          >
            <Grid ml="4px" display="flex" alignItems="center">
              {!linkCopied ? (
                <Link
                  fontSize="small"
                  sx={{
                    color: theme.palette.taskView.textMainColor,
                    cursor: "pointer",
                  }}
                  onClick={copyLinkHandler}
                />
              ) : (
                <Check color="primary" fontSize="small" />
              )}
            </Grid>
          </Tooltip>
        </Grid>

        <Box
          display="flex"
          alignItems="center"
          gap={isMobileView ? "8px" : "12px"}
        >
          <TaskTypeForm noLabel={isMobileView} backlogItem={backlogItem} />

          <TaskPriorityForm noLabel={isMobileView} backlogItem={backlogItem} />
        </Box>
      </Grid>
    </>
  );
};

export default BacklogItemViewHeader;
