import { Box, Typography } from "@mui/material";
import { AddLinkOutlined } from "@mui/icons-material";
import { TTaskMenuProps } from "./types/TTaskMenuProps";
import { menuItem } from "../../../../containers/Menu/utils/menuItem";
import useUtils from "../../../../../modules/linkList/hooks/useUtils";
import { useAppDispatch } from "../../../../hooks/redux";
import { linkActions } from "../../../../../modules/link/slices/linkSlice";
import { EntityTypeEnum } from "../../../../../entities/enums/EntityTypeEnum";
import { useRef } from "react";

const TaskLinkMenuItem = (props: TTaskMenuProps) => {
  const dispatch = useAppDispatch();
  const { addLinkLabel } = useUtils();

  const ref = useRef<HTMLAnchorElement>(null);

  const openModalHandler = (
    event: React.MouseEvent<Element, MouseEvent> | undefined
  ) => {
    event?.stopPropagation();
    event?.preventDefault();

    dispatch(
      linkActions.openCreateLinkModal({
        entityType: EntityTypeEnum.TASK,
        entityId: props.taskId,
        projectRef: props.projectRef,
      })
    );
  };

  return menuItem.make({
    component: (
      <Box display="flex" alignItems="center" gap={1} ref={ref}>
        <AddLinkOutlined fontWeight="400" sx={{ color: "action.active" }} />
        <Typography variant="subtitle2" fontWeight="400">
          {addLinkLabel}
        </Typography>
      </Box>
    ),
    handler: (e) => {
      if (ref.current) {
        ref.current.click();
      }
      openModalHandler(e); // Pass the event directly
    },
    hasDivider: false,
  });
};

export default TaskLinkMenuItem;
