import TListItemProps from "../../../../../../components/Navigation/ListItem/types/TListItemProps";
import { LowPriorityOutlined } from "@mui/icons-material";
import { TSectionProps } from "../../types/TSectionProps";

const TaskLinkListItem = (props: TSectionProps) =>
  ({
    id: "TaskLinkList",
    name: "Task Links",
    icon: (
      <LowPriorityOutlined
        style={{ color: props.theme?.palette.action.active }}
      />
    ),
    path: "/settings/taskLinkList",
    navigateToPath: true,
    onNavigate: props.onClick,
  } as TListItemProps);

export default TaskLinkListItem;
