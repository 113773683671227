import BillingSettingsSection from "./BillingSettings/BillingSettingsSection";
import IntegrationSettingsSection from "./IntegrationSettings/IntegrationSettings";

const BillingAndIntegrationSections = ({
  onClick,
}: {
  onClick: () => void;
}) => {
  return (
    <>
      <BillingSettingsSection onClick={onClick} />
      <IntegrationSettingsSection onClick={onClick} />
    </>
  );
};
export default BillingAndIntegrationSections;
