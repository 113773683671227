import GuesstimationItemViewMobile from "./GuesstimationItemViewMobile";
import GuesstimationItemViewDesktop from "./GuesstimationItemViewDesktop";
import { TGuesstimationItemContentProps } from "./types/TGuesstimationItemContentProps";
import AddAssigneesDialog from "./AddAssignees/AddAssigneesDialog";
import RemoveAssigneeConfirmation from "./RemoveAssignee/RemoveAssigneeConfirmation";
import DeleteGuesstimationItemConfirmation from "./DeleteGuesstimationItem/DeleteGuesstimationItemConfirmation";
import PhaseModal from "./Phase/PhaseModal";

const GuesstimationItemContent = (props: TGuesstimationItemContentProps) => {
  return (
    <>
      {props.isMobileView ? (
        <GuesstimationItemViewMobile {...props} />
      ) : (
        <GuesstimationItemViewDesktop {...props} />
      )}

      <PhaseModal />
      <AddAssigneesDialog />
      <RemoveAssigneeConfirmation />
      <DeleteGuesstimationItemConfirmation />
    </>
  );
};

export default GuesstimationItemContent;
