import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import TListItemProps from "../../../../../../components/Navigation/ListItem/types/TListItemProps";
import ListItemComponent from "../../../../../../components/Navigation/ListItem/ListItem";
import { useAppDispatch } from "../../../../../../hooks/redux";
import { systemNotificationActions } from "../../../../../../modules/systemNotification/slices/systemNotificationSlice";
import { featureUtil } from "../../../../../../modules/accessControl/utils/featureUtil";

const ListItem = (props: TListItemProps) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const isExpandable = props.items !== undefined;

  // Only the first time of this component render we need to check if this menu item is supposed to be expanded
  // that's why we have the empty [] of dependencies.
  useEffect(() => {
    if (
      isExpandable &&
      !isExpanded &&
      location.pathname.startsWith(props.path)
    ) {
      setIsExpanded(true);
    }
  }, []);

  const clickHandler: React.MouseEventHandler<HTMLAnchorElement> = (event) => {
    if (props.isDisabled) {
      event.preventDefault();

      // @todo accept the warning message as a prop
      dispatch(
        systemNotificationActions.open({
          message: featureUtil.notAvailableFullText,
          variant: "warning",
        })
      );
      return;
    }

    setIsExpanded((isExpanded) => !isExpanded);
    props.onClick && props.onClick(event);
  };

  return (
    <ListItemComponent
      {...props}
      isExpanded={isExpanded}
      isExpandable={isExpandable}
      onClick={clickHandler}
      onlyIconList={props.onlyIconList}
    />
  );
};

export default ListItem;
