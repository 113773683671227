import { Route, Routes, useNavigate } from "react-router-dom";
import Board from "../../../../../../modules/board/containers/Board/Board";
import NotFound from "../../../../../components/Page/NotFound/NotFound";
import { useFetchOrgInitDataService } from "../../../../../../modules/org/services/useFetchOrgInitDataService";
import { useEffect } from "react";
import { THttpClientError } from "../../../../../modules/httpClient/types/THttpClientError";

export const READ_ONLY_TOKEN_EXPIRED_ROUTE = "/shared/readOnlyTokenExpired";

const BoardRoutes = () => {
  const navigate = useNavigate();
  const url = new URL(window.location.href);
  const match = url.pathname.match(/board\/([^/]+)/);
  const boardRef = match ? match[1] : null;

  const { dispatch: dispatchFetchOrgInitDataService } =
    useFetchOrgInitDataService(true, boardRef);

  useEffect(() => {
    dispatchFetchOrgInitDataService().catch((error: THttpClientError) => {
      error.status === 406 && navigate(READ_ONLY_TOKEN_EXPIRED_ROUTE);
    });
  }, []);

  return (
    <Routes>
      <Route path=":boardRef/:boardView" element={<Board />} />
      <Route
        path=":boardRef/:boardView/:holisticViewVision"
        element={<Board />}
      />
      <Route path=":boardRef/*" element={<Board />} />
      <Route index element={<NotFound />} />
    </Routes>
  );
};

export default BoardRoutes;
