import { useHttpClientDispatchable } from "../../../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { RequestMethodEnum } from "../../../../../common/modules/httpClient/enums/RequestMethodEnum";
import { ITaskGitHubDataResource } from "../interfaces/ITaskGitHubDataResource";

export const useGetTaskGitHubDataService = (props: { taskId: number }) => {
  const response = useHttpClientDispatchable({
    url: `/task/${props.taskId}/githubData`,
    method: RequestMethodEnum.GET,
  });

  return {
    ...response,
    data: response.data as ITaskGitHubDataResource,
  };
};
