import { Route, Routes } from "react-router-dom";
import NotFound from "../../../../../components/Page/NotFound/NotFound";
import ReadOnlyTokenInvalid from "../../../../../components/Page/ReadOnlyTokenExpired/ReadOnlyTokenInvalid";
import BoardRoutes from "./BoardRoutes";

export const READ_ONLY_TOKEN_EXPIRED_ROUTE = "/shared/readOnlyTokenExpired";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path=":projectAbbr/board/*" element={<BoardRoutes />} />
      <Route path="readOnlyTokenExpired" element={<ReadOnlyTokenInvalid />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;
