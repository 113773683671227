import { Button } from "../../../../common/modules/materialUI";
import { useState } from "react";
import ReportDialog from "./ReportDialog";

export default function ReportButton() {
  const [openReportDialog, setOpenReportDialog] = useState(false);

  return (
    <>
      <Button
        variant="text"
        sx={{
          color: "text.main",
          position: "relative",
          top: "8px",
          left: "-8px",
        }}
        size="small"
        onClick={() => setOpenReportDialog(true)}
      >
        Report
      </Button>
      <ReportDialog
        openReportDialog={openReportDialog}
        closeHandler={() => setOpenReportDialog(false)}
      />
    </>
  );
}
