import { env } from "../../../common/utils/env";
import useWindow from "../../../common/hooks/useWindow";
import { useContactSupportService } from "../../user/services/useContactSupportService";
import { useAppDispatch } from "../../../common/hooks/redux";
import { systemNotificationActions } from "../../../common/modules/systemNotification/slices/systemNotificationSlice";

const useContactSupportHandler = () => {
  const { openNewTab } = useWindow();
  const { isLoading, dispatch: dispatchContactSupportService } =
    useContactSupportService();
  const dispatch = useAppDispatch();

  const openSupportChat = async () => {
    if (isLoading) {
      return;
    }

    try {
      const result = await dispatchContactSupportService();
      openNewTab(`${env.liveChatUrl}?internalSupportHash=${result.hash}`, true);
    } catch (_) {
      dispatch(
        systemNotificationActions.open({
          message: "Failed to open support chat. Please try again.",
          variant: "error",
        })
      );
    }
  };

  return { openSupportChat };
};

export { useContactSupportHandler };
