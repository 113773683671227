import { IconButton, Typography, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { ArrowDropDownRounded, AutoAwesome } from "@mui/icons-material";
import { menuItem } from "../../../../containers/Menu/utils/menuItem";
import Menu from "../../../../containers/Menu/Menu";
import { TChildren } from "../../../../types/TChildren";
import useMedia from "../../../../hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../hooks/useMedia/enums/QueryEnum";

export default function QuickActionsMobile({ children }: TChildren) {
  const matchesMax576Screen = useMedia(QueryEnum.MAX_WIDTH_576);
  const theme = useTheme();

  return (
    <Menu
      closeOnSelection={false}
      sx={{
        bgcolor: "transparent",
        ...(matchesMax576Screen && {
          minWidth: "auto",
          width: "auto",
        }),
        ".MuiMenu-list": {
          p: 0,
        },
        ".MuiMenuItem-root": {
          p: 0,
          ".MuiTouchRipple-root": {
            display: "none",
          },
          ":hover": {
            bgcolor: "transparent",
            cursor: "default",
          },
        },
        "&:before": {
          content: "none",
        },
      }}
      button={(clickHandler) => (
        <IconButton
          disableRipple
          onClick={clickHandler}
          sx={{ p: 0, display: "flex", gap: "4px" }}
        >
          <AutoAwesome sx={{ color: "#FDBF62", fontSize: "16px" }} />

          <Typography
            color={theme.palette.taskView.textSecondaryColor}
            fontSize="15px"
            fontWeight={500}
          >
            Quick Actions
          </Typography>

          <ArrowDropDownRounded
            fontSize="small"
            sx={{
              color: theme.palette.taskView.textMainColor,
            }}
          />
        </IconButton>
      )}
      menuItems={[
        menuItem.make({
          component: (
            <Grid
              container
              bgcolor={theme.palette.taskView.mainBackground}
              borderRadius="12px"
              p="12px"
              gap="12px"
            >
              {children}
            </Grid>
          ),
        }),
      ]}
    />
  );
}
