import classes from "./ViewContainer.module.css";
import { array } from "../../../../common/utils/array";
import { TViewContainerProps } from "./types/TViewContainerProps";

const ViewContainer = (props: TViewContainerProps) => {
  // const displaySideView = useAppSelector(
  //   (state) => state.boardView.displaySideView
  // );

  return (
    <div className={classes.container}>
      {/*{displaySideView && (*/}
      {/*  <div*/}
      {/*    className={classes["side-view"]}*/}
      {/*    style={{*/}
      {/*      backgroundColor: theme.palette.cardsBackground.default,*/}
      {/*      color: theme.palette.text.primary,*/}
      {/*      boxShadow: theme.palette.boardSideViewShadow.main,*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <div>*/}
      {/*      <IconButton*/}
      {/*        onClick={() =>*/}
      {/*          dispatch(boardViewActions.displaySideView(undefined))*/}
      {/*        }*/}
      {/*      >*/}
      {/*        <Close />*/}
      {/*      </IconButton>*/}
      {/*    </div>*/}
      {/*    {displaySideView === SideViewSectionEnum.CAPACITY && (*/}
      {/*      <CapacityPlannerSection />*/}
      {/*    )}*/}
      {/*  </div>*/}
      {/*)}*/}
      <div
        className={array.toClassName([
          classes.content,
          // displaySideView ? classes["right-handed"] : "",
        ])}
      >
        {props.children}
      </div>
    </div>
  );
};

export default ViewContainer;
