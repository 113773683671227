import Content from "../../Content/Content";
import SystemNotification from "../../../../../modules/systemNotification/containers/SystemNotification";
import AppRoutes from "../../Routes/ReadOnly/AppRoutes";
import Header from "../../Header/Header/Readonly/Header";
import useHeaderHeight from "../../Header/hooks/useHeaderHeight";
import GlobalStyle from "../../../../../modules/theme/containers/GlobalStyle/GlobalStyle";

const Layout = () => {
  const { HEADER_HEIGHT_PX } = useHeaderHeight();

  return (
    <>
      <GlobalStyle />
      <SystemNotification />
      <Header />
      <div style={{ marginTop: HEADER_HEIGHT_PX }}>
        <Content>
          <AppRoutes />
        </Content>
      </div>
    </>
  );
};

export default Layout;
