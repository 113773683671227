import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../common/hooks/redux";
import { useParams } from "react-router-dom";
import { TGuesstimationItemPageParams } from "./types/TGuesstimationItemPageParams";
import { useGetGuesstimationItemDetailsService } from "../services/useGetGuesstimationItemDetailsService";
import { useEffect, useState } from "react";
import NotFound from "../../../../../common/components/Page/NotFound/NotFound";
import useMedia from "../../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../../common/hooks/useMedia/enums/QueryEnum";
import TaskSkeleton from "../../../../task/modules/view/containers/TaskSkeleton";
import useWindowInnerHeight from "../../../../../common/hooks/useWindowInnerHeight";
import { guesstimationItemViewActions } from "../slices/viewSlice";
import GuesstimationItemContent from "./GuesstimationItemContent";
import useHeaderHeight from "../../../../../common/containers/Layout/App/Header/hooks/useHeaderHeight";
import GuesstimationItemIsDeletedContent from "./GuesstimationItemIsDeletedContent";

const GuesstimationItemViewPage = () => {
  const dispatch = useAppDispatch();
  const { guesstimationItemRef } = useParams<TGuesstimationItemPageParams>();
  const [guesstimationItemExists, setGuesstimationItemExists] =
    useState<boolean>(true);
  const [showSkeleton, setShowSkeleton] = useState<boolean>(true);
  const detailsResource = useAppSelector(
    (state) => state.guesstimationItemView.detailsResource
  );
  const deletedGuesstimationItemRefs = useAppSelector(
    (state) => state.guesstimationItemView.deletedGuesstimationItemRefs
  );
  const { dispatch: dispatchGetDetails } =
    useGetGuesstimationItemDetailsService();
  const isMobileView = useMedia(QueryEnum.MAX_WIDTH_1200);
  const windowHeight = useWindowInnerHeight();
  const { HEADER_HEIGHT } = useHeaderHeight();

  useEffect(() => {
    guesstimationItemRef &&
      dispatchGetDetails({
        urlPath: `/${guesstimationItemRef}`,
      })
        .catch(() => {
          setGuesstimationItemExists(false);
        })
        .finally(() => setShowSkeleton(false));

    return () => {
      dispatch(guesstimationItemViewActions.reset());
    };
  }, [guesstimationItemRef]);

  if (
    guesstimationItemRef &&
    deletedGuesstimationItemRefs.includes(guesstimationItemRef)
  ) {
    return <GuesstimationItemIsDeletedContent />;
  }

  return (
    <>
      {showSkeleton && <TaskSkeleton isMobileView={isMobileView} />}
      {!showSkeleton &&
        guesstimationItemExists &&
        detailsResource?.guesstimationItem && (
          <GuesstimationItemContent
            detailsResource={detailsResource}
            isMobileView={isMobileView}
            contentHeight={`${windowHeight - HEADER_HEIGHT}px`}
            isPageView
          />
        )}
      {!guesstimationItemExists && <NotFound />}
    </>
  );
};

export default GuesstimationItemViewPage;
