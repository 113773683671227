import { TTextFieldProps } from "./types/TTextFieldProps";
import { TextField as MuiTextField, useTheme } from "@mui/material";
import { forwardRef, useEffect, useRef } from "react";
import { keyboard } from "../../../../utils/keyboard";
import useMedia from "../../../../hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../hooks/useMedia/enums/QueryEnum";

const TextField = forwardRef<HTMLDivElement, TTextFieldProps<any>>(
  <TFormAttributes extends unknown>(
    props: TTextFieldProps<TFormAttributes>,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const theme = useTheme();
    const isTouchDevice = useMedia(QueryEnum.IS_TOUCH_DEVICE);
    const {
      showRequired,
      form,
      error,
      onEnter,
      forceFocus,
      ...textFieldProps
    } = props;
    const { name } = textFieldProps;
    const inputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
      forceFocus && inputRef?.current?.focus();
    }, [forceFocus]);

    return (
      <MuiTextField
        ref={ref}
        inputRef={inputRef}
        error={
          (!!name && form?.hasError(name as keyof TFormAttributes)) || error
        }
        value={(!!name && form?.get(name as keyof TFormAttributes)) ?? ""}
        fullWidth
        variant="standard"
        {...textFieldProps}
        slotProps={{
          ...textFieldProps.slotProps,
          input: {
            sx: {
              ".MuiInputBase-input": {
                color: theme.palette.taskView.textSecondaryColor,
                fontSize: !isTouchDevice ? "15px" : "16px",
                fontWeight: 500,
              },
            },
            ...textFieldProps?.slotProps?.input,
          },
          inputLabel: {
            required: showRequired || false,
            ...textFieldProps?.slotProps?.inputLabel,
          },
        }}
        sx={{ opacity: props.disabled ? 0.5 : 1, ...textFieldProps?.sx }}
        helperText={
          !!name && form?.hasError(name as keyof TFormAttributes)
            ? (form.validationErrors?.[
                name! as keyof TFormAttributes
              ] as string)
            : textFieldProps.helperText
        }
        onChange={(e) => {
          props.onChange && props.onChange(e);
          !!name && form?.changeHandler(e.target.name, e.target.value);
        }}
        onKeyDown={(e) => {
          props.onKeyDown?.(e);
          onEnter && keyboard.onEnter(e, () => onEnter(e, inputRef));
        }}
      />
    );
  }
);

export default TextField;
