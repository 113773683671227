import { TEditableTitleFormProps } from "./types/TEditableTitleFormProps";
import { useEffect, useRef, useState } from "react";
import useForm from "../../../../hooks/useForm/useForm";
import { TEditableTitleFormAttributes } from "./types/TEditableTitleFormAttributes";
import useKeyboardShortcut from "../../../keyboardShortcut/hooks/useKeyboardShortcut";
import { KeyboardShortcutEnum } from "../../../keyboardShortcut/hooks/enums/KeyboardShortcutEnum";
import { windowHelper } from "../../../../utils/windowHelper";
import Form from "../../../../components/Form/Form";
import Actions from "../../../../../modules/task/modules/view/containers/Actions/Actions";
import { TextField } from "../../../materialUI";
import { keyboard } from "../../../../utils/keyboard";
import classes from "./EditableTitleForm.module.css";
import { Typography, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid2";
import ShortcutProTip from "../../../keyboardShortcut/containers/ProTip/ShortcutProTip";

const EditableTitleForm = ({
  label,
  name,
  onChange,
}: TEditableTitleFormProps) => {
  const theme = useTheme();
  const ref = useRef<HTMLDivElement>(null);
  const form = useForm<TEditableTitleFormAttributes>({
    name,
  });
  const [isEditable, setIsEditable] = useState(false);

  useKeyboardShortcut({
    key: KeyboardShortcutEnum.s,
    callback: () => {
      setIsEditable(true);
      ref.current?.scrollIntoView({ behavior: "smooth" });
    },
  });

  useEffect(() => {
    !isEditable && form.set({ name });
  }, [name]);

  const clickHandler = () => {
    !isEditable && !windowHelper.hasSelection() && setIsEditable(true);
  };

  const formDisplayHandler = () => {
    setIsEditable(false);
    form.set({ name });
  };

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();
    setIsEditable(false);

    onChange(
      form.get("name"),
      () => {
        form.notDirty();
      },
      (error) => {
        error && form.errorHandler(error);
      }
    );
  };

  return (
    <Grid size={12} onClick={clickHandler} ref={ref}>
      {isEditable ? (
        <Form
          onSubmit={submitHandler}
          actionStyle={{ paddingTop: "3px" }}
          actionElement={<Actions cancelHandler={formDisplayHandler} />}
        >
          <TextField
            onKeyDown={(event) => {
              keyboard.onControlEnter(event, submitHandler);
              keyboard.isControlSavePressed(event) && submitHandler(event);
            }}
            className={classes["form-margin"]}
            form={form}
            variant="outlined"
            placeholder="Add a title..."
            name="name"
            autoFocus={true}
          />
        </Form>
      ) : (
        <>
          <Typography
            className={classes.title}
            color={theme.palette.taskView.textMainColor}
            fontSize="20px"
            fontWeight={600}
            sx={{
              overflowWrap: "break-word",
            }}
          >
            {form?.get("name" as keyof TEditableTitleFormAttributes) !== "" ? (
              form?.get("name" as keyof TEditableTitleFormAttributes)
            ) : (
              <span style={{ color: "#808080", fontSize: "16px" }}>
                Add a title...
              </span>
            )}
          </Typography>
          <ShortcutProTip
            shortcutKeyString={KeyboardShortcutEnum.s}
            description={`change ${label.toLowerCase()} summary`}
            sx={{ mt: "8px", color: theme.palette.taskView.labelColor }}
            size="medium"
          />
        </>
      )}
    </Grid>
  );
};

export default EditableTitleForm;
