import { TAvatarProps } from "./types/TAvatarProps";
import { Box } from "@mui/material";
import AvatarGroup from "../../../../user/containers/AvatarGroup/AvatarGroup";
import AvatarMenu from "./menu/AvatarMenu";
import { useState } from "react";
import useDynamicsAvatars from "../../../../user/containers/AvatarGroup/hooks/useDynamicsAvatars";

const Avatar = ({ users, maxCount, boxStyle }: TAvatarProps) => {
  const [isOpenCloseMenu, setOpenCloseMenu] = useState<boolean>(false);
  const { avatarCount, containerRef, avatarSize } = useDynamicsAvatars({
    maxCount: maxCount,
  });

  if (!users.length) return;

  return (
    <Box
      ref={containerRef}
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      sx={{ ...boxStyle }}
      width="85%"
    >
      <AvatarGroup
        users={users}
        count={avatarCount}
        avatarSize={avatarSize}
        sx={{ padding: "0" }}
        isOpenCloseMenu={isOpenCloseMenu}
        setOpenCloseMenu={setOpenCloseMenu}
        options={{ hovered: true, clicked: true, checked: true }}
        openMenu={true}
      />
      <AvatarMenu
        users={users}
        isArrowButton
        isOpenCloseMenu={isOpenCloseMenu}
        setOpenCloseMenu={setOpenCloseMenu}
      />
    </Box>
  );
};

export default Avatar;
