import {
  Box,
  Tooltip as MuiTooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { ForwardedRef, forwardRef } from "react";
import LaneItemHorizontal from "./LaneItemHorizontal";
import AvatarGroup from "../../../../../user/containers/AvatarGroup/AvatarGroup";
import { pointConverter } from "../../../../../../common/utils/pointConverter";
import { THorizontalItemProps } from "./types/THorizontalItemProps";
import StatusIcon from "../../../../../../common/components/Task/components/Status/StatusIcon";
import useStickyElements from "../../hooks/useStickyElements";
import useGetTaskType from "../../../../../taskType/hooks/useGetTaskType";
import useGetTaskPriority from "../../../../../taskPriority/hooks/useGetTaskPriority";
import { guesstimationItemUtil } from "../../../../../../common/utils/guesstimationItemUtil";
import { link } from "../../../../../../common/utils/link";
import { NavLink } from "react-router-dom";
import KpiSection from "./KpiSection";
import useMedia from "../../../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../../../common/hooks/useMedia/enums/QueryEnum";
import KpisMenu from "./menu/KpisMenu";
import { StatusEnum } from "../../../../../../entities/columns/user/StatusEnum";

export enum PositionEnum {
  STICKY = "sticky",
  STATIC = "static",
}

const LaneItemHorizontalLarge = forwardRef(
  (props: THorizontalItemProps, ref: ForwardedRef<HTMLDivElement>) => {
    const theme = useTheme();
    const { guesstimationItem } = props;
    const users =
      props.users.filter(
        (user) =>
          guesstimationItem.assignees?.includes(user.id) ||
          guesstimationItem.inactiveAssignees?.includes(user.id)
      ) ?? [];

    const stickyOrStatic: PositionEnum =
      guesstimationItem.tsPoints < 5
        ? PositionEnum.STATIC
        : PositionEnum.STICKY;

    const matchesMax768Screen = useMedia(QueryEnum.MAX_WIDTH_768);

    const itemHeader = useStickyElements()?.current;
    const itemFooter = useStickyElements(stickyOrStatic)?.current;

    const taskType = useGetTaskType({
      taskTypeId: props.guesstimationItem.taskTypeId,
    });
    const taskPriority = useGetTaskPriority({
      taskPriorityId: props.guesstimationItem.taskPriorityId,
    });

    // 0.7 is 70% of the card to show the users, 30 is for the state icon, 5 is for matching the real width (e.g. on the board 1 ts point is 195, not 200)
    const pixelsForUsers = Math.floor(
      pointConverter.oneTSPointToPixels * guesstimationItem.tsPoints * 0.7 -
        30 -
        5
    );
    const pixelsPerUser = 24;
    const showUsersNum = Math.floor(pixelsForUsers / pixelsPerUser);
    const leftUsersNum =
      users.length - showUsersNum > 0 ? users.length - showUsersNum : 0;

    return (
      <LaneItemHorizontal
        {...props}
        ref={ref}
        style={{
          backgroundColor: theme.palette.background.default,
          borderRadius: "12px",

          ...props.style,
        }}
        contentStyle={{
          width: "calc(100% - 3px)",
          height: "calc(100% - 3px)",
          borderRadius: "10px",
          position: "relative",

          backgroundColor:
            theme.palette.backgroundElevation.backgroundPaperElevation_0,
          color: theme.palette.brightCardBackground.main,
          padding: "4px",
        }}
      >
        <Box display="flex" justifyContent="space-between" height="28px">
          <Box
            ref={(ref: HTMLDivElement | null) =>
              itemHeader && (itemHeader.startStickElement = ref)
            }
            display="flex"
            alignItems="center"
            position="sticky"
            left="20px"
            marginRight={
              itemHeader && itemHeader.endStickElement
                ? `${itemHeader.endStickElement.offsetWidth + 20}px`
                : "0px"
            }
          >
            <AvatarGroup
              borderColor={
                theme.palette.backgroundElevation.backgroundPaperElevation_0
              }
              users={users}
              avatarSize={24}
              fontWeight={400}
              fontSizeRatio={1.9}
              textColor={
                theme.palette.mode === "light" ? "common.white" : "common.black"
              }
              count={showUsersNum}
              tooltipCallback={(user) =>
                `${user.name} ${
                  user.status === StatusEnum.INACTIVE
                    ? " | Inactive"
                    : !!guesstimationItem.collisions?.some(
                        (collison) => collison.userId === user.id
                      )
                    ? "| Busy on colliding item"
                    : guesstimationItem.userOffDays &&
                      Object.keys(guesstimationItem.userOffDays).includes(
                        `${user.id}`
                      )
                    ? " | " +
                      guesstimationItem.userOffDays[user.id] +
                      " days off"
                    : ""
                }`
              }
              isColorErrorCallback={(user) =>
                !!guesstimationItem.collisions?.some(
                  (collision) => collision.userId === user.id
                )
              }
              isColorWarningCallback={(user) =>
                guesstimationItem.userOffDays &&
                Object.keys(guesstimationItem.userOffDays).includes(
                  `${user.id}`
                )
              }
            />
            {!!props.users.length && (
              <Typography variant="caption">
                {leftUsersNum < 0 && (
                  <Typography
                    component="span"
                    sx={{
                      marginLeft: "12px",
                      fontSize: "0.6875rem",
                      height: "1rem",
                    }}
                  >
                    +{leftUsersNum}
                  </Typography>
                )}
              </Typography>
            )}
          </Box>
          <Box
            ref={(ref: HTMLDivElement | null) =>
              itemHeader && (itemHeader.endStickElement = ref)
            }
            display="flex"
            alignItems="center"
            position="sticky"
            right={"-20px"}
            marginLeft={
              itemHeader && itemHeader.startStickElement
                ? `${itemHeader.startStickElement.offsetWidth + 20}px`
                : "0px"
            }
          >
            {props.phaseIndex > 1 && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 1,
                  paddingX: "2px",
                  border: "1px solid " + theme.palette.divider,
                  height: "20px",
                  backgroundColor:
                    theme.palette.backgroundElevation
                      .backgroundPaperElevation_2,
                }}
              >
                <Typography variant="caption">#{props.phaseIndex}</Typography>
              </Box>
            )}
          </Box>
        </Box>

        <Box
          display="flex"
          justifyContent="flex-start"
          margin={"4px"}
          height="20px"
        >
          <Box
            display="flex"
            alignItems="center"
            position="sticky"
            left="20px"
            marginRight="20px"
          >
            <NavLink
              to={guesstimationItemUtil.urlByRef(guesstimationItem.ref)}
              onClick={link.anchorClickHandler}
              style={{
                color: "inherit",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography variant={"body2"} color={"text.primary"}>
                {guesstimationItem.name}
              </Typography>
            </NavLink>
          </Box>
        </Box>

        {/*3rd Row*/}
        <Box display="flex" justifyContent="space-between">
          {/*Collisions Info*/}
          <Box
            ref={(ref: HTMLDivElement | null) =>
              itemFooter && (itemFooter.startStickElement = ref)
            }
            display="flex"
            alignItems="center"
            position={stickyOrStatic}
            left="20px"
            marginRight={
              itemFooter && itemFooter.endStickElement
                ? `${itemFooter.endStickElement.offsetWidth + 20}px`
                : "0px"
            }
            gap="2px"
            height="25px"
          >
            <StatusIcon
              statusType={props.guesstimationItem.statusType}
              status={props.guesstimationItem.status}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            />

            {taskPriority && (
              <MuiTooltip title={taskPriority.name}>
                <img
                  src={`${process.env.CDN_URL}/${taskPriority.iconFilePath}`}
                  alt={taskPriority.name}
                  width={18}
                  height={18}
                  style={{ display: "flex" }}
                />
              </MuiTooltip>
            )}

            {taskType && (
              <MuiTooltip title={taskType.name}>
                <img
                  src={`${process.env.CDN_URL}/${taskType.iconFilePath}`}
                  alt={taskType.name}
                  width={18}
                  height={18}
                  style={{ display: "flex" }}
                />
              </MuiTooltip>
            )}
          </Box>

          {!matchesMax768Screen ? (
            <KpiSection
              itemFooter={itemFooter}
              guesstimationItem={guesstimationItem}
              stickyOrStatic={stickyOrStatic}
            />
          ) : (
            <KpisMenu
              itemFooter={itemFooter}
              guesstimationItem={guesstimationItem}
              stickyOrStatic={stickyOrStatic}
            />
          )}
        </Box>
      </LaneItemHorizontal>
    );
  }
);
export default LaneItemHorizontalLarge;
