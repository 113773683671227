import List from "../../../common/List/List";
import { TSectionProps } from "../../../types/TSectionProps";
import { useTheme } from "@mui/material";
import LayersIcon from "@mui/icons-material/Layers";

const IntegrationSettingsList = (props: TSectionProps) => {
  const theme = useTheme();
  return (
    <List
      title="Integration"
      items={[
        {
          id: "Integrations",
          name: "Integrations",
          icon: <LayersIcon style={{ color: theme.palette.action.active }} />,
          path: "/settings/integration/list",
          navigateToPath: true,
          onNavigate: props.onClick,
        },
      ]}
    />
  );
};

export default IntegrationSettingsList;
