import classes from "./Task.module.scss";
import { TTaskProps } from "./types/TTaskProps";
import { Box, Typography, useTheme } from "@mui/material";
import { useTaskViewHandler } from "../../../../../modules/task/modules/view/hooks/useTaskViewHandler";
import { NavLink } from "react-router-dom";
import { taskUtil } from "../../../../utils/taskUtil";
import useGetTaskType from "../../../../../modules/taskType/hooks/useGetTaskType";
import useGetTaskPriority from "../../../../../modules/taskPriority/hooks/useGetTaskPriority";
import { link } from "../../../../utils/link";
import { pointConverter } from "../../../../utils/pointConverter";
import TaskIcons from "../TaskHorizontal/components/TaskIcons";
import TaskMenuSection from "../TaskHorizontal/components/TaskMenuSection";

const Task = (props: TTaskProps) => {
  const theme = useTheme();
  const { task } = props;
  const { openTaskViewHandler } = useTaskViewHandler({
    taskRef: task.ref,
    segmentNumber: task.number,
  });

  const taskType = useGetTaskType({
    taskTypeId: task.taskTypeId,
  });
  const taskPriority = useGetTaskPriority({
    taskPriorityId: task.taskPriorityId,
  });

  const timeDiff: string = pointConverter.WorkingHoursOrDayToPoints(
    task.tsPoints
  );

  return (
    <>
      <div
        className={classes.container}
        style={{
          backgroundColor: "background.default",
          border: "1px solid " + theme.palette.divider,
          color: theme.palette.brightCardBackground.main,
          width: props.width,
        }}
        onClick={openTaskViewHandler}
      >
        <Box
          className={classes.content}
          bgcolor={"brightCardBackground.paper"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
        >
          <Box className={classes.topInfo}>
            <NavLink
              to={taskUtil.url(task)}
              className={classes.ref}
              onClick={link.anchorClickHandler}
              style={{
                color: "inherit",
                display: "flex",
                alignItems: "center",
                gap: "2px",
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography variant="caption">{timeDiff}</Typography>

                <Box display="flex" ml="4px">
                  {task.hasSplits && (
                    <Box
                      sx={{
                        borderRadius: 1,
                        paddingX: "2px",
                        marginRight: "2px",
                        border: "1px solid " + theme.palette.divider,
                        backgroundColor:
                          theme.palette.backgroundElevation
                            .backgroundPaperElevation_2,
                      }}
                    >
                      <Typography variant="caption" color="text.primary">
                        #{task.number}
                      </Typography>
                    </Box>
                  )}
                  <Box display="inline-grid">
                    <Typography noWrap variant="caption">
                      {task.ref}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </NavLink>
            <Typography
              variant="body2"
              lineHeight="16px"
              height="2rem"
              overflow="hidden"
              marginTop="2px"
              sx={{
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
              }}
            >
              {task.name}
            </Typography>
          </Box>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            margin="0 5px 5px 5px"
          >
            <TaskIcons
              taskTypeId={props.task.taskTypeId}
              taskPriorityId={props.task.taskPriorityId}
              size={props.width}
            />
            <TaskMenuSection
              task={props.task}
              isHovered={true}
              size={props.width}
            />
          </Box>
        </Box>
      </div>
    </>
  );
};

export default Task;
