import { Box, Typography } from "@mui/material";

const EffortUnitsItem = ({
  allocatedResourceDays,
  estimatedResourceDays,
}: {
  allocatedResourceDays?: number;
  estimatedResourceDays?: number;
}) => {
  return (
    <Box display="flex" gap={1}>
      <Typography variant="caption">Effort units:</Typography>
      <Typography
        variant="caption"
        component="strong"
        sx={{
          color:
            allocatedResourceDays! < estimatedResourceDays!
              ? "warning.main"
              : allocatedResourceDays! === estimatedResourceDays!
              ? "primary.main"
              : "text.primary",
        }}
      >
        {" "}
        {allocatedResourceDays} / {estimatedResourceDays}
      </Typography>
    </Box>
  );
};
export default EffortUnitsItem;
