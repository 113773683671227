import { useState } from "react";
import { Box, Menu as MuiMenu, useTheme } from "@mui/material";
import { TSxProp } from "../../../../types/TSxProp";

type FilteringItems = {
  when: boolean;
  items: JSX.Element | null;
  disabled?: boolean;
  disabledTooltipMessage?: string;
  closeOnClick?: boolean;
};

type TMultiMenuProps = {
  menus: FilteringItems[];
  button: (
    onClick: (event: React.MouseEvent<HTMLElement>) => void
  ) => JSX.Element;
  closeOnSelection?: boolean;
  stretchOnMobile?: boolean;
  openHandler?: (openClose: boolean) => void;
  headerSection?: JSX.Element;
} & TSxProp;

const MultiMenu = (props: TMultiMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();

  const clickHandler = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    props.openHandler?.(true);
  };

  const closeHandler = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
    props.openHandler?.(false);
  };

  const menuItemsFiltered = props.menus.filter(({ when }) => when);

  const menuItems = menuItemsFiltered.map(({ items }, index) => (
    <Box
      key={items?.key ?? index}
      display={"flex"}
      flexDirection={"column"}
      sx={{
        borderRight: "1px solid " + theme.palette.divider,
        width: "180px",
        height: "180px",
        overflow: "auto",
        py: "4px",
      }}
    >
      {items}
    </Box>
  ));

  return (
    <>
      {props.button(clickHandler)}
      <MuiMenu
        anchorEl={anchorEl}
        open={open}
        onClose={closeHandler}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              borderRadius: 2,
              backgroundColor: "background.paper",
              boxShadow: 8,
              ...props.sx,
            },
          },
        }}
        sx={{
          "& .MuiMenu-list": {
            padding: "0",
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {props.headerSection}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          {menuItems}
        </Box>
      </MuiMenu>
    </>
  );
};

export default MultiMenu;
