import useListenMessage from "./useListenMessage";

let platformOS = "";

const usePlatformOS = () => {
  useListenMessage("platformOS", (data) => {
    platformOS = data;
  });

  return {
    isIOS: platformOS === "ios",
    isAndroid: platformOS === "android",
    isMobileApp: platformOS !== "",
  };
};

export default usePlatformOS;
