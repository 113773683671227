import { Dialog as MuiDialog, IconButton } from "@mui/material";
import { TDialogProps } from "./types/TDialogProps";
import { Close } from "@mui/icons-material";

const Dialog = ({
  renderCloseButton,
  closeButtonHandler,
  closeButtonStyles,
  ...props
}: TDialogProps) => {
  return (
    <MuiDialog
      sx={{
        zIndex: 1500,
      }}
      {...props}
    >
      {renderCloseButton && (
        <IconButton
          onClick={closeButtonHandler}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "action.active",
            zIndex: 999,
            ...closeButtonStyles,
          }}
        >
          <Close />
        </IconButton>
      )}

      {props.children}
    </MuiDialog>
  );
};

export default Dialog;
