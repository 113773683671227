import { TTaskRowProps } from "./types/TTaskRowProps";
import classes from "./Row.module.scss";
import LeftContainer from "./LeftContainer";
import RightContainer from "./RightContainer";
import { useTheme } from "@mui/material";
import TaskSplitLine from "../TaskHorizontal/TaskSplitLine";

const TaskRow = (props: TTaskRowProps) => {
  const color = props.color ?? "text.primary";
  const theme = useTheme();
  const showLeftBorder = !props.isSegmentView && props.task.segmentCount > 1;

  return (
    <div
      className={classes.container}
      style={{
        backgroundColor: "brightCardBackground.paper",
        color: color,
        borderTop: `1px solid ${theme.palette.divider}`,
        position: "relative",
        overflow: "hidden",
        ...props.style,
      }}
    >
      <TaskSplitLine
        left={showLeftBorder}
        leftSx={{
          position: "absolute",
          top: "-5px",
          left: "0.2px",
        }}
        imgHeight={"65px"}
      />
      <LeftContainer {...props} color={color} />
      <RightContainer {...props} color={color} />
    </div>
  );
};

export default TaskRow;
