import useMedia from "../../../../../hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../../hooks/useMedia/enums/QueryEnum";

const useHeaderHeight = () => {
  const isMobileView = useMedia(QueryEnum.MAX_WIDTH_992);

  const HEADER_HEIGHT = isMobileView ? 56 : 64;
  const HEADER_HEIGHT_PX = `${HEADER_HEIGHT}px`;

  return { HEADER_HEIGHT, HEADER_HEIGHT_PX, isMobileView };
};

export default useHeaderHeight;
