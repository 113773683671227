import { date } from "./date";
import { Moment } from "moment";

export const pointConverter = {
  oneTSPointToPixels: 232,

  oneTSPointToDays: 1,

  oneDayToWorkingHours: 8,

  intervalToPoints: (
    pointA: Moment,
    pointB: Moment,
    inclusive: boolean = false
  ) =>
    date.intervalToDays(pointA, pointB, inclusive) *
    pointConverter.oneTSPointToDays,

  intervalToXPositionInPixels: (pointA: Moment, pointB: Moment) =>
    pointConverter.intervalToPoints(pointA, pointB) *
    pointConverter.oneTSPointToPixels,

  pointToXSizeInPixels: (tsPoints?: number) =>
    (tsPoints ?? 1) * pointConverter.oneTSPointToPixels,

  numberOfDaysInWidth: (width: number) =>
    (width / pointConverter.oneTSPointToPixels) *
    pointConverter.oneTSPointToDays,

  oneWorkingHourInPixels: (workingHours: number) =>
    pointConverter.oneTSPointToPixels / workingHours,

  workingHoursToPixels: (workingHours: number) =>
    (pointConverter.oneTSPointToPixels / pointConverter.oneDayToWorkingHours) *
    workingHours,
  WorkingHoursOrDayToPoints: (tsPoints: number) => {
    if (tsPoints < 1) {
      const hours = pointConverter.oneDayToWorkingHours * tsPoints;
      return `${hours.toFixed(0)}h`;
    } else {
      return `${tsPoints.toFixed(1)}d`;
    }
  },
};
