import List from "../../common/List/List";
import { useAccessControl } from "../../../../../../modules/accessControl/hooks/useAccessControl";
import { AbilityEnum } from "../../../../../../modules/accessControl/enums/AbilityEnum";
import TListItemProps from "../../../../../../components/Navigation/ListItem/types/TListItemProps";
import { TSectionProps } from "../../types/TSectionProps";
import ProjectManagementListItem from "./ProjectManagementListItem";
import CustomFieldsListItem from "./CustomFieldsListItem";
import TaskLinkListItem from "./TaskLinkListItem";
import TaskTypesListItem from "./TaskTypesListItem";
import TaskPrioritiesListItem from "./TaskPrioritiesListItem";
import { useTheme } from "@mui/material";
import NavigationTitle from "../../../../../../components/navigationTitle/NavigationTitle";

const AppSettingsSection = (props: TSectionProps) => {
  const { can } = useAccessControl();
  const theme = useTheme();

  const list: TListItemProps[] = can(AbilityEnum.administrate)
    ? [
        ProjectManagementListItem({ onClick: props.onClick, theme }),
        CustomFieldsListItem({ onClick: props.onClick, theme }),
        TaskLinkListItem({ onClick: props.onClick, theme }),
        TaskTypesListItem({ onClick: props.onClick, theme }),
        TaskPrioritiesListItem({ onClick: props.onClick, theme }),
      ]
    : [];

  return list.length > 0 ? (
    <>
      <NavigationTitle title={"Settings"} />
      <List items={list} />
    </>
  ) : null;
};

export default AppSettingsSection;
