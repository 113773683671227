import { Box, SxProps, Typography, useTheme } from "@mui/material";

const TaskRefAndNumber = ({
  number,
  hasSplits,
  taskRef,
  isSpike,
  refSx,
  gap = "5px",
}: {
  number: number;
  hasSplits: boolean;
  taskRef: string;
  isSpike?: boolean;
  refSx?: SxProps;
  gap?: string;
}) => {
  const theme = useTheme();

  return (
    <Box display="flex" alignItems="center" gap={gap} ml="2px">
      {hasSplits && (
        <Box
          borderRadius={1}
          px="2px"
          mr="2px"
          border={"1px solid " + theme.palette.divider}
          sx={{
            ...(isSpike
              ? {
                  bgcolor: "secondary.main",
                }
              : {
                  bgcolor: "backgroundElevation.backgroundPaperElevation_2",
                }),
          }}
        >
          <Typography variant="caption">#{number}</Typography>
        </Box>
      )}

      <Box display="inline-grid">
        <Typography
          noWrap
          variant="caption"
          sx={{
            ...refSx,
          }}
        >
          {taskRef}
        </Typography>
      </Box>
    </Box>
  );
};
export default TaskRefAndNumber;
