import { Button } from "../index";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import MuiDialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import { systemActions } from "../../../store/systemSlice";
import useLinkTarget from "../../../hooks/useLinkTarget";

type TProps = {
  content?: string;
  confirmText?: string;
  disagreeText?: string;
  target?: "_blank" | "_self" | "_parent" | "_top";
};

const ConfirmRedirectionDialog = (props: TProps) => {
  const { open, link } = useAppSelector(
    (state) => state.system.redirectionDialog
  );
  const dispatch = useAppDispatch();
  const { _blank } = useLinkTarget();

  const closeHandler = () =>
    dispatch(systemActions.closeConfirmRedirectionDialog());

  return (
    <MuiDialog
      open={open}
      onClose={closeHandler}
      PaperProps={{ sx: { position: "fixed", top: 100 } }}
    >
      <DialogContent>
        <DialogContentText>
          {props.content ?? "You are about to leave the page. Open?"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button sx={{ color: "text.secondary" }} onClick={closeHandler}>
          {props.disagreeText ?? "No"}
        </Button>
        <a
          href={link}
          target={props.target ?? _blank}
          onClick={() => setTimeout(closeHandler, 100)}
        >
          <Button>{props.confirmText ?? "Yes"}</Button>
        </a>
      </DialogActions>
    </MuiDialog>
  );
};

export default ConfirmRedirectionDialog;
