import { NavLink } from "react-router-dom";
import TLinkProps from "./types/TLinkProps";
import { forwardRef, useEffect } from "react";
import { useTheme } from "@mui/material";

const Link = forwardRef<HTMLAnchorElement, TLinkProps>(
  (props: TLinkProps, ref) => {
    const theme = useTheme();

    useEffect(() => {
      // Set CSS variable color based on the template palette
      if (ref && typeof ref !== "function" && ref.current) {
        ref.current.style.setProperty(
          "--clr-menu-active-item",
          theme.palette.primary.main
        );
      }
    }, [ref, theme]);

    return (
      <>
        {props.navigateToPath ? (
          <NavLink
            ref={ref}
            className="text-truncate"
            title={props.title}
            to={props.path}
            onClick={(event) => {
              props.onClick && props.onClick(event);
              props.onNavigate && props.onNavigate(event);
            }}
          >
            {props.children}
          </NavLink>
        ) : (
          <a
            title={props.title}
            href="#"
            onClick={(event) => {
              event.preventDefault();
              props.onClick && props.onClick(event);
            }}
          >
            {props.children}
          </a>
        )}
      </>
    );
  }
);

export default Link;
