import { useParams } from "react-router-dom";
import { TBoardParams } from "../Board/types/TBoardParams";
import {
  Button,
  ConfirmationButton,
} from "../../../../common/modules/materialUI";
import { Box, ListItemIcon, Typography } from "@mui/material";
import InboxIcon from "@mui/icons-material/Inbox";
import { useArchiveBoardHandler } from "../../hooks/useArchiveBoard";
import { Delete } from "@mui/icons-material";
import { useArchiveBoardService } from "../../services/useArchiveBoardService";

const ArchiveBoard = ({ forMenuItem }: { forMenuItem?: boolean }) => {
  const { projectAbbr, boardRef } = useParams<TBoardParams>();
  const { isLoading } = useArchiveBoardService(boardRef!);
  const archiveBoard = useArchiveBoardHandler(boardRef!, projectAbbr!);

  return (
    <ConfirmationButton
      title="Archive Board"
      content="Are you sure you want to archive board? You won't be able to unarchived it manually."
      button={
        forMenuItem ? (
          <Box display="flex" alignItems="center">
            <ListItemIcon>
              <InboxIcon sx={{ color: "error.main" }} />
            </ListItemIcon>
            <Typography color="error.main">Archive Board</Typography>
          </Box>
        ) : (
          <Button
            variant="text"
            color={"error"}
            startIcon={<Delete />}
            sx={{ padding: "3px 15px" }}
            loading={isLoading}
            disabled={isLoading}
          >
            Archive Board
          </Button>
        )
      }
      successHandler={archiveBoard}
      disagreeText="Cancel"
      confirmText="Archive"
      forMenuItem={forMenuItem}
    />
  );
};

export default ArchiveBoard;
