import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { Box, MenuItem, Typography } from "@mui/material";
import { boardQueryActions } from "../../../../modules/boardView/slices/boardQuerySlice";
import { Tooltip } from "../../materialUI";
import { TListResource } from "../../../types/TListResource";
import CheckIcon from "@mui/icons-material/Check";

const useSprint = () => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector((state) => state.boardQuery.extraFilters);
  const sprintList = useAppSelector((state) => state.listing.sprintList)!;

  const filterChangeHandler = (id: string) => {
    const label = sprintList.find((item) => item.id == id)!.label!;
    const updatedSprintRefs = [...filters.sprintRefs];
    const existingIndex = updatedSprintRefs.findIndex(
      (sprint) => String(sprint.id) === id
    );

    if (existingIndex !== -1) {
      updatedSprintRefs.splice(existingIndex, 1);
    } else {
      updatedSprintRefs.push({
        id: id as unknown as number,
        label,
      });
    }

    dispatch(
      boardQueryActions.setExtraFiltering({
        ...filters,
        sprintRefs: updatedSprintRefs,
      })
    );
  };

  return {
    listFilter: (values: TListResource<number>) => {
      return sprintList && sprintList.length > 0 ? (
        <Box>
          {sprintList.map((item) => (
            <MenuItem
              key={item.label}
              onClick={() => {
                filterChangeHandler(item.id);
              }}
              sx={{
                height: "32px",
                p: "4px 16px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Tooltip title={item.label}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Typography noWrap>{item.label}</Typography>
                  {values.find((sprint) => String(sprint.id) === item.id) && (
                    <CheckIcon
                      sx={{
                        color: "primary.main",
                        height: "24px",
                      }}
                    />
                  )}
                </Box>
              </Tooltip>
            </MenuItem>
          ))}
        </Box>
      ) : null;
    },
  };
};

export default useSprint;
