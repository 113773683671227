import useForm from "../../../../../common/hooks/useForm/useForm";
import Form from "../../../../../common/components/Form/Form";
import { THttpClientError } from "../../../../../common/modules/httpClient/types/THttpClientError";
import {
  Button,
  TextField,
  Tooltip,
} from "../../../../../common/modules/materialUI";
import { useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import { systemNotificationActions } from "../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { useAppDispatch } from "../../../../../common/hooks/redux";
import StartOver from "../StartOver/StartOver";
import useMedia from "../../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../../common/hooks/useMedia/enums/QueryEnum";
import { input } from "../../../../../common/utils/input";
import { TVerifyProps } from "./types/TVerifyProps";
import { TVerifyFormAttributes } from "./types/TVerifyFormAttributes";
import { string } from "../../../../../common/utils/string";

const Verify = (props: TVerifyProps) => {
  const isTouchDevice = useMedia(QueryEnum.IS_TOUCH_DEVICE);
  const [isDisabled, setIsDisabled] = useState(false);
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const [displayResendButton, setDisplayResendButton] =
    useState<boolean>(false);
  const form = useForm<TVerifyFormAttributes>({
    inquiryUuid: props.inquiryUuid,
  });
  const {
    isLoading,
    dispatch: dispatchVerifyService,
    error,
  } = props.verifyService;

  const {
    isLoading: isVerificationLoading,
    dispatch: dispatchResendVerificationService,
  } = props.resendService;

  const code = form.get("code");

  useEffect(() => {
    displayResendButtonHandler();
  }, []);

  useEffect(() => {
    input.onNumberEntered(4, code, submitHandler);
  }, [code]);

  const displayResendButtonHandler = () => {
    setDisplayResendButton(false);
    setTimeout(() => {
      setDisplayResendButton(true);
    }, 1000 * 60 * 3);
  };

  const submitHandler = (event?: React.FormEvent) => {
    setIsDisabled(true);
    event?.preventDefault();

    dispatchVerifyService({ body: form.data })
      .then(() => {
        props.onNextHandler && props.onNextHandler();
      })
      .catch((error: THttpClientError) => {
        form.errorHandler(error);
        setIsDisabled(false);
        error.status === 406 &&
          dispatch(
            systemNotificationActions.open({
              message: error.data.message,
              variant: "error",
            })
          );
      });
  };

  const resendVerificationHandler = () => {
    dispatchResendVerificationService({
      body: { inquiryUuid: props.inquiryUuid, type: props.type },
    })
      .then(() => {
        displayResendButtonHandler();
        dispatch(
          systemNotificationActions.open({
            message: props.resentMessage,
            variant: "success",
          })
        );
      })
      .catch(() => {
        dispatch(
          systemNotificationActions.open({
            message: "Something went wrong. Please try again.",
            variant: "error",
          })
        );
      });
  };

  return (
    <Form
      onSubmit={submitHandler}
      actionElement={
        <>
          <StartOver />
          <Button
            variant="text"
            type="submit"
            size="large"
            disabled={isDisabled || isLoading}
            loading={isLoading}
          >
            Verify
          </Button>
        </>
      }
    >
      <TextField
        forceFocus={(!!error ? string.random() : false) || !isTouchDevice}
        disabled={isDisabled}
        form={form}
        name="code"
        label="Code"
        showRequired
        slotProps={{
          input: {
            startAdornment: props.icon,
            endAdornment: (
              <Tooltip title="The code is active for 3 minutes. You can resend a new one after expiration.">
                <div>
                  <Button
                    variant="text"
                    onClick={resendVerificationHandler}
                    size="medium"
                    sx={{ color: theme.palette.text.primary }}
                    disabled={!displayResendButton || isVerificationLoading}
                  >
                    Resend
                  </Button>
                </div>
              </Tooltip>
            ),
          },
        }}
        helperText={props.helperText}
      />
    </Form>
  );
};

export default Verify;
