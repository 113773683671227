import { useNavigate } from "react-router-dom";
import { menuItem } from "../utils/menuItem";
import { Box, Typography } from "@mui/material";
import CreditCardIcon from "@mui/icons-material/CreditCard";

const Subscription = () => {
  const navigate = useNavigate();

  return menuItem.make({
    component: (
      <Box
        display="flex"
        alignItems="center"
        gap={"8px"}
        sx={{ cursor: "pointer" }}
      >
        <CreditCardIcon sx={{ color: "action.active" }} />
        <Typography color={"text.primary"}>Subscription</Typography>
      </Box>
    ),
    handler: () => {
      navigate("/settings/billing/invoice");
    },
    hasDivider: false,
  });
};

export default Subscription;
