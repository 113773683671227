import { TLinkEntityRowProps } from "./types/TLinkEntityRowProps";
import { Box, Typography, useTheme } from "@mui/material";
import { AggStatusEnum } from "../../../../entities/columns/task/AggStatusEnum";
import StatusIcon from "../../../../common/components/Task/components/Status/StatusIcon";
import { NavLink } from "react-router-dom";
import useGetTaskType from "../../../taskType/hooks/useGetTaskType";
import useGetTaskPriority from "../../../taskPriority/hooks/useGetTaskPriority";
import { number } from "../../../../common/utils/number";
import { string } from "../../../../common/utils/string";
import Grid from "@mui/material/Grid2";
import { Tooltip } from "../../../../common/modules/materialUI";
import LinkQuickRemove from "../LinkRemove/LinkQuickRemove";
import useLinkTarget from "../../../../common/hooks/useLinkTarget";

const LinkEntityRow = (props: TLinkEntityRowProps) => {
  const theme = useTheme();
  const { _blank } = useLinkTarget();
  const color = theme.palette.brightCardBackground.main;
  const showLeftBorder = props.linkEntity.isGroupItem;
  const linkEntity = props.linkEntity;
  const showState =
    !!linkEntity.statusType &&
    !!linkEntity.status &&
    (!!linkEntity.riskStatus || linkEntity.statusType !== AggStatusEnum.TO_DO);
  const taskType = useGetTaskType({
    taskTypeId: linkEntity.taskTypeId,
  });
  const taskPriority = useGetTaskPriority({
    taskPriorityId: linkEntity.taskPriorityId,
  });

  return (
    <Grid
      container
      borderLeft={showLeftBorder ? theme.palette.cardSplit.border : undefined}
      px={props.taskMobileView ? 0 : "8px"}
      py="2px"
      pl={showLeftBorder || !props.taskMobileView ? "8px" : 0}
      alignItems="center"
      justifyContent="space-between"
      gap="32px"
      sx={{
        borderWidth: showLeftBorder ? "3px" : undefined,
        ...props.style,
      }}
    >
      <Grid flex={1} minWidth={0} display="flex" alignItems="center" gap="8px">
        {showState ? (
          <StatusIcon
            statusType={linkEntity.statusType!}
            status={linkEntity.status!}
          />
        ) : (
          <Box width={"24px"} />
        )}

        <NavLink to={linkEntity.url} style={{ color }} target={_blank}>
          <Typography
            noWrap
            fontSize="13px"
            fontWeight={500}
            sx={{ cursor: "pointer" }}
          >
            {linkEntity.title}
          </Typography>
        </NavLink>

        <Box sx={{ overflow: "hidden" }}>
          <NavLink to={linkEntity.url} style={{ color }} target={_blank}>
            <Typography
              noWrap
              color={theme.palette.taskView.textMainColor}
              fontSize="13px"
            >
              {linkEntity.description}
            </Typography>
          </NavLink>
        </Box>
      </Grid>

      <Grid display="flex" alignItems="center" gap="8px">
        <Typography
          color={theme.palette.taskView.textMainColor}
          fontSize="13px"
        >
          {!!linkEntity.estimationPoints && !!linkEntity.estimationUom && (
            <>
              {number.format(linkEntity.estimationPoints)}
              {string.acronym(linkEntity.estimationUom)}
            </>
          )}
        </Typography>

        {taskPriority ? (
          <Tooltip title={taskPriority.name}>
            <img
              src={`${process.env.CDN_URL}/${taskPriority.iconFilePath}`}
              alt={taskPriority.name}
              width={22}
              height={22}
              style={{ display: "flex" }}
            />
          </Tooltip>
        ) : (
          <Box width={22} />
        )}

        {taskType ? (
          <Tooltip title={taskType.name}>
            <img
              src={`${process.env.CDN_URL}/${taskType.iconFilePath}`}
              alt={taskType.name}
              width={18}
              height={18}
              style={{ display: "flex" }}
            />
          </Tooltip>
        ) : (
          <Box width={18} />
        )}

        <LinkQuickRemove linkId={props.linkId} />
      </Grid>
    </Grid>
  );
};

export default LinkEntityRow;
