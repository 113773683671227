import List from "../../../common/List/List";
import { Receipt } from "@mui/icons-material";
import { TSectionProps } from "../../../types/TSectionProps";
import ManageSubscription from "../../../../../../../../modules/stripe/containers/BillingSettings/ManageSubscription";
import TListItemProps from "../../../../../../../components/Navigation/ListItem/types/TListItemProps";
import { useTheme } from "@mui/material";

const BillingSettingsList = (props: TSectionProps) => {
  const theme = useTheme();
  return (
    <List
      title="Billing"
      items={[
        {
          id: "Billing",
          name: "Invoices",
          icon: <Receipt style={{ color: theme.palette.action.active }} />,
          path: `/settings/billing/invoice`,
          navigateToPath: true,
          onNavigate: props.onClick,
        },
        ManageSubscription() as TListItemProps,
      ]}
    />
  );
};

export default BillingSettingsList;
