import { TTaskIdProp } from "../../types/TTaskIdProp";
import QuickActionButton from "../QuickActionButton";
import { taskViewActions } from "../../../../../../modules/task/modules/view/slices/viewSlice";
import { AddTask, MultilineChart } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { InternalTypeEnum } from "../../../../../../entities/columns/task/InternalTypeEnum";
import { useAppDispatch } from "../../../../../hooks/redux";
import { useIsFeatureAllowed } from "../../../../accessControl/hooks/useIsFeatureAllowed";
import { FeatureEnum } from "../../../../accessControl/enums/FeatureEnum";
import { systemNotificationActions } from "../../../../systemNotification/slices/systemNotificationSlice";
import { featureUtil } from "../../../../accessControl/utils/featureUtil";

type TProps = {
  taskInternalType: InternalTypeEnum;
} & TTaskIdProp;

const ConvertInternalType = ({ taskId, taskInternalType }: TProps) => {
  const dispatch = useAppDispatch();
  const isSpikeCreationAllowed = useIsFeatureAllowed(FeatureEnum.SPIKE);
  const targetInternalType =
    taskInternalType === InternalTypeEnum.TASK
      ? InternalTypeEnum.SPIKE
      : InternalTypeEnum.TASK;

  return (
    <>
      <QuickActionButton
        onClick={() => {
          if (
            targetInternalType === InternalTypeEnum.SPIKE &&
            !isSpikeCreationAllowed
          ) {
            dispatch(
              systemNotificationActions.open({
                message: featureUtil.notAvailableFullText,
                variant: "warning",
              })
            );
            return;
          }

          dispatch(
            taskViewActions.openConvertInternalTypeConfirmation({
              taskId,
              taskInternalType,
              targetInternalType,
            })
          );
        }}
      >
        {targetInternalType === InternalTypeEnum.SPIKE ? (
          <MultilineChart fontSize="small" />
        ) : (
          <AddTask fontSize="small" />
        )}
        <Typography fontSize="13px" fontWeight={500}>
          Convert to a {targetInternalType}
        </Typography>
      </QuickActionButton>
    </>
  );
};

export default ConvertInternalType;
