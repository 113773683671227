import { Box, SvgIcon, Typography, useTheme } from "@mui/material";
import { TAiSentinelIconProps } from "./types/TAiSentinelIconProps";

const AiSentinelIcon = (props: TAiSentinelIconProps) => {
  const size = props.size ?? 30;
  const border = props.border ?? "1px solid #999999";
  const borderRadius = props.border ?? "7px";
  const headerIcon = props.headerIcon ?? false;
  const padding = props.padding ?? "6px";

  const theme = useTheme();

  return (
    <Box display="flex" alignItems="center" gap={1} sx={props.sx}>
      <SvgIcon
        sx={{
          width: size,
          height: size,
          border,
          borderRadius,
          padding,
          backgroundColor: headerIcon
            ? "aiSentinel.headerIconBackgroundColor"
            : "transparent",
        }}
      >
        <image
          href={
            props.aiIcon
              ? props.aiIcon
              : headerIcon
              ? theme.palette.aiSentinel.headerIcon
              : theme.palette.aiSentinel.regularIcon
          }
          width="100%"
          height="100%"
        />
      </SvgIcon>
      {props.displayText && (
        <Typography fontWeight={100} fontSize={14} {...props.textProps}>
          Sentinel
        </Typography>
      )}
    </Box>
  );
};

export default AiSentinelIcon;
