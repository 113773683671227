import Avatar from "../../../../containers/Filter/Avatar/Avatar";
import { useEffect } from "react";
import { useAppSelector } from "../../../../../../common/hooks/redux";
import { useGuesstimationBoardUsersService } from "../../services/useGuesstimationBoardUsersService";
import { useSubscribeEvent } from "../../../../../../common/modules/eventProvider";
import { EventEnum } from "../../../../../../common/modules/eventProvider/enums/EventEnum";
import { TGuesstimationItemCreatedEvent } from "../../../../../guesstimation/modules/create/containers/CreateItem/types/TGuesstimationItemCreatedEvent";
import { TGuesstimationItemUpdatedEvent } from "../../../../../guesstimation/modules/view/containers/types/TGuesstimationItemUpdatedEvent";
import { TGuesstimationItemDeletedEvent } from "../../../../../guesstimation/modules/view/containers/types/TGuesstimationItemDeletedEvent";

type TProps = {
  projectAbbr: string;
  boardRef: string;
  autoFetchBoardUsers?: boolean;
};

const BoardUsersFilter = (props: TProps) => {
  const users = useAppSelector((state) => state.user.userList);
  const { dispatch: dispatchBoardUsersService } =
    useGuesstimationBoardUsersService({
      query: {
        projectAbbr: props.projectAbbr,
        boardRef: props.boardRef,
      },
    });

  useSubscribeEvent(
    EventEnum.ON_GUESSTIMATION_ITEM_CREATED,
    (_: TGuesstimationItemCreatedEvent) => {
      dispatchBoardUsersService();
    },
    [props.boardRef]
  );

  useSubscribeEvent(
    EventEnum.ON_GUESSTIMATION_ITEM_UPDATED,
    (_: TGuesstimationItemUpdatedEvent) => {
      dispatchBoardUsersService();
    },
    [props.boardRef]
  );

  useSubscribeEvent(
    EventEnum.ON_GUESSTIMATION_ITEM_DELETED,
    (_: TGuesstimationItemDeletedEvent) => {
      dispatchBoardUsersService();
    },
    [props.boardRef]
  );

  useEffect(() => {
    !(users.length > 0) &&
      (props.autoFetchBoardUsers ?? true) &&
      dispatchBoardUsersService();
  }, [props.boardRef, users.length]);

  return (
    <Avatar
      users={users}
      maxCount={12}
      boxStyle={{ justifyContent: "flex-start" }}
    />
  );
};

export default BoardUsersFilter;
