import { TBacklogItemRowProps } from "./types/TBacklogItemRowProps";
import { Box, Typography, useTheme } from "@mui/material";
import classes from "./BacklogItemRow.module.scss";
import { NavLink } from "react-router-dom";
import { link } from "../../../../../../common/utils/link";
import useGetTaskType from "../../../../../taskType/hooks/useGetTaskType";
import useGetTaskPriority from "../../../../../taskPriority/hooks/useGetTaskPriority";
import { Tooltip } from "../../../../../../common/modules/materialUI";
import BacklogItemMenu from "../BacklogItemMenu/BacklogItemMenu";
import { useAppDispatch } from "../../../../../../common/hooks/redux";
import { backlogItemViewActions } from "../../../../../backlog/modules/view/slices/viewSlice";
import { backlogItemUtil } from "../../../../../../common/utils/backlogItemUtil";

const BacklogItemRow = ({ backlogItem }: TBacklogItemRowProps) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const color = theme.palette.brightCardBackground.main;
  const taskType = useGetTaskType({
    taskTypeId: backlogItem.taskTypeId,
  });
  const taskPriority = useGetTaskPriority({
    taskPriorityId: backlogItem.taskPriorityId,
  });

  const openBacklogView = () => {
    dispatch(backlogItemViewActions.open({ ref: backlogItem.ref }));
  };

  const anchorHandler = (e: React.MouseEvent<HTMLAnchorElement>) => {
    link.anchorClickHandler(e);
  };

  const backlogItemUrl = backlogItemUtil.urlByRef(backlogItem.ref);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0.3rem 0.5rem",
        marginBottom: "1px",
        backgroundColor: theme.palette.brightCardBackground.paper,
        color: color,
      }}
    >
      {/* Left container */}
      <div className={classes["left-container"]}>
        {/* Ref */}
        <div className={classes.ref} onClick={openBacklogView}>
          <NavLink
            to={backlogItemUrl}
            onClick={anchorHandler}
            style={{ color }}
          >
            <Typography
              variant="caption"
              fontWeight="bold"
              sx={{ cursor: "pointer" }}
            >
              {backlogItem.ref}
            </Typography>
          </NavLink>
        </div>

        {/* Name */}
        <div className={classes.name} onClick={openBacklogView}>
          <Typography variant="caption">{backlogItem.name}</Typography>
        </div>
      </div>

      {/* Right container */}
      <div className={classes["right-container"]}>
        {/* Backlog Item Priority */}
        <div>
          {taskPriority ? (
            <Tooltip title={taskPriority.name}>
              <img
                src={`${process.env.CDN_URL}/${taskPriority.iconFilePath}`}
                alt={taskPriority.name}
                width={18}
                height={18}
                style={{ display: "flex" }}
              />
            </Tooltip>
          ) : (
            <Box width={18} />
          )}
        </div>

        {/* Backlog Item Type */}
        <div>
          {taskType ? (
            <Tooltip title={taskType.name}>
              <img
                src={`${process.env.CDN_URL}/${taskType.iconFilePath}`}
                alt={taskType.name}
                width={18}
                height={18}
                style={{ display: "flex" }}
              />
            </Tooltip>
          ) : (
            <Box width={18} />
          )}
        </div>

        {/* Backlog Item menu options */}
        <BacklogItemMenu backlogItem={backlogItem} />
      </div>
    </div>
  );
};

export default BacklogItemRow;
