import Button from "../../../../../../common/modules/materialUI/components/Button/Button";
import SettingsIcon from "@mui/icons-material/Settings";
import EditBoard from "../../../../../board/containers/Menu/EditBoard";
import { menuItem } from "../../../../../../common/containers/Menu/utils/menuItem";
import ArchiveBoard from "../../../../../board/containers/Archive/ArchiveBoard";
import Menu from "../../../../../../common/containers/Menu/Menu";
import useMedia from "../../../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../../../common/hooks/useMedia/enums/QueryEnum";
import { IBoard } from "../../../../../../entities/IBoard";

const GuesstimationViewSettingsMenu = ({
  projectAbbr,
  boardRef,
  setOpen,
  board,
}: {
  setOpen: (value: boolean) => void;
  projectAbbr?: string;
  boardRef?: string;
  board?: IBoard;
}) => {
  const matchesMax768Screen = useMedia(QueryEnum.MAX_WIDTH_768);

  return (
    <Menu
      button={(clickHandler) => (
        <Button
          size="medium"
          onClick={clickHandler}
          variant="outlined"
          color="inherit"
          isBorderStyle
          sx={{
            p: matchesMax768Screen ? "5px 9px" : "6px 9px",
            borderColor: "_common.black_states.outlinedBorder",
          }}
        >
          <SettingsIcon sx={{ fontSize: "18px" }} />
        </Button>
      )}
      menuItems={[
        EditBoard({
          projectAbbr: projectAbbr ?? "",
          board,
          boardRef: boardRef ?? "",
        }),

        menuItem.make({
          component: <ArchiveBoard forMenuItem />,
          hasDivider: false,
          handler: () => {
            setOpen(true);
          },
          menuItemsProps: {
            sx: {
              marginBottom: 0,
              py: "8px",
            },
          },
        }),
      ]}
    />
  );
};

export default GuesstimationViewSettingsMenu;
