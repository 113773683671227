import Title from "../../../common/components/Title/Title";
import { Box } from "@mui/material";
import { Button, Tooltip } from "../../../common/modules/materialUI";
import { Add } from "@mui/icons-material";
import { objectiveActions } from "../slices/objectiveSlice";
import { useAppDispatch } from "../../../common/hooks/redux";
import CreateModal from "./CreateObjective/CreateModal";
import useKeyboardShortcut from "../../../common/modules/keyboardShortcut/hooks/useKeyboardShortcut";
import { KeyboardShortcutEnum } from "../../../common/modules/keyboardShortcut/hooks/enums/KeyboardShortcutEnum";
import ShortcutProTip from "../../../common/modules/keyboardShortcut/containers/ProTip/ShortcutProTip";
import ObjectiveGrid from "./ObjectiveGrid/ObjectiveGrid";
import { useIsFeatureAllowed } from "../../../common/modules/accessControl/hooks/useIsFeatureAllowed";
import { FeatureEnum } from "../../../common/modules/accessControl/enums/FeatureEnum";
import { featureUtil } from "../../../common/modules/accessControl/utils/featureUtil";
import { systemNotificationActions } from "../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { useFirebaseEventHelper } from "../../../common/modules/firebase/hooks/useFirebaseEventHelper";
import { useEffect } from "react";
import { FirebaseEventEnum } from "../../../common/modules/firebase/hooks/types/FirebaseEventEnum";

const ObjectivesPage = () => {
  const { addToFirebaseQueue } = useFirebaseEventHelper();
  const dispatch = useAppDispatch();
  const isObjectivesAllowed = useIsFeatureAllowed(FeatureEnum.OBJECTIVES);

  useKeyboardShortcut({
    key: KeyboardShortcutEnum.o,
    callback: () => (isObjectivesAllowed ? addObjectiveHandler() : undefined),
  });

  const addObjectiveHandler = () =>
    isObjectivesAllowed
      ? dispatch(objectiveActions.openCreateModal())
      : dispatch(
          systemNotificationActions.open({
            message: featureUtil.notAvailableFullText,
            variant: "warning",
          })
        );

  useEffect(() => {
    addToFirebaseQueue(FirebaseEventEnum.FEATURE_ACCESS, {
      feature: FeatureEnum.OBJECTIVES,
    });
  }, []);

  return (
    <>
      <Title>Objectives</Title>
      <Box mb={3}>
        <Tooltip
          title={featureUtil.notAvailableText}
          when={!isObjectivesAllowed}
          withSpanWrapper
        >
          <Button
            variant="text"
            startIcon={<Add />}
            onClick={addObjectiveHandler}
          >
            Add Objective
          </Button>
        </Tooltip>
        <ShortcutProTip
          shortcutKeyString={KeyboardShortcutEnum.o}
          description="add an objective"
        />
        <CreateModal />
      </Box>
      <ObjectiveGrid />
    </>
  );
};

export default ObjectivesPage;
