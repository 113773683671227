import { Box } from "@mui/material";
import useMedia from "../../../../../hooks/useMedia/useMedia";
import { useAppSelector } from "../../../../../hooks/redux";
import { QueryEnum } from "../../../../../hooks/useMedia/enums/QueryEnum";
import AiSentinel from "../../../../../../modules/aiSentinel/containers/AiSentinel/AiSentinel";
import HelpCenterIcon from "../../../../../../modules/helpCenter/containers/HelpCenterIcon";

const FloatingActions = () => {
  const maxWidth768 = useMedia(QueryEnum.MAX_WIDTH_768);
  const isOpen = useAppSelector((state) => state.aiSentinel.isOpen);

  return (
    <Box
      position="fixed"
      zIndex={isOpen && maxWidth768 ? 0 : 1500}
      bottom="20px"
      right="20px"
      height="56px"
      display="flex"
      alignItems="center"
      gap="8px"
    >
      <HelpCenterIcon />
      <AiSentinel />
    </Box>
  );
};

export default FloatingActions;
