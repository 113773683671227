import { useSendMessage } from "../useSendMessage";
import { useEffect } from "react";
import useListenMessage from "../useListenMessage";

export function ReactNativeBridge() {
  const { sendMessage } = useSendMessage();

  useEffect(() => {
    sendMessage("webUiLoaded");
  }, []);

  useListenMessage("pushNotification", (data) => {
    alert(JSON.stringify(data));
  });

  return null;
}
