import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/hooks/redux";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { useSubscribeEvent } from "../../../../../../common/modules/eventProvider";
import { EventEnum } from "../../../../../../common/modules/eventProvider/enums/EventEnum";
import { guesstimationItemCreateActions } from "../../slices/guesstimationItemCreateSlice";
import CreateForm from "./CreateForm";

type TProps = {
  boardRef: string;
};

const CreateModal = (props: TProps) => {
  const dispatch = useAppDispatch();
  const openCreationDialog = useAppSelector(
    (state) => state.guesstimationItemCreate.displayModal
  );

  const closeHandler = () => {
    dispatch(guesstimationItemCreateActions.closeCreationModal());
  };

  useSubscribeEvent(EventEnum.ON_GUESSTIMATION_ITEM_CREATED, () =>
    closeHandler()
  );

  return (
    <Dialog
      open={openCreationDialog}
      onClose={closeHandler}
      fullWidth
      maxWidth="sm"
    >
      {/* Render content only if displayModal is enabled, otherwise we might have incorrect data when closing the dialog
       and animation didn't finish yet, e.g. title will show incorrect value when closing the dialog animation is running */}
      {openCreationDialog && (
        <>
          <DialogTitle>Create Item</DialogTitle>
          <DialogContent>
            <CreateForm boardRef={props.boardRef} onCancel={closeHandler} />
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

export default CreateModal;
