import useForm from "../../../../common/hooks/useForm/useForm";
import Form from "../../../../common/components/Form/Form";
import {
  Button,
  DatePicker,
  TextField,
  Tooltip,
} from "../../../../common/modules/materialUI";
import Grid from "@mui/material/Grid2";
import { THttpClientError } from "../../../../common/modules/httpClient/types/THttpClientError";
import { TOfftimeFormAttributes } from "./types/TOfftimeFormAttributes";
import { useCreateOfftimeService } from "../../services/useCreateOfftimeService";
import { useEffect, useState } from "react";
import { offtimeActions } from "../../slices/offtimeSlice";
import { useAppDispatch } from "../../../../common/hooks/redux";
import { IUserOfftime } from "../../../../entities/IUserOfftime";
import { TGeneralProps } from "./types/TGeneralProps";
import { systemNotificationActions } from "../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { useIsFeatureAllowed } from "../../../../common/modules/accessControl/hooks/useIsFeatureAllowed";
import { FeatureEnum } from "../../../../common/modules/accessControl/enums/FeatureEnum";
import { featureUtil } from "../../../../common/modules/accessControl/utils/featureUtil";
import useMedia from "../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../common/hooks/useMedia/enums/QueryEnum";
import { useDispatchEvent } from "../../../../common/modules/eventProvider";
import { EventEnum } from "../../../../common/modules/eventProvider/enums/EventEnum";
import ReactDOM from "react-dom";
import { ACTIONS_ID } from "./CreateDialog";

type TProps = {
  cancelHandler: () => void;
} & TGeneralProps;

const OfftimeForm = (props: TProps) => {
  const isTouchDevice = useMedia(QueryEnum.IS_TOUCH_DEVICE);
  const isFeatureAllowed = useIsFeatureAllowed(FeatureEnum.OFFTIME_MANAGEMENT);
  const form = useForm<TOfftimeFormAttributes>();
  const dispatch = useAppDispatch();
  const dispatchEvent = useDispatchEvent();
  const { isLoading, dispatch: dispatchCreateOfftime } =
    useCreateOfftimeService();
  const [portalReady, setPortalReady] = useState<boolean>(false);

  const isDisabled = isLoading;

  useEffect(() => {
    !!document.getElementById(ACTIONS_ID) && setPortalReady(true);
  }, []);

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();

    if (!isFeatureAllowed) {
      dispatch(
        systemNotificationActions.open({
          message: featureUtil.notAvailableFullText,
          variant: "warning",
        })
      );
      return;
    }

    dispatchCreateOfftime({
      body: form.data,
      urlPath: !props.userId ? "" : `/for/${props.userId}`,
    })
      .then((payload: IUserOfftime) => {
        dispatch(offtimeActions.addOfftime(payload));
        dispatch(
          systemNotificationActions.open({
            message: "Offtime created",
            variant: "success",
          })
        );
        dispatchEvent(EventEnum.ON_OFFTIME_CREATED);
      })
      .catch((error: THttpClientError) => {
        error.status === 406 &&
          dispatch(
            systemNotificationActions.open({
              message: error.data.message,
              variant: "warning",
            })
          );

        form.errorHandler(error);
      });
  };

  const actions = (
    <>
      <Button
        onClick={props.cancelHandler}
        disabled={isDisabled}
        variant="outlined"
      >
        Close
      </Button>
      <Tooltip
        title={featureUtil.notAvailableText}
        when={!isFeatureAllowed}
        withSpanWrapper
      >
        <Button
          disabled={isDisabled || !form.isDirty()}
          variant="contained"
          loading={isLoading}
          onClick={submitHandler}
        >
          Add
        </Button>
      </Tooltip>
    </>
  );

  return (
    <Form
      onSubmit={submitHandler}
      actionPosition="left"
      actionElement={
        portalReady
          ? ReactDOM.createPortal(actions, document.getElementById(ACTIONS_ID)!)
          : undefined
      }
    >
      <Grid container spacing={2}>
        <Grid
          size={{
            xs: 12,
          }}
        >
          <TextField
            form={form}
            name="description"
            label="Description"
            showRequired
            disabled={isDisabled}
            forceFocus={!isTouchDevice}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid
          size={{
            xs: 12,
            sm: 6,
          }}
        >
          <DatePicker
            form={form}
            name="startDate"
            label="Start Date"
            showRequired
            disabled={isDisabled}
          />
        </Grid>

        <Grid
          size={{
            xs: 12,
            sm: 6,
          }}
        >
          <DatePicker
            form={form}
            name="endDate"
            label="End Date"
            showRequired
            disabled={isDisabled}
          />
        </Grid>
      </Grid>
    </Form>
  );
};

export default OfftimeForm;
