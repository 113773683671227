import { useEffect, useMemo, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../hooks/redux";
import { jwt } from "../../../../../../utils/jwt";
import useHeaderHeight from "../../../Header/hooks/useHeaderHeight";
import { navigationActions } from "../../../../../../store/navigationSlice";
import { userActions } from "../../../../../../../modules/user/slices/userSlice";
import { useAccessControl } from "../../../../../../modules/accessControl/hooks/useAccessControl";

export const useNavigationLogic = (additionalSize = 0) => {
  const dispatch = useAppDispatch();
  const userName = useAppSelector(
    (state) => state.user.userInfo?.name.split(" ")[0]
  );
  const userJwt = useAppSelector((state) => state.user.userInfo);
  const jwtParsed = useMemo(() => jwt.parse(), [userJwt?.id]);

  const orgRef = useRef<HTMLDivElement | null>(null);
  const { HEADER_HEIGHT_PX } = useHeaderHeight();
  const [orgRefHeight, setOrgRefHeight] = useState(0);

  useEffect(() => {
    dispatch(navigationActions.setNavigationSize(additionalSize));
  }, [additionalSize, dispatch]);

  useEffect(() => {
    if (orgRef.current) {
      setOrgRefHeight(orgRef.current.offsetHeight);
    }
    if (!userName) {
      dispatch(userActions.setUserInfo(jwtParsed));
    }
  }, [orgRef, userName, dispatch, jwtParsed]);

  const { can } = useAccessControl();

  return {
    userName,
    jwtParsed,
    orgRef,
    orgRefHeight,
    HEADER_HEIGHT_PX,
    can,
  };
};
