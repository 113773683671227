import { Fragment } from "react";
import { TAssigneesProps } from "./types/TAssigneesProps";
import { Box, IconButton, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useAppDispatch } from "../../../../../../common/hooks/redux";
import { Add, PersonRemove as PersonRemoveIcon } from "@mui/icons-material";
import usePortalTabActions from "../../../../../task/modules/view/hooks/usePortalTabActions";
import { guesstimationItemViewActions } from "../../slices/viewSlice";
import { IUser } from "../../../../../../entities/IUser";
import { StatusEnum } from "../../../../../../entities/columns/user/StatusEnum";
import { date, FORMAT_THREE } from "../../../../../../common/utils/date";
import Divider from "../../../../../../common/components/Divider/Divider";

const Assignees = ({ resource, isMobileView, tabIndex }: TAssigneesProps) => {
  const { CountsPortalComponent, ActionsPortalComponent } =
    usePortalTabActions(tabIndex);
  const dispatch = useAppDispatch();

  const removeAssigneeClickHandler = (assignee: IUser) => {
    dispatch(
      guesstimationItemViewActions.openRemoveAssigneeConfirmation({
        item: resource.guesstimationItem,
        assignee,
      })
    );
  };

  return (
    <>
      {CountsPortalComponent(
        <>
          {!resource.assignees.length && "Not Assigned"}
          {!!resource.assignees.length &&
            `${resource.assignees.length} Assignee${
              resource.assignees.length > 1 ? "s" : ""
            }`}
        </>
      )}
      {ActionsPortalComponent(
        <Typography
          color="#818692"
          fontSize="13px"
          display="flex"
          alignItems="center"
          gap="4px"
          sx={{ cursor: "pointer" }}
          onClick={() => {
            dispatch(
              guesstimationItemViewActions.openAddAssigneesDialog({
                item: resource.guesstimationItem,
              })
            );
          }}
        >
          <Add fontSize="small" />
          Add {!isMobileView && "assignees"}
        </Typography>
      )}

      {resource.assignees.length > 0 && (
        <Grid container flexDirection="column">
          {resource.assignees.map((assignee, _) => {
            const color = "taskView.labelColor";

            return (
              <Fragment key={assignee.id}>
                <Grid
                  px={isMobileView ? 0 : "8px"}
                  display="flex"
                  flexWrap="wrap"
                  alignItems="center"
                  justifyContent="space-between"
                  gap="0 32px"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    gap={3}
                  >
                    <IconButton
                      onClick={() => removeAssigneeClickHandler(assignee)}
                    >
                      <PersonRemoveIcon fontSize="inherit" />
                    </IconButton>

                    <Typography
                      noWrap
                      fontSize="13px"
                      fontWeight={500}
                      color={color}
                      sx={{ cursor: "pointer" }}
                    >
                      {assignee.name}
                    </Typography>
                  </Box>

                  {assignee.status === StatusEnum.INACTIVE &&
                    assignee.inactiveDate && (
                      <Typography
                        noWrap
                        fontSize="13px"
                        fontWeight={500}
                        color={color}
                      >
                        Inactivated{" "}
                        {date.format(assignee.inactiveDate, FORMAT_THREE)}
                      </Typography>
                    )}
                </Grid>

                <Divider />
              </Fragment>
            );
          })}
        </Grid>
      )}
    </>
  );
};

export default Assignees;
