import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import { DialogContent, DialogTitle } from "@mui/material";
import OfftimeForm from "./OfftimeForm";
import OfftimeList from "./OfftimeList";
import { useAccessControl } from "../../../../common/modules/accessControl/hooks/useAccessControl";
import { offtimeActions } from "../../slices/offtimeSlice";
import { AbilityEnum } from "../../../../common/modules/accessControl/enums/AbilityEnum";
import UserPicker from "./UserPicker";
import DialogActions from "@mui/material/DialogActions";
import { useSubscribeEvent } from "../../../../common/modules/eventProvider";
import { EventEnum } from "../../../../common/modules/eventProvider/enums/EventEnum";
import Dialog from "../../../../common/modules/materialUI/components/Dialog/Dialog";
import useMedia from "../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../common/hooks/useMedia/enums/QueryEnum";

export const ACTIONS_ID = "add-offtime-actions";

const CreateDialog = () => {
  const { can } = useAccessControl();
  const open = useAppSelector((state) => state.offtime.createModal);
  const userId = useAppSelector((state) => state.offtime.userId);
  const dispatch = useAppDispatch();

  const matchesMax576Screen = useMedia(QueryEnum.MAX_WIDTH_576);

  const closeHandler = () => dispatch(offtimeActions.closeCreateModal());

  useSubscribeEvent(EventEnum.ON_OFFTIME_CREATED, closeHandler);

  return (
    <Dialog
      open={!!open}
      onClose={closeHandler}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          borderRadius: 3,
          margin: matchesMax576Screen ? "32px 0" : undefined,
          width: "95%",
        },
      }}
    >
      <DialogTitle display="flex" justifyContent="space-between">
        Add Offtime
        {can(AbilityEnum.manage) && <UserPicker />}
      </DialogTitle>

      <DialogContent>
        <OfftimeForm userId={userId} cancelHandler={closeHandler} />
        <OfftimeList userId={userId} />
      </DialogContent>

      <DialogActions id={ACTIONS_ID} />
    </Dialog>
  );
};

export default CreateDialog;
