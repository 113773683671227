import { TOfftimeHorizontalProps } from "./types/TOfftimeHorizontalProps";
import classes from "./OfftimeHorizontal.module.scss";
import { FORMAT_WITH_ORDINAL } from "../../utils/date";
import moment from "moment";
import { Box, Typography, useTheme } from "@mui/material";
import { array } from "../../utils/array";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";

const OfftimeHorizontal = (props: TOfftimeHorizontalProps) => {
  const theme = useTheme();
  const startDate = moment(props.offtime.startDate);
  const endDate = moment(props.offtime.endDate);
  const days = props.offtime.tsPoints;

  return (
    <div
      className={classes.container}
      style={{
        left: props.position,
        width: props.size,
        backgroundColor: theme.palette.background.default,
        border: "1px solid " + theme.palette.divider,
      }}
    >
      <Box
        className={array.toClassName([classes.content])}
        sx={{
          backgroundColor:
            theme.palette.backgroundElevation.backgroundPaperElevation_6,
          backgroundImage: `url(${theme.palette.offtime.backgroundImgSrc})`,
          color: theme.palette.text.secondary,
          backgroundSize: "contain",
          width: "calc(100% - 4px)",
          height: "calc(100% - 4px)",
          borderRadius: "10px",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "3px",
        }}
      >
        <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
          <QueryBuilderIcon sx={{ width: "16px", height: "16px" }} />
          <Typography variant={"body2"} sx={{ marginLeft: "4px" }}>
            Offtime
          </Typography>
        </Box>
        <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
          <Typography>{props.offtime.description}</Typography>
        </Box>

        {days > 1 && (
          <Box>
            {startDate.format(FORMAT_WITH_ORDINAL)} -{" "}
            {endDate.subtract(1, "d").format(FORMAT_WITH_ORDINAL)}, {days} days
          </Box>
        )}
        {!(days > 1) && (
          <Box>
            {startDate.format(FORMAT_WITH_ORDINAL)}, {days} day
          </Box>
        )}
      </Box>
    </div>
  );
};

export default OfftimeHorizontal;
