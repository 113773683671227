import { TFileUploadProviderProps } from "./types/TFileUploadProviderProps";
import { Box, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { useAppDispatch } from "../../hooks/redux";
import { systemNotificationActions } from "../../modules/systemNotification/slices/systemNotificationSlice";
import classes from "./FileUploadProvider.module.css";

const FileUploadProvider = ({
  children,
  onFileLoad,
  fileDropText,
  dropZoneId,
  fileMaxLimit,
  allowedMimeTypes,
  inputConfig,
  style,
  fileDropZoneTextBoxStyles,
  labelStyles,
  shouldRender,
}: TFileUploadProviderProps) => {
  const [dragEnter, setDragEnter] = useState(false);
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const dragEnterHandler = (event: any) => {
    // Check if the dragged element contains a file
    const hasFiles = Array.from(event.dataTransfer.types).includes("Files");
    if (!hasFiles) {
      return;
    }

    event.stopPropagation();
    event.preventDefault();
    setDragEnter(true);
  };

  const dragLeaveHandler = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    if (event.target.id === dropZoneId) {
      setDragEnter(false);
    }
  };

  const dropHandler = (event: React.DragEvent<HTMLDivElement>) => {
    setDragEnter(false);
    event.preventDefault();
    const files = [...event.dataTransfer.files];

    if (!files.length) {
      return;
    }

    const maxLimit = fileMaxLimit ?? +(process.env.MAX_ATTACHMENTS_COUNT ?? 0);
    if (files.length > maxLimit) {
      dispatch(
        systemNotificationActions.open({
          variant: "warning",
          message: `You can upload maximum ${maxLimit} file(s) at a time.`,
        })
      );
      return;
    }

    // Validating for mime types if those were provided
    const invalidFile =
      allowedMimeTypes &&
      files.find((file) => !allowedMimeTypes.includes(file.type));
    if (invalidFile) {
      dispatch(
        systemNotificationActions.open({
          variant: "warning",
          message: `${invalidFile.name} file type is not supported.`,
        })
      );
      return;
    }

    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = (_) => onFileLoad(file);
      reader.readAsDataURL(file);
    });
  };

  if (!(shouldRender ?? true)) {
    return <>{children}</>;
  }

  return (
    <>
      <div
        id={dropZoneId}
        onDragOver={(event) => event.preventDefault()}
        onDragLeave={dragLeaveHandler}
        onDrop={dropHandler}
        className={dragEnter ? classes["file-drop-zone"] : ""}
        style={{
          display: !dragEnter ? "none" : "block",
          backgroundColor: `${theme.palette.taskView.dropContainerBackground}`,
          ...style,
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.brightCardBackground.paper,
            ...fileDropZoneTextBoxStyles,
          }}
        >
          <img
            src={`${process.env.CDN_URL}/icons/upload-cloud.png`}
            alt="attachment"
          />
          <Typography
            style={{ color: theme.palette.brightCardBackground.main }}
          >
            {fileDropText}
          </Typography>
        </Box>
      </div>
      <label
        htmlFor={inputConfig.id}
        onDragEnter={dragEnterHandler}
        style={{
          ...(dragEnter
            ? {
                zIndex: 998,
              }
            : { position: "relative" }),
          ...labelStyles,
        }}
      >
        {children}
      </label>
      <input
        type="file"
        style={{ display: "none" }}
        disabled={true}
        id={inputConfig.id}
        name={inputConfig.name}
      />
    </>
  );
};

export default FileUploadProvider;
