import { useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../../../../common/hooks/redux";
import useMedia from "../../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../../common/hooks/useMedia/enums/QueryEnum";

const useDynamicsAvatars = ({ maxCount }: { maxCount?: number } = {}) => {
  const { display: navigationDisplayed } = useAppSelector(
    (state) => state.navigation
  );
  const [avatarCount, setAvatarCount] = useState<number>(1);

  const containerRef = useRef<HTMLDivElement>(null);

  const matchesMax1200Screen = useMedia(QueryEnum.MAX_WIDTH_1200);

  const avatarSize = matchesMax1200Screen ? 27 : 32;

  useEffect(() => {
    const calculateAvatarCount = () => {
      if (containerRef.current) {
        const boxWidth = containerRef.current.offsetWidth;
        const count = Math.floor(boxWidth / avatarSize);
        setAvatarCount(maxCount && maxCount < count ? maxCount : count);
      }
    };

    calculateAvatarCount();

    window.addEventListener("resize", calculateAvatarCount);
    return () => {
      window.removeEventListener("resize", calculateAvatarCount);
    };
  }, [avatarSize, containerRef.current?.offsetWidth, navigationDisplayed]);
  return { avatarCount, containerRef, avatarSize };
};

export default useDynamicsAvatars;
