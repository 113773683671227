import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import { THttpClientError } from "../../../../common/modules/httpClient/types/THttpClientError";
import { useEffect, useMemo } from "react";
import moment from "moment/moment";
import { useToggleOfftimeStatusService } from "../../services/useToggleOfftimeStatusService";
import { offtimeActions } from "../../slices/offtimeSlice";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import { IToggleOfftimeStatusRequest } from "../../interfaces/IToggleOfftimeStatusRequest";
import { useFetchOfftimeService } from "../../services/useFetchOfftimeService";
import Loader from "../../../../common/containers/Loader/Loader";
import { systemNotificationActions } from "../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { TGeneralProps } from "./types/TGeneralProps";
import { useDispatchEvent } from "../../../../common/modules/eventProvider";
import { EventEnum } from "../../../../common/modules/eventProvider/enums/EventEnum";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Chip from "../../../../common/modules/materialUI/components/Chip/Chip";
import { lightTheme } from "../../../../common/modules/theme/lightTheme";
import OfftimeSwitch from "./OfftimeSwitch";
import Badge from "../../../../common/modules/materialUI/components/Badge/Badge";

const OfftimeList = (props: TGeneralProps) => {
  const theme = useTheme();
  const { isLoading } = useFetchOfftimeService({ userId: props.userId });
  const dispatch = useAppDispatch();
  const dispatchEvent = useDispatchEvent();
  const data = useAppSelector((state) => state.offtime.list);
  const {
    isLoading: isLoadingToggleChange,
    dispatch: dispatchToggleOfftimeStatus,
  } = useToggleOfftimeStatusService();

  useEffect(() => {
    return () => {
      dispatch(offtimeActions.setList([]));
    };
  }, []);

  const toggleStatusHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    const payload: IToggleOfftimeStatusRequest = {
      id,
      isActive: event.target.checked,
    };
    dispatchToggleOfftimeStatus({
      body: payload,
      urlPath: !props.userId ? "" : `/for/${props.userId}`,
    })
      .then(() => {
        dispatch(offtimeActions.toggleStatus(payload));
        dispatch(
          systemNotificationActions.open({
            message: payload.isActive
              ? "Offtime activated"
              : "Offtime deactivated",
            variant: "success",
          })
        );
        dispatchEvent(EventEnum.ON_OFFTIME_STATUS_TOGGLED);
      })
      .catch((error: THttpClientError) => {
        dispatch(
          systemNotificationActions.open({
            message: [403, 406].includes(error.status)
              ? error.data.message
              : "Activation unsuccessful. Please check for possible overlap in your designated off-times.",
            variant: "warning",
          })
        );
      });
  };

  const getDateFormat = (startDate: string, endDate: string) => {
    const momentFormat = "MMM D, YYYY";
    const startDateFormatted = moment(startDate).format(momentFormat);
    const endDateFormatted = moment(endDate)
      .subtract(1, "d")
      .format(momentFormat);
    return `From ${startDateFormatted} till ${endDateFormatted}`;
  };

  const offDays = useMemo(() => {
    return data.reduce((acc, item) => {
      return item.isActive ? acc + 1 : acc;
    }, 0);
  }, [data]);

  return (
    <>
      {isLoading && <Loader />}
      <Accordion
        sx={{
          bgcolor: "backgroundElevation.backgroundPaperElevation_1",
          boxShadow: 0,
          borderRadius: "12px !important",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="offtime-list-content"
          id="offtime-list-header"
          sx={{
            minHeight: "55px",
            "&.Mui-expanded": {
              minHeight: "55px",
            },
            "& .MuiAccordionSummary-content": {
              display: "flex",
              alignItems: "center",
              gap: "8px",
              margin: "12px 0 !important",
            },
          }}
        >
          <Typography variant="subtitle1" sx={{ alignItems: "center" }}>
            Current Off Days
          </Typography>
          <Chip
            sx={{
              padding: "0 4px",
              height: "fit-content",
              alignItems: "center",
              "& span": {
                lineHeight: "20px",
                color: lightTheme.palette.text.primary,
              },
            }}
            color="secondary"
            label={`${offDays} item${offDays > 1 ? "s" : ""}`}
          />
        </AccordionSummary>

        <AccordionDetails
          sx={{
            maxHeight: "300px",
            overflowY: "auto",
          }}
        >
          <List>
            {data.length ? (
              data.map((item, index) => (
                <ListItem
                  sx={{
                    backgroundColor:
                      "backgroundElevation.backgroundPaperElevation_0",
                    height: "60px",
                    border: "1px solid" + theme.palette.divider,
                    borderRadius: 2,
                    marginTop: index === 0 ? "0px" : "12px",
                  }}
                  key={item.id}
                >
                  <ListItemText
                    primary={
                      <Typography noWrap variant="subtitle2">
                        {item.description}
                      </Typography>
                    }
                    secondary={
                      <Badge
                        variant="outlined"
                        color="text.secondary"
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          maxWidth: "100%",
                        }}
                      >
                        {getDateFormat(item.startDate, item.endDate)}
                      </Badge>
                    }
                  />
                  <OfftimeSwitch
                    checked={item.isActive}
                    disabled={isLoadingToggleChange}
                    onChange={(e) => toggleStatusHandler(e, item.id)}
                  />
                </ListItem>
              ))
            ) : (
              <Typography variant="body2" color="text.secondary">
                No off time days available
              </Typography>
            )}
          </List>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default OfftimeList;
