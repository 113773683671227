import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import useForm from "../../../../common/hooks/useForm/useForm";
import Form from "../../../../common/components/Form/Form";
import {
  Autocomplete,
  Button,
  Checkbox,
  TextField,
} from "../../../../common/modules/materialUI";
import { THttpClientError } from "../../../../common/modules/httpClient/types/THttpClientError";
import { systemNotificationActions } from "../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { TUpdateFormProps } from "./types/TUpdateFormProps";
import { TUpdateFormAttributes } from "./types/TUpdateFormAttributes";
import { useUpdateLinkListService } from "../../services/useUpdateLinkListService";
import { linkListActions } from "../../slices/linkListSlice";
import { TUpdateFormAttributesTransformed } from "./types/TUpdateFormAttributesTransformed";
import { projectUtil } from "../../../../common/utils/projectUtil";
import { useEffect } from "react";
import { Box, FormControlLabel, Typography } from "@mui/material";

const UpdateForm = ({ onCancel, linkList }: TUpdateFormProps) => {
  const dispatch = useAppDispatch();
  const projects = useAppSelector((state) => state.project.projectList);
  const taskLabel = useAppSelector((state) => state.customs.taskLabel);

  const form = useForm<TUpdateFormAttributes>({
    description: linkList.description,
    projectIds: [],
    timeSensitive: linkList.timeSensitive,
    linkedDescription: linkList.linkedDescription,
  });
  const { isLoading, dispatch: dispatchUpdateLinkListService } =
    useUpdateLinkListService({
      id: linkList.id as number,
    });

  useEffect(() => {
    if (projects.length) {
      form.set({
        ...form.data!,
        projectIds: projectUtil.list(
          projects.filter((project) =>
            linkList.projectIds?.includes(project.id as number)
          )
        ),
      });
    }
  }, [projects.length]);

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();

    dispatchUpdateLinkListService({
      body: form.getTransformed<TUpdateFormAttributesTransformed>((data) => ({
        ...data,
        projectIds: data.projectIds?.length
          ? data.projectIds.map((item) => item.id)
          : null,
      })),
    })
      .then(() => {
        dispatch(
          linkListActions.updateList({
            id: linkList.id as number,
            description: form?.data!.description,
            linkedDescription: form?.data!.linkedDescription,
            timeSensitive: form?.data!.timeSensitive,
            projectIds: form?.data!.projectIds?.map((item) => item.id),
          })
        );
        dispatch(linkListActions.closeUpdateModal());
        dispatch(
          systemNotificationActions.open({
            message: "Task link updated successfully",
            variant: "success",
          })
        );
      })
      .catch((error: THttpClientError) => {
        form.errorHandler(error);
      });
  };

  return (
    <Form
      onSubmit={submitHandler}
      actionElement={
        <>
          <Button onClick={onCancel} disabled={isLoading} variant="outlined">
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            variant="contained"
            type="submit"
            loading={isLoading}
          >
            Update
          </Button>
        </>
      }
    >
      <TextField
        form={form}
        name="description"
        label="Description"
        showRequired
      />
      <TextField
        form={form}
        name="linkedDescription"
        label={`Linked ${taskLabel} Description`}
        placeholder="e.g. Is blocked by"
      />
      <Box
        display="flex"
        alignItems="center"
        sx={{ marginBottom: "0 !important" }}
      >
        <FormControlLabel
          control={<Checkbox form={form} name="timeSensitive" />}
          label="Time sensitive"
        />
      </Box>
      {form.get("timeSensitive") && (
        <Typography variant="caption">
          Warning: This flag identifies hard dependency between linked tasks.
          The linked one will be automatically forced to follow the end date of
          originating one.
        </Typography>
      )}
      <Autocomplete
        form={form}
        name="projectIds"
        label={!form.data?.projectIds?.length ? "All projects" : "Projects"}
        multiple
        disabled={!projects.length}
        options={projectUtil.list(projects)}
      />
    </Form>
  );
};

export default UpdateForm;
