import { TAttachmentIdProps } from "./types/TAttachmentIdProps";
import { useAttachmentPreviewUrlService } from "../services/useAttachmentPreviewUrlService";
import Loading from "../../../../../common/components/Icon/Loading/Loading";
import { useEffect, useRef } from "react";
import { IPreviewSignedUrlResource } from "../interfaces/IPreviewSignedUrlResource";
import { useAttachmentPreviewUrlDispatchableOnlyService } from "../services/useAttachmentPreviewUrlDispatchableOnlyService";

export default function VideoPlayer({
  attachmentId,
  dialogCloseHandler,
}: TAttachmentIdProps) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const {
    isLoading,
    dispatch: dispatchAttachmentPreviewUrl,
    data,
  } = useAttachmentPreviewUrlService({
    attachmentId: attachmentId,
  });
  const { dispatch: dispatchFetchPreviewUrl } =
    useAttachmentPreviewUrlDispatchableOnlyService({
      attachmentId: attachmentId,
    });

  useEffect(() => {
    dispatchAttachmentPreviewUrl().catch(() => {
      dialogCloseHandler();
    });
  }, []);

  const errorHandler = () => {
    const video = videoRef.current!;
    dispatchFetchPreviewUrl()
      .then((data: IPreviewSignedUrlResource) => {
        const currentTime = video.currentTime; // store the current time
        const width = video.offsetWidth; // store the width
        const height = video.offsetHeight; // store the height
        video.src = data.previewUrl; // update the video source
        video.width = width; // update the width
        video.height = height; // update the height
        video.load(); // reload the video
        // Seek to the same position
        video.oncanplaythrough = function () {
          video.currentTime = currentTime;
          video.oncanplaythrough = null; // remove the event listener to prevent it from firing repeatedly
          video.play().catch(() => {});
        };
      })
      .catch(() => {});
  };

  return (
    <>
      {isLoading && <Loading />}
      {!isLoading && data && (
        <video
          ref={videoRef}
          src={data.previewUrl}
          autoPlay
          style={{ maxWidth: "100%", maxHeight: "95%" }}
          controls
          onError={errorHandler}
        />
      )}
    </>
  );
}
