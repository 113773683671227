import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/hooks/redux";
import useTaskType from "../../../../../../common/modules/task/hooks/useTaskType";
import useTaskPriority from "../../../../../../common/modules/task/hooks/useTaskPriority";
import { backlogBoardQueryActions } from "../../slices/backlogBoardQuerySlice";
import useCustomField from "../../../../../../common/modules/task/hooks/useCustomField";
import MultiMenu from "../../../../../../common/modules/materialUI/components/MultiMenu/MultiMenu";
import { useEffect, useState } from "react";
import useFilterTitle from "../../../../../../common/modules/task/hooks/useFilterTitle";
import FilterButton from "../../../../containers/Filter/FilterButton/FilterButton";
import { Box } from "@mui/material";
import Badge from "../../../../../../common/modules/materialUI/components/Badge/Badge";
import { Button } from "../../../../../../common/modules/materialUI";
import { userActions } from "../../../../../user/slices/userSlice";
import useMedia from "../../../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../../../common/hooks/useMedia/enums/QueryEnum";

const Filter = () => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(
    (state) => state.backlogBoardQuery.extraFilters
  );
  const sprintList = useAppSelector((state) => state.listing.sprintList);

  const [filterCount, setFilterCount] = useState(0);
  const [activeFilterList, setActiveFilterList] = useState<{
    filteredItem: string;
  }>({
    filteredItem: "Type",
  });

  const taskTypeUtil = useTaskType();
  const taskPriorityUtil = useTaskPriority();

  const matchesMax768Screen = useMedia(QueryEnum.MAX_WIDTH_768);

  const clearHandler = () => {
    dispatch(backlogBoardQueryActions.clearExtraFiltering());
    if (matchesMax768Screen) {
      dispatch(userActions.deleteSelectedIds());
    }
  };

  const taskTypeComponent = taskTypeUtil.listFilter(
    filters.taskTypeIds,
    (values) =>
      dispatch(
        backlogBoardQueryActions.setExtraFiltering({
          ...filters,
          taskTypeIds: values,
        })
      )
  );
  const taskPriorityComponent = taskPriorityUtil.listFilter(
    filters.taskPriorityIds,
    (values) =>
      dispatch(
        backlogBoardQueryActions.setExtraFiltering({
          ...filters,
          taskPriorityIds: values,
        })
      )
  );

  const filtrationTitle = useFilterTitle({
    filters: filters,
    showUser: false,
    showSprint: sprintList !== undefined && sprintList.length > 0,
  }).titleList(activeFilterList.filteredItem, (filteredItem: string) => {
    setActiveFilterList({
      filteredItem,
    });
  });

  const customFieldComponent = useCustomField({
    isBacklog: true,
  }).listFilters(filters.customFields);

  useEffect(() => {
    setFilterCount(
      filters.taskTypeIds.length +
        filters.taskPriorityIds.length +
        filters.customFields.length
    );
  }, [filters]);

  return (
    <MultiMenu
      closeOnSelection={false}
      button={(clickHandler) => (
        <FilterButton clickHandler={clickHandler} filterCount={filterCount} />
      )}
      headerSection={
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mx="18px"
          my="8px"
          height="22px"
        >
          <Badge variant="outlined">Filters</Badge>
          {filterCount > 0 && (
            <Button
              size="small"
              variant="text"
              color="error"
              onClick={clearHandler}
            >
              Clear Filters
            </Button>
          )}
        </Box>
      }
      menus={[
        {
          items: filtrationTitle,
          when: true,
        },
        {
          items: taskTypeComponent,
          when: activeFilterList.filteredItem === "Type",
        },
        {
          items: taskPriorityComponent,
          when: activeFilterList.filteredItem === "Priority",
        },
        ...(customFieldComponent?.flatMap((items) => {
          return {
            items: items.content,
            when: items.label === activeFilterList.filteredItem,
          };
        }) ?? []),
      ]}
    />
  );
};

export default Filter;
