import { TSimpleTextMessageProps } from "./types/TSimpleTextMessageProps";
import { Typography } from "@mui/material";
import RawMessage from "./RawMessage";
import ReportButton from "../Report/ReportButton";

const SimpleTextMessage = ({
  message,
  aiSentinel,
}: TSimpleTextMessageProps) => {
  return (
    <RawMessage type={message.type} aiSentinel={aiSentinel}>
      <Typography sx={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>
        {message.text}
      </Typography>
      {message.type === "ai" && !aiSentinel && <ReportButton />}
    </RawMessage>
  );
};

export default SimpleTextMessage;
