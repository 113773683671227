import { TTaskProps } from "./types/TTaskProps";
import classes from "./TaskHorizontalLarge.module.scss";
import TaskHorizontal from "./TaskHorizontal";
import { Box, Typography, useTheme } from "@mui/material";
import { ForwardedRef, forwardRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { taskUtil } from "../../../../utils/taskUtil";
import { Tooltip } from "../../../../modules/materialUI";
import { link } from "../../../../utils/link";
import { pointConverter } from "../../../../utils/pointConverter";
import { InternalTypeEnum } from "../../../../../entities/columns/task/InternalTypeEnum";
import { spikeStyleConfig } from "./spikeStyleConfig";
import TaskRefAndNumber from "./components/TaskRefAndNumber";

const TaskHorizontalLarge = forwardRef(
  (props: TTaskProps, ref: ForwardedRef<HTMLDivElement>) => {
    const theme = useTheme();
    const { task } = props;

    const [isHovered, setIsHovered] = useState(false);
    const mouseEnterHandle = () => setIsHovered(true);
    const mouseLeaveHandle = () => setIsHovered(false);

    const isSpike = task.internalType === InternalTypeEnum.SPIKE;
    const spikeStyles = spikeStyleConfig(theme, isHovered);

    const time: string = pointConverter.WorkingHoursOrDayToPoints(
      task.tsPoints
    );

    return (
      <TaskHorizontal
        {...props}
        ref={ref}
        className={`${props.className} ${classes.container}`}
        contentClassName={classes.content}
        style={{
          backgroundColor: theme.palette.background.default,
          border: "1px solid " + theme.palette.divider,
          ...props.style,
          ...(isSpike ? spikeStyles.containerStyle : {}),
        }}
        contentStyle={{
          backgroundColor: isHovered
            ? theme.palette.backgroundElevation.backgroundPaperElevation_3
            : theme.palette.background.paper,
          color: theme.palette.text.primary,
          overflow: "hidden",
          ...(isSpike ? spikeStyles.contentStyle : {}),
        }}
        mouseEnterHandle={mouseEnterHandle}
        mouseLeaveHandle={mouseLeaveHandle}
        isHovered={isHovered}
      >
        <Box>
          <NavLink
            to={taskUtil.url(task)}
            className={classes.ref}
            onClick={link.anchorClickHandler}
            style={{
              color: "inherit",
              display: "flex",
              alignItems: "center",
              gap: "2px",
            }}
          >
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
              width={"100%"}
            >
              <Box display={"flex"} alignItems={"center"} gap={"4px"}>
                {isSpike && (
                  <Tooltip title={InternalTypeEnum.SPIKE}>
                    <img
                      src={theme.palette.spike.xsImg}
                      alt=""
                      style={{
                        width: "11px",
                        height: "11px",
                      }}
                    />
                  </Tooltip>
                )}
                <Typography variant="caption" fontWeight={500}>
                  {time}
                </Typography>
              </Box>
              <TaskRefAndNumber
                gap={"2px"}
                number={task.number}
                hasSplits={task.hasSplits}
                taskRef={task.ref}
                isSpike={isSpike}
              />
            </Box>
          </NavLink>
        </Box>
        <Typography
          variant={"body2"}
          sx={{
            lineHeight: "16px",
            padding: "0 4px",
            height: "2rem",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
            overflow: "hidden",
          }}
        >
          {task.name}
        </Typography>
      </TaskHorizontal>
    );
  }
);
export default TaskHorizontalLarge;
