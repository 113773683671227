import { Box, Typography } from "@mui/material";
import AddNew from "../../../modules/project/containers/CreateProject/CreateProject";
import useMedia from "../../hooks/useMedia/useMedia";
import { QueryEnum } from "../../hooks/useMedia/enums/QueryEnum";

type TProps = {
  title: string;
  onClick: () => void;
  buttonText: string;
};
const TitleAndActionButton = ({ title, onClick, buttonText }: TProps) => {
  const matchesMax576Screen = useMedia(QueryEnum.MAX_WIDTH_576);

  return (
    <Box
      display="flex"
      justifyContent={"space-between"}
      alignItems={matchesMax576Screen ? "flex-start" : "center"}
      flexDirection={matchesMax576Screen ? "column" : "row"}
      gap={matchesMax576Screen ? "12px" : 0}
      mb="22px"
    >
      <Typography
        variant="h5"
        sx={{
          color: "text.primary",
          fontSize: matchesMax576Screen ? "17px" : undefined,
        }}
      >
        {title}
      </Typography>
      <Box
        display="flex"
        flexDirection={matchesMax576Screen ? "column" : "row"}
        alignItems={matchesMax576Screen ? "flex-end" : ""}
        width={matchesMax576Screen ? "100%" : "fit-content"}
      >
        <AddNew onClick={onClick} title={buttonText} />
      </Box>
    </Box>
  );
};
export default TitleAndActionButton;
