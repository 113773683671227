import { useAppSelector } from "../../../../common/hooks/redux";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import AttachmentRow from "../Row/AttachmentRow";
import { TAttachmentProps } from "./types/TAttachmentProps";
import { useEffect } from "react";
import PreviewDialog from "../../modules/preview/containers/PreviewDialog";
import { useAttachmentsService } from "../../services/useAttachmentsService";
import usePortalTabActions from "../../../task/modules/view/hooks/usePortalTabActions";
import { AttachFile } from "@mui/icons-material";
import useAttachmentFormHelper from "../../hooks/useAttachmentFormHelper";

const Attachment = (props: TAttachmentProps) => {
  const { fileSelectHandler, InputComponent } = useAttachmentFormHelper(
    props.entityType,
    props.entityId
  );
  const { CountsPortalComponent, ActionsPortalComponent } = usePortalTabActions(
    props.tabIndex
  );
  const attachments = useAppSelector((state) => state.attachment.list);
  const { isLoading: loadingAttachments, dispatch: dispatchAttachmentService } =
    useAttachmentsService({
      entityType: props.entityType,
      entityId: props.entityId,
    });

  useEffect(() => {
    dispatchAttachmentService();
  }, []);

  return (
    <>
      {CountsPortalComponent(
        <>{attachments.length > 0 ? attachments.length : 0} Attachments</>
      )}
      {ActionsPortalComponent(
        <Typography
          color="#818692"
          fontSize="13px"
          display="flex"
          alignItems="center"
          gap="4px"
          sx={{ cursor: "pointer" }}
          onClick={(event) => fileSelectHandler(event)}
        >
          <AttachFile fontSize="small" />
          Add {!props.isMobileView && "Attachment"}
        </Typography>
      )}
      {!loadingAttachments && (
        <Grid container flexDirection="column" gap="8px">
          {attachments.map((attachment) => (
            <AttachmentRow
              key={`${attachment.id}-${attachment.hashName}`}
              attachment={attachment}
              isMobileView={props.isMobileView}
            />
          ))}

          <PreviewDialog />

          {InputComponent}
        </Grid>
      )}
    </>
  );
};

export default Attachment;
