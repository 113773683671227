import { GridColDef } from "@mui/x-data-grid";
import { Box, IconButton, Tooltip, useTheme } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { IProject } from "../../../../entities/IProject";
import { ModeEdit } from "@mui/icons-material";
import { ConfirmationButton } from "../../../../common/modules/materialUI";
import InboxIcon from "@mui/icons-material/Inbox";
import Chip from "../../../../common/modules/materialUI/components/Chip/Chip";
import { TColumnsProps } from "./types/TColumnsProps";

export const Columns: ({
  editProjectHandler,
  editStatusFlowsHandler,
  configNonWorkingDaysHandler,
  manageSprintsHandler,
  archiveProjectHandler,
}: TColumnsProps) => GridColDef<IProject>[] = ({
  editProjectHandler,
  editStatusFlowsHandler,
  configNonWorkingDaysHandler,
  manageSprintsHandler,
  archiveProjectHandler,
}: TColumnsProps) => {
  const theme = useTheme();

  return [
    {
      field: "Actions",
      headerName: "Actions",
      hideSortIcons: false,
      disableColumnMenu: true,
      resizable: false,
      width: 100,
      hideable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="Edit Project">
              <IconButton onClick={(_) => editProjectHandler(params.row.abbr)}>
                <ModeEdit fontSize="small" />
              </IconButton>
            </Tooltip>

            <ConfirmationButton
              title="Archive Project"
              content="Are you sure you want to archive project? You won't be able to unarchived it manually."
              button={
                <Tooltip title="Archive Project">
                  <IconButton color="error">
                    <InboxIcon color="error" fontSize="small" />
                  </IconButton>
                </Tooltip>
              }
              confirmText="Archive"
              disagreeText="Cancel"
              data={params.row}
              successHandler={archiveProjectHandler}
            />
          </>
        );
      },
    },
    {
      field: "Name",
      headerName: "Name",
      hideSortIcons: false,
      disableColumnMenu: true,
      resizable: false,
      width: 210,
      hideable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            {params.row.name}
            <CircleIcon
              style={{
                color: params.row.color ?? theme.palette.primary.main,
                marginLeft: "17px",
                fontSize: "8px",
              }}
            />
          </>
        );
      },
    },
    {
      field: "abbr",
      headerName: "Abbr",
      hideSortIcons: false,
      disableColumnMenu: true,
      resizable: false,
      width: 100,
      hideable: false,
      sortable: false,
    },
    {
      field: "StatusFlow",
      headerName: "Status Flow",
      hideSortIcons: false,
      disableColumnMenu: true,
      resizable: false,
      width: 206,
      hideable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <Box display="flex" alignItems="center" gap="8px">
            <Chip
              sx={{
                padding: "4px",
                borderRadius: 2,
                height: "26px",
                "& span": {
                  fontSize: "14px",
                },
              }}
              size="small"
              variant="filled"
              label={"Manage Status Flow"}
            />
            <Box id="MyButton">
              <Tooltip title={"Edit Status Flow"}>
                <IconButton
                  onClick={(_) => editStatusFlowsHandler(params.row.abbr)}
                >
                  <ModeEdit fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        );
      },
    },
    {
      field: "NonWorkingDays",
      headerName: "Non Working Days",
      hideSortIcons: false,
      disableColumnMenu: true,
      resizable: false,
      width: 256,
      hideable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <Box display="flex" alignItems="center" gap="8px">
            <Chip
              sx={{
                padding: "4px",
                borderRadius: 2,
                height: "26px",
                "& span": {
                  fontSize: "14px",
                },
              }}
              size="small"
              variant="filled"
              label={"Manage Non Working Days"}
            />
            <Box id="MyButton">
              <Tooltip title="Edit Non Working Days">
                <IconButton
                  onClick={(_) => configNonWorkingDaysHandler(params.row.abbr)}
                >
                  <ModeEdit fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        );
      },
    },
    {
      field: "Sprints",
      headerName: "Sprints",
      hideSortIcons: false,
      disableColumnMenu: true,
      resizable: false,
      width: 180,
      hideable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <Box display="flex" alignItems="center" gap="8px">
            <Chip
              sx={{
                padding: "4px",
                borderRadius: 2,
                height: "26px",
                "& span": {
                  fontSize: "14px",
                },
              }}
              size="small"
              variant="filled"
              color="info"
              label={"Manage Sprints"}
            />
            <Box id="MyButton">
              <Tooltip title="Edit Sprints">
                <IconButton
                  onClick={(_) => manageSprintsHandler(params.row.abbr)}
                >
                  <ModeEdit fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Date Added",
      hideSortIcons: false,
      disableColumnMenu: true,
      resizable: false,
      width: 180,
      hideable: false,
      sortable: false,
    },
    {
      field: "updatedAt",
      headerName: "Date Updated",
      hideSortIcons: false,
      disableColumnMenu: true,
      resizable: false,
      width: 180,
      hideable: false,
      sortable: false,
    },
  ];
};
