import { useAppDispatch } from "../../../../../../common/hooks/redux";
import { TTaskPriorityFormProps } from "./types/TTaskPriorityFormProps";
import { useUpdateTaskService } from "../../../../services/useUpdateTaskService";
import { ITaskPatchResource } from "../../interfaces/ITaskPatchResource";
import { THttpClientError } from "../../../../../../common/modules/httpClient/types/THttpClientError";
import { systemNotificationActions } from "../../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { useDispatchEvent } from "../../../../../../common/modules/eventProvider";
import { TTaskEvent } from "../types/TTaskEvent";
import { EventEnum } from "../../../../../../common/modules/eventProvider/enums/EventEnum";
import EntityTaskPriorityUpdateForm from "../../../../../taskPriority/containers/EntityTaskPriorityUpdateForm/EntityTaskPriorityUpdateForm";

const TaskPriorityForm = ({ task, noLabel }: TTaskPriorityFormProps) => {
  const dispatch = useAppDispatch();
  const dispatchEvent = useDispatchEvent();
  const { dispatch: dispatchUpdateTaskService } = useUpdateTaskService(
    task.ref
  );

  const changeHandler = (taskPriorityId: number | null) => {
    dispatchUpdateTaskService({
      body: {
        taskPriorityId: taskPriorityId,
      },
    })
      .then((taskResource: ITaskPatchResource) => {
        const taskEvent = {
          initTask: task,
          task: taskResource.task,
        } as TTaskEvent;
        dispatchEvent(EventEnum.ON_TASK_MAIN_DATA_UPDATED, taskEvent);
      })
      .catch((_: THttpClientError) => {
        dispatch(
          systemNotificationActions.open({
            message:
              "There was a problem with updating the Task Priority. Please try again.",
            variant: "error",
          })
        );
      });
  };

  return (
    <EntityTaskPriorityUpdateForm
      projectRef={task.projectRef}
      taskPriorityId={task.taskPriorityId}
      onChange={changeHandler}
      isLoading={!task.id}
      noLabel={noLabel}
    />
  );
};

export default TaskPriorityForm;
