import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUserOfftime } from "../../../entities/IUserOfftime";
import { IToggleOfftimeStatusRequest } from "../interfaces/IToggleOfftimeStatusRequest";

export type TState = {
  list: IUserOfftime[];
  userId?: number;
  createModal?: boolean;
};

const initialState: TState = {
  list: [],
};

const slice = createSlice({
  name: "offtime",
  initialState,
  reducers: {
    setList: (state, action: PayloadAction<IUserOfftime[]>) => {
      state.list = action.payload;
    },
    addOfftime: (state, action: PayloadAction<IUserOfftime>) => {
      state.list = [action.payload, ...state.list];
    },
    toggleStatus: (
      state,
      action: PayloadAction<IToggleOfftimeStatusRequest>
    ) => {
      const offtime = state.list.find((item) => item.id === action.payload.id)!;
      offtime.isActive = action.payload.isActive;
    },
    setUserId: (state, action: PayloadAction<number | undefined>) => {
      state.userId = action.payload;
    },
    openCreateModal: (state) => {
      state.createModal = true;
    },
    closeCreateModal: (state) => {
      state.createModal = false;
    },
  },
});

export const offtimeReducer = slice.reducer;

export const offtimeActions = slice.actions;
