import { useNavigate } from "react-router-dom";
import { menuItem } from "../utils/menuItem";
import { Box, Typography } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";

const Settings = () => {
  const navigate = useNavigate();
  return menuItem.make({
    component: (
      <Box
        display="flex"
        alignItems="center"
        gap={"8px"}
        sx={{ cursor: "pointer" }}
      >
        <SettingsIcon sx={{ color: "action.active" }} />
        <Typography color={"text.primary"}>Settings</Typography>
      </Box>
    ),
    handler: () => {
      navigate("/settings/project");
    },
    hasDivider: false,
  });
};

export default Settings;
