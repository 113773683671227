import { attachmentActions } from "../../../slices/attachmentSlice";

import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../common/hooks/redux";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { PreviewTypeEnum } from "../../../enums/PreviewTypeEnum";
import Image from "./Image";
import VideoPlayer from "./VideoPlayer";

const PreviewDialog = () => {
  const dispatch = useAppDispatch();
  const { attachmentId, previewType } = useAppSelector(
    (state) => state.attachment.preview
  );

  const closeHandler = () => {
    dispatch(attachmentActions.closePreviewModal());
  };

  return (
    <Dialog
      open={!!attachmentId}
      onClose={closeHandler}
      fullWidth
      maxWidth="xl"
    >
      <DialogTitle></DialogTitle>

      <IconButton
        onClick={closeHandler}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
      <DialogContent sx={{ pt: 0, height: "90vh" }}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          {!!attachmentId && previewType === PreviewTypeEnum.image && (
            <Image
              attachmentId={attachmentId}
              dialogCloseHandler={closeHandler}
            />
          )}
          {!!attachmentId && previewType === PreviewTypeEnum.video && (
            <VideoPlayer
              attachmentId={attachmentId}
              dialogCloseHandler={closeHandler}
            />
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default PreviewDialog;
