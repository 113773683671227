import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { ArrowDropDownRounded, AutoAwesome } from "@mui/icons-material";
import { TChildren } from "../../../../types/TChildren";
import { TSxProp } from "../../../../types/TSxProp";

const QuickActionsDesktop = ({ children, sx }: TChildren & TSxProp) => {
  const theme = useTheme();

  return (
    <MuiAccordion
      onChange={(_, expaned) =>
        localStorage.setItem("quickActionsExpanded", `${expaned}`)
      }
      defaultExpanded={
        (localStorage.getItem("quickActionsExpanded") ?? "true") === "true"
      }
      sx={{
        background: "transparent",
        borderRadius: "12px",
        boxShadow: "none",
        margin: "0 !important",
        marginTop: "auto !important",
        "::before": {
          display: "none",
        },
        ...sx,
      }}
    >
      <AccordionSummary
        sx={{
          minHeight: "auto !important",
          padding: "0 8px",
          "& .MuiAccordionSummary-content": {
            alignItems: "center",
            margin: 0,
          },
        }}
        expandIcon={<ArrowDropDownRounded fontSize="small" />}
      >
        <AutoAwesome fontSize="small" sx={{ color: "#FDBF62", mr: "8px" }} />
        <Typography
          color={theme.palette.taskView.textSecondaryColor}
          fontSize="15px"
          fontWeight={500}
        >
          Quick Actions
        </Typography>
      </AccordionSummary>

      <AccordionDetails sx={{ marginTop: "8px", padding: 0 }}>
        <Grid
          container
          bgcolor={theme.palette.taskView.mainBackground}
          borderRadius="12px"
          p="12px"
          gap="12px"
        >
          {children}
        </Grid>
      </AccordionDetails>
    </MuiAccordion>
  );
};

export default QuickActionsDesktop;
