import { TListItemComponentProps } from "./types/TListItemComponentProps";
import List from "../../../containers/Layout/App/Navigation/common/List/List";
import Link from "./Link";
import ExpandableIcon from "../../Icon/Expandable/ExpandableIcon";
import {
  Box,
  ListItem as MuiListItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import { string } from "../../../utils/string";

const ListItem = (props: TListItemComponentProps) => {
  const theme = useTheme();
  const location = useLocation();
  const isActive = string.pathsMatching(props.path, location.pathname);

  return (
    <Link
      onClick={props.onClick}
      onNavigate={props.onNavigate}
      navigateToPath={props.navigateToPath ?? true}
      path={props.path}
    >
      <MuiListItem
        disablePadding
        sx={{
          display: "flex",
          height: "36px",
          listStyle: "outside none none",
          margin: 0,
          padding: 0,
          paddingLeft: "15px",
          position: "relative",
          background:
            isActive && !props.onlyIconList ? theme.palette.action.focus : "",
        }}
      >
        <Tooltip
          title={props.disabledTooltipMessage}
          disableHoverListener={
            !props.isDisabled || !props.disabledTooltipMessage
          }
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{
                width: "23px",
                height: "26px",
                position: "relative",
                top: "3px",
                "& svg": {
                  fontSize: "1rem",
                },
              }}
            >
              {props.icon}
            </Typography>
            <Typography
              component={"span"}
              sx={{ fontSize: "14px", color: theme.palette.text.primary }}
            >
              {props.name}
            </Typography>
            <ExpandableIcon
              display={props.isExpandable}
              direction={props.isExpanded ? "down" : "right"}
            />
          </Box>
        </Tooltip>
        {props.isExpandable && props.isExpanded && <List items={props.items} />}
      </MuiListItem>
    </Link>
  );
};

export default ListItem;
