import { Typography, useTheme } from "@mui/material";
import classes from "./ReadOnlyTokenExpired.module.scss";

const ReadOnlyTokenInvalid = () => {
  const theme = useTheme();

  return (
    <div className={`text-center ${classes.container}`}>
      <img
        src={`${process.env.CDN_URL}/icons/${
          theme.palette.mode === "dark"
            ? "not-found-404-dark.png"
            : "not-found-404-light.png"
        }`}
        alt=""
      />
      <Typography variant="h5" sx={{ mb: 1 }}>
        Your shared link is not valid anymore.
      </Typography>
    </div>
  );
};

export default ReadOnlyTokenInvalid;
