import { menuItem } from "../utils/menuItem";
import { Typography } from "@mui/material";

const HeaderItem = (title: string, when: boolean = true) => {
  return menuItem.make({
    component: (
      <Typography
        variant={"subtitle2"}
        color={"text.secondary"}
        sx={{
          lineHeight: "40px",
        }}
      >
        {title}
      </Typography>
    ),
    hasDivider: false,
    isHeaderItem: true,
    when,
    headerItemProps: {
      sx: {
        marginBottom: 0,
        p: "0 12px",
      },
    },
  });
};

export default HeaderItem;
