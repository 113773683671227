import { Box, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useAppSelector } from "../../../../../common/hooks/redux";
import FileUploadProvider from "../../../../../common/containers/FileUploadProvider/FileUploadProvider";
import { MAIN_CONTENT_PADDING_BOTTOM } from "../../../../../common/containers/Layout/App/Content/Content";
import useUploadAttachmentServiceHelper from "../../../../attachment/hooks/useUploadAttachmentServiceHelper";
import { EntityTypeEnum } from "../../../../../entities/enums/EntityTypeEnum";
import CustomFieldSection from "../../../../customField/containers/CustomFieldSection/CustomFiledSection";
import { TBacklogItemContentProps } from "./types/TBacklogItemContentProps";
import QuickActions from "../../../../../common/modules/taskView/containers/QuickActions/QuickActions";
import AddAttachment from "../../../../../common/modules/taskView/containers/QuickActions/containers/AddAttachment";
import AddLink from "../../../../../common/modules/taskView/containers/QuickActions/containers/AddLink";
import AddToTimeline from "../../../../../common/modules/taskView/containers/QuickActions/containers/AddToTimeline";
import { useUpdateBacklogItemService } from "../services/useUpdateBacklogItemService";
import { TASK_VIEW_MAIN_CONTENT_HEADER_HEIGHT } from "../../../../task/modules/view/containers/Header/TaskViewHeader";
import BacklogItemViewHeader from "./Header/BacklogItemViewHeader";
import BacklogItemViewMainContent from "./TaskViewMainContent/BacklogItemViewMainContent";
import { useState } from "react";

const BacklogItemViewDesktop = ({
  detailsResource,
  contentHeight,
  isMobileView,
  isPageView,
}: TBacklogItemContentProps) => {
  const [havingCustomFields, setHavingCustomFields] = useState(false);

  const { backlogItem } = detailsResource;
  const entityType = EntityTypeEnum.BACKLOG_ITEM;
  const id = backlogItem.id;

  const theme = useTheme();

  const { dispatch: dispatchUpdateBacklogItemService } =
    useUpdateBacklogItemService(backlogItem.ref);
  const { dispatch: dispatchUploadAttachmentService } =
    useUploadAttachmentServiceHelper(entityType, id);

  const taskLabel = useAppSelector((state) => state.customs.taskLabel);

  return (
    <Grid
      container
      height="100%"
      bgcolor={theme.palette.taskView.mainBackground}
      alignItems="flex-start"
      wrap="nowrap"
    >
      <Grid height="100%" width="calc(100% - 405px)">
        <BacklogItemViewHeader
          backlogItem={backlogItem}
          isMobileView={isMobileView}
        />

        <Grid
          container
          height={`calc(${contentHeight} - ${TASK_VIEW_MAIN_CONTENT_HEADER_HEIGHT}px)`}
          sx={{ overflowY: "auto" }}
        >
          <Grid container size={12} p="20px 26px" flexDirection="column">
            <BacklogItemViewMainContent
              backlogItem={backlogItem}
              isMobileView={isMobileView}
            />
          </Grid>
        </Grid>
      </Grid>

      <FileUploadProvider
        onFileLoad={(file) => dispatchUploadAttachmentService(file)}
        fileDropText={`Drop files here to attach to the ${taskLabel}`}
        dropZoneId="taskDropZone"
        inputConfig={{
          id: "taskAttachment",
          name: "taskAttachment",
        }}
        style={{
          bottom: 0,
          height: contentHeight,
          right: 0,
          width: "405px",
        }}
        fileDropZoneTextBoxStyles={{
          bottom: "150px !important",
          width: "90%",
        }}
        labelStyles={{
          height: "100%",
        }}
        shouldRender={true}
      >
        <Grid
          container
          bgcolor={theme.palette.taskView.secondaryBackground}
          borderLeft="1px solid rgba(0, 0, 0, 0.10)"
          height={contentHeight}
          p="16px 32px"
          pb={isPageView ? `${MAIN_CONTENT_PADDING_BOTTOM}px` : undefined}
          position="relative"
          width="405px"
          flexDirection="column"
          flexWrap="nowrap"
          gap="20px"
        >
          <Box sx={{ overflowY: "auto" }}>
            <CustomFieldSection
              boardRef={backlogItem.boardRef}
              dispatchUpdateService={dispatchUpdateBacklogItemService}
              customFieldValues={backlogItem.customFieldValues}
              isMobileView={isMobileView}
              setHavingCustomFields={setHavingCustomFields}
            />
          </Box>

          <QuickActions
            isMobileView={isMobileView}
            sx={{
              marginTop: !havingCustomFields
                ? "0 !important"
                : "auto !important",
            }}
          >
            <AddAttachment
              isMobileView={isMobileView}
              entityType={entityType}
              entityId={id}
            />
            <AddLink
              entityType={entityType}
              entityId={id}
              projectRef={backlogItem.projectRef}
            />
            <AddToTimeline
              backlogItemRef={backlogItem.ref}
              boardRef={backlogItem.boardRef}
            />
          </QuickActions>
        </Grid>
      </FileUploadProvider>
    </Grid>
  );
};

export default BacklogItemViewDesktop;
