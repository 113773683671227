import { DialogContent } from "@mui/material";
import Grid from "@mui/material/Grid2";
import DialogTitle from "@mui/material/DialogTitle";
import { Dialog } from "../../../../common/modules/materialUI";
import { QueryEnum } from "../../../../common/hooks/useMedia/enums/QueryEnum";
import useMedia from "../../../../common/hooks/useMedia/useMedia";
import Comments from "../../../comment/containers/Comments/Comments";
import { EntityTypeEnum } from "../../../../entities/enums/EntityTypeEnum";
import { TNotesModalProps } from "./types/TNotesModalProps";

const NotesModal = (props: TNotesModalProps) => {
  const isMobileView = useMedia(QueryEnum.MAX_WIDTH_1200);

  return (
    <Dialog
      closeButtonHandler={props.closeHandler}
      renderCloseButton
      open={true}
      onClose={props.closeHandler}
      fullWidth
      maxWidth={"lg"}
      fullScreen={isMobileView}
    >
      <DialogTitle>Notes</DialogTitle>
      <DialogContent>
        <Grid container mb="30px" flexDirection="column" gap="40px">
          <Comments
            entityType={EntityTypeEnum.PROJECT}
            entityId={props.projectId}
            typeTitle={"note"}
            isMobileView={isMobileView}
          />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default NotesModal;
