import { TCommentProps } from "./types/TCommentProps";
import {
  EditorContent,
  useIOHandler,
  useStateManager,
} from "../../../../common/modules/editor";
import { useAppDispatch } from "../../../../common/hooks/redux";
import { commentActions } from "../../slices/commentSlice";
import { TFnHandlerParams } from "../CommentEditor/types/TFnHandlerParams";
import { useUpdateCommentService } from "../../services/useUpdateCommentService";
import {
  date,
  FORMAT_MONTH_DAY_YEAR_HOUR_MINUTE,
} from "../../../../common/utils/date";
import AvatarIcon from "../../../user/containers/AvatarIcon/AvatarIcon";
import { array } from "../../../../common/utils/array";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Delete, Edit } from "@mui/icons-material";
import CommentEditor from "../CommentEditor/CommentEditor";
import useMedia from "../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../common/hooks/useMedia/enums/QueryEnum";

export default function Comment({
  comment,
  entityType,
  entityId,
  onSave,
  isMobileView,
}: TCommentProps) {
  const { sanitize } = useIOHandler();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const {
    editorRequested,
    editorReady,
    immediateRequestEditorHandler,
    initEditorHandler,
    closeEditorHandler,
  } = useStateManager();
  const updateCommentService = useUpdateCommentService();
  const isTouchDevice = useMedia(QueryEnum.IS_TOUCH_DEVICE);

  const saveHandler = (handlerParams: TFnHandlerParams) => {
    closeEditorHandler();
    dispatch(
      commentActions.updateComment({
        html: handlerParams.html,
        comment,
      })
    );

    onSave?.(handlerParams);
  };

  return (
    <Grid container alignItems="flex-start" gap="8px">
      {!editorReady && (
        <Grid>
          <AvatarIcon
            userName={comment.userName}
            noPadding
            avatarStyles={{ color: "#ffffff", fontWeight: 500 }}
          />
        </Grid>
      )}

      <Grid flex={1}>
        {(!editorRequested || !editorReady) && (
          <>
            <Grid container mb="8px" alignItems="center" gap="8px">
              <Grid>
                <Typography
                  color={theme.palette.taskView.textMainColor}
                  fontSize="14px"
                  fontWeight={700}
                >
                  {comment.userName.split(" ")[0]}
                </Typography>
              </Grid>

              <Grid>
                <Typography
                  fontSize="12px"
                  color={theme.palette.taskView.labelColor}
                >
                  {date.format(
                    comment.createdAt,
                    FORMAT_MONTH_DAY_YEAR_HOUR_MINUTE
                  )}
                </Typography>
              </Grid>

              <Grid
                container
                ml={isMobileView ? "auto" : "12px"}
                width="auto"
                gap={isTouchDevice ? "16px" : "8px"}
              >
                {comment.canEdit && (
                  <IconButton
                    sx={{ p: 0 }}
                    onClick={immediateRequestEditorHandler}
                  >
                    <Edit
                      sx={{
                        color: theme.palette.taskView.textMainColor,
                        fontSize: isMobileView ? "20px" : "16px",
                      }}
                    />
                  </IconButton>
                )}

                {comment.canDelete && (
                  <IconButton
                    sx={{ p: 0 }}
                    onClick={() =>
                      dispatch(commentActions.openDeletionDialog(comment))
                    }
                  >
                    <Delete
                      sx={{
                        color: theme.palette.taskView.textMainColor,
                        fontSize: isMobileView ? "20px" : "16px",
                      }}
                    />
                  </IconButton>
                )}
              </Grid>
            </Grid>

            <Box
              className={array.toClassName([
                editorRequested && !editorReady ? "pulsating" : "",
              ])}
            >
              <EditorContent
                html={comment.html ? sanitize(comment.html)! : ""}
                style={{
                  padding: "0.1rem",
                  color: theme.palette.taskView.textMainColor,
                  overflowWrap: "anywhere",
                }}
              />
            </Box>
          </>
        )}

        {editorRequested && (
          <CommentEditor
            html={comment.html}
            entityType={entityType}
            entityId={entityId}
            onSave={saveHandler}
            onCancel={closeEditorHandler}
            onInit={initEditorHandler}
            saveService={updateCommentService}
            commentId={comment.id}
            style={{ display: editorReady ? undefined : "none" }}
          />
        )}
      </Grid>
    </Grid>
  );
}
