import TaskSensorIcon from "../../../../../../components/Logo/TaskSensorIcon";
import { Box } from "@mui/material";
import ReadOnlyLabel from "../../Header/Readonly/ReadOnlyLabel";

const Mobile = () => {
  return (
    <Box display="flex" alignItems="center" gap="4px">
      <TaskSensorIcon size={70} colorCombo={"white-green"} width={22} />

      <Box ml="12px">
        <ReadOnlyLabel />
      </Box>
    </Box>
  );
};

export default Mobile;
