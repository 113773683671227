import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../config/authConfig";
import { InteractionStatus } from "@azure/msal-browser";
import { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { useAuthenticateService } from "../../services/useAuthenticateService";
import { useLocation, useNavigate } from "react-router-dom";
import { IOAuthResource } from "../../../../../user/interfaces/IOAuthResource";
import { jwt } from "../../../../../../common/utils/jwt";
import { ROUTE_ON_SIGNIN } from "../../../../../user/containers/Auth/Signin/TSSigninForm";
import MicrosoftAuthButton from "../../components/MicrosoftAuthButton/MicrosoftAuthButton";
import { THttpClientError } from "../../../../../../common/modules/httpClient/types/THttpClientError";
import { IStripeError } from "../../../../../stripe/interfaces/IStripeError";
import { IStripeSessionResource } from "../../../../../stripe/interfaces/IStripeSessionResource";
import { systemNotificationActions } from "../../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { useCreateStripeSessionService } from "../../../../../stripe/services/useCreateStripeSessionService";
import { useAppDispatch } from "../../../../../../common/hooks/redux";
import useForm from "../../../../../../common/hooks/useForm/useForm";
import { Button } from "../../../../../../common/modules/materialUI";
import { TSignLayout } from "../types/TSignLayout";
import useWindow from "../../../../../../common/hooks/useWindow";

const SignInRedirectButton = (props: TSignLayout) => {
  const { openNewTab } = useWindow();
  const { instance, inProgress } = useMsal();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { getValidationErrorAtIndex } = useForm();
  const navigate = useNavigate();
  const [isSigningIn, setIsSigningIn] = useState(false);
  const { dispatch: dispatchAuthenticate, error } = useAuthenticateService();
  const [paymentRequiredError, setPaymentRequiredError] = useState<
    string | null
  >(null);
  const [subscriptionPlan, setSubscriptionPlan] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  const {
    isLoading: isLoadingStripeSession,
    dispatch: dispatchCreateStripeSessionService,
  } = useCreateStripeSessionService();

  const isLoading = inProgress !== InteractionStatus.None || isSigningIn;

  useEffect(() => {
    instance.handleRedirectPromise().then((response) => {
      if (!response) {
        return;
      }

      setIsSigningIn(true);

      dispatchAuthenticate({ body: { accessToken: response.accessToken } })
        .then((payload: IOAuthResource) => {
          jwt.store(payload.accessToken);
          navigate(
            (response.state?.trim().length ? response.state : null) ??
              ROUTE_ON_SIGNIN,
            { state: null }
          );
        })
        .catch((error: THttpClientError) => {
          if (error.status === 402) {
            const errors = error.data.errors as IStripeError;
            setPaymentRequiredError(error.data.message);
            setSubscriptionPlan(errors.subscriptionPlan!);
            setEmail(errors.email!);
          }
          setIsSigningIn(false);
        });
    });
  }, []);

  const createStripeSessionHandler = () => {
    dispatchCreateStripeSessionService({
      body: {
        subscriptionPlan: subscriptionPlan,
        email: email,
      },
    })
      .then((resource: IStripeSessionResource) => {
        openNewTab(resource.url, true);
      })
      .catch((error: THttpClientError) => {
        const validationFirstError = getValidationErrorAtIndex(0, error);
        if (validationFirstError) {
          dispatch(
            systemNotificationActions.open({
              variant: "error",
              message: validationFirstError,
            })
          );
        }
      });
  };

  const signInHandler = () => {
    instance.loginRedirect({
      ...loginRequest,
      state: location.state?.from?.pathname,
    });
  };

  return (
    <>
      <MicrosoftAuthButton
        isLoading={isLoading}
        onClick={signInHandler}
        login={props.isLogin}
      />
      {error && error?.status !== 402 && (
        <Typography color="error" sx={{ fontSize: "14px" }}>{`${
          error.data.message
        }. ${(Array.isArray(error.data.errors)
          ? (error.data.errors as string[])
          : []
        ).join(" ")}`}</Typography>
      )}

      {paymentRequiredError && (
        <Typography textAlign="left" sx={{ fontSize: "14px" }}>
          {paymentRequiredError}
          <Button
            variant="text"
            type="submit"
            size="medium"
            onClick={createStripeSessionHandler}
            disabled={isLoadingStripeSession || isLoading}
            loading={isLoadingStripeSession}
          >
            Go to checkout
          </Button>
        </Typography>
      )}
    </>
  );
};

export default SignInRedirectButton;
