import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import useMedia from "../../../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../../../common/hooks/useMedia/enums/QueryEnum";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/hooks/redux";
import { boardQueryActions } from "../../../../slices/boardQuerySlice";
import Menu from "../../../../../../common/containers/Menu/Menu";
import { menuItem } from "../../../../../../common/containers/Menu/utils/menuItem";
import { Check } from "@mui/icons-material";
import { Button } from "../../../../../../common/modules/materialUI";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useState } from "react";
import Badge from "../../../../../../common/modules/materialUI/components/Badge/Badge";
import { backlogBoardQueryActions } from "../../../../modules/backlogView/slices/backlogBoardQuerySlice";

const sortingTaskTypeItems = [
  {
    id: "taskTypeId",
    label: "From High To Low",
    type: "taskTypeId_desc",
  },
  {
    id: "taskTypeId",
    label: "From Low To High",
    type: "taskTypeId_asc",
  },
];

const sortingTaskPriorityItems = [
  {
    id: "taskPriorityId",
    label: "From High To Low",
    type: "taskPriorityId_desc",
  },
  {
    id: "taskPriorityId",
    label: "From Low To High",
    type: "taskPriorityId_asc",
  },
];

const Sorting = ({
  actions,
}: {
  actions: typeof boardQueryActions | typeof backlogBoardQueryActions;
}) => {
  const dispatch = useAppDispatch();
  const sort = useAppSelector((state) => state.boardQuery.sorting);

  const [activeSortType, setActiveSortType] = useState<string | undefined>(
    sort?.column && sort?.direction
      ? `${sort.column}_${sort.direction}`
      : undefined
  );

  const matchesMax768Screen = useMedia(QueryEnum.MAX_WIDTH_768);

  const menuItemTitle = (title: string, haseDivider: boolean = false) => {
    return {
      component: <Badge variant="outlined">{title}</Badge>,
      isHeaderItem: true,
      hasDivider: haseDivider,
      dividerSx: {
        marginBottom: "0 !important",
      },
      headerItemProps: {
        sx: {
          marginBottom: "0",
          py: 0,
        },
      },
    };
  };

  const menuItems = [
    menuItem.make(menuItemTitle("Task Type", true)),
    ...sortingTaskTypeItems.map((taskTypeItems, index) => {
      const isSelected = activeSortType === taskTypeItems.type;

      return menuItem.make({
        component: (
          <Grid container spacing={2} alignItems="center" height="32px">
            <Grid>
              <Typography variant="body2">{taskTypeItems.label}</Typography>
            </Grid>
            {isSelected && (
              <Grid>
                <Check sx={{ color: "primary.main" }} />
              </Grid>
            )}
          </Grid>
        ),
        handler: () => {
          if (activeSortType === taskTypeItems.type) {
            setActiveSortType(undefined);
            dispatch(actions.setSorting());
          } else {
            setActiveSortType(taskTypeItems.type);
            dispatch(
              actions.setSorting({
                column: taskTypeItems.id,
                direction:
                  taskTypeItems.type === "taskTypeId_desc" ? "desc" : "asc",
                putNullAtEnd: 1,
              })
            );
          }
        },
        hasDivider: index === sortingTaskTypeItems.length - 1,
        dividerSx: {
          marginTop: "0 !important",
        },
        menuItemsProps: {
          sx: {
            marginBottom: "0",
          },
        },
      });
    }),
    menuItem.make(menuItemTitle("Task Priority")),
    ...sortingTaskPriorityItems.map((sortingTaskPriorityItems) => {
      const isSelected = activeSortType === sortingTaskPriorityItems.type;

      return menuItem.make({
        component: (
          <Grid container spacing={2} alignItems="center" height="32px">
            <Grid>
              <Typography variant="body2">
                {sortingTaskPriorityItems.label}
              </Typography>
            </Grid>
            {isSelected && (
              <Grid>
                <Check sx={{ color: "primary.main" }} />
              </Grid>
            )}
          </Grid>
        ),
        handler: () => {
          if (activeSortType === sortingTaskPriorityItems.type) {
            setActiveSortType(undefined);
            dispatch(actions.setSorting());
          } else {
            setActiveSortType(sortingTaskPriorityItems.type);
            dispatch(
              actions.setSorting({
                column: sortingTaskPriorityItems.id,
                direction:
                  sortingTaskPriorityItems.type === "taskPriorityId_desc"
                    ? "desc"
                    : "asc",
                putNullAtEnd: 1,
              })
            );
          }
        },
        hasDivider: false,
        menuItemsProps: {
          sx: {
            marginBottom: "0",
          },
        },
      });
    }),
  ];

  return (
    <Menu
      stretchOnMobile={false}
      closeOnSelection={false}
      button={(clickHandler) => (
        <Button
          isBorderStyle
          variant="outlined"
          startIcon={<FilterListIcon sx={{ fontSize: "18px" }} />}
          size="small"
          color="inherit"
          onClick={clickHandler}
          sx={{
            p: matchesMax768Screen ? "4px 9px" : "4px 10px",
          }}
        >
          {!matchesMax768Screen ? "Sorting" : ""}
        </Button>
      )}
      sx={{
        width: "206px",
      }}
      menuItems={menuItems}
    />
  );
};

export default Sorting;
