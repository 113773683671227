import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/hooks/redux";
import { guesstimationItemViewActions } from "../../slices/viewSlice";
import PhaseForm from "./PhaseForm";

const PhaseModal = () => {
  const guesstimationItem = useAppSelector(
    (state) => state.guesstimationItemView.phase.guesstimationItem
  );
  const dispatch = useAppDispatch();

  const closeHandler = () => {
    dispatch(guesstimationItemViewActions.closePhaseDialog());
  };

  return (
    <Dialog
      open={!!guesstimationItem}
      onClose={closeHandler}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>Phase Item</DialogTitle>
      <DialogContent>
        {guesstimationItem && (
          <PhaseForm
            onCancel={closeHandler}
            guesstimationItem={guesstimationItem}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default PhaseModal;
