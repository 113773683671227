import { menuItem } from "../../../../../../../common/containers/Menu/utils/menuItem";
import ResourcesItem from "../Items/ResourcesItem";

const ResourcesMenuItem = ({
  assigneesLength,
  estimatedResources,
}: {
  assigneesLength?: number;
  estimatedResources?: number;
}) => {
  return menuItem.make({
    component: (
      <ResourcesItem
        assigneesLength={assigneesLength}
        estimatedResources={estimatedResources}
      />
    ),
    isHeaderItem: true,
  });
};
export default ResourcesMenuItem;
