import { TTooltipProps } from "./types/TTooltipProps";
import { Tooltip as MuiTooltip } from "@mui/material";

const Tooltip = (props: TTooltipProps) => {
  const { when, children, withSpanWrapper, titleStyles, ...tooltipProps } =
    props;

  return when ?? true ? (
    <MuiTooltip
      placement="top"
      slotProps={{
        tooltip: {
          sx: titleStyles,
        },
      }}
      {...tooltipProps}
    >
      {withSpanWrapper ? <span>{children}</span> : children}
    </MuiTooltip>
  ) : (
    <>{children}</>
  );
};

export default Tooltip;
