import classes from "./Layout.module.scss";
import { env } from "../../../../utils/env";
import { useTheme } from "@mui/material";
import Grid from "@mui/material/Grid2";
import TaskSensorLogo from "../../../../components/Logo/TaskSensorLogo";
import useWindowInnerHeight from "../../../../hooks/useWindowInnerHeight";
import useMedia from "../../../../hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../hooks/useMedia/enums/QueryEnum";
import { TChildren } from "../../../../types/TChildren";
import { useRewardful } from "../../../../hooks/useRewardful";
import ConfirmRedirectionDialog from "../../../../modules/materialUI/containers/ConfirmRedirectionDialog";
import { useLocation } from "react-router-dom";

const LayoutWrapper = (props: TChildren) => {
  const { pathname } = useLocation();
  const theme = useTheme();
  const windowHeight = useWindowInnerHeight();
  const matchesMax768 = useMedia(QueryEnum.MAX_WIDTH_768);
  const contentBackgroundColor = theme.palette.loginPallet.main;
  const backgroundColor = theme.palette.background.default;

  useRewardful();

  const startOverHandler = () => {
    if (pathname.startsWith("/forgotPassword")) {
      window.location.href = "/auth";
      return;
    }

    window.location.reload();
  };

  return (
    <>
      <ConfirmRedirectionDialog />
      <div className={classes.container} style={{ backgroundColor }}>
        <div
          className={classes.content}
          style={{
            background: matchesMax768
              ? `url("${env.cdnUrl}/auth/mobile-login-v7-background.png"), linear-gradient(${contentBackgroundColor} 60%, ${backgroundColor})`
              : `url("${env.cdnUrl}/auth/desktop-login-background.png")`,
            backgroundColor: !matchesMax768
              ? contentBackgroundColor
              : undefined,
            minHeight: matchesMax768 ? windowHeight : "504px",
          }}
        >
          <Grid
            container
            className={classes["form-content"]}
            direction="column"
            rowSpacing={4}
          >
            <Grid sx={{ cursor: "pointer" }} onClick={startOverHandler}>
              <TaskSensorLogo
                size={300}
                width={200}
                colorCombo={
                  theme.palette.mode === "dark" ? "white-green" : "gray-green"
                }
              />
            </Grid>
            {props.children}
          </Grid>
        </div>
      </div>
    </>
  );
};

export default LayoutWrapper;
