import { TInitiativesProps } from "./types/TInitiativesProps";
import { useFetchInitiativeListService } from "../../../services/initiative/useFetchInitiativeListService";
import { useEffect } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../common/hooks/redux";
import usePortalTabActions from "../../../../task/modules/view/hooks/usePortalTabActions";
import { Box, Divider, Typography, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  Add,
  AddLinkOutlined,
  Delete,
  LinkOffOutlined,
  Settings,
  StickyNote2,
} from "@mui/icons-material";
import { array } from "../../../../../common/utils/array";
import { useOrgProjectData } from "../../../../org/hooks/useOrgProjectData";
import { IData } from "../../../../../entities/columns/objective/IData";
import useKeyboardShortcut from "../../../../../common/modules/keyboardShortcut/hooks/useKeyboardShortcut";
import { KeyboardShortcutEnum } from "../../../../../common/modules/keyboardShortcut/hooks/enums/KeyboardShortcutEnum";
import { initiativeActions } from "../../../slices/initiativeSlice";
import {
  useDispatchEvent,
  useSubscribeEvent,
} from "../../../../../common/modules/eventProvider";
import { EventEnum } from "../../../../../common/modules/eventProvider/enums/EventEnum";
import { ConfirmationButton } from "../../../../../common/modules/materialUI";
import { useDeleteInitiativeService } from "../../../services/initiative/useDeleteInitiativeService";
import { THttpClientError } from "../../../../../common/modules/httpClient/types/THttpClientError";
import useMedia from "../../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../../common/hooks/useMedia/enums/QueryEnum";
import DateColumn from "./Columns/DateColumn";
import ProgressColumn from "./Columns/ProgressColumn";
import MainData from "./Columns/MainData";
import { useUpdateInitiativeService } from "../../../services/initiative/useUpdateInitiativeService";
import { systemNotificationActions } from "../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { useFetchOrgInitDataService } from "../../../../org/services/useFetchOrgInitDataService";

export default function Initiatives({
  objective,
  tabIndex,
  isMobileView,
}: TInitiativesProps) {
  const matchesMax576Screen = useMedia(QueryEnum.MAX_WIDTH_576);
  const dispatch = useAppDispatch();
  const dispatchEvent = useDispatchEvent();
  const theme = useTheme();
  const { CountsPortalComponent, ActionsPortalComponent } =
    usePortalTabActions(tabIndex);

  const list = useAppSelector((state) => state.initiative.list);
  const { findProjectByRef } = useOrgProjectData();

  const { dispatch: dispatchFetchInitiativeList, isLoading } =
    useFetchInitiativeListService(objective.id);
  const { dispatch: dispatchDeleteInitiativeList, isLoading: isDeleting } =
    useDeleteInitiativeService(objective.id);
  const { dispatch: dispatchUpdateInitiativeService, isLoading: isUnlinking } =
    useUpdateInitiativeService(objective.id);
  const { dispatch: dispatchFetchOrgInitDataService } =
    useFetchOrgInitDataService();

  useKeyboardShortcut({
    key: KeyboardShortcutEnum.i,
    callback: () => addInitiativeHandler(),
  });

  useKeyboardShortcut({
    key: KeyboardShortcutEnum.l,
    callback: () => linkInitiativeHandler(),
  });

  useEffect(() => {
    loadInitiativesHandler();
  }, []);

  useSubscribeEvent(EventEnum.ON_INITIATIVE_CREATED, () => {
    loadInitiativesHandler();
    dispatchFetchOrgInitDataService();
  });

  useSubscribeEvent(EventEnum.ON_INITIATIVE_UPDATED, () => {
    loadInitiativesHandler();
    dispatchFetchOrgInitDataService();
  });

  const loadInitiativesHandler = () => dispatchFetchInitiativeList();

  const linkInitiativeHandler = () => {
    dispatch(initiativeActions.openCreateModal("linkExisting"));
  };

  const addInitiativeHandler = () => {
    dispatch(initiativeActions.openCreateModal("createNew"));
  };
  const updateInitiativeHandler = (initiative: IData) => {
    dispatch(initiativeActions.openUpdateModal(initiative));
  };

  const linkInitiativeToBoardHandler = (initiative: IData) => {
    dispatch(initiativeActions.openLinkModal(initiative));
  };

  const addInitiativeNotes = (initiative: IData) => {
    dispatch(initiativeActions.openNotesModal(initiative));
  };

  const unlinkInitiativeHandler = (initiativeId: string) => {
    dispatchUpdateInitiativeService({
      body: {
        isActive: false,
        projectRef: 0,
      },
      urlPath: initiativeId,
    })
      .then(() => {
        loadInitiativesHandler();
      })
      .catch((error: THttpClientError) => {
        dispatch(
          systemNotificationActions.open({
            variant: "error",
            message: "Unable to update the initiative. Please try again.",
          })
        );
      });
  };

  const deleteInitiativeHandler = (initiative: IData) => {
    dispatchDeleteInitiativeList({ urlPath: initiative.id })
      .then(() => {
        loadInitiativesHandler();
        initiative.boardRef &&
          setTimeout(dispatchFetchOrgInitDataService, 1000);
        dispatchEvent(EventEnum.ON_INITIATIVE_DELETED);
      })
      .catch((error: THttpClientError) => {
        console.log(error);
      });
  };

  const disabled = isLoading || isDeleting || isUnlinking;

  return (
    <>
      {CountsPortalComponent(
        <>{list.length > 0 ? list.length : 0} Initiatives</>
      )}
      {ActionsPortalComponent(
        <Box display="flex" gap={2}>
          <Typography
            color="#818692"
            fontSize="13px"
            display="flex"
            alignItems="center"
            gap="4px"
            sx={{ cursor: "pointer" }}
            onClick={linkInitiativeHandler}
          >
            <AddLinkOutlined fontSize="small" />
            Link {!isMobileView && "Board"}
          </Typography>

          <Typography
            color="#818692"
            fontSize="13px"
            display="flex"
            alignItems="center"
            gap="4px"
            sx={{ cursor: "pointer" }}
            onClick={addInitiativeHandler}
          >
            <Add fontSize="small" />
            Add {!isMobileView && "Initiative"}
          </Typography>
        </Box>
      )}

      <Grid container gap="16px">
        {Object.entries(array.groupBy(list, "projectRef")).map(
          ([projectRef, data]) => {
            const projectName = findProjectByRef(projectRef)?.project.name;
            const items = data as IData[];

            return (
              <Grid container size={12} flexDirection="column" key={projectRef}>
                <Grid
                  container
                  bgcolor={theme.palette.taskView.headerTitleBackground}
                  color="#818692"
                  p="4px 8px"
                  alignItems="center"
                  gap="8px"
                >
                  <Typography
                    fontSize="13px"
                    sx={{ textTransform: "capitalize" }}
                  >
                    {projectName ? (
                      <>
                        Linked boards of{" - "}
                        <strong>{projectName}</strong>
                      </>
                    ) : (
                      "Initiatives with no boards linked"
                    )}
                  </Typography>
                </Grid>

                <Grid container flexDirection="column" gap="8px">
                  {items.map((initiative, index) => (
                    <Grid
                      key={initiative.id}
                      container
                      p="2px 8px"
                      alignItems="center"
                      justifyContent="space-between"
                      gap={matchesMax576Screen ? 0 : "32px"}
                    >
                      <Grid
                        flex={1}
                        minWidth={0}
                        display="flex"
                        alignItems="center"
                        gap="8px"
                      >
                        <MainData initiative={initiative} />
                      </Grid>

                      <Grid
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        gap={3}
                      >
                        {!matchesMax576Screen && (
                          <>
                            <DateColumn initiative={initiative} />
                            <ProgressColumn initiative={initiative} />
                          </>
                        )}

                        <Grid display="flex" alignItems="center" gap={1}>
                          {initiative.boardRef ? (
                            <ConfirmationButton
                              title="Unlink Board"
                              content="Are you sure you want to unlink the initiative board? Both elements will exist independently."
                              disabled={disabled}
                              button={
                                <LinkOffOutlined
                                  titleAccess={"Unlink board"}
                                  fontSize="small"
                                  style={{
                                    cursor: "pointer",
                                    opacity: disabled ? 0.5 : 1,
                                  }}
                                />
                              }
                              successHandler={() =>
                                unlinkInitiativeHandler(initiative.id)
                              }
                            />
                          ) : (
                            <AddLinkOutlined
                              titleAccess={"Link board"}
                              fontSize="small"
                              style={{
                                cursor: "pointer",
                                opacity: disabled ? 0.5 : 1,
                              }}
                              onClick={function () {
                                linkInitiativeToBoardHandler(initiative);
                              }}
                            />
                          )}
                          <StickyNote2
                            titleAccess={"Notes"}
                            fontSize="small"
                            style={{
                              cursor: "pointer",
                              opacity: disabled ? 0.5 : 1,
                            }}
                            onClick={function () {
                              addInitiativeNotes(initiative);
                            }}
                          />
                          <Settings
                            titleAccess={"Update"}
                            fontSize="small"
                            style={{
                              cursor: "pointer",
                              opacity: disabled ? 0.5 : 1,
                            }}
                            onClick={function () {
                              updateInitiativeHandler(initiative);
                            }}
                          />
                          <ConfirmationButton
                            title="Delete Initiative"
                            content={`Are you sure you want to delete this initiative? ${
                              initiative.boardRef
                                ? "Your linked board will auto archive."
                                : ""
                            }`}
                            disabled={disabled}
                            button={
                              <Delete
                                fontSize="small"
                                style={{
                                  cursor: "pointer",
                                  opacity: disabled ? 0.5 : 1,
                                }}
                              />
                            }
                            successHandler={() =>
                              deleteInitiativeHandler(initiative)
                            }
                          />
                        </Grid>
                      </Grid>
                      {matchesMax576Screen && (
                        <>
                          <Grid size={12} container flexDirection="column">
                            <DateColumn
                              initiative={initiative}
                              color={theme.palette.taskView.labelColor}
                              fontSize="11px"
                            />
                            <ProgressColumn
                              initiative={initiative}
                              fontSize="11px"
                            />
                          </Grid>
                          {items.length - 1 > index && (
                            <Divider
                              sx={{
                                backgroundColor:
                                  theme.palette.taskView
                                    .attachmentDividerBackground,
                                border: "none",
                                height: "1px",
                                width: "100%",
                                mt: "8px",
                              }}
                            />
                          )}
                        </>
                      )}
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            );
          }
        )}
      </Grid>
    </>
  );
}
