import { Box, Typography } from "@mui/material";

const ResourcesItem = ({
  assigneesLength,
  estimatedResources,
}: {
  assigneesLength?: number;
  estimatedResources?: number;
}) => {
  return (
    <Box display="flex" gap={1}>
      <Typography variant="caption">Resources:</Typography>
      <Typography
        component="strong"
        variant="caption"
        sx={{
          color:
            assigneesLength! < estimatedResources!
              ? "warning.main"
              : assigneesLength! === estimatedResources!
              ? "primary.main"
              : "text.primary",
        }}
      >
        {" "}
        {assigneesLength ?? 0} / {estimatedResources}
      </Typography>
    </Box>
  );
};
export default ResourcesItem;
