import { TCreateFormProps } from "./types/TCreateFormProps";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import useForm from "../../../../common/hooks/useForm/useForm";
import Form from "../../../../common/components/Form/Form";
import { THttpClientError } from "../../../../common/modules/httpClient/types/THttpClientError";
import { ITask } from "../../../../entities/ITask";
import { useCreateTaskService } from "../../services/useCreateTaskService";
import { useUserListService } from "../../../user/services/useUserListService";
import {
  Autocomplete,
  Button,
  DatePicker,
  TextField,
} from "../../../../common/modules/materialUI";
import { useDispatchEvent } from "../../../../common/modules/eventProvider";
import { EventEnum } from "../../../../common/modules/eventProvider/enums/EventEnum";
import { EstimationUomEnum } from "../../../../entities/enums/EstimationUomEnum";
import { FormControl, FormHelperText } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { TCreateFormAttributesTransformed } from "./types/TCreateFormAttributesTransformed";
import { TCreateFormAttributes } from "./types/TCreateFormAttributes";
import { enumUtil } from "../../../../common/utils/enum";
import { orgActions } from "../../../org/slices/orgSlice";
import { useFetchCustomFieldsByBoardRefService } from "../../../customField/services/useFetchCustomFieldsByBoardRefService";
import { useEffect } from "react";
import CustomFieldInput from "../../../customField/containers/CustomFieldInput/CustomFieldInput";
import { customFieldInputUtil } from "../../../customField/utils/customFieldInputUtil";
import { useFetchEntityFieldDefaultValuesByBoardRefService } from "../../services/useFetchEntityFieldDefaultValuesByBoardRefService";
import { entityFieldDefaultValueUtil } from "../../utils/entityFieldDefaultValueUtil";
import { systemNotificationActions } from "../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import TaskTypeAutocomplete from "../../../taskType/containers/TaskTypeAutocomplete/TaskTypeAutocomplete";
import TaskPriorityAutocomplete from "../../../taskPriority/containers/TaskPriorityAutocomplete/TaskPriorityAutocomplete";
import useMedia from "../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../common/hooks/useMedia/enums/QueryEnum";
import useTaskType from "../../../../common/modules/task/hooks/useTaskType";
import useTaskPriority from "../../../../common/modules/task/hooks/useTaskPriority";
import { StartDateTypeEnum } from "../../enums/StartDateTypeEnum";
import { TListItem } from "../../../../common/types/TListItem";
import { TListResource } from "../../../../common/types/TListResource";
import { InternalTypeEnum } from "../../../../entities/columns/task/InternalTypeEnum";
import { taskStartDateUtil } from "../../utils/taskStartDateUtil";

const CreateForm = (props: TCreateFormProps) => {
  const isTouchDevice = useMedia(QueryEnum.IS_TOUCH_DEVICE);
  const dispatch = useAppDispatch();
  const customs = useAppSelector((state) => state.customs);
  const dispatchEvent = useDispatchEvent();
  const form = useForm<TCreateFormAttributes>({
    boardRef: props.boardRef,
    assignedTo: [],
  });
  const { isLoading, dispatch: dispatchCreateTask } = useCreateTaskService();
  const { taskTypeAutocompleteData } = useTaskType();
  const { taskPriorityAutocompleteData } = useTaskPriority();
  const {
    taskTypes,
    taskTypeOptions,
    isLoading: isTaskTypesLoading,
  } = taskTypeAutocompleteData(props.boardRef);
  const {
    taskPriorities,
    taskPriorityOptions,
    isLoading: isTaskPrioritiesLoading,
  } = taskPriorityAutocompleteData(props.boardRef);
  const {
    isLoading: isCustomFieldsLoading,
    dispatch: dispatchFetchCustomFields,
    data: customFieldsResource,
  } = useFetchCustomFieldsByBoardRefService({ boardRef: props.boardRef });
  const {
    isLoading: isTaskFieldDefaultValuesLoading,
    dispatch: dispatchFetchTaskFieldDefaultValues,
    data: taskFieldDefaultValuesResource,
  } = useFetchEntityFieldDefaultValuesByBoardRefService();

  const userListService = useUserListService();
  const monitorList = useAppSelector((state) => state.listing.monitorList);

  const renderOptionalFields = props.renderOptionalFields ?? true;

  const isDefaultDataLoading =
    isCustomFieldsLoading ||
    isTaskFieldDefaultValuesLoading ||
    isTaskTypesLoading ||
    isTaskPrioritiesLoading;

  const selectedAssignedTo: TListResource<number> = form.get("assignedTo");
  const selectedStartDateType: TListItem<string> | null =
    form.get("startDateType");
  const startDateTypeHelperText = taskStartDateUtil.getStartDateTypeHelperText(
    selectedStartDateType
  );
  const isStartDateTypeOnSelectedDate =
    selectedStartDateType?.id === StartDateTypeEnum.ON_SELECTED_DATE;

  useEffect(() => {
    // If assignedTo is not set then start date fields must be empty
    if (!selectedAssignedTo.length) {
      form.changeHandler("startDateType", undefined);
      form.changeHandler("selectedStartDate", undefined);
    }

    // If start date type is changed, and it's not "On Selected Date" - change value for selectedStartDate as well.
    if (selectedStartDateType?.id !== StartDateTypeEnum.ON_SELECTED_DATE) {
      form.changeHandler("selectedStartDate", undefined);
    }
  }, [selectedAssignedTo?.length, selectedStartDateType?.id]);

  useEffect(() => {
    dispatchFetchCustomFields();
    dispatchFetchTaskFieldDefaultValues({ urlPath: props.boardRef });
  }, []);

  useEffect(() => {
    if (isDefaultDataLoading || !taskFieldDefaultValuesResource) {
      return;
    }

    form.changeDefaultData({
      boardRef: props.boardRef,
      ...entityFieldDefaultValueUtil.getFormDefaultValues(
        taskFieldDefaultValuesResource,
        taskTypeOptions,
        taskPriorityOptions,
        customFieldsResource?.customFields
      ),
    });
  }, [isDefaultDataLoading]);

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();

    dispatchCreateTask({
      body: form.getTransformed<TCreateFormAttributesTransformed>((data) => {
        return {
          ...data,
          internalType: props.internalType,
          // Making sure not to pass this field if it's empty
          taskTypeId: data.taskTypeId?.id,
          taskPriorityId: data.taskPriorityId?.id,
          estimationPoints: !!(data.estimationPoints ?? null)
            ? data.estimationPoints
            : undefined,
          goalRef: data.goalRef?.id,
          estimationUom: data.estimationUom?.id,
          rep: data.rep?.id,
          assignedTo: data.assignedTo?.length
            ? data.assignedTo.map((assignedToItem) => assignedToItem.id)
            : undefined,
          startDateType: data.startDateType?.id,
          selectedStartDate: data.selectedStartDate
            ?.startOf("day")
            .format("DD-MM-YYYY HH:mm")
            .toString(),
          ...customFieldInputUtil.getCustomFieldFormAttributesTransformed(
            customFieldsResource?.customFields ?? [],
            data
          ),
        };
      }),
    })
      .then((payload: ITask[]) => {
        dispatch(orgActions.setHasTasks(true));
        payload.forEach((task) => {
          dispatchEvent(EventEnum.ON_TASK_CREATED, task);
        });
        dispatch(
          systemNotificationActions.open({
            message: {
              type: "withLinks",
              text: "{link} has been created successfully",
              placeholders: {
                link: {
                  url: `/task/${payload[0].ref}`,
                  label: payload[0].ref,
                },
              },
            },
            variant: "success",
          })
        );
      })
      .catch((error: THttpClientError) => {
        form.errorHandler(error);
      });
  };

  return (
    <Form
      onSubmit={submitHandler}
      actionElement={
        <>
          <Button
            onClick={props.onCancel}
            disabled={isLoading}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            disabled={isLoading || isDefaultDataLoading || !form.isDirty()}
            variant="contained"
            type="submit"
            loading={isLoading || isDefaultDataLoading}
          >
            Add
          </Button>
        </>
      }
    >
      <TextField
        forceFocus={!isTouchDevice}
        form={form}
        name="name"
        label="Summary"
        showRequired
      />

      {renderOptionalFields && !!monitorList?.length && (
        <Autocomplete
          form={form}
          name="goalRef"
          label={customs.monitorLabel}
          options={monitorList}
        />
      )}

      {renderOptionalFields && (
        <Grid container spacing={2}>
          <Grid
            size={{
              xs: 12,
              sm: 6,
            }}
          >
            <TaskTypeAutocomplete
              form={form}
              name="taskTypeId"
              loading={isTaskTypesLoading}
              options={taskTypeOptions}
              taskTypes={taskTypes}
            />
          </Grid>
          <Grid
            size={{
              xs: 12,
              sm: 6,
            }}
          >
            <TaskPriorityAutocomplete
              form={form}
              name="taskPriorityId"
              loading={isTaskPrioritiesLoading}
              options={taskPriorityOptions}
              taskPriorities={taskPriorities}
            />
          </Grid>
        </Grid>
      )}

      {renderOptionalFields && (
        <Grid container spacing={2}>
          <Grid
            size={{
              xs: 12,
              sm: 6,
            }}
          >
            <Autocomplete
              form={form}
              name="estimationUom"
              label="Estimation UOM"
              options={enumUtil.toList(EstimationUomEnum)}
            />
          </Grid>
          <Grid
            size={{
              xs: 12,
              sm: 6,
            }}
          >
            <TextField form={form} name="estimationPoints" label="Estimation" />
          </Grid>
        </Grid>
      )}

      {renderOptionalFields && (
        <Autocomplete
          form={form}
          name="rep"
          label="Representative"
          service={userListService}
          options={
            form.data?.rep
              ? [{ id: form.data.rep.id, label: form.data.rep.label }]
              : []
          }
          textFieldProps={{
            helperText:
              "If no representative is specified, our system will automatically assign the project's designated representatives as the primary contacts for the task.",
          }}
        />
      )}

      {renderOptionalFields && (
        <Autocomplete
          form={form}
          name="assignedTo"
          label="Assigned To"
          multiple
          showRequired={props.internalType === InternalTypeEnum.SPIKE}
          service={userListService}
          options={form.data?.assignedTo}
          textFieldProps={{
            helperText:
              selectedAssignedTo.length > 1
                ? "Each segment estimation will be the same for each assignee"
                : undefined,
          }}
        />
      )}

      {renderOptionalFields && (
        <Grid container spacing={2}>
          <Grid
            size={{
              xs: 12,
              sm: isStartDateTypeOnSelectedDate ? 6 : 12,
            }}
          >
            <Autocomplete
              form={form}
              name="startDateType"
              label="Start Date"
              options={enumUtil.toList(StartDateTypeEnum)}
              textFieldProps={{
                helperText: !selectedAssignedTo.length
                  ? "Assignee is required to select start date."
                  : startDateTypeHelperText,
              }}
              disabled={!selectedAssignedTo.length}
            />
          </Grid>
          {isStartDateTypeOnSelectedDate && (
            <Grid
              size={{
                xs: 12,
                sm: 6,
              }}
            >
              <DatePicker
                form={form}
                name="selectedStartDate"
                label="Date"
                showRequired
              />
            </Grid>
          )}
        </Grid>
      )}

      {customFieldsResource?.customFields &&
        customFieldsResource?.customFields
          .filter(
            (customField) => renderOptionalFields || customField.isRequired
          )
          .map((customField) => (
            <CustomFieldInput
              key={customField.id}
              customField={customField}
              form={form}
              showLabel
            />
          ))}
      {form.hasError(
        customFieldInputUtil.customFieldsAttribute as keyof TCreateFormAttributes
      ) && (
        <FormControl error>
          <FormHelperText>
            {form.getError(
              customFieldInputUtil.customFieldsAttribute as keyof TCreateFormAttributes
            )}
          </FormHelperText>
        </FormControl>
      )}
    </Form>
  );
};

export default CreateForm;
