import { Fragment, useCallback, useEffect, useState } from "react";
import { Box, DialogContent, Typography } from "@mui/material";
import { Button, Dialog } from "../../../../common/modules/materialUI";
import {
  ArrowBack,
  ArrowForward,
  Description,
  YouTube,
} from "@mui/icons-material";
import { QueryEnum } from "../../../../common/hooks/useMedia/enums/QueryEnum";
import useMedia from "../../../../common/hooks/useMedia/useMedia";

import styles from "./styles.module.scss";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import { AssetTypeEnum } from "../../../../entities/columns/tip/AssetTypeEnum";
import { tipsSliderActions } from "../../slices/tipsSliderSlice";
import { ITip } from "../../../../entities/ITip";
import { useDispatchEvent } from "../../../../common/modules/eventProvider";
import { EventEnum } from "../../../../common/modules/eventProvider/enums/EventEnum";
import { systemNotificationActions } from "../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import useWindow from "../../../../common/hooks/useWindow";

const sliderAnimation = "0.5s ease-in forwards";

type TProps = {
  slugPrefix: string;
  tips: ITip[];
  learningLater: boolean;
};

const TipsSlider = ({ tips, slugPrefix, learningLater }: TProps) => {
  const { openNewTab } = useWindow();
  const { openTipsSlider } = useAppSelector((state) => state.tipsSlider);
  const dispatch = useAppDispatch();
  const dispatchEvent = useDispatchEvent();
  const maxWidth768 = useMedia(QueryEnum.MAX_WIDTH_768);

  const [sliderElements, setSliderElements] = useState<HTMLDivElement[]>([]);

  const initialTip = tips.find((tip) => !tip.seen) ?? tips[0];
  const [activeTipIndex, setTipActiveIndex] = useState(
    tips.findIndex((t) => t.slug === initialTip.slug)!
  );
  const [newSeenSlugs, setNewSeenSlugs] = useState<string[]>([]);

  const setNewSeenSlugsHandler = (newElement: ITip) => {
    // This tip has not been seen before, marking as seen
    !newElement.seen &&
      setNewSeenSlugs((prev) =>
        prev.includes(newElement.slug) ? prev : [...prev, newElement.slug]
      );
  };

  const setSliderContainerRef = useCallback(
    (sliderContainer: HTMLDivElement) => {
      if (sliderContainer) {
        const currentSliderElements = Array.from(
          sliderContainer.querySelectorAll(`[data-id="slider-element"]`)
        ) as HTMLDivElement[];

        if (!sliderElements.length) {
          setSliderElements(currentSliderElements);
        }

        const handleResize = () => {
          let sliderElementsMaxHeight = 0;

          currentSliderElements.forEach((element) => {
            const elementHeight = element.offsetHeight;
            if (elementHeight > sliderElementsMaxHeight) {
              sliderElementsMaxHeight = elementHeight;
            }
          });

          sliderContainer.style.height = `${sliderElementsMaxHeight}px`;
        };

        handleResize();

        window.addEventListener("resize", handleResize);
      }
    },
    []
  );

  useEffect(() => {
    setNewSeenSlugsHandler(initialTip);
  }, []);

  if (!openTipsSlider) {
    return null;
  }

  const closeHandler = () => {
    dispatch(tipsSliderActions.closeTipsSlider());
    newSeenSlugs.length > 0 &&
      dispatchEvent(EventEnum.ON_SEEN_TIP_FEATURES, {
        slugPrefix,
        slugs: newSeenSlugs,
      });
    setNewSeenSlugs([]);
  };

  const allSeen =
    !learningLater &&
    tips.filter((tip) => tip.seen).length + newSeenSlugs.length === tips.length;

  const slideHandler = (newIndex: number) => {
    // Either it is same slide or bigger 1 or smaller 1
    const newElement = tips[newIndex];
    if (newIndex === activeTipIndex || !newElement) {
      return;
    }

    let class1 = styles.prev1;
    let class2 = styles.prev2;
    // Moving right
    if (newIndex > activeTipIndex) {
      class1 = styles.next1;
      class2 = styles.next2;
    }

    // Old element animation
    sliderElements[
      activeTipIndex
    ].style.animation = `${class1} ${sliderAnimation}`;
    // New element animation
    sliderElements[newIndex].style.animation = `${class2} ${sliderAnimation}`;

    // This tip has not been seen before, marking as seen
    setNewSeenSlugsHandler(newElement);
    // Change the current one to be active
    setTipActiveIndex(newIndex);
  };

  return (
    <Dialog
      open={openTipsSlider}
      onClose={closeHandler}
      fullWidth
      maxWidth="md"
      PaperProps={{
        sx: {
          padding: maxWidth768 ? "8px" : "20px",
          margin: maxWidth768 ? "8px" : 0,
          width: maxWidth768 ? "100%" : undefined,
          borderRadius: 3,
          background: "backgroundElevation.backgroundPaperElevation_8",
        },
      }}
    >
      <DialogContent
        sx={{
          p: 0,
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <Box ref={setSliderContainerRef} position="relative" overflow="hidden">
          {tips.map(
            (
              { assetType, assetUrl, title, description, metadata, slug },
              index
            ) => (
              <Box
                key={index}
                data-id="slider-element"
                width="100%"
                position="absolute"
                display="flex"
                flexDirection="column"
                {...(index !== activeTipIndex && {
                  top: 0,
                  left: "-100%",
                })}
                sx={{
                  overflowY: "auto",
                }}
              >
                <div className={styles.videoContainer}>
                  <img
                    src={metadata?.videoPosterImage}
                    alt={title}
                    className={styles.poster}
                  />
                  {AssetTypeEnum.VIDEO === assetType && (
                    <video key={slug} autoPlay loop playsInline muted>
                      {activeTipIndex === index && (
                        <source src={assetUrl} type="video/mp4" />
                      )}
                    </video>
                  )}
                </div>

                <Box p="20px 20px 2px">
                  <Typography variant="h6" mb="4px">
                    {title}
                  </Typography>
                  <Typography variant="body1" mb="13px">
                    {description}
                  </Typography>

                  {metadata?.links?.map((link, index) => (
                    <Fragment key={index}>
                      {link.docsLink && (
                        <Button
                          color="text"
                          size="small"
                          variant="outlined"
                          sx={{ mr: "12px", my: "8px" }}
                          startIcon={<Description />}
                          onClick={() => openNewTab(link.docsLink!)}
                        >
                          {link.name} Docs
                        </Button>
                      )}

                      {link.youtubeLink && (
                        <Button
                          color="text"
                          size="small"
                          variant="outlined"
                          sx={{ mr: "12px", my: "8px" }}
                          startIcon={<YouTube />}
                          onClick={() => openNewTab(link.youtubeLink!)}
                        >
                          {link.name} Youtube
                        </Button>
                      )}
                    </Fragment>
                  ))}
                </Box>
              </Box>
            )
          )}
        </Box>

        <Box
          alignSelf="center"
          p="6px"
          display="flex"
          alignItems="center"
          gap="8px"
          borderRadius={5}
          bgcolor="action.disabledBackground"
        >
          <Button
            color="text"
            variant="text"
            size="small"
            sx={{
              p: 0,
            }}
            startIcon={<ArrowBack sx={{ fontSize: "25px !important" }} />}
            onClick={() => slideHandler(activeTipIndex - 1)}
          />

          {tips.map((tip, index) => (
            <Box
              key={index}
              bgcolor={
                tip.seen || newSeenSlugs.includes(tip.slug)
                  ? "primary.main"
                  : "action.active"
              }
              borderRadius="50%"
              height="11px"
              width="11px"
              sx={(theme) => ({
                cursor: "pointer",
                transition: "background-color 0.6s ease",
                WebkitTapHighlightColor: "transparent",
                outline:
                  index === activeTipIndex
                    ? `1px solid ${theme.palette.text.main}`
                    : undefined,
              })}
              onClick={() => slideHandler(index)}
            ></Box>
          ))}

          <Button
            color="text"
            variant="text"
            size="medium"
            sx={{
              p: 0,
              px: "2px",
              "& .MuiButton-endIcon": {
                m: 0,
              },
            }}
            {...(!allSeen && {
              endIcon: (
                <ArrowForward sx={{ fontSize: "25px !important", m: 0 }} />
              ),
            })}
            onClick={() => {
              if (allSeen) {
                closeHandler();
                dispatch(
                  systemNotificationActions.open({
                    message:
                      "You can revisit these tips anytime in the Info Center whenever you return to this page.",
                    variant: "info",
                  })
                );
                return;
              }
              slideHandler(activeTipIndex + 1);
            }}
          >
            {allSeen ? "Done!" : "Next"}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default TipsSlider;
