import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ITip } from "../../../entities/ITip";

type TState = {
  openTipsSlider: boolean;
  featureData: {
    name?: string;
    data: ITip[];
    allSeen?: boolean;
  };
};

const initialState: TState = {
  openTipsSlider: false,
  featureData: {
    data: [],
  },
};

const slice = createSlice({
  name: "tipsSlider",
  initialState,
  reducers: {
    openTipsSlider: (state) => {
      state.openTipsSlider = true;
    },
    closeTipsSlider: (state) => {
      state.openTipsSlider = false;
    },
    setFeatureData: (state, action: PayloadAction<TState["featureData"]>) => {
      state.featureData.data = action.payload.data;
      state.featureData.allSeen = action.payload.allSeen;
      if (action.payload.name) {
        state.featureData.name = action.payload.name;
      }
    },
    clearFeatureData: (state) => {
      state.featureData = initialState.featureData;
    },
  },
});

export const tipsSliderReducer = slice.reducer;
export const tipsSliderActions = slice.actions;
