import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { TDropdownProps } from "./types/TDropdownProps";
import Loading from "../../../../components/Icon/Loading/Loading";
import { Badge, IconButton } from "@mui/material";
import Grid from "@mui/material/Grid2";

const Dropdown = <TFormAttributes extends unknown>(
  props: TDropdownProps<TFormAttributes>
) => {
  const {
    form,
    options,
    renderOption,
    loading,
    withoutBorder,
    hideEmpty,
    renderBadge,
    ...selectProps
  } = props;
  const { name, ...restProps } = selectProps;

  return (
    <Select
      value={(!!name && form?.get(name as keyof TFormAttributes)) ?? ""}
      displayEmpty
      disabled={loading}
      endAdornment={loading && <Loading />}
      {...restProps}
      sx={{
        ...{
          height: "30px",
          ...(withoutBorder && {
            "& .MuiOutlinedInput-notchedOutline": {
              border: 0,
            },
          }),
        },
        ...restProps.sx,
      }}
      onChange={(e, child) => {
        props.onChange && props.onChange(e, child);
        !!name && form?.changeHandler(name, e.target.value);
      }}
    >
      {(props.options ?? []).map((option, index) => (
        <MenuItem
          key={index}
          value={option.id}
          sx={
            hideEmpty && index === 0 && !!name && option.id === ""
              ? { display: "none" }
              : {}
          }
        >
          {renderOption?.(option) ?? (
            <Grid display="flex" justifyContent="end">
              {option.label}
              {renderBadge?.(option) && (
                <IconButton sx={{ display: "flex", justifyContent: "end" }}>
                  <Badge color="error" variant={"dot"}></Badge>
                </IconButton>
              )}
            </Grid>
          )}
        </MenuItem>
      ))}
    </Select>
  );
};

export default Dropdown;
