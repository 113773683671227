import { TAttachmentIdProps } from "./types/TAttachmentIdProps";
import Loading from "../../../../../common/components/Icon/Loading/Loading";
import { useAttachmentPreviewUrlService } from "../services/useAttachmentPreviewUrlService";
import { useEffect } from "react";

export default function Image({
  attachmentId,
  dialogCloseHandler,
}: TAttachmentIdProps) {
  const {
    isLoading,
    dispatch: dispatchAttachmentPreviewUrl,
    data,
  } = useAttachmentPreviewUrlService({
    attachmentId: attachmentId,
  });

  useEffect(() => {
    dispatchAttachmentPreviewUrl().catch(() => {
      dialogCloseHandler();
    });
  }, []);

  return (
    <>
      {isLoading && <Loading />}
      {!isLoading && data && (
        <img
          src={data.previewUrl}
          alt="attachment"
          style={{ maxWidth: "100%", maxHeight: "95%" }}
        />
      )}
    </>
  );
}
