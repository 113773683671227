import { GridColDef } from "@mui/x-data-grid";
import { typeLabels } from "../../../../entities/columns/customField/TypeEnum";
import { ICustomField } from "../../../../entities/ICustomField";
import { Chip, IconButton, Stack, Tooltip } from "@mui/material";
import { TColumnsProps } from "./types/TColumnsProps";
import { Delete, ModeEdit } from "@mui/icons-material";
import { projectUtil } from "../../../../common/utils/projectUtil";
import useMedia from "../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../common/hooks/useMedia/enums/QueryEnum";

export const Columns: (props: TColumnsProps) => GridColDef<ICustomField>[] = ({
  projects,
  onEditButtonClick,
  onDeleteButtonClick,
}) => {
  const projectsList = projectUtil.list(projects);
  const isTouchDevice = useMedia(QueryEnum.IS_TOUCH_DEVICE);
  const boolColumnMinWidth = !isTouchDevice ? 90 : 150;

  return [
    {
      field: "actions",
      headerName: "Actions",
      width: 90,
      hideable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="Edit Custom Field">
              <IconButton
                onClick={() => onEditButtonClick(params.row)}
                color="primary"
              >
                <ModeEdit color="primary" fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete Custom Field">
              <IconButton
                onClick={() => onDeleteButtonClick(params.row)}
                color="error"
              >
                <Delete color="error" fontSize="small" />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 200,
      maxWidth: 300,
      hideable: false,
    },
    {
      field: "isRequired",
      headerName: "Is Required",
      hideable: false,
      minWidth: boolColumnMinWidth,
      valueGetter: (_, row) => (row.isRequired ? "Yes" : "No"),
    },
    {
      disableColumnMenu: true,
      field: "projectIds",
      headerName: "Projects",
      flex: 1,
      minWidth: 300,
      maxWidth: 400,
      hideable: false,
      renderCell: (params) => {
        const projectIds = params.row.projectIds;

        if (!projectIds) {
          return <Chip size="small" label="All" />;
        }

        const rowProjectsList = projectsList.filter((project) =>
          projectIds.includes(project.id)
        );

        return (
          <Stack my={1} direction="row" spacing={1} useFlexGap flexWrap="wrap">
            {rowProjectsList.map((projectListItem) => (
              <Chip
                key={projectListItem.id}
                size="small"
                label={projectListItem.label}
              />
            ))}
          </Stack>
        );
      },
    },
    {
      field: "type",
      headerName: "Type",
      hideable: false,
      width: 100,
      valueGetter: (_, row) => typeLabels[row.type],
    },
    {
      field: "isMultiple",
      headerName: "Is Multiple",
      flex: 1,
      hideable: false,
      minWidth: boolColumnMinWidth,
      valueGetter: (_, row) => (row.isMultiple ? "Yes" : "No"),
    },
    {
      field: "isFilterable",
      headerName: "Is Filterable",
      flex: 1,
      hideable: false,
      minWidth: boolColumnMinWidth,
      valueGetter: (_, row) => (row.isFilterable ? "Yes" : "No"),
    },
    {
      field: "options",
      headerName: "Options",
      flex: 1,
      minWidth: 350,
      maxWidth: 400,
      sortable: false,
      renderCell: (params) => {
        const options = params.row.data?.options;

        if (!options) {
          return null;
        }

        return (
          <Stack my={1} direction="row" spacing={1} useFlexGap flexWrap="wrap">
            {options.map((option) => (
              <Chip key={option.id} size="small" label={option.label} />
            ))}
          </Stack>
        );
      },
    },
    {
      field: "isActive",
      headerName: "Is Active",
      hideable: false,
      minWidth: boolColumnMinWidth,
      valueGetter: (_, row) => (row.isActive ? "Yes" : "No"),
    },
    {
      field: "createdAt",
      headerName: "Date Added",
      width: 170,
    },
    {
      field: "updatedAt",
      headerName: "Date Updated",
      width: 170,
    },
  ];
};
