import Title from "../../../../../common/components/Title/Title";
import { Button } from "../../../../../common/modules/materialUI";
import { TCheckoutFormProps } from "./types/TCheckoutFormProps";
import { useCreateStripeSessionService } from "../../../services/useCreateStripeSessionService";
import { IStripeSessionResource } from "../../../interfaces/IStripeSessionResource";

const CheckoutForm = (props: TCheckoutFormProps) => {
  const { isLoading, dispatch: dispatchCreateStripeSessionService } =
    useCreateStripeSessionService();

  const submitHandler = () => {
    dispatchCreateStripeSessionService({
      body: {
        subscriptionPlan: props.subscriptionPlan,
        email: props.orgEmail,
      },
    }).then((resource: IStripeSessionResource) => {
      window.location.replace(resource.url);
    });
  };

  return (
    <>
      <Title>
        You will be redirected to the Stripe payment page to complete the
        registration.
      </Title>
      <Button
        variant="text"
        type="submit"
        size="large"
        onClick={submitHandler}
        disabled={isLoading}
        loading={isLoading}
      >
        Checkout
      </Button>
    </>
  );
};

export default CheckoutForm;
