import { ListItemIcon, Typography } from "@mui/material";
import { Logout } from "@mui/icons-material";
import { useSignOutHandler } from "../../hooks/useSignOutHandler";
import { menuItem } from "../../../../common/containers/Menu/utils/menuItem";

const SignOut = () => {
  const signOutHandler = useSignOutHandler();

  return menuItem.make({
    component: (
      <>
        <ListItemIcon>
          <Logout
            sx={{
              color: "error.light",
            }}
          />
        </ListItemIcon>
        <Typography variant="body2" color="error.main">
          Sign Out
        </Typography>
      </>
    ),
    handler: signOutHandler,
  });
};

export default SignOut;
