import useForm from "../../../../../../common/hooks/useForm/useForm";
import { TEntityFieldValuesFormAttributes } from "./types/TEntityFieldValuesFormAttributes";
import { TEntityFieldValuesFormProps } from "./types/TEntityFieldValuesFormProps";
import { useFetchCustomFieldsByBoardRefService } from "../../../../../customField/services/useFetchCustomFieldsByBoardRefService";
import Form from "../../../../../../common/components/Form/Form";
import {
  Autocomplete,
  Button,
  TextField,
} from "../../../../../../common/modules/materialUI";
import { useAppDispatch } from "../../../../../../common/hooks/redux";
import { FormControl, FormHelperText } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { enumUtil } from "../../../../../../common/utils/enum";
import { EstimationUomEnum } from "../../../../../../entities/enums/EstimationUomEnum";
import { useEffect, useState } from "react";
import { useUserListService } from "../../../../../user/services/useUserListService";
import CustomFieldInput from "../../../../../customField/containers/CustomFieldInput/CustomFieldInput";
import { customFieldInputUtil } from "../../../../../customField/utils/customFieldInputUtil";
import { useFetchEntityFieldDefaultValuesByBoardRefService } from "../../../../../task/services/useFetchEntityFieldDefaultValuesByBoardRefService";
import { useStoreEntityFieldDefaultValuesService } from "../../../../services/useStoreEntityFieldDefaultValuesService";
import { systemNotificationActions } from "../../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { THttpClientError } from "../../../../../../common/modules/httpClient/types/THttpClientError";
import { TEntityFieldValuesFormAttributesTransformed } from "./types/TEntityFieldValuesFormAttributesTransformed";
import { entityFieldDefaultValueUtil } from "../../../../../task/utils/entityFieldDefaultValueUtil";
import EntityFieldValuesFormSkeleton from "./EntityFieldValuesFormSkeleton";
import { IEntityFieldDefaultValue } from "../../../../../../entities/IEntityFieldDefaultValue";
import Section from "../../../../../../common/components/Section/Section";
import CloneFromBoard from "./CloneFromBoard";
import TaskTypeAutocomplete from "../../../../../taskType/containers/TaskTypeAutocomplete/TaskTypeAutocomplete";
import TaskPriorityAutocomplete from "../../../../../taskPriority/containers/TaskPriorityAutocomplete/TaskPriorityAutocomplete";
import useTaskType from "../../../../../../common/modules/task/hooks/useTaskType";
import useTaskPriority from "../../../../../../common/modules/task/hooks/useTaskPriority";
import { BoardTypeEnum } from "../../../../../../entities/columns/board/BoardTypeEnum";

const EntityFieldValuesForm = ({
  board,
  projectAbbr,
  entityLabel,
}: TEntityFieldValuesFormProps) => {
  const dispatch = useAppDispatch();
  const [isFormReady, setIsFormReady] = useState<boolean>(false);
  const boardRef = board.ref!;
  const form = useForm<TEntityFieldValuesFormAttributes>({
    assignedTo: [],
  });
  const userListService = useUserListService();
  const {
    isLoading: isCustomFieldsLoading,
    dispatch: dispatchFetchCustomFields,
    data: customFieldsResource,
  } = useFetchCustomFieldsByBoardRefService({ boardRef });
  const {
    isLoading: isEntityFieldDefaultValuesLoading,
    dispatch: dispatchFetchEntityFieldDefaultValues,
    data: entityFieldDefaultValuesResource,
  } = useFetchEntityFieldDefaultValuesByBoardRefService();
  const { isLoading: isStoreLoading, dispatch: dispatchStore } =
    useStoreEntityFieldDefaultValuesService();
  const { taskTypeAutocompleteData } = useTaskType();
  const { taskPriorityAutocompleteData } = useTaskPriority();
  const {
    taskTypes,
    taskTypeOptions,
    isLoading: isTaskTypesLoading,
  } = taskTypeAutocompleteData(boardRef);
  const {
    taskPriorities,
    taskPriorityOptions,
    isLoading: isTaskPrioritiesLoading,
  } = taskPriorityAutocompleteData(boardRef);
  const requiredCustomFields = customFieldsResource?.customFields?.filter(
    (customField) => customField.isRequired
  );
  const optionalCustomFields = customFieldsResource?.customFields?.filter(
    (customField) => !customField.isRequired
  );

  // General data loading
  const isFormDataLoading =
    isCustomFieldsLoading ||
    isEntityFieldDefaultValuesLoading ||
    isTaskTypesLoading ||
    isTaskPrioritiesLoading;

  useEffect(() => {
    if (board) {
      dispatchFetchCustomFields();
      dispatchFetchEntityFieldDefaultValues({ urlPath: boardRef });
    }
  }, [board]);

  const setFormDefaultValues = (
    isInit: boolean,
    entityFieldDefaultValues?: IEntityFieldDefaultValue[]
  ) => {
    if (!entityFieldDefaultValues) {
      return;
    }

    const formData = entityFieldDefaultValueUtil.getFormDefaultValues(
      entityFieldDefaultValues,
      taskTypeOptions,
      taskPriorityOptions,
      customFieldsResource?.customFields
    );
    // Setting default values
    isInit ? form.changeDefaultData(formData) : form.set(formData);
  };

  useEffect(() => {
    if (isFormDataLoading) {
      return;
    }
    setFormDataHandler(true, entityFieldDefaultValuesResource);
  }, [isFormDataLoading]);

  const setFormDataHandler = (
    isInit: boolean,
    entityFieldDefaultValues: IEntityFieldDefaultValue[]
  ) => {
    setFormDefaultValues(isInit, entityFieldDefaultValues);
    // Ready to load the form
    setIsFormReady(true);
  };

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();

    dispatchStore({
      body: form.getTransformed<TEntityFieldValuesFormAttributesTransformed>(
        (data) => ({
          boardRef: boardRef,
          taskTypeId: data.taskTypeId?.id,
          taskPriorityId: data.taskPriorityId?.id,
          estimationPoints: !!(data.estimationPoints ?? null)
            ? data.estimationPoints
            : undefined,
          estimationUom: data.estimationUom?.id,
          rep: data.rep?.id,
          assignedTo: data.assignedTo?.length
            ? data.assignedTo.map((assignedToItem) => assignedToItem.id)
            : undefined,
          ...customFieldInputUtil.getCustomFieldFormAttributesTransformed(
            customFieldsResource?.customFields ?? [],
            data,
            false
          ),
        })
      ),
    })
      .then(() => {
        form.notDirty();
        dispatch(
          systemNotificationActions.open({
            message: "Stored successfully",
            variant: "success",
          })
        );
      })
      .catch((error: THttpClientError) => {
        if (error.status === 422) {
          form.errorHandler(error);
          return;
        } else if (error.status === 403) {
          dispatch(
            systemNotificationActions.open({
              message: error.data.message,
              variant: "warning",
            })
          );
          return;
        }

        dispatch(
          systemNotificationActions.open({
            message:
              "There was a problem with storing the task field values. Please try again.",
            variant: "error",
          })
        );
      });
  };

  return (
    <>
      {!isFormReady && <EntityFieldValuesFormSkeleton />}
      {isFormReady && (
        <>
          <CloneFromBoard
            onDataLoaded={(entityFieldDefaultValues) => {
              setFormDataHandler(false, entityFieldDefaultValues);
            }}
            board={board}
            projectAbbr={projectAbbr}
          />
          <Form
            onSubmit={submitHandler}
            actionElement={
              <Button
                disabled={
                  isStoreLoading || isFormDataLoading || !form.isDirty()
                }
                variant="contained"
                type="submit"
                loading={isStoreLoading}
              >
                Save
              </Button>
            }
          >
            <Grid container spacing={2} direction="row">
              {board && board.type !== BoardTypeEnum.BACKLOG && (
                <Grid
                  size={{
                    xl: 4,
                    lg: 6,
                    xs: 12,
                  }}
                >
                  <Section
                    title="Task main fields"
                    subtitle="Main fields of the task, if filled with attributes common to all tasks in this board, can greatly facilitate the task creation process."
                  >
                    <Grid container direction="column" spacing={2}>
                      <Grid container spacing={2}>
                        <Grid
                          size={{
                            xs: 12,
                            sm: 6,
                          }}
                        >
                          <TaskTypeAutocomplete
                            form={form}
                            name="taskTypeId"
                            loading={isTaskTypesLoading}
                            options={taskTypeOptions}
                            taskTypes={taskTypes}
                          />
                        </Grid>
                        <Grid
                          size={{
                            xs: 12,
                            sm: 6,
                          }}
                        >
                          <TaskPriorityAutocomplete
                            form={form}
                            name="taskPriorityId"
                            loading={isTaskPrioritiesLoading}
                            options={taskPriorityOptions}
                            taskPriorities={taskPriorities}
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid
                          size={{
                            xs: 12,
                            sm: 6,
                          }}
                        >
                          <Autocomplete
                            form={form}
                            name="estimationUom"
                            label="Estimation UOM"
                            options={enumUtil.toList(EstimationUomEnum)}
                          />
                        </Grid>
                        <Grid
                          size={{
                            xs: 12,
                            sm: 6,
                          }}
                        >
                          <TextField
                            form={form}
                            name="estimationPoints"
                            label="Estimation"
                          />
                        </Grid>
                      </Grid>

                      <Grid>
                        <Autocomplete
                          form={form}
                          name="rep"
                          label="Representative"
                          service={userListService}
                          options={
                            form.data?.rep
                              ? [
                                  {
                                    id: form.data.rep.id,
                                    label: form.data.rep.label,
                                  },
                                ]
                              : []
                          }
                        />
                      </Grid>

                      <Grid>
                        <Autocomplete
                          form={form}
                          name="assignedTo"
                          label="Assigned To"
                          multiple
                          service={userListService}
                          options={form.data?.assignedTo}
                        />
                      </Grid>
                    </Grid>
                  </Section>
                </Grid>
              )}

              {board && board.type === BoardTypeEnum.BACKLOG && (
                <Grid
                  size={{
                    xl: 4,
                    lg: 6,
                    xs: 12,
                  }}
                >
                  <Section
                    title="Backlog Item main fields"
                    subtitle="Main fields of the backlog item, if filled with attributes common to all backlog items in this board, can greatly facilitate the backlog item creation process."
                  >
                    <Grid container direction="column" spacing={2}>
                      <Grid container spacing={2}>
                        <Grid
                          size={{
                            xs: 12,
                            sm: 6,
                          }}
                        >
                          <TaskTypeAutocomplete
                            form={form}
                            name="taskTypeId"
                            loading={isTaskTypesLoading}
                            options={taskTypeOptions}
                            taskTypes={taskTypes}
                          />
                        </Grid>
                        <Grid
                          size={{
                            xs: 12,
                            sm: 6,
                          }}
                        >
                          <TaskPriorityAutocomplete
                            form={form}
                            name="taskPriorityId"
                            loading={isTaskPrioritiesLoading}
                            options={taskPriorityOptions}
                            taskPriorities={taskPriorities}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Section>
                </Grid>
              )}

              {!!requiredCustomFields?.length && (
                <Grid
                  size={{
                    xl: 4,
                    lg: 6,
                    xs: 12,
                  }}
                >
                  <Section
                    title={`${entityLabel} custom required fields`}
                    subtitle={`Fields in this section are required for every ${entityLabel.toLowerCase()} creation. Pre-populating them with default values will streamline ${entityLabel.toLowerCase()} creation.`}
                  >
                    <Grid container direction="column" spacing={2}>
                      {requiredCustomFields.map((customField) => (
                        <Grid key={customField.id}>
                          <CustomFieldInput
                            customField={customField}
                            allowEmptyValue
                            form={form}
                            showLabel
                            hideRequiredMark
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Section>
                </Grid>
              )}

              {!!optionalCustomFields?.length && (
                <Grid
                  size={{
                    xl: 4,
                    lg: 6,
                    xs: 12,
                  }}
                >
                  <Section
                    title={`${entityLabel} custom optional fields`}
                    subtitle={
                      board.type !== BoardTypeEnum.BACKLOG
                        ? `Custom optional fields, while not required, can reduce repetitive work if filled once for tasks across the board.`
                        : `Custom optional fields, while not required, can reduce repetitive work if filled once for backlog items.`
                    }
                  >
                    <Grid container direction="column" spacing={2}>
                      {optionalCustomFields.map((customField) => (
                        <Grid key={customField.id}>
                          <CustomFieldInput
                            customField={customField}
                            allowEmptyValue
                            form={form}
                            showLabel
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Section>
                </Grid>
              )}
            </Grid>
            {form.hasError(
              customFieldInputUtil.customFieldsAttribute as keyof TEntityFieldValuesFormAttributes
            ) && (
              <FormControl error>
                <FormHelperText>
                  {form.getError(
                    customFieldInputUtil.customFieldsAttribute as keyof TEntityFieldValuesFormAttributes
                  )}
                </FormHelperText>
              </FormControl>
            )}
          </Form>
        </>
      )}
    </>
  );
};

export default EntityFieldValuesForm;
