import { TextField } from "../../../../../../common/modules/materialUI";
import { TPasswordFieldProps } from "./types/TPasswordFieldProps";
import { useState } from "react";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const PasswordField = <TFormAttributes extends unknown>(
  props: TPasswordFieldProps<TFormAttributes>
) => {
  const { showHint, ...restProps } = props;
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  return (
    <TextField
      name="password"
      label="Password"
      showRequired
      {...restProps}
      type={showPassword ? "text" : "password"}
      helperText={
        props.showHint
          ? "Use a mix of upper, lower, numeric, and symbolic characters (e.g. @, #, $), at least 8 characters long."
          : props.helperText
      }
      slotProps={{
        input: {
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="Toggle Password Visibility"
                onClick={handleClickShowPassword}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        },
      }}
    />
  );
};

export default PasswordField;
