import { useAppDispatch } from "../../../common/hooks/redux";
import { useSubscribeEvent } from "../../../common/modules/eventProvider";
import { EventEnum } from "../../../common/modules/eventProvider/enums/EventEnum";
import { orgActions } from "../../org/slices/orgSlice";
import { ITaskPriority } from "../../../entities/ITaskPriority";
import { taskPriorityActions } from "../slices/taskPrioritySlice";
import TaskPriorityGrid from "./TaskPriorityGrid/TaskPriorityGrid";
import CreateModal from "./CreateTaskPriority/CreateModal";
import TitleAndActionButton from "../../../common/components/Title/TitleAndActionButton";

const TaskPrioritiesPage = () => {
  const dispatch = useAppDispatch();

  useSubscribeEvent(
    EventEnum.ON_TASK_PRIORITY_CREATED,
    (taskPriority: ITaskPriority) => {
      dispatch(orgActions.addTaskPriority(taskPriority));
    }
  );
  useSubscribeEvent(
    EventEnum.ON_TASK_PRIORITY_UPDATED,
    (taskPriority: ITaskPriority) => {
      dispatch(orgActions.updateTaskPriority(taskPriority));
    }
  );
  useSubscribeEvent(
    EventEnum.ON_TASK_PRIORITY_DELETED,
    (taskPriorityId: number) => {
      dispatch(orgActions.deleteTaskPriority({ id: taskPriorityId }));
    }
  );

  return (
    <>
      <TitleAndActionButton
        title="Task Priorities"
        buttonText="Add Task Priority"
        onClick={() => dispatch(taskPriorityActions.openCreateModal())}
      />

      <TaskPriorityGrid />
      <CreateModal />
    </>
  );
};

export default TaskPrioritiesPage;
