import { TBacklogViewProps } from "./types/TBacklogViewProps";
import { useFetchGroupDataService } from "../services/useFetchGroupDataService";
import { useEffect } from "react";
import BacklogViewSkeleton from "./BacklogViewSkeleton";
import { Box, ButtonGroup, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import GroupSection from "./GroupSection/GroupSection";
import { useSubscribeEvent } from "../../../../../common/modules/eventProvider";
import { EventEnum } from "../../../../../common/modules/eventProvider/enums/EventEnum";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../common/hooks/redux";
import WelcomeBacklogBoard from "./WelcomeBacklogBoard/WelcomeBacklogBoard";
import { useBacklogBoardViewQueryParams } from "../hooks/useBacklogBoardViewQueryParams";
import Filter from "./Filter/Filter";
import SelectedFiltersView from "./SelectedFiltersView/SelectedFiltersView";
import Loader from "../../../../../common/containers/Loader/Loader";
import { backlogBoardQueryActions } from "../slices/backlogBoardQuerySlice";
import { boardViewActions } from "../../../slices/boardViewSlice";
import { IYearMonthGroupResource } from "../../../interfaces/IYearMonthGroupResource";
import { FirebaseEventEnum } from "../../../../../common/modules/firebase/hooks/types/FirebaseEventEnum";
import { FeatureEnum } from "../../../../../common/modules/accessControl/enums/FeatureEnum";
import { useFirebaseEventHelper } from "../../../../../common/modules/firebase/hooks/useFirebaseEventHelper";
import Sorting from "../../../containers/Filter/Filter/Sorting/Sorting";

const BacklogViewContent = ({ board, projectAbbr }: TBacklogViewProps) => {
  const { addToFirebaseQueue } = useFirebaseEventHelper();
  const dispatch = useAppDispatch();
  const queryParams = useBacklogBoardViewQueryParams();
  const filters = queryParams.filters;
  const filtersString = JSON.stringify(queryParams.filters);
  const {
    dispatch: dispatchFetchGroupDataService,
    data: groupServiceData,
    isLoading: isGroupDataLoading,
  } = useFetchGroupDataService({
    boardRef: board.ref!,
    query: {
      projectAbbr,
    },
  });
  const {
    resource: backlogGroupData,
    resourceUpdatedCount: groupDataFetchedCount,
    expandedGroups,
  } = useAppSelector((state) => state.boardView.backlogView.yearMonthGroup);

  const isInitialLoadComplete = groupDataFetchedCount > 0;
  const hasFilters = !!Object.keys(queryParams.filters ?? {}).length;
  const isEmptyStateData = !backlogGroupData?.length;
  const isEmptyServiceData = !groupServiceData?.length;

  useEffect(() => {
    addToFirebaseQueue(FirebaseEventEnum.FEATURE_ACCESS, {
      feature: FeatureEnum.BACKLOG_ICEBOX,
    });

    return () => {
      dispatch(backlogBoardQueryActions.reset());
      dispatch(boardViewActions.resetBacklogView());
    };
  }, [board.ref]);

  useEffect(() => {
    dispatchFetchGroupDataService({
      query: { filters },
    });
  }, [board.ref, filtersString]);

  useSubscribeEvent(
    EventEnum.ON_BACKLOG_ITEM_DELETED,
    () =>
      dispatchFetchGroupDataService({
        query: queryParams,
      }),
    [board.ref, filtersString]
  );
  useSubscribeEvent(
    EventEnum.ON_BACKLOG_ITEM_CREATED,
    () =>
      dispatchFetchGroupDataService({
        query: queryParams,
      }).then((resourceData: IYearMonthGroupResource[] | null) => {
        // If initially page doesn't have any data and one element is created, then making that group expanded by default
        if (
          !hasFilters &&
          resourceData?.length === 1 &&
          resourceData[0].itemsCount === 1
        ) {
          dispatch(
            boardViewActions.setBacklogYearMonthGroupExpanded({
              groupKey: resourceData[0].yearMonthGroup,
              isExpanded: true,
            })
          );
        }
      }),
    [board.ref, filtersString]
  );

  return (
    <>
      {isGroupDataLoading && <Loader />}

      {!isInitialLoadComplete && <BacklogViewSkeleton />}

      {!isGroupDataLoading &&
        isInitialLoadComplete &&
        !hasFilters &&
        isEmptyStateData &&
        isEmptyServiceData && <WelcomeBacklogBoard />}

      {/* Filters */}
      {isInitialLoadComplete && (hasFilters || !isEmptyStateData) && (
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          pb={1}
        >
          <Grid
            size={{
              lg: 9,
              sm: 8,
              xs: 9,
            }}
          >
            <SelectedFiltersView />
          </Grid>
          <Grid
            container
            justifyContent="flex-end"
            size={{
              lg: 3,
              sm: 4,
              xs: 3,
            }}
          >
            <Grid>
              <ButtonGroup variant="outlined" sx={{ borderColor: "divider" }}>
                <Filter />
                <Sorting actions={backlogBoardQueryActions} />
              </ButtonGroup>
            </Grid>
          </Grid>
        </Grid>
      )}

      {/* No data found */}
      {hasFilters && isEmptyStateData && (
        <Typography variant="body2" sx={{ textAlign: "center" }}>
          No data found for your filters
        </Typography>
      )}

      {/* Group data */}
      {backlogGroupData?.map((yearMonthGroupResource) => (
        <Box key={yearMonthGroupResource.yearMonthGroup} display="flex">
          <Box width="100%">
            <GroupSection
              board={board}
              projectAbbr={projectAbbr}
              yearMonthGroupResource={yearMonthGroupResource}
              expanded={
                expandedGroups?.[yearMonthGroupResource.yearMonthGroup] ?? false
              }
              queryParams={queryParams}
            />
          </Box>
        </Box>
      ))}
    </>
  );
};

export default BacklogViewContent;
