import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";
import { IReadOnlyAccessToken } from "../../../entities/IReadOnlyAccessToken";
import { useAppDispatch } from "../../../common/hooks/redux";
import { readOnlyAccessActions } from "../slices/readOnlyAccessSlice";
import { ReadOnlyAccessTypeEnum } from "../../../common/modules/accessControl/enums/ReadOnlyAccessTypeEnum";
import { useHttpClientDispatchable } from "../../../common/modules/httpClient/hooks/useHttpClientDispatchable";

export const useReadOnlyAccessListService = (props: {
  accessTypeRef: string;
  accessType: ReadOnlyAccessTypeEnum;
}) => {
  const dispatch = useAppDispatch();
  const response = useHttpClientDispatchable({
    url: `/readOnlyAccessToken/${props.accessType}/${props.accessTypeRef}/list`,
    method: RequestMethodEnum.GET,
    events: {
      beforeTransform: (data: IReadOnlyAccessToken[]) =>
        dispatch(readOnlyAccessActions.setList(data)),
    },
  });

  return {
    ...response,
    data: response.data as IReadOnlyAccessToken[],
  };
};
