import { TOccupiedSpaceProps } from "./types/TOccupiedSpaceProps";
import { Box, Typography } from "@mui/material";
import Link from "./Link";
import IconImage from "../Icon/IconImage/IconImage";

export default function OccupiedSpaceBig(props: TOccupiedSpaceProps) {
  return (
    <Box
      sx={{
        width: props.size,
        height: 110,
        backgroundColor: "background.default",
        color: "text.disabled",
        fontSize: 15,
        position: "relative",
        ...props.style,
      }}
    >
      <Link {...props}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            position: "relative",
            gap: "4px",
          }}
        >
          <IconImage
            img={"/app-v2/assets/Occupied-Icon-Dark.svg"}
            imgDark={"/app-v2/assets/Occupied-Icon-Light.svg"}
            size={16}
          />
          <Typography variant="body2">{props.time}</Typography>
        </Box>
        <Typography
          noWrap
          padding="0 5px"
          width="100%"
          textAlign="center"
          sx={{
            wordBreak: "break-word",
          }}
        >
          {props.taskRef}
        </Typography>
        <Typography variant="body2" textAlign="center">
          {props.project}
        </Typography>
      </Link>
    </Box>
  );
}
