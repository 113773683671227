import { useFetchUserListService } from "../../../services/useFetchUserListService";
import { Box, Typography } from "@mui/material";
import Loader from "../../../../../common/containers/Loader/Loader";
import { Columns } from "./Columns";
import { useEffect } from "react";
import InviteMember from "../../Invitation/InviteMember";
import { TMembersProps } from "./types/TMembersProps";
import { DataGrid } from "../../../../../common/modules/materialUI";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../common/hooks/redux";
import { teamActions } from "../../../slices/teamSlice";
import FinancialDataModal from "../../StoreFinancialData/FinancialDataModal";
import { useDropOrgInvitationService } from "../../../../user/services/useDropOrgInvitationService";
import { systemNotificationActions } from "../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { IUserResource } from "../../../interfaces/IUserResource";
import { useFetchOrgInitDataService } from "../../../../org/services/useFetchOrgInitDataService";
import CreateVirtualUser from "../../VirtualUser/CreateVirtualUser";
import ManageRoleMap from "../../RoleMap/ManageRoleMap";
import ConfirmationDialog from "../../../../../common/modules/materialUI/containers/ConfirmationDialog/ConfirmationDialog";
import { THttpClientError } from "../../../../../common/modules/httpClient/types/THttpClientError";
import { StatusEnum } from "../../../../../entities/columns/user/StatusEnum";
import { usePatchUserService } from "../../../services/usePatchUserService";
import { IPatchResource } from "../../../interfaces/IPatchResource";
import VerticalDivider from "../../../../../common/components/Divider/VerticalDivider";
import { useIsFeatureAllowed } from "../../../../../common/modules/accessControl/hooks/useIsFeatureAllowed";
import { FeatureEnum } from "../../../../../common/modules/accessControl/enums/FeatureEnum";
import { featureUtil } from "../../../../../common/modules/accessControl/utils/featureUtil";

const Members = (props: TMembersProps) => {
  const dispatch = useAppDispatch();
  const isFinancialForecastAllowed = useIsFeatureAllowed(
    FeatureEnum.FINANCIAL_FORECAST
  );
  const changeStatusData = useAppSelector((state) => state.team.changeStatus);
  const { isLoading, dispatch: dispatchFetchUserList } =
    useFetchUserListService();
  const { isLoading: isPatchLoading, dispatch: dispatchPatchUser } =
    usePatchUserService();

  const { dispatch: dispatchDropOrgInvitationService } =
    useDropOrgInvitationService();
  const data = useAppSelector((state) => state.team.list);

  const { dispatch: dispatchFetchOrgInitDataService } =
    useFetchOrgInitDataService(true);

  useEffect(() => {
    dispatchFetchUserList();
  }, []);

  const onDeleteButtonClickHandler = (user: IUserResource) => {
    dispatch(teamActions.removeFromList(user.identifier));
    dispatchDropOrgInvitationService({
      body: { email: user.email },
    })
      .then(() => {
        dispatchFetchOrgInitDataService();
        dispatch(
          systemNotificationActions.open({
            message: `Invitation dropped for ${user.email}`,
            variant: "success",
          })
        );
      })
      .catch(() => {
        dispatch(teamActions.addToList(user));
        dispatch(
          systemNotificationActions.open({
            message: `Failed to drop invitation for ${user.email}. Please try again.`,
            variant: "error",
          })
        );
      });
  };

  const onSendInvitationClickHandler = (user: IUserResource) => {
    dispatch(teamActions.openInvitationModal(user.email));
  };

  const closeConfirmationHandler = () => {
    dispatch(teamActions.closeConfirmationDialog());
  };

  const submitStatusChange = () => {
    dispatchPatchUser({
      urlPath: `${changeStatusData.userId}/changeStatus`,
      body: { status: StatusEnum.INACTIVE, virtualDeactivation: true },
    })
      .then((response: IPatchResource) => {
        const userIds = (response.metadata as { userIds: number[] })?.userIds;
        dispatch(
          teamActions.updateVirtualDeactivationUserStatuses({
            userId: changeStatusData.userId,
            virtualUserIds: userIds,
            status: StatusEnum.INACTIVE,
          })
        );
        dispatch(teamActions.closeConfirmationDialog());
        dispatchFetchOrgInitDataService();
        dispatch(
          systemNotificationActions.open({
            message: `Status changed`,
            variant: "success",
          })
        );
      })
      .catch((error: THttpClientError) => {
        error.status === 406 &&
          dispatch(
            systemNotificationActions.open({
              message: error.data.message,
              variant: "error",
            })
          );

        error.status !== 406 &&
          dispatch(
            systemNotificationActions.open({
              message: `Error while changing Status. Please try again.`,
              variant: "error",
            })
          );
      });
  };

  return (
    <>
      <Box sx={{ mt: 1, mb: 3 }} gap={1} display="flex" flexWrap="wrap">
        <InviteMember />
        <CreateVirtualUser />
        <ManageRoleMap />
      </Box>
      <Box sx={{ width: "100%" }}>
        {data && data.length > 0 && !isLoading && (
          <>
            <FinancialDataModal />
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              gap={1}
              sx={{ mb: 1 }}
            >
              <Typography variant="caption">
                Members: {data.filter((item) => !item.isVirtual).length}
              </Typography>
              <VerticalDivider />
              <Typography variant="caption">
                Virtual Users: {data.filter((item) => item.isVirtual).length}
              </Typography>
            </Box>

            <DataGrid
              rows={data}
              getRowId={(row) => row.identifier}
              columns={Columns({
                ...props,
                onFinancialDataButtonClick: (userId) =>
                  isFinancialForecastAllowed
                    ? dispatch(teamActions.openFinancialDataModal(userId))
                    : dispatch(
                        systemNotificationActions.open({
                          message: featureUtil.notAvailableFullText,
                          variant: "warning",
                        })
                      ),
                onDeleteButtonClick: onDeleteButtonClickHandler,
                onSendInvitation: onSendInvitationClickHandler,
              })}
              disableRowSelectionOnClick
              checkboxSelection={false}
            />
          </>
        )}
        {isLoading && <Loader />}
      </Box>
      <ConfirmationDialog
        open={!!changeStatusData.userId}
        title="Deactivate User"
        content={changeStatusData.message ?? ""}
        closeHandler={closeConfirmationHandler}
        loading={isPatchLoading}
        successHandler={submitStatusChange}
      />
    </>
  );
};

export default Members;
