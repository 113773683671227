import { TTabPanel } from "../../../../../../../common/modules/taskView/containers/InfoTabs/types/TTabPanel";
import { TNoteTabsPanelsProps } from "./types/TNoteTabsPanelsProps";
import Comments from "../../../../../../comment/containers/Comments/Comments";
import { EntityTypeEnum } from "../../../../../../../entities/enums/EntityTypeEnum";
import Grid from "@mui/material/Grid2";

export default function NoteTabs({
  isBoardView = false,
  initiatives,
  boardId,
  isMobileView,
}: TNoteTabsPanelsProps) {
  return [
    ...(!boardId
      ? []
      : [
          {
            tabIndex: `initiatives-tab-board-${boardId}`,
            tabLabel: "Board Notes",
            tabComponent: (
              <Grid container flexDirection="column" gap="40px">
                <Comments
                  entityType={EntityTypeEnum.BOARD}
                  entityId={boardId}
                  typeTitle={"note"}
                  isMobileView={isMobileView}
                  enableHotKey={isBoardView}
                />
              </Grid>
            ),
          },
        ]),
    ...initiatives.map((initiative) => {
      return {
        tabIndex: `initiatives-tab-initiative-${initiative.numericId}`,
        tabLabel: isBoardView
          ? `${initiative.name} (Objective)`
          : "Initiative Notes",
        tabComponent: (
          <Grid container flexDirection="column" gap="40px">
            <Comments
              entityType={EntityTypeEnum.INITIATIVE}
              entityId={initiative.numericId || 0}
              typeTitle={"note"}
              isMobileView={isMobileView}
              enableHotKey={isBoardView}
            />
          </Grid>
        ),
      };
    }),
  ] as TTabPanel[];
}
