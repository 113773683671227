import { Box } from "@mui/material";
import { QueryEnum } from "../../../../../common/hooks/useMedia/enums/QueryEnum";
import useMedia from "../../../../../common/hooks/useMedia/useMedia";
import { Button } from "../../../../../common/modules/materialUI";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Badge from "../../../../../common/modules/materialUI/components/Badge/Badge";

const FilterButton = ({
  clickHandler,
  filterCount,
}: {
  clickHandler: (event: React.MouseEvent<HTMLElement>) => void;
  filterCount: number;
}) => {
  const matchesMax768Screen = useMedia(QueryEnum.MAX_WIDTH_768);

  return (
    <Button
      isBorderStyle
      variant="outlined"
      color="inherit"
      startIcon={<FilterAltIcon sx={{ fontSize: "18px" }} />}
      size="small"
      onClick={clickHandler}
      sx={{
        p: matchesMax768Screen ? "4px 9px" : "4px 10px",
        gap: matchesMax768Screen ? (filterCount > 0 ? "10px" : "0px") : "10px",
      }}
    >
      <Box display="flex" alignItems="center" gap={"8px"}>
        {!matchesMax768Screen && "Filter"}
        {filterCount !== 0 && <Badge>{filterCount}</Badge>}
      </Box>
    </Button>
  );
};

export default FilterButton;
