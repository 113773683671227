import Title from "../../../../common/components/Title/Title";
import { useFetchPriorityListDataService } from "../services/useFetchPriorityListDataService";
import OngoingSection from "./Sections/OngoingSection";
import ComingNextSection from "./Sections/ComingNextSection";
import UpcomingSection from "./Sections/UpcomingSection";
import { useEffect } from "react";
import ViewSkeleton from "./ViewSkeleton";
import EmptyView from "./EmptyView";
import { useSubscribeEvent } from "../../../../common/modules/eventProvider";
import { EventEnum } from "../../../../common/modules/eventProvider/enums/EventEnum";
import { TTaskEvent } from "../../../task/modules/view/containers/types/TTaskEvent";
import { useFetchPriorityListDataOnlyService } from "../services/useFetchPriorityListDataOnlyService";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import LinkModal from "../../../link/containers/Create/LinkModal";
import MoveToBacklogConfirmation from "../../../task/modules/view/containers/MoveToBacklog/MoveToBacklogConfirmation";
import AddAssigneesDialog from "../../../task/modules/view/containers/AddAssignee/AddAssigneesDialog";
import DeleteSegmentConfirmation from "../../../task/modules/view/containers/DeleteSegment/DeleteSegmentConfirmation";
import ConvertInternalTypeConfirmation from "../../../task/modules/view/containers/ConvertInternalType/ConvertInternalTypeConfirmation";
import { THttpClientError } from "../../../../common/modules/httpClient/types/THttpClientError";
import { systemNotificationActions } from "../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { useFirebaseEventHelper } from "../../../../common/modules/firebase/hooks/useFirebaseEventHelper";
import { FirebaseEventEnum } from "../../../../common/modules/firebase/hooks/types/FirebaseEventEnum";
import { FeatureEnum } from "../../../../common/modules/accessControl/enums/FeatureEnum";

const PriorityViewContent = () => {
  const { addToFirebaseQueue } = useFirebaseEventHelper();
  const dispatch = useAppDispatch();
  const dataCount = useAppSelector((state) => state.priorityView.dataCount);

  const { dispatch: dispatchFetchPriorityListDataOnlyService } =
    useFetchPriorityListDataOnlyService();

  const { isLoading, dispatch: dispatchFetchPriorityListService } =
    useFetchPriorityListDataService();

  const fetchCatchHandler = (_error: THttpClientError) => {
    dispatch(
      systemNotificationActions.open({
        message:
          "There was a problem with fetching priority view data. Please try again.",
        variant: "error",
      })
    );
  };

  useEffect(() => {
    addToFirebaseQueue(FirebaseEventEnum.FEATURE_ACCESS, {
      feature: FeatureEnum.PRIORITY_VIEW,
    });

    dispatchFetchPriorityListService().catch(fetchCatchHandler);
  }, []);

  useSubscribeEvent(EventEnum.ON_TASK_MAIN_DATA_UPDATED, (_: TTaskEvent) => {
    dispatchFetchPriorityListDataOnlyService().catch(fetchCatchHandler);
  });

  return (
    <>
      <Title>Priority view</Title>
      {isLoading && <ViewSkeleton />}
      {!isLoading && dataCount > 0 && (
        <>
          <OngoingSection />
          <ComingNextSection />
          <UpcomingSection />
          <LinkModal />
          <MoveToBacklogConfirmation isOnPage={false} />
          <ConvertInternalTypeConfirmation />
          <AddAssigneesDialog />
          <DeleteSegmentConfirmation isOnPage={false} />
        </>
      )}
      {!isLoading && dataCount === 0 && <EmptyView />}
    </>
  );
};

export default PriorityViewContent;
