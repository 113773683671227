import { cloneElement, useState } from "react";
import { TConfirmationButtonProps } from "./types/TConfirmationButtonProps";
import { ConfirmationDialog } from "../../index";
import { colorEnum } from "../../../../utils/color";
import { variantEnum } from "../../../../utils/variant";

const ConfirmationButton = (props: TConfirmationButtonProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const clickHandler = () => {
    if (props.disabled) {
      return;
    }

    setOpen((prevState) => !prevState);
  };

  const successHandler = () => {
    props.successHandler(props.data);
    clickHandler();
  };

  return (
    <>
      {cloneElement(props.button, { onClick: clickHandler })}
      {!props.forMenuItem && (
        <ConfirmationDialog
          open={open}
          title={props.title}
          content={props.content}
          disagreeText={props.disagreeText}
          confirmText={props.confirmText}
          closeHandler={clickHandler}
          successHandler={successHandler}
          confirmColor={props.confirmColor ?? colorEnum.ERROR}
          disagreeColor={colorEnum.INHERIT}
          disagreeVariant={variantEnum.OUTLINED}
        />
      )}
    </>
  );
};

export default ConfirmationButton;
