import { Box, Tooltip } from "@mui/material";
import StatusIcon from "../../Status/StatusIcon";
import classes from "../TaskHorizontal.module.scss";
import TaskMenu from "../../Menu/TaskMenu";
import useGetTaskType from "../../../../../../modules/taskType/hooks/useGetTaskType";
import useGetTaskPriority from "../../../../../../modules/taskPriority/hooks/useGetTaskPriority";
import { useState } from "react";
import { ITask } from "../../../../../../entities/ITask";
import { TaskHelper } from "../../../helpers/TaskHelper";
import useMedia from "../../../../../hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../../hooks/useMedia/enums/QueryEnum";
import TaskRefAndNumber from "./TaskRefAndNumber";
import { lightTheme } from "../../../../../modules/theme/lightTheme";
import { ITaskSegmentCost } from "../../../../../../entities/transientProps/taskSegment/ITaskSegmentCost";
import TaskCost from "../TaskCost";
import RiskStatusIcon from "../../RiskStatus/RiskStatusIcon";
import { AggStatusEnum } from "../../../../../../entities/columns/task/AggStatusEnum";
import { InternalTypeEnum } from "../../../../../../entities/columns/task/InternalTypeEnum";

const TaskMenuSection = ({
  task,
  isHovered,
  mouseLeaveHandle,
  size,
  displayCostPerItem,
  cost,
}: {
  task: ITask;
  isHovered?: boolean;
  mouseLeaveHandle?: () => void;
  size?: number;
  displayCostPerItem?: boolean;
  cost?: ITaskSegmentCost;
}) => {
  const [isOpenTaskMenu, setOpenCloseTaskMenu] = useState(false);

  const sizeMd = TaskHelper.sizeMd(size!);
  const isTouchDevice = useMedia(QueryEnum.IS_TOUCH_DEVICE);

  const taskType = useGetTaskType({
    taskTypeId: task.taskTypeId,
  });
  const taskPriority = useGetTaskPriority({
    taskPriorityId: task.taskPriorityId,
  });

  const shouldDisplayCost = displayCostPerItem && !!cost;

  return (
    <Box display={"flex"} alignItems={"center"}>
      <Box position={"relative"} display={"inline-flex"} height={"18px"}>
        <Box position={"absolute"} top={"-20px"}>
          {task.riskStatus && task.statusType !== AggStatusEnum.DONE ? (
            <RiskStatusIcon
              riskStatus={task.riskStatus}
              style={{ display: "inline-flex" }}
              iconSize={18}
              placement={"bottom"}
            />
          ) : null}
        </Box>
        {((!isHovered && !isTouchDevice) || !sizeMd) && (
          <StatusIcon
            statusType={task.statusType}
            status={task.status}
            style={{ display: "inline-flex" }}
            iconSize={18}
            placement={"bottom"}
            iconColor={
              task.internalType === InternalTypeEnum.SPIKE &&
              task.statusType === AggStatusEnum.TO_DO
                ? lightTheme.palette.action.active
                : undefined
            }
          />
        )}
      </Box>
      <div className={`${classes.metadata}`}>
        <TaskMenu
          openHandler={(openClose: boolean) => {
            setOpenCloseTaskMenu(openClose);
            if (!openClose) {
              mouseLeaveHandle?.();
            }
          }}
          taskRef={task.ref!}
          taskId={task.taskId}
          taskInternalType={task.internalType}
          projectRef={task.projectRef}
          showButton={isHovered || isTouchDevice}
          isOpenTaskMenu={isOpenTaskMenu}
          listSubheader={
            <Box display={"flex"} flexDirection={"column"} gap={"5px"}>
              <Box
                display={"flex"}
                alignItems="center"
                justifyContent={"space-between"}
              >
                <TaskRefAndNumber
                  number={task.number}
                  hasSplits={task.hasSplits}
                  taskRef={task.ref}
                  refSx={{ maxWidth: "80px" }}
                />
                <Box
                  display={"flex"}
                  justifyContent="flex-end"
                  alignItems={"center"}
                  gap={"4px"}
                >
                  {taskPriority && (
                    <Box>
                      <Tooltip title={taskPriority.name}>
                        <img
                          src={`${process.env.CDN_URL}/${taskPriority.iconFilePath}`}
                          alt={taskPriority.name}
                          width={16}
                          height={16}
                          style={{ display: "flex" }}
                        />
                      </Tooltip>
                    </Box>
                  )}
                  {taskType && (
                    <Box>
                      <Tooltip title={taskType.name}>
                        <img
                          src={`${process.env.CDN_URL}/${taskType.iconFilePath}`}
                          alt={taskType.name}
                          width={16}
                          height={16}
                          style={{ display: "flex" }}
                        />
                      </Tooltip>
                    </Box>
                  )}
                  <RiskStatusIcon
                    riskStatus={task.riskStatus}
                    style={{ display: "inline-flex" }}
                    iconProps={{ fontSize: "small" }}
                  />
                  <StatusIcon
                    statusType={task.statusType}
                    status={task.status}
                    style={{ display: "inline-flex" }}
                    iconProps={{ fontSize: "small" }}
                  />
                </Box>
              </Box>
              {shouldDisplayCost && (
                <TaskCost
                  cost={cost!}
                  sx={{
                    display: "inline-grid",
                    padding: "3px 4px",
                    bgcolor: "secondary.main",
                    color: lightTheme.palette.text.primary,
                    borderRadius: 1,
                    height: "20px",
                    maxWidth: "142px",
                    width: "fit-content",
                    marginRight: "2px",
                  }}
                />
              )}
            </Box>
          }
        />
      </div>
    </Box>
  );
};

export default TaskMenuSection;
