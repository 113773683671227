import { MouseEvent } from "react";
import { useAppSelector } from "../../../../../common/hooks/redux";
import { Box, Typography } from "@mui/material";
import { date } from "../../../../../common/utils/date";
import moment from "moment/moment";
import Chip from "../../../../../common/modules/materialUI/components/Chip/Chip";
import { useNavigate } from "react-router-dom";
import { useAccessControl } from "../../../../../common/modules/accessControl/hooks/useAccessControl";
import { string } from "../../../../../common/utils/string";
import Menu from "../../../../../common/containers/Menu/Menu";
import { menuItem } from "../../../../../common/containers/Menu/utils/menuItem";
import { CreditCard } from "@mui/icons-material";
import { Button } from "../../../../../common/modules/materialUI";
import { AbilityEnum } from "../../../../../common/modules/accessControl/enums/AbilityEnum";

type TActivePlanProps = {
  isMobile?: boolean;
};

const ActivePlan = ({ isMobile }: TActivePlanProps) => {
  const navigate = useNavigate();
  const org = useAppSelector((state) => state.org.init.org);
  const { can, plan } = useAccessControl();

  const hasSubscriptionAccess = can(AbilityEnum.administrate);

  if (!plan) {
    return;
  }

  const freeDaysRemaining = !!org?.freeTrialEndDate
    ? date.intervalToDays(moment(), moment(org.freeTrialEndDate)).toFixed(0)
    : -1;
  const freeTrialExpired = +freeDaysRemaining <= 0;

  const handleNavigation = () => {
    if (hasSubscriptionAccess) {
      navigate("/settings/billing/invoice");
    }
  };

  const subscriptionChip = (
    onClick: (event: MouseEvent<HTMLElement>) => void
  ) => (
    <Chip
      clickable
      onClick={onClick}
      label={freeTrialExpired ? string.capitalize(plan) : "Free"}
      variant="outlined"
      sx={{ color: "common.white", borderColor: "common.white" }}
    />
  );

  const renderDaysLeft = () => (
    <Box display="flex" alignItems="center" justifyContent="center" gap="4px">
      <Typography
        variant="subtitle2"
        color="secondary"
        sx={{ lineHeight: "24px", letterSpacing: "0.4px" }}
      >
        {freeDaysRemaining}
      </Typography>
      <Typography
        variant="subtitle2"
        color={isMobile ? "text" : "common.white"}
        sx={{ fontSize: "13px", lineHeight: "22px", letterSpacing: "0.46px" }}
      >
        Days Left
      </Typography>
    </Box>
  );

  return (
    <>
      {isMobile && !freeTrialExpired ? (
        <Menu
          sx={{ mt: 1, width: "fit-content" }}
          padding="4px 0"
          button={(clickHandler) => subscriptionChip(clickHandler)}
          menuItems={[
            menuItem.make({
              component: renderDaysLeft(),
              isHeaderItem: true,
              dividerSx: { m: 0 },
            }),
            menuItem.make({
              component: (
                <Button
                  size="small"
                  variant="text"
                  color="text"
                  disableRipple
                  startIcon={<CreditCard />}
                  onClick={handleNavigation}
                  sx={{ "&:hover": { background: "none" } }}
                >
                  Subscription
                </Button>
              ),
              hasDivider: false,
            }),
          ]}
        />
      ) : (
        <>
          {subscriptionChip(handleNavigation)}
          {!freeTrialExpired && renderDaysLeft()}
        </>
      )}
    </>
  );
};

export default ActivePlan;
