import useNavigateBoard from "../../hooks/useNavigateBoard";
import { BoardViewEnum } from "../../enums/BoardViewEnum";
import { useParams } from "react-router-dom";
import { TBoardParams } from "../../../board/containers/Board/types/TBoardParams";
import IconImage from "../../../../common/components/Icon/IconImage/IconImage";
import { useEffect, useTransition } from "react";
import useMedia from "../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../common/hooks/useMedia/enums/QueryEnum";
import { useFetchBoardInitiativesService } from "../../../board/services/useFetchBoardInitiativesService";
import { Button } from "../../../../common/modules/materialUI";
import ButtonGroup from "../../../../common/modules/materialUI/components/ButtonGroup/ButtonGroup";
import { Box } from "@mui/material";
import { StickyNote2 } from "@mui/icons-material";
import BoardMenu from "../../../board/containers/Menu/BoardMenu";
import { BoardTypeEnum } from "../../../../entities/columns/board/BoardTypeEnum";
import { viewPickerActions } from "../../slices/viewPickerSlice";
import { useAppDispatch } from "../../../../common/hooks/redux";
import ExtraFilters from "../Filter/ExtraFilters/ExtraFilters";
import { TPickerProps } from "./types/TPickerProps";
import { readOnlyTokenUtil } from "../../../../common/utils/readOnlyTokenUtil";

// export type TVisionType = {
//   name: string;
//   icon: JSX.Element;
// };

const ViewPicker = (props: TPickerProps) => {
  const dispatch = useAppDispatch();
  const { boardView } = useParams<TBoardParams>();
  const [, startTransition] = useTransition();
  const matchesMax992Screen = useMedia(QueryEnum.MAX_WIDTH_992);
  const matchesMax576Screen = useMedia(QueryEnum.MAX_WIDTH_576);
  const matchesMax400Screen = useMedia(QueryEnum.MAX_WIDTH_400);
  const readonlyToken = readOnlyTokenUtil.fetch();

  const navigate = useNavigateBoard();
  const size = 18;
  // const [visionData, setVisionData] = useState<TVisionType>({
  //   name: "Daily",
  //   icon: <CalendarViewDay />,
  // });

  const { dispatch: dispatchFetchBoardInitiativesService } =
    useFetchBoardInitiativesService({
      ref: props.board.ref!,
    });

  const changeViewHandler = (view: BoardViewEnum) => {
    startTransition(() => {
      navigate(view);
    });
  };

  useEffect(() => {
    dispatchFetchBoardInitiativesService();
  }, []);

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="12px"
        height="40px"
      >
        <Box display="flex" alignItems="center" gap="12px">
          <ButtonGroup
            variant="outlined"
            sx={{ borderColor: "_common.black_states.outlinedBorder" }}
          >
            {[
              {
                icon: (
                  <IconImage
                    img={"/app-v2/assets/HolisticDark.svg"}
                    imgDark={"/app-v2/assets/HolisticLight.svg"}
                    size={size}
                  />
                ),
                text: !matchesMax992Screen ? "Holistic View" : "Holistic",
                value: BoardViewEnum.HOLISTIC_VIEW,
                onClick: () => changeViewHandler(BoardViewEnum.HOLISTIC_VIEW),
              },
              {
                icon: (
                  <IconImage
                    img={"/app-v2/assets/KanbanDark.svg"}
                    imgDark={"/app-v2/assets/KanbanLight.svg"}
                    size={size}
                  />
                ),
                text: !matchesMax992Screen ? "Kanban View" : "Kanban",
                value: BoardViewEnum.KANBAN_VIEW,
                onClick: () => changeViewHandler(BoardViewEnum.KANBAN_VIEW),
              },
              {
                icon: (
                  <IconImage
                    img={"/app-v2/assets/ListDark.svg"}
                    imgDark={"/app-v2/assets/ListLight.svg"}
                    size={size}
                  />
                ),
                text: !matchesMax992Screen ? "List View" : "List",
                value: BoardViewEnum.LIST_VIEW,
                onClick: () => changeViewHandler(BoardViewEnum.LIST_VIEW),
              },
            ].map((section, index) => (
              <Button
                key={index}
                onClick={section.onClick}
                startIcon={section.icon}
                size="small"
                color="inherit"
                isBorderStyle
                sx={{
                  p: matchesMax992Screen ? "4px 9px" : "4px 10px",
                  bgcolor:
                    section.value === boardView && matchesMax576Screen
                      ? "action.selected"
                      : "",
                }}
              >
                {section.value === boardView && !matchesMax576Screen
                  ? section.text
                  : ""}
              </Button>
            ))}
          </ButtonGroup>
          {/* <ButtonGroup variant="outlined" sx={{ borderColor: "_common.black_states.outlinedBorder" }}>
            <Button startIcon={visionData.icon} forButtonGroup>
              {visionData.name}
            </Button>
            <VisionMenu setVisionData={setVisionData} />
          </ButtonGroup> */}
        </Box>
        <Box
          display="flex"
          alignItems="center"
          gap={matchesMax576Screen ? "8px" : "12px"}
          width="100%"
          justifyContent="flex-end"
        >
          {props.middleElement && props.middleElement}

          {props.havingBoardUsers && props.filterElement}

          {!matchesMax400Screen && (
            <Button
              onClick={() => {
                dispatch(viewPickerActions.setDisplayNote(true));
              }}
              variant="outlined"
              size="small"
              color="inherit"
              isBorderStyle
              startIcon={<StickyNote2 sx={{ width: "18px", height: "18px" }} />}
              sx={{
                p: matchesMax992Screen ? "5px 9px" : "4px 10px",
              }}
            >
              {!matchesMax992Screen ? "Notes" : ""}
            </Button>
          )}

          {!readonlyToken && (
            <BoardMenu
              boardRef={props.boardRef}
              projectAbbr={props.projectAbbr}
              boardType={props.boardType as BoardTypeEnum}
            />
          )}
        </Box>
      </Box>
      <ExtraFilters />
    </>
  );
};

export default ViewPicker;
