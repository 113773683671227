import { Fragment, useMemo } from "react";
import { Box, Card, CardContent, Skeleton } from "@mui/material";
import { ViewHelper } from "./helpers/ViewHelper";

const ViewSkeleton = () => {
  return (
    <Card
      sx={{
        borderRadius: 3,
        padding: "0.5rem",
      }}
    >
      <CardContent
        sx={{
          padding: "0.5rem 0 0.5rem 0",
          overflowX: "hidden",
          position: "relative",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
            marginBottom: "24px",
          }}
        >
          {Array.from({ length: 9 }).map((_, index) => (
            <Box
              key={`timeline-skeleton-${index}`}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  minWidth: ViewHelper.columnWidth(),
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                  textAlign: "center",
                }}
              >
                <Skeleton
                  variant="text"
                  width="40%"
                  sx={{ margin: "0 auto" }}
                />
                <Skeleton
                  variant="text"
                  width="30%"
                  sx={{ margin: "0 auto" }}
                />
              </Box>
              <Skeleton
                variant="rectangular"
                width="2px"
                height="30px"
                sx={{ margin: "5px auto" }}
              />
            </Box>
          ))}
        </Box>
        {useMemo(
          () =>
            Array.from({ length: 4 }).map((_, index) => (
              <Fragment key={index}>
                <Box
                  sx={{
                    width: "fit-content",
                    flexShrink: 0,
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    position: "sticky",
                    left: "4px",
                    zIndex: 20,
                  }}
                >
                  <Skeleton variant="text" width={70} />
                </Box>
                <Box
                  key={`user-lane-skeleton-${index}`}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "8px",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      height: "118px",
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      position: "relative",
                      marginTop: "5px",
                      bgcolor: "backgroundElevation.backgroundPaperElevation_1",
                    }}
                  >
                    <Skeleton
                      variant="rounded"
                      width={Math.floor(Math.random() * 700) + 100}
                      sx={{
                        position: "absolute",
                        left: Math.floor(Math.random() * 200),
                        height: "90%",
                      }}
                    />
                  </Box>
                </Box>
              </Fragment>
            )),
          []
        )}
      </CardContent>
    </Card>
  );
};

export default ViewSkeleton;
