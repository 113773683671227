import { ListItemIcon, Typography } from "@mui/material";
import { menuItem } from "../../../../common/containers/Menu/utils/menuItem";
import IconImage from "../../../../common/components/Icon/IconImage/IconImage";
import { NavLink } from "react-router-dom";
import { TBoardDefaultProps } from "../../types/TBoardDefaultProps";
import { propsForMenuItems } from "../../../../common/containers/Menu/common/propsForMenuItems";

const TaskFieldValues = (props: TBoardDefaultProps) => {
  return menuItem.make({
    component: (
      <NavLink
        to={`/${props.projectAbbr}/board/${props.boardRef}/settings/taskFieldValues`}
      >
        <ListItemIcon>
          <IconImage
            img="/app-v2/assets/Task-Default-Values-Light.svg"
            imgDark="/app-v2/assets/Task-Default-Values-Dark.svg"
            size={19}
          />
        </ListItemIcon>
        <Typography>Task Preset Values</Typography>
      </NavLink>
    ),
    hasDivider: false,
    menuItemsProps: propsForMenuItems,
  });
};

export default TaskFieldValues;
