import { Box, Typography } from "@mui/material";
import { menuItem } from "../../../../../../common/containers/Menu/utils/menuItem";
import { taskViewActions } from "../../slices/viewSlice";
import { TMoveToBacklogMenuItemProps } from "./types/TMoveToBacklogMenuItemProps";
import { featureUtil } from "../../../../../../common/modules/accessControl/utils/featureUtil";
import { systemNotificationActions } from "../../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import BacklogIcon from "../../../../../../common/components/Icon/IconImage/BacklogIcon";

const MoveToBacklogMenuItem = ({
  taskId,
  dispatch,
  isBacklogAllowed,
  shouldRender,
}: TMoveToBacklogMenuItemProps) => {
  if (!shouldRender) {
    return [];
  }

  return menuItem.make({
    component: (
      <Box display="flex" alignItems="center" gap={1}>
        <BacklogIcon size={24} />
        <Typography variant="subtitle2" fontWeight="400">
          Move to backlog
        </Typography>
      </Box>
    ),
    handler: () => {
      isBacklogAllowed
        ? dispatch(taskViewActions.openMoveToBacklogConfirmation({ taskId }))
        : dispatch(
            systemNotificationActions.open({
              message: featureUtil.notAvailableFullText,
              variant: "warning",
            })
          );
    },
    disabled: !isBacklogAllowed,
    disabledTooltipMessage: featureUtil.notAvailableText,
    hasDivider: false,
  });
};

export default MoveToBacklogMenuItem;
