import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import useMedia from "../../../../hooks/useMedia/useMedia";
import { navigationActions } from "../../../../store/navigationSlice";
import Backdrop from "../../../../components/Backdrop/Backdrop";
import { QueryEnum } from "../../../../hooks/useMedia/enums/QueryEnum";
import { useFetchOrgInitDataService } from "../../../../../modules/org/services/useFetchOrgInitDataService";
import { useEffect } from "react";
import { matchPath, Route, Routes, useLocation } from "react-router-dom";
import AppSettingsNavigationContent from "./containers/AppSettings/AppSettingsNavigationContent";
import MainNavigationContent from "./containers/Main/MainNavigationContent";
import BoardSettingsNavigationContent from "./containers/BoardSettings/BoardSettingsNavigationContent";
import UserProfileNavigationContent from "./containers/UserProfile/UserProfileNavigationContent";
import DoubleNavigationContent from "./containers/Double/DoubleNavigationContent";

export const NAV_STATE_KEY = "nav-state";

const shouldHideByDefault = () => localStorage.getItem(NAV_STATE_KEY) === "0";

export const navigationSize = 222;
export const doubleNavigationSize = 63;

const Navigation = () => {
  const dispatch = useAppDispatch();
  const refresh = useAppSelector((state) => state.navigation.refresh);
  const { pathname } = useLocation();
  const {
    isLoading: orgInitDataLoading,
    dispatch: dispatchFetchOrgInitDataService,
  } = useFetchOrgInitDataService();
  const projects = useAppSelector((state) => state.org.init.list);
  const display = useAppSelector((state) => state.navigation.display);
  const matchesMax992Screen = useMedia(QueryEnum.MAX_WIDTH_992, (matches) => {
    matches || shouldHideByDefault()
      ? dispatch(navigationActions.hide())
      : dispatch(navigationActions.show());
  });

  useEffect(() => {
    !matchesMax992Screen &&
      shouldHideByDefault() &&
      dispatch(navigationActions.hide());
  }, []);

  useEffect(() => {
    dispatchFetchOrgInitDataService();
  }, [refresh]);

  if (!display) {
    return null;
  }

  // On smaller screens the menu behaves as an overlay and needs to be closed when user clicked on
  // real menu item and followed some navigation route
  const navigationRequestedHandler = () => {
    matchesMax992Screen && dispatch(navigationActions.hide());
  };

  const content = (
    <Routes>
      <Route
        path="/:projectAbbr/board/:boardRef/settings/*"
        element={
          <BoardSettingsNavigationContent
            navigationRequestedHandler={navigationRequestedHandler}
            orgInitDataLoading={orgInitDataLoading}
          />
        }
      />
      <Route
        path="/settings/*"
        element={
          <AppSettingsNavigationContent
            navigationRequestedHandler={navigationRequestedHandler}
          />
        }
      />
      <Route
        path="/user/*"
        element={
          <UserProfileNavigationContent
            navigationRequestedHandler={navigationRequestedHandler}
          />
        }
      />
    </Routes>
  );

  // List of known routes
  const definedPaths = [
    "/:projectAbbr/board/:boardRef/settings/*",
    "/settings/*",
    "/user/*",
  ];

  // if the current path in defined paths
  const isWildcardRoute = !definedPaths.some((path) =>
    matchPath(path, pathname)
  );

  const mainContent = isWildcardRoute ? (
    <MainNavigationContent
      navigationRequestedHandler={navigationRequestedHandler}
      projects={projects}
      orgInitDataLoading={orgInitDataLoading}
    />
  ) : (
    <DoubleNavigationContent
      navigationRequestedHandler={navigationRequestedHandler}
      projects={projects}
      orgInitDataLoading={orgInitDataLoading}
      content={content}
    />
  );

  if (!matchesMax992Screen) {
    return <>{mainContent}</>;
  }

  return (
    <Backdrop
      id="nav-backdrop"
      closeHandler={() => {
        dispatch(navigationActions.hide());
      }}
      closeOnClick={true}
      usePortal={false}
    >
      {mainContent}
    </Backdrop>
  );
};

export default Navigation;
