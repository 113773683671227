import { keyboard } from "./keyboard";

export const link = {
  anchorClickHandler: (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (!keyboard.isControlPressed(e)) {
      // If no control pressed we will allow parent div click take over and show the modal
      e.preventDefault();
    } else {
      // In case of control we will allow browser to open the link in a new tab and not show the modal
      // by stopping event propagation
      e.stopPropagation();
    }
  },
};
