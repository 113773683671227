import { useFetchListViewDataService } from "../services/useFetchListViewDataService";
import { TBoardViewProps } from "../../../../board/types/TBoardViewProps";
import Filter from "../../../containers/Filter/Filter/Filter";
import ListViewSkeleton from "./ListViewSkeleton";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../common/hooks/redux";
import EmptyView from "../../../../board/containers/EmptyView/EmptyView";
import ViewPicker from "../../../containers/ViewPicker/ViewPicker";
import Section from "./Section/Section";
import ViewContainer from "../../../containers/ViewContainer/ViewContainer";
import { useSubscribeEvent } from "../../../../../common/modules/eventProvider";
import { EventEnum } from "../../../../../common/modules/eventProvider/enums/EventEnum";
import { TTaskEvent } from "../../../../task/modules/view/containers/types/TTaskEvent";
import { listViewActions } from "../slices/listViewSlice";
import { useEffect } from "react";
import { ITask } from "../../../../../entities/ITask";
import { BoardViewEnum } from "../../../enums/BoardViewEnum";
import { useListViewQueryParams } from "../hooks/useListViewQueryParams";
import { viewPickerActions } from "../../../slices/viewPickerSlice";
import NotesModal from "../../../../objective/containers/View/Initiatives/ManageInitiative/NotesModal";
import { useFirebaseEventHelper } from "../../../../../common/modules/firebase/hooks/useFirebaseEventHelper";
import { FirebaseEventEnum } from "../../../../../common/modules/firebase/hooks/types/FirebaseEventEnum";
import { FeatureEnum } from "../../../../../common/modules/accessControl/enums/FeatureEnum";

const ListViewContent = (props: TBoardViewProps) => {
  const { addToFirebaseQueue } = useFirebaseEventHelper();
  const dispatch = useAppDispatch();
  const { groupBySprint: isGroupedBySprint, displaySplits: showSegments } =
    useAppSelector((state) => state.boardView.listView);
  const {
    list: data,
    showSkeleton,
    isSegmentView,
  } = useAppSelector((state) => state.listView);
  const queryParams = useListViewQueryParams({
    projectAbbr: props.projectAbbr,
    showSegments,
    isGroupedBySprint,
  });
  const queryParamsString = JSON.stringify(queryParams);
  const initiatives = useAppSelector((state) => state.board.initiatives);
  const showNotesModal = useAppSelector(
    (state) => state.viewPicker.displayNote
  );

  const { dispatch: dispatchFetchListViewDataService } =
    useFetchListViewDataService({
      boardRef: props.boardRef,
      query: queryParams,
    });
  const hasData = !!data?.tasks.length;

  useSubscribeEvent(
    EventEnum.ON_TASK_CREATED,
    (_: ITask) => {
      dispatchFetchListViewDataService({
        query: queryParams,
      });
    },
    [queryParamsString]
  );

  useSubscribeEvent(
    EventEnum.ON_TASK_MAIN_DATA_UPDATED,
    (_: TTaskEvent) => {
      dispatchFetchListViewDataService({
        query: queryParams,
      });
    },
    [queryParamsString]
  );

  useEffect(() => {
    // Do not fetch data on first render if this component wasn't yet mounted. Default data will be fetched in another useEffect.
    dispatchFetchListViewDataService({
      query: queryParams,
    });
  }, [queryParamsString]);

  useEffect(() => {
    addToFirebaseQueue(FirebaseEventEnum.FEATURE_ACCESS, {
      feature: FeatureEnum.LIST_VIEW,
    });

    return () => {
      dispatch(listViewActions.reset());
    };
  }, []);

  return (
    <>
      <ViewPicker
        board={props.board}
        projectAbbr={props.projectAbbr}
        boardRef={props.boardRef}
        boardType={props.board.type}
        havingBoardUsers={data.havingBoardUsers}
        filterElement={
          <Filter
            boardType={props.board.type}
            board={props.board}
            boardView={BoardViewEnum.LIST_VIEW}
            projectAbbr={props.projectAbbr}
            boardRef={props.boardRef}
            havingBoardUsers={data.havingBoardUsers}
          />
        }
      />

      {showSkeleton && <ListViewSkeleton />}

      {!showSkeleton && !hasData && <EmptyView {...props} />}

      {!showSkeleton && hasData && !isGroupedBySprint && (
        <ViewContainer boardRef={props.boardRef}>
          <Section
            index={0}
            name={"All tasks"}
            tasks={data.tasks}
            sprints={data.sprints}
            users={data.users}
            showSegments={isSegmentView}
          />
        </ViewContainer>
      )}

      {!showSkeleton &&
        hasData &&
        isGroupedBySprint &&
        data.sprints.map((sprint, index) => (
          <ViewContainer key={`lv-s-${index}`} boardRef={props.boardRef}>
            <Section
              index={index}
              name={sprint.name}
              tasks={data.tasks.filter((task) => task.sprintRef === sprint.ref)}
              sprints={data.sprints}
              users={data.users}
              showSegments={isSegmentView}
            />
          </ViewContainer>
        ))}
      <NotesModal
        show={showNotesModal}
        boardId={props.board.id}
        initiatives={initiatives}
        isBoardView={true}
        closeHandler={function () {
          dispatch(viewPickerActions.setDisplayNote(false));
        }}
      />
    </>
  );
};

export default ListViewContent;
