import { TFinancialForecastAccordionProps } from "./types/TFinancialForecastAccordionProps";
import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  useTheme,
} from "@mui/material";
import { ArrowDropDownRounded, AttachMoney } from "@mui/icons-material";
import FinancialForecastDetails from "./FinancialForecastDetails";

const FinancialForecastAccordion = ({
  task,
}: TFinancialForecastAccordionProps) => {
  const theme = useTheme();

  if (!task.aggCosts?.length) {
    return null;
  }

  return (
    <MuiAccordion
      defaultExpanded={true}
      sx={{
        background: "transparent",
        borderRadius: "12px",
        boxShadow: "none",
        m: "0 !important",
        mt: "20px !important",
        "::before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary
        sx={{
          minHeight: "auto !important",
          padding: "0 8px",
          "& .MuiAccordionSummary-content": {
            alignItems: "center",
            margin: 0,
          },
        }}
        expandIcon={
          <ArrowDropDownRounded
            fontSize="small"
            sx={{ color: theme.palette.taskView.textMainColor }}
          />
        }
      >
        <AttachMoney
          fontSize="small"
          sx={{
            color: theme.palette.taskView.textMainColor,
            mr: "8px",
          }}
        />
        <Typography
          color={theme.palette.taskView.textSecondaryColor}
          fontSize="15px"
          fontWeight={500}
        >
          Financial Forecast
        </Typography>
      </AccordionSummary>

      <AccordionDetails
        sx={{
          bgcolor: theme.palette.taskView.mainBackground,
          borderRadius: "12px",
          marginTop: "8px",
          p: "12px",
        }}
      >
        <FinancialForecastDetails
          aggCosts={task.aggCosts}
          cost={task.segmentCount > 1 ? task.cost : undefined}
        />
      </AccordionDetails>
    </MuiAccordion>
  );
};

export default FinancialForecastAccordion;
