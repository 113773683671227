import { TDatePicker } from "./types/TDatePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import TextField from "../../components/TextField/TextField";
import {
  Box,
  IconButton,
  InputAdornment,
  Popper,
  SvgIconProps,
  useTheme,
} from "@mui/material";
import { Clear, Event } from "@mui/icons-material";
import { useRef, useState } from "react";
import useMedia from "../../../../hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../hooks/useMedia/enums/QueryEnum";
import moment from "moment";

const DatePicker = <TFormAttributes extends unknown>(
  props: TDatePicker<TFormAttributes>
) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isTouchDevice = useMedia(QueryEnum.IS_TOUCH_DEVICE);
  const textFieldRef = useRef<null>(null);

  const {
    form,
    name,
    allowClear,
    textFieldProps,
    showRequired,
    textFieldInputIconsProps,
    ...datePickerProps
  } = props;
  const value = (!!name && form?.get(name as keyof TFormAttributes)) || null;

  const changeHandler: TDatePicker<TFormAttributes>["onChange"] = (value) => {
    props.onChange && props.onChange(value);
    !!name && form?.changeHandler(name, value);
  };

  const iconsProps = {
    fontSize: "small",
    sx: { color: theme.palette.taskView.textMainColor },
    ...textFieldInputIconsProps,
  } as SvgIconProps;

  const validDate =
    moment(value).toDate().toLocaleDateString() === "Invalid Date"
      ? null
      : moment(value);

  return (
    <Box ref={textFieldRef}>
      <DesktopDatePicker
        value={validDate}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        slots={{
          textField: (props) => (
            <TextField
              autoComplete="off"
              onKeyDown={(e) => e.preventDefault()}
              {...props}
              {...textFieldProps}
              form={form}
              name={name}
              label={datePickerProps.label}
              showRequired={showRequired}
              slotProps={{
                input: {
                  ...props.InputProps,
                  endAdornment: (
                    <InputAdornment position="end">
                      {allowClear && value && (
                        <IconButton onClick={() => changeHandler(null)}>
                          <Clear {...iconsProps} />
                        </IconButton>
                      )}
                      <IconButton
                        onClick={() => setOpen((prevState) => !prevState)}
                      >
                        <Event {...iconsProps} />
                      </IconButton>
                    </InputAdornment>
                  ),
                  sx: {
                    ".MuiInputBase-input": {
                      fontSize: !isTouchDevice ? "15px" : "16px",
                      fontWeight: 500,
                      color: theme.palette.taskView.textSecondaryColor,
                      WebkitTextFillColor: "unset !important",
                    },
                  },
                },
              }}
            />
          ),
          popper: (props) => {
            return (
              <Popper
                {...props}
                anchorEl={textFieldRef.current}
                placement="bottom-start"
              />
            );
          },
        }}
        {...datePickerProps}
        onChange={changeHandler}
      />
    </Box>
  );
};

export default DatePicker;
