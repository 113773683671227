import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";
import { useAppDispatch } from "../../../common/hooks/redux";
import { useHttpClientDispatchable } from "../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { useHttpClient } from "../../../common/modules/httpClient/hooks/useHttpClient";
import { THttpClientProps } from "../../../common/modules/httpClient/types/THttpClientProps";
import { TUseHttpClientCoreResponse } from "../../../common/modules/httpClient/hooks/types/TUseHttpClientCoreResponse";
import { orgActions } from "../slices/orgSlice";
import { IInitDataResource } from "../interfaces/IInitDataResource";

export const useFetchOrgInitDataService = (
  dispatchable = true,
  boardRef: string | null = null
) => {
  const dispatch = useAppDispatch();
  const props: THttpClientProps = {
    url: boardRef ? `/org/${boardRef}/init` : "/org/init",
    method: RequestMethodEnum.GET,
    events: {
      beforeRequest: () => dispatch(orgActions.setIsLoading(true)),
      beforeTransform: (data: IInitDataResource) =>
        dispatch(orgActions.init(data)),
      afterTransform: () => dispatch(orgActions.setIsLoading(false)),
    },
  };

  return (
    dispatchable ? useHttpClientDispatchable(props) : useHttpClient(props)
  ) as TUseHttpClientCoreResponse;
};
