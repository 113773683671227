import { menuItem } from "../utils/menuItem";
import { Box, Typography } from "@mui/material";
import LayersIcon from "@mui/icons-material/Layers";
import { useNavigate } from "react-router-dom";

const Integrations = () => {
  const navigate = useNavigate();

  return menuItem.make({
    component: (
      <Box
        display="flex"
        alignItems="center"
        gap={"8px"}
        sx={{ cursor: "pointer" }}
      >
        <LayersIcon sx={{ color: "action.active" }} />
        <Typography color={"text.primary"}>Integrations</Typography>
      </Box>
    ),
    handler: () => {
      navigate("/settings/integration/list");
    },
    hasDivider: false,
  });
};

export default Integrations;
