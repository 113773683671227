import { TMenuItemProps } from "../../../../common/containers/Menu/types/TMenuItemProps";
import SignOut from "./SignOut";
import AccountSettings from "./AccountSettings";
import AvatarIcon from "../AvatarIcon/AvatarIcon";
import Menu from "../../../../common/containers/Menu/Menu";
import { ListItemIcon, Typography, useTheme } from "@mui/material";
import ThemeToggle from "./ThemeToggle";
import { lightTheme } from "../../../../common/modules/theme/lightTheme";
import { menuItem } from "../../../../common/containers/Menu/utils/menuItem";
import { QueryBuilder } from "@mui/icons-material";
import { offtimeActions } from "../../../offtime/slices/offtimeSlice";
import { useAppDispatch } from "../../../../common/hooks/redux";
import { exploreAndShareActions } from "../../../../common/modules/exploreAndShare/slices/exploreAndShareSlice";
import { Favorite } from "@mui/icons-material";
import FeedbackModal from "../../../../common/modules/exploreAndShare/containers/Feedback/FeedbackModal";

const UserMenu = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  return (
    <>
      <Menu
        sx={{
          mt: 1,
          width: "228px",
        }}
        padding="0 0 8px"
        button={(clickHandler) => (
          <AvatarIcon
            iconButtonProps={{
              id: "tip:account",
            }}
            clickHandler={clickHandler}
            tooltip="Account Settings"
            noPadding
            avatarStyles={{
              bgcolor: lightTheme.palette._components.avatar[2],
              color: "_components.avatar.avatarText",
              ...theme.typography.body2,
            }}
          />
        )}
        menuItems={
          [
            ...AccountSettings(),
            menuItem.make({
              component: (
                <>
                  <ListItemIcon>
                    <QueryBuilder
                      sx={{
                        color: "action.active",
                      }}
                    />
                  </ListItemIcon>
                  <Typography variant="body2" color="textPrimary">
                    Add Offtime
                  </Typography>
                </>
              ),
              handler: () => {
                dispatch(offtimeActions.openCreateModal());
              },
            }),
            menuItem.make({
              component: (
                <>
                  <ListItemIcon>
                    <Favorite
                      sx={{
                        color: "action.active",
                      }}
                    />
                  </ListItemIcon>
                  <Typography variant="body2" color="textPrimary">
                    Give Feedback
                  </Typography>
                </>
              ),
              handler: () => {
                dispatch(exploreAndShareActions.openFeedback());
              },
            }),
            SignOut(),
            ThemeToggle(),
          ] as TMenuItemProps[]
        }
      />

      <FeedbackModal />
    </>
  );
};

export default UserMenu;
