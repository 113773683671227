import { iconLibraryUtil } from "../../../../modules/iconLibrary/utils/iconLibraryUtil";
import { useAppSelector } from "../../../hooks/redux";
import { TListResource } from "../../../types/TListResource";
import { taskPriorityUtil } from "../../../../modules/taskPriority/utils/taskPriorityUtil";
import { useParams } from "react-router-dom";
import { TBoardParams } from "../../../../modules/board/containers/Board/types/TBoardParams";
import { useOrgProjectData } from "../../../../modules/org/hooks/useOrgProjectData";
import { Box, MenuItem } from "@mui/material";
import { Tooltip } from "../../materialUI";
import CheckIcon from "@mui/icons-material/Check";

const useTaskPriority = () => {
  const orgInit = useAppSelector((state) => state.org.init);
  const projectData = useOrgProjectData();
  const { projectAbbr } = useParams<TBoardParams>();
  const {
    taskPriorities,
    list: projectWithBoardResource,
    isLoading: isOrgInitLoading,
  } = orgInit;

  return {
    listFilter: (
      values: TListResource<number>,
      changeHandler: (values: TListResource<number>) => void
    ) => {
      const projectId = projectData.findProjectByAbbr(projectAbbr!)?.project
        .id!;
      const taskPrioritiesActive = taskPriorities.filter((item) => {
        if (item.projectIds && item.projectIds?.length > 0) {
          return item.isActive && item.projectIds.includes(projectId);
        }
        return item.isActive;
      });

      const options = taskPriorityUtil.getDropdownOptions(
        taskPrioritiesActive,
        undefined
      );

      return (
        <Box>
          {options.length === 0 && (
            <MenuItem
              sx={{
                minHeight: "32px",
                p: "4px 16px",
                display: "flex",
                alignItems: "center",
              }}
            >
              No Task Priority Found
            </MenuItem>
          )}
          {options.map((item) => (
            <MenuItem
              key={item.label}
              onClick={() => {
                const updatedValues = [...values];
                const existingIndex = updatedValues.findIndex(
                  (existingItem) => existingItem.id === item.id
                );

                if (existingIndex !== -1) {
                  updatedValues.splice(existingIndex, 1);
                } else {
                  updatedValues.push({
                    id: Number(item.id),
                    label: item.label,
                  });
                }

                changeHandler(updatedValues);
              }}
              sx={{
                minHeight: "32px",
                p: "4px 16px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Tooltip title={item.label}>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  width={"100%"}
                >
                  {iconLibraryUtil.getDropdownRenderOption(
                    item,
                    taskPriorityUtil.getSelectedTaskPriority(
                      taskPrioritiesActive,
                      item
                    )?.iconFilePath,
                    undefined,
                    undefined,
                    {
                      optionContainerSpacing: 1,
                      optionContainerGap: "12px",
                      iconSize: 24,
                    }
                  )}
                  {values.find(
                    (taskPriority) => taskPriority.id === item.id
                  ) && (
                    <CheckIcon
                      sx={{
                        color: "primary.main",
                        height: "24px",
                      }}
                    />
                  )}
                </Box>
              </Tooltip>
            </MenuItem>
          ))}
        </Box>
      );
    },
    taskPriorityAutocompleteData: (boardRef: string) => {
      const projectId =
        (projectWithBoardResource.find(
          (resourceItem) =>
            !!resourceItem.boards.find((board) => board.ref === boardRef)
        )?.project.id as number) ?? 0;

      return {
        taskPriorities,
        taskPriorityOptions: taskPriorityUtil.getDropdownOptions(
          taskPriorities,
          projectId
        ),
        isLoading: isOrgInitLoading,
      };
    },
  };
};

export default useTaskPriority;
