import { TAppSettingsNavigationContentProps } from "./types/TAppSettingsNavigationContentProps";
import AppSettingsSection from "./AppSettingsSection";
import BillingAndIntegrationSections from "../BillingAndIntegrationSections/BillingAndIntegrationSections";

const AppSettingsNavigationContent = ({
  navigationRequestedHandler,
}: TAppSettingsNavigationContentProps) => {
  return (
    <>
      <AppSettingsSection onClick={navigationRequestedHandler} />
      <BillingAndIntegrationSections onClick={navigationRequestedHandler} />
    </>
  );
};

export default AppSettingsNavigationContent;
