import { useAppDispatch } from "../../../common/hooks/redux";
import { linkListActions } from "../slices/linkListSlice";
import CreateModal from "./CreateLinkList/CreateModal";
import LinkListGrid from "./LinkListGrid/LinkListGrid";
import { useFetchProjectListService } from "../../project/services/useFetchProjectListService";
import TitleAndActionButton from "../../../common/components/Title/TitleAndActionButton";

const LinkListPage = () => {
  const dispatch = useAppDispatch();
  useFetchProjectListService();

  return (
    <>
      <TitleAndActionButton
        title="Task Links"
        buttonText="Add New Link"
        onClick={() => dispatch(linkListActions.openCreateModal())}
      />
      <LinkListGrid />
      <CreateModal />
    </>
  );
};

export default LinkListPage;
