import { Box, Switch, Typography } from "@mui/material";
import { menuItem } from "../../../../common/containers/Menu/utils/menuItem";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import { useState, useTransition } from "react";
import { themeActions } from "../../../../common/modules/theme/slices/themeSlice";
import { themeUtil } from "../../../../common/modules/theme/utils/themeUtil";
import { useSendMessage } from "../../../../common/hooks/useReactNativeBridge/useSendMessage";

const activeTextColor = (activeMode: boolean) =>
  activeMode ? "textPrimary" : "textSecondary";

const ThemeToggle = () => {
  const dispatch = useAppDispatch();
  const currentMode = useAppSelector((state) => state.theme.mode);
  const [mode, setMode] = useState(currentMode);
  const isLightMode = mode === "light";
  const [, startTransition] = useTransition();
  const { sendMessage } = useSendMessage();

  const handleThemeChange = () => {
    const mode = isLightMode ? "dark" : "light";
    setMode(mode);
    sendMessage("appearance", mode);
    startTransition(() => {
      dispatch(themeActions.setMode(mode));
      themeUtil.setUserPreferredMode(mode);
    });
  };

  return menuItem.make({
    component: (
      <Box display="flex" alignItems="center" gap="4px">
        <Typography variant="body2" color={activeTextColor(isLightMode)}>
          Light Mode
        </Typography>
        <Switch checked={!isLightMode} size="small" />
        <Typography variant="body2" color={activeTextColor(!isLightMode)}>
          Dark Mode
        </Typography>
      </Box>
    ),
    handler: handleThemeChange,
    closeOnClick: false,
  });
};

export default ThemeToggle;
