import useForm from "../../../../../../common/hooks/useForm/useForm";
import { TRepFormAttributes } from "./types/TRepFormAttributes";
import { Autocomplete } from "../../../../../../common/modules/materialUI";
import { useUserListService } from "../../../../../user/services/useUserListService";
import { useEffect, useState } from "react";
import { TRepFormAttributesTransformed } from "./types/TRepFormAttributesTransformed";
import { systemNotificationActions } from "../../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { useAppDispatch } from "../../../../../../common/hooks/redux";
import { TListItem } from "../../../../../../common/types/TListItem";
import { THttpClientError } from "../../../../../../common/modules/httpClient/types/THttpClientError";
import { TListResource } from "../../../../../../common/types/TListResource";
import { AggStatusEnum } from "../../../../../../entities/columns/task/AggStatusEnum";
import { useUpdateGuesstimationItemService } from "../../services/useUpdateGuesstimationItemService";
import { IGuesstimationItem } from "../../../../../../entities/IGuesstimationItem";
import Grid from "@mui/material/Grid2";
import DetailsLabel from "../../../../../task/modules/view/containers/TaskViewDetails/DetailsLabel";

export type TProps = {
  guesstimationItem: IGuesstimationItem;
  representative?: TListItem<number>;
};

const RepForm = (props: TProps) => {
  const dispatch = useAppDispatch();
  const rep = props.representative;

  const [options, setOptions] = useState<TListResource<string | number>>(
    rep ? [rep] : []
  );
  const form = useForm<TRepFormAttributes>({
    rep: rep,
  });
  const { dispatch: dispatchUpdateGuesstimationItemService } =
    useUpdateGuesstimationItemService(props.guesstimationItem.id);
  const userListService = useUserListService();

  useEffect(() => {
    form.set({
      rep: rep,
    });
    setOptions(rep ? [rep] : []);
  }, [rep]);

  const submitHandler = (value?: TListItem<number>) => {
    dispatchUpdateGuesstimationItemService({
      body: {
        rep: value?.id ?? null,
      } as TRepFormAttributesTransformed,
    })
      .then((payload: any) => {
        form.notDirty();
      })
      .catch((error: THttpClientError) => {
        notificationHandler(error.status, error.data?.message);
      });
  };

  const notificationHandler = (errorStatus?: number, errorMessage?: string) => {
    errorStatus === 406 &&
      dispatch(
        systemNotificationActions.open({
          message: errorMessage ?? "Something went wrong, please try again.",
          variant: "warning",
          autoHideDuration: 3000,
          closeOnBlur: false,
        })
      );
    errorStatus !== 406 &&
      dispatch(
        systemNotificationActions.open({
          message:
            "There was a problem with changing the representative. Please try again.",
          variant: "error",
          autoHideDuration: 3000,
          closeOnBlur: false,
        })
      );
  };

  return (
    <>
      <Grid size={4}>
        <DetailsLabel>Representative</DetailsLabel>
      </Grid>
      <Grid size={8}>
        <Autocomplete
          form={form}
          name="rep"
          placeholder="Unassigned"
          disabled={props.guesstimationItem?.statusType === AggStatusEnum.DONE}
          onBlur={() => submitHandler(form.data?.rep)}
          service={userListService}
          options={options}
        />
      </Grid>
    </>
  );
};

export default RepForm;
