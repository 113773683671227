import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { Box, MenuItem, SelectChangeEvent, Typography } from "@mui/material";
import { boardQueryActions } from "../../../../modules/boardView/slices/boardQuerySlice";
import { backlogBoardQueryActions } from "../../../../modules/boardView/modules/backlogView/slices/backlogBoardQuerySlice";
import { Tooltip } from "../../materialUI";
import CheckIcon from "@mui/icons-material/Check";
import { TListResource } from "../../../types/TListResource";

type TCustomField = {
  isBacklog?: boolean;
};

const useCustomField = (props: TCustomField) => {
  const dispatch = useAppDispatch();
  const boardFilters = useAppSelector((state) => state.boardQuery.extraFilters);
  const backlogFilters = useAppSelector(
    (state) => state.backlogBoardQuery.extraFilters
  );
  const filterableCustomFields = useAppSelector(
    (state) => state.listing.filterableCustomFieldList
  )!;
  const filters = props?.isBacklog ? backlogFilters : boardFilters;

  const filterChangeHandler = (
    name: string | SelectChangeEvent<any>,
    customFieldId: number
  ) => {
    const value = typeof name === "string" ? name : name.target.value;

    const label = filterableCustomFields
      .find((item) => item.id === customFieldId)
      ?.data?.options.find((item) => item.id == name)?.label!;

    const updatedCustomFields = [...filters.customFields];

    const existingIndex = updatedCustomFields.findIndex(
      (customField) =>
        customField.extraId === customFieldId &&
        customField.value === String(name)
    );

    if (existingIndex !== -1) {
      updatedCustomFields.splice(existingIndex, 1);
    } else {
      updatedCustomFields.push({
        id: customFieldId + value,
        label,
        value: value,
        extraId: customFieldId,
      });
    }

    props.isBacklog &&
      dispatch(
        backlogBoardQueryActions.setExtraFiltering({
          ...backlogFilters,
          customFields: updatedCustomFields,
        })
      );

    !props.isBacklog &&
      dispatch(
        boardQueryActions.setExtraFiltering({
          ...boardFilters,
          customFields: updatedCustomFields,
        })
      );
  };

  return {
    listFilters: (values?: TListResource<string | number>) => {
      return filterableCustomFields && filterableCustomFields.length > 0
        ? filterableCustomFields?.map((customField) => {
            return {
              content: (
                <Box>
                  {customField.data?.options.map((item) => (
                    <MenuItem
                      key={item.id}
                      onClick={() => {
                        filterChangeHandler(item.id, customField.id);
                      }}
                      sx={{
                        height: "32px",
                        minHeight: "32px",
                        p: "4px 16px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Tooltip title={item.label}>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          width="100%"
                        >
                          <Typography noWrap>{item.label}</Typography>
                          {values?.some(
                            (value) => value.label === item.label
                          ) && (
                            <CheckIcon
                              sx={{
                                color: "primary.main",
                                height: "24px",
                              }}
                            />
                          )}
                        </Box>
                      </Tooltip>
                    </MenuItem>
                  ))}
                </Box>
              ),
              label: customField.name,
              id: customField.id,
            };
          })
        : null;
    },
  };
};

export default useCustomField;
