import ViewSkeleton from "../View/ViewSkeleton";
import View from "../View/View";
import EmptyView from "../../../../../board/containers/EmptyView/EmptyView";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/hooks/redux";
import { TBoardViewProps } from "../../../../../board/types/TBoardViewProps";
import ViewPicker from "../../../../containers/ViewPicker/ViewPicker";
import Filter from "../../../../containers/Filter/Filter/Filter";
import LinkModal from "../../../../../link/containers/Create/LinkModal";
import { BoardViewEnum } from "../../../../enums/BoardViewEnum";
import TaskViewModal from "../../../../../task/modules/view/containers/TaskViewModal";
import { useEffect } from "react";
import { holisticViewActions } from "../../slices/holisticViewSlice";
import useInitData from "../../hooks/useInitData";
import NotesModal from "../../../../../objective/containers/View/Initiatives/ManageInitiative/NotesModal";
import { viewPickerActions } from "../../../../slices/viewPickerSlice";
import { FirebaseEventEnum } from "../../../../../../common/modules/firebase/hooks/types/FirebaseEventEnum";
import { FeatureEnum } from "../../../../../../common/modules/accessControl/enums/FeatureEnum";
import { useFirebaseEventHelper } from "../../../../../../common/modules/firebase/hooks/useFirebaseEventHelper";
import { BoardTypeEnum } from "../../../../../../entities/columns/board/BoardTypeEnum";

const HolisticView = (props: TBoardViewProps) => {
  const { addToFirebaseQueue } = useFirebaseEventHelper();
  const havingBoardUsers = useAppSelector(
    (state) => state.holisticView.havingBoardUsers
  );
  const dispatch = useAppDispatch();
  const { isLoading, isFirstTimeLoading } = useInitData(props);
  const data = useAppSelector((state) => state.holisticView.data);
  const initiatives = useAppSelector((state) => state.board.initiatives);
  const showNotesModal = useAppSelector(
    (state) => state.viewPicker.displayNote
  );
  useEffect(() => {
    addToFirebaseQueue(FirebaseEventEnum.FEATURE_ACCESS, {
      feature:
        props.board.type === BoardTypeEnum.HOLISTIC
          ? FeatureEnum.HOLISTIC_VIEW
          : FeatureEnum.INITIATIVE_BOARD,
    });

    return () => {
      dispatch(holisticViewActions.resetData());
    };
  }, [props.boardRef]);

  return (
    <>
      <ViewPicker
        board={props.board}
        projectAbbr={props.projectAbbr}
        boardRef={props.boardRef}
        boardType={props.board.type}
        havingBoardUsers={havingBoardUsers}
        filterElement={
          <Filter
            boardView={BoardViewEnum.HOLISTIC_VIEW}
            autoFetchBoardUsers={false}
            projectAbbr={props.projectAbbr}
            boardRef={props.boardRef}
            renderDisplaySplits={false}
            renderGroupBySprint={false}
            renderCostPerItems={true}
            board={props.board}
            boardType={props.board.type}
            havingBoardUsers={havingBoardUsers}
          />
        }
      />
      {isFirstTimeLoading && <ViewSkeleton />}

      {!isLoading && !havingBoardUsers && <EmptyView {...props} />}

      {!isFirstTimeLoading && havingBoardUsers && (
        <View {...props} data={data!} />
      )}

      <TaskViewModal />
      <LinkModal />

      <NotesModal
        show={showNotesModal}
        boardId={props.board.id}
        initiatives={initiatives}
        isBoardView={true}
        closeHandler={function () {
          dispatch(viewPickerActions.setDisplayNote(false));
        }}
      />
    </>
  );
};

export default HolisticView;
