import { Avatar, ListItemIcon, Typography } from "@mui/material";
import { TMenuItemProps } from "../types/TMenuItemProps";
import { IOrg } from "../../../../entities/IOrg";
import CheckIcon from "@mui/icons-material/Check";
import Logo from "../../../components/Org/Logo";

const SwitchWorkspace = (org?: IOrg): TMenuItemProps[] => {
  const items: TMenuItemProps[] = [org]?.map(
    (workspace: IOrg | undefined, i) => ({
      component: (
        <>
          <ListItemIcon>
            {workspace?.logo ? (
              <Avatar
                sx={{
                  width: "24px",
                  height: "24px",
                }}
                src={workspace?.logo}
              />
            ) : (
              <Logo />
            )}
          </ListItemIcon>
          <Typography sx={{ pr: "8px", color: "text.primary" }}>
            {workspace?.name}
          </Typography>
          <CheckIcon sx={{ color: "text.primary" }} />
        </>
      ),
      hasDivider: [org].length - 1 !== i ? false : undefined,
    })
  );
  return items;
};

export default SwitchWorkspace;
