import { ListItemIcon, Typography } from "@mui/material";
import LayersIcon from "@mui/icons-material/Layers";
import { NavLink } from "react-router-dom";
import { TBoardDefaultProps } from "../../types/TBoardDefaultProps";
import { menuItem } from "../../../../common/containers/Menu/utils/menuItem";
import { propsForMenuItems } from "../../../../common/containers/Menu/common/propsForMenuItems";

const IntegrationMenuItem = (props: TBoardDefaultProps) => {
  return menuItem.make({
    component: (
      <NavLink
        to={`/${props.projectAbbr}/board/${props.boardRef}/settings/integration`}
      >
        <ListItemIcon>
          <LayersIcon />
        </ListItemIcon>
        <Typography>Board Integrations</Typography>
      </NavLink>
    ),
    hasDivider: false,
    menuItemsProps: propsForMenuItems,
  });
};

export default IntegrationMenuItem;
