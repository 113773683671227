import { useEffect, useState, useTransition } from "react";
import Grid from "@mui/material/Grid2";
import { DatePicker } from "../../../../../../common/modules/materialUI";
import { systemNotificationActions } from "../../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/hooks/redux";
import { THttpClientError } from "../../../../../../common/modules/httpClient/types/THttpClientError";
import { useTaskSegmentPatchService } from "../../../../services/useTaskSegmentPatchService";
import moment from "moment";
import { TDueDateFormProps } from "./types/TDueDateFormProps";
import { IResizeByEndDateRequest } from "../../../../interfaces/IResizeByEndDateRequest";
import { TTaskEvent } from "../types/TTaskEvent";
import { EventEnum } from "../../../../../../common/modules/eventProvider/enums/EventEnum";
import { useDispatchEvent } from "../../../../../../common/modules/eventProvider";
import { IResizeResource } from "../../../../interfaces/IResizeResource";
import { taskActions } from "../../../../slices/taskSlice";
import { TEventOnTaskResizeInTaskViewPayload } from "../../../../../../common/modules/eventProvider/types/TEventOnTaskResizeInTaskViewPayload";
import { date } from "../../../../../../common/utils/date";
import { AggStatusEnum } from "../../../../../../entities/columns/task/AggStatusEnum";
import DetailsLabel from "../TaskViewDetails/DetailsLabel";

const DueDateForm = ({ task }: TDueDateFormProps) => {
  const isSegmentView =
    useAppSelector((state) => state.taskView.viewType) === "segment";
  const dispatch = useAppDispatch();
  const dispatchEvent = useDispatchEvent();
  const dueDateChangedAt = useAppSelector(
    (state) => state.task.dueDateChangedAt
  );
  const taskDueDate = !isSegmentView ? task.aggDueDate : task.dueDate;

  // The due date must be the end of the previous day if time is 00:00:00
  const [dueDate, setDueDate] = useState<string>(taskDueDate);
  const [, startTransition] = useTransition();

  const { dispatch: dispatchTaskSegmentPatchService } =
    useTaskSegmentPatchService();

  useEffect(() => {
    setDueDate(
      date.convertedToPrevDayEnd(moment(taskDueDate)).format("YYYY-MM-DD")
    );
  }, [taskDueDate, dueDateChangedAt]);

  const submitHandler = (value: Date | null, keyboardInputValue?: string) => {
    if (dueDate === `${value ?? keyboardInputValue}`) {
      return;
    }

    // Save the current due date to revert back in case of error
    const prevDueDate = dueDate;

    // Update the due date in the state
    setDueDate(`${value!}`);

    // Prepare the new due date to send to the server
    const newDueDate = moment(value ?? keyboardInputValue)
      .startOf("day")
      .format("DD-MM-YYYY HH:mm")
      .toString();

    startTransition(() => {
      dispatchTaskSegmentPatchService({
        urlPath: `${task.segmentId}/resizeByEndDate`,
        body: {
          endDate: newDueDate,
        } as IResizeByEndDateRequest,
      })
        .then((payload: IResizeResource) => {
          const taskEvent = {
            initTask: task,
            task: payload.task,
          } as TTaskEvent;

          taskEventDispatchHandler(taskEvent, payload);
          taskActionDispatchHandler(taskEvent, payload);
        })
        .catch((error: THttpClientError) => {
          error.status === 406 &&
            dispatch(
              systemNotificationActions.open({
                message: error.data.message,
                variant: "warning",
              })
            );
          error.status !== 406 &&
            dispatch(
              systemNotificationActions.open({
                message:
                  "There was a problem with updating the due date. Please try again.",
                variant: "error",
              })
            );

          // Reverting back the due-date
          setDueDate(prevDueDate);
        });
    });
  };

  const taskEventDispatchHandler = (
    taskEvent: TTaskEvent,
    payload: IResizeResource
  ) => {
    dispatchEvent(EventEnum.ON_TASK_MAIN_DATA_UPDATED, taskEvent);
    dispatchEvent(EventEnum.ON_TASK_RESIZE_IN_TASK_VIEW, {
      prevSegmentCount: task.segmentCount,
      currentSegmentCount: payload.task.segmentCount,
    } as TEventOnTaskResizeInTaskViewPayload);
  };

  const taskActionDispatchHandler = (
    taskEvent: TTaskEvent,
    payload: IResizeResource
  ) => {
    dispatch(taskActions.setDueDateChangedAt());
    dispatch(taskActions.setTask(payload.task));
  };

  return (
    <>
      <Grid size={4}>
        <DetailsLabel>Due Date</DetailsLabel>
      </Grid>
      <Grid size={8}>
        <DatePicker
          disabled={!isSegmentView || task.statusType === AggStatusEnum.DONE}
          showRequired
          value={moment(dueDate)}
          onChange={submitHandler}
        />
      </Grid>
    </>
  );
};

export default DueDateForm;
