import { useTheme } from "@mui/material";

const DarkThemeGlobalStyle = () => {
  const theme = useTheme();

  const css = `
    body {
      color: ${theme.palette.text.primary};
      background-color: ${theme.palette.background.default};
    }
    
    a {
      display: flex;
      align-items: center;
      color: ${theme.palette.text.primary};
    }
    
    /* For autofill input elements */
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px ${theme.palette.background.default} inset !important;
      -webkit-text-fill-color: ${theme.palette.inputPallet.main} !important;
    }
  `;

  return <style>{css}</style>;
};

export default DarkThemeGlobalStyle;
