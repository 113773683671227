import { menuItem } from "../../../../common/containers/Menu/utils/menuItem";
import { QueryBuilder } from "@mui/icons-material";
import { useAppDispatch } from "../../../../common/hooks/redux";
import { offtimeActions } from "../../slices/offtimeSlice";
import Section from "../../../boardView/containers/AddButton/Section";

const AddOfftimeMenu = () => {
  const dispatch = useAppDispatch();

  return menuItem.make({
    component: (
      <Section
        icon={
          <QueryBuilder
            sx={{
              color: "action.active",
            }}
          />
        }
        name="Add Offtime"
      />
    ),
    handler: () => dispatch(offtimeActions.openCreateModal()),
  });
};

export default AddOfftimeMenu;
