import { TTaskProps } from "./types/TTaskProps";
import classes from "./TaskHorizontalSmall.module.scss";
import TaskHorizontal from "./TaskHorizontal";
import { TaskHelper } from "../../helpers/TaskHelper";
import { Box, Typography, useTheme } from "@mui/material";
import { ForwardedRef, forwardRef, useState } from "react";
import { Tooltip } from "../../../../modules/materialUI";
import { InternalTypeEnum } from "../../../../../entities/columns/task/InternalTypeEnum";
import { spikeStyleConfig } from "./spikeStyleConfig";
import { pointConverter } from "../../../../utils/pointConverter";

const TaskHorizontalSmall = forwardRef(
  (props: TTaskProps, ref: ForwardedRef<HTMLDivElement>) => {
    const theme = useTheme();
    const { task } = props;
    const sizeXs = TaskHelper.sizeXs(props.size!);
    const sizeSs = TaskHelper.sizeSs(props.size!);

    const [isHovered, setIsHovered] = useState(false);
    const mouseEnterHandle = () => setIsHovered(true);
    const mouseLeaveHandle = () => setIsHovered(false);

    const isSpike = task.internalType === InternalTypeEnum.SPIKE;
    const spikeStyles = spikeStyleConfig(theme, isHovered);

    const timeDiff: string = pointConverter.WorkingHoursOrDayToPoints(
      task.tsPoints
    );

    return (
      <TaskHorizontal
        {...props}
        ref={ref}
        className={`${props.className} ${classes.container}`}
        contentClassName={classes.content}
        style={{
          backgroundColor: theme.palette.background.default,
          border: "1px solid " + theme.palette.divider,
          ...props.style,
          ...(isSpike
            ? {
                ...spikeStyles.containerStyle,
                ...spikeStyles.smallContainerStyle,
              }
            : {}),
        }}
        contentStyle={{
          backgroundColor: isHovered
            ? theme.palette.backgroundElevation.backgroundPaperElevation_3
            : theme.palette.background.paper,
          color: theme.palette.text.primary,
          flexDirection: "column",
          justifyContent: "space-between",
          overflow: "hidden",
          ...(isSpike
            ? { ...spikeStyles.contentStyle, ...spikeStyles.smallContentStyle }
            : {}),
        }}
        mouseEnterHandle={mouseEnterHandle}
        mouseLeaveHandle={mouseLeaveHandle}
        isHovered={isHovered}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          flexWrap={"wrap"}
          justifyContent={sizeXs ? "center" : ""}
          marginTop={"4px"}
          sx={{
            ...(!sizeXs
              ? {
                  marginLeft: "4px",
                  gap: "4px",
                }
              : {}),
          }}
        >
          {isSpike && (
            <Tooltip title={InternalTypeEnum.SPIKE}>
              <img
                src={theme.palette.spike.xsImg}
                alt=""
                style={{
                  width: "11px",
                  height: "11px",
                }}
              />
            </Tooltip>
          )}
          <Typography variant="caption" fontWeight={500}>
            {timeDiff}
          </Typography>
        </Box>
        {!sizeSs && <div className={`${classes.name}`}>{task.name}</div>}
      </TaskHorizontal>
    );
  }
);
export default TaskHorizontalSmall;
