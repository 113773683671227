import { useFetchFeaturesService } from "../services/useFetchFeaturesService";
import { useFetchTipsService } from "../services/useFetchTipsService";
import { useSeenTipsService } from "../services/useSeenTipsService";
import { useEffect } from "react";
import { ITip } from "../../../entities/ITip";
import { readOnlyTokenUtil } from "../../../common/utils/readOnlyTokenUtil";
import { useSubscribeEvent } from "../../../common/modules/eventProvider";
import { EventEnum } from "../../../common/modules/eventProvider/enums/EventEnum";
import { tipActions } from "../slices/tipSlice";
import { useAppDispatch } from "../../../common/hooks/redux";
import { tipsSliderActions } from "../slices/tipsSliderSlice";

let tips: ITip[] = [];
let tipsLoading = false;

const features: { [key: string]: ITip[] } = {};
const featuresLoading: { [key: string]: boolean } = {};

export default function useTipHelper() {
  const { dispatch: dispatchFetchTips } = useFetchTipsService();
  const { dispatch: dispatchFetchFeatures } = useFetchFeaturesService();
  const { dispatch: dispatchSeenTips } = useSeenTipsService();
  const dispatch = useAppDispatch();

  // This one will go through all the global tips if those are any not seen
  // by the user
  const displayNextGlobalTip = () => {
    let allSeen;
    for (const tip of tips) {
      if (tip.seen) {
        continue;
      }
      dispatch(tipActions.setGlobalTip(tip));
      allSeen = false;
      break;
    }

    allSeen !== false && dispatch(tipActions.seenGlobalTip());
  };

  const syncSeenTipsToServer = (slugs: string[]) => {
    dispatchSeenTips({
      body: { slugs },
    }).catch();
  };

  useEffect(() => {
    // No tips for readonly
    // No need to fetch tips if tips are already loading
    // No need to fetch tips if tips are already loaded
    if (readOnlyTokenUtil.fetch() || tipsLoading || tips.length > 0) {
      return;
    }

    tipsLoading = true;
    dispatchFetchTips()
      .then((data: ITip[]) => {
        tips.push(...data);
        displayNextGlobalTip();
      })
      .catch()
      .finally(() => {
        tipsLoading = false;
      });
  }, []);

  // This is called when on a global tip user presses "Got it"
  useSubscribeEvent(
    EventEnum.ON_SEEN_TIP_GLOBAL,
    (payload: ITip) => {
      // Mark this tip as seen in cache
      tips = tips.map((tip) =>
        tip.slug === payload!.slug ? { ...tip, seen: true } : tip
      );
      displayNextGlobalTip();
      syncSeenTipsToServer([payload!.slug]);
    },
    []
  );

  // This is called when a feature using tip on some page is watched by a user
  // and some or all the slides on it are now seen, so we sync with server
  useSubscribeEvent(
    EventEnum.ON_SEEN_TIP_FEATURES,
    (payload: { slugPrefix: string; slugs: string[] }) => {
      if (!features[payload.slugPrefix]) {
        return;
      }
      // Mark this tip as seen in cache
      let newSeenSlugs: string[] = [];
      features[payload.slugPrefix] = features[payload.slugPrefix].map((tip) => {
        if (tip.seen || !payload.slugs.includes(tip.slug)) {
          return tip;
        }
        newSeenSlugs.push(tip.slug);

        return { ...tip, seen: true };
      });

      if (newSeenSlugs.length > 0) {
        syncSeenTipsToServer(newSeenSlugs);
        dispatch(
          tipsSliderActions.setFeatureData({
            data: features[payload.slugPrefix],
            allSeen: features[payload.slugPrefix].every((tip) => tip.seen),
          })
        );
      }
    },
    []
  );

  // Fetch and cache the features requested for a specific page slug prefix
  const fetchFeatures = async (slugPrefix: string, name: string) => {
    if (readOnlyTokenUtil.fetch() || featuresLoading[slugPrefix]) {
      return;
    }

    if (features[slugPrefix]) {
      dispatch(
        tipsSliderActions.setFeatureData({
          data: features[slugPrefix],
          allSeen: features[slugPrefix].every((tip) => tip.seen),
          name,
        })
      );
      return;
    }

    featuresLoading[slugPrefix] = true;
    try {
      const data: ITip[] = await dispatchFetchFeatures({ urlPath: slugPrefix });
      features[slugPrefix] = data;
      featuresLoading[slugPrefix] = false;

      dispatch(
        tipsSliderActions.setFeatureData({
          data,
          allSeen: data.every((tip) => tip.seen),
          name,
        })
      );
    } catch (error) {
      featuresLoading[slugPrefix] = false;
      console.error(error);
    }
  };

  return {
    fetchFeatures,
  };
}
