import { NavLink, NavLinkProps } from "react-router-dom";
import useLinkTarget from "../../../hooks/useLinkTarget";

export default function NotificationLink(props: NavLinkProps) {
  const { _blank } = useLinkTarget();

  return (
    <NavLink
      target={_blank}
      style={{
        display: "inline",
        textDecoration: "underline",
        color: "inherit",
        fontWeight: "bold",
      }}
      {...props}
    >
      {props.children}
    </NavLink>
  );
}
