import { menuItem } from "../../../../../../../common/containers/Menu/utils/menuItem";
import EffortUnitsItem from "../Items/EffortUnitsItem";

const EffortUnitsMenuItem = ({
  allocatedResourceDays,
  estimatedResourceDays,
}: {
  allocatedResourceDays?: number;
  estimatedResourceDays?: number;
}) => {
  return menuItem.make({
    component: (
      <EffortUnitsItem
        allocatedResourceDays={allocatedResourceDays}
        estimatedResourceDays={estimatedResourceDays}
      />
    ),
    isHeaderItem: true,
  });
};
export default EffortUnitsMenuItem;
