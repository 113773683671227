import { TFilterProps } from "./types/TFilterProps";
import { Box } from "@mui/material";
import { useEffect } from "react";
import Avatar from "../Avatar/Avatar";
import { useBoardUsersService } from "../../../../user/services/useBoardUsersService";
import { useSubscribeEvent } from "../../../../../common/modules/eventProvider";
import { EventEnum } from "../../../../../common/modules/eventProvider/enums/EventEnum";
import { ITask } from "../../../../../entities/ITask";
import { TTaskEvent } from "../../../../task/modules/view/containers/types/TTaskEvent";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../common/hooks/redux";
import { BoardViewEnum } from "../../../enums/BoardViewEnum";
import Sorting from "./Sorting/Sorting";
import useMedia from "../../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../../common/hooks/useMedia/enums/QueryEnum";
import ButtonGroup from "../../../../../common/modules/materialUI/components/ButtonGroup/ButtonGroup";
import Filtering from "./SectionFilter/Filtering";
import MoreOptionsMenu from "../../../../../common/containers/Menu/MoreOptionsMenu";
import DisplaySplitsOption from "../../../modules/listView/containers/Filter/More/DisplaySplitsOption";
import GroupBySprintOption from "../../../modules/listView/containers/Filter/More/GroupBySprintOption";
import DisplayCostPerItemMenuItem from "../../FinancialForecast/DisplayCostPerItemMenuItem";
import FinancialForecastDialog from "../../FinancialForecast/FinancialForecastDialog";
import { boardQueryActions } from "../../../slices/boardQuerySlice";
import { useAccessControl } from "../../../../../common/modules/accessControl/hooks/useAccessControl";
import { AbilityEnum } from "../../../../../common/modules/accessControl/enums/AbilityEnum";
import HeaderItem from "../../../../../common/containers/Menu/common/HeaderItem";
import NotesMenuItem from "../../FinancialForecast/NotesMenuItem";
import FinancialForecastMenuItem from "../../FinancialForecast/FinancialForecastMenuItem";
import ShareMenuItem from "../../FinancialForecast/ShareMenuItem";
import { viewPickerActions } from "../../../slices/viewPickerSlice";
import { readOnlyAccessActions } from "../../../../readOnlyAccess/slices/readOnlyAccessSlice";

const Filter = (props: TFilterProps) => {
  const users = useAppSelector((state) => state.user.userList);
  const displaySideView = useAppSelector(
    (state) => state.boardView.displaySideView
  );
  const matchesMax400Screen = useMedia(QueryEnum.MAX_WIDTH_400);
  const matchesMax768Screen = useMedia(QueryEnum.MAX_WIDTH_768);
  const matchesMax576Screen = useMedia(QueryEnum.MAX_WIDTH_576);
  const dispatch = useAppDispatch();
  const { can } = useAccessControl();

  const { isLoading: usersIsLoading, dispatch: dispatchBoardUsersService } =
    useBoardUsersService({
      query: {
        projectAbbr: props.projectAbbr,
        boardRef: props.boardRef,
      },
    });

  useSubscribeEvent(EventEnum.ON_TASK_CREATED, (_: ITask) => {
    dispatchBoardUsersService();
  });

  useSubscribeEvent(EventEnum.ON_TASK_ASSIGNED_TO_UPDATED, (_: TTaskEvent) => {
    dispatchBoardUsersService();
  });

  const isKanbanOrListView = () =>
    [BoardViewEnum.KANBAN_VIEW, BoardViewEnum.LIST_VIEW].includes(
      props.boardView
    );

  const shareBoardHandler = () =>
    dispatch(readOnlyAccessActions.openManageModal());

  useEffect(() => {
    !(users.length > 0) &&
      (props.autoFetchBoardUsers ?? true) &&
      dispatchBoardUsersService();
  }, []);

  const moreOptionItems = [
    FinancialForecastMenuItem(),
    ShareMenuItem({
      matchesScreen: matchesMax576Screen,
      clickHandler: shareBoardHandler,
    }),
    NotesMenuItem({
      clickHandler: () => {
        dispatch(viewPickerActions.setDisplayNote(true));
      },
      matchesMax400Screen: matchesMax400Screen,
    }),
    HeaderItem(
      "Display Modes",
      can(AbilityEnum.financing) || !props.renderCostPerItems
    ),
    DisplayCostPerItemMenuItem(props),

    DisplaySplitsOption(props),
    GroupBySprintOption(props),
  ];
  const hasMoreOptions = moreOptionItems.find((item) => item.when === true);

  return (
    <Box
      display="flex"
      alignItems="center"
      width="100%"
      gap={"8px"}
      justifyContent={"flex-end"}
    >
      {!matchesMax768Screen && (
        <Box display="flex" alignItems="center" gap={"8px"} width={"100%"}>
          <Box width={"100%"} display={"flex"} justifyContent={"flex-end"}>
            {!usersIsLoading && users && (
              <Avatar users={users} board={props.boardView} maxCount={12} />
            )}
          </Box>
        </Box>
      )}

      <ButtonGroup variant="outlined" sx={{ borderColor: "divider" }}>
        <Filtering users={users} />
        {isKanbanOrListView() && <Sorting actions={boardQueryActions} />}

        {!!hasMoreOptions && (
          <MoreOptionsMenu closeOnSelection menuItems={moreOptionItems} />
        )}
      </ButtonGroup>

      <FinancialForecastDialog
        boardRef={props.boardRef}
        displaySideView={displaySideView!}
      />
    </Box>
  );
};

export default Filter;
