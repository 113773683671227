import classes from "./TimeLine.module.css";
import { date, FORMAT_RAW_DATE_ONLY } from "../../utils/date";
import { pointConverter } from "../../utils/pointConverter";
import { useMemo } from "react";
import { TTimelineDate } from "./types/TTimelineDate";
import { Box, Typography, useTheme } from "@mui/material";
import { useAppSelector } from "../../hooks/redux";
import { Tooltip } from "../../modules/materialUI";
import TimeArrows from "./TimeArrows";
import moment, { Moment } from "moment";
import { array } from "../../utils/array";
import { useNonWorkdayData } from "../../../modules/nonWorkday/hooks/useNonWorkdayData";
import { IExceptionTypeEnum } from "../../../entities/columns/nonWorkday/IExceptionTypeEnum";
import {
  addDayToRegistry,
  resetDaysRegistry,
} from "../../../modules/boardView/modules/holisticView/hooks/useVirtualizationHelper";
import { ISprint } from "../../../entities/ISprint";
import { INonWorkday } from "../../../entities/INonWorkday";
import SportsScoreIcon from "@mui/icons-material/SportsScore";
import IconImage from "../Icon/IconImage/IconImage";
import { USER_NAME_HEIGHT } from "../../../modules/boardView/modules/holisticView/containers/UserLane/UserLane";
import { TIMELINE_CONTAINER_HEIGHT } from "../../../modules/boardView/modules/holisticView/containers/View/View";

type TProps = {
  startDate: Moment;
  endDate: Moment;
  sprints?: ISprint[];
  positionShift?: number;
  nonWorkdays?: INonWorkday[];
  nonWorkdayOverlay?: {
    height: number;
  };
};

const TimeLine = (props: TProps) => {
  const theme = useTheme();
  const sprintLabel = useAppSelector((state) => state.customs.sprintLabel);
  // Removing the time part from the provided dates
  const startDate = moment(props.startDate).startOf("day");
  const endDate = moment(props.endDate).startOf("day");
  const { getOffDays, getExceptions } = useNonWorkdayData();

  const offDays = getOffDays(props.nonWorkdays ?? []);
  const exceptions = getExceptions(props.nonWorkdays ?? []);

  const currentYear = moment().year();

  const columnWidth = pointConverter.pointToXSizeInPixels();
  const timelineDates = useMemo<TTimelineDate[]>(() => {
    const timelineDates: TTimelineDate[] = [
      {
        date: moment(),
        label: (
          <Typography variant="subtitle2" color="text.primary">
            Today
          </Typography>
        ),
        priority: 0,
        timeHighlight: "now",
        timeArrow: "now",
      },
    ];

    props.sprints?.forEach((sprint) => {
      const sprintStartDate = moment(sprint.startDate);
      sprintStartDate.isBetween(startDate, endDate) &&
        timelineDates.push({
          date: sprintStartDate.startOf("day"),
          label: (
            <Box
              display="flex"
              gap={1}
              alignItems="center"
              sx={{
                backgroundColor: "info.main",
                borderRadius: 1,
                paddingX: 1,
                maxWidth: "100%",
              }}
            >
              <IconImage
                imgDark="/app-v2/assets/SprintStartDark.svg"
                img="/app-v2/assets/SprintStartLight.svg"
                size={16}
              />
              <Typography noWrap variant="caption" color="info.contrastText">
                {sprint.name}
              </Typography>
            </Box>
          ),
          priority: 1,
          timeHighlight: "checkpoint",
          timeArrow: "checkpoint",
          description: `Start of the ${sprintLabel} - ${sprint.name}`,
        });
      const sprintEndDate = moment(sprint.dueDate);
      sprintEndDate.isBetween(startDate, endDate) &&
        timelineDates.push({
          date: sprintEndDate.startOf("day"),
          label: (
            <Box
              display="flex"
              gap={1}
              alignItems="center"
              sx={{
                backgroundColor: "action.selected",
                borderRadius: 1,
                paddingX: 1,
                maxWidth: "100%",
              }}
            >
              <SportsScoreIcon
                fontSize="small"
                sx={{ color: "action.active" }}
              />
              <Typography noWrap variant="caption" color="text.primary">
                {sprint.name}
              </Typography>
            </Box>
          ),
          priority: 1,
          timeHighlight: "checkpoint",
          timeArrow: "checkpoint",
          isEndDate: true,
          description: `End of the ${sprintLabel} - ${sprint.name}`,
        });
    });

    return timelineDates;
  }, [props.sprints, startDate, endDate]);

  const days = date.intervalToDays(startDate, endDate, true);
  const currentDate = startDate.clone();
  const pointers = [];

  const isNonWorkingDay = (currentDate: Moment) => {
    const exception = exceptions.find(
      (exception) =>
        date.format(currentDate, FORMAT_RAW_DATE_ONLY) ===
        date.format(exception.exceptionData.date, FORMAT_RAW_DATE_ONLY)
    );
    if (exception?.exceptionData.type === IExceptionTypeEnum.ON) {
      return false;
    }

    return offDays.includes(currentDate.day()) || exception;
  };

  resetDaysRegistry();
  for (let i = 1; i <= days; i++) {
    addDayToRegistry(currentDate.toISOString(), i - 1);

    const timelineDate = timelineDates.reduce(
      (prev: TTimelineDate | undefined, current) =>
        date.dayMatches(current.date, currentDate)
          ? prev && prev.priority >= current.priority
            ? prev
            : current
          : prev,
      undefined
    );

    pointers.push(
      <div
        key={`tl-pointer-${i}`}
        className={array.toClassName([
          classes.pointer,
          theme.palette.mode === "dark" ? classes.dark : classes.light,
        ])}
        style={{
          minWidth: columnWidth,
          color: theme.palette.text.secondary,
          height: "40px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          ...(isNonWorkingDay(currentDate)
            ? {
                color: theme.palette.text.disabled,
                fontWeight: theme.palette.nonWorkday.timelineFontWeight,
              }
            : {}),
        }}
      >
        {isNonWorkingDay(currentDate) && props.nonWorkdayOverlay && (
          <Box
            bgcolor="nonWorkday.overlay"
            width="calc(100% + 1px)"
            height={props.nonWorkdayOverlay.height}
            position="absolute"
            top={TIMELINE_CONTAINER_HEIGHT + USER_NAME_HEIGHT + 5 + "px"} // 5px for padding
            zIndex={11}
            left="-2px"
            overflow="hidden"
            sx={{
              pointerEvents: "none",
            }}
          />
        )}
        <div
          className={array.toClassName([
            classes[timelineDate?.timeHighlight ?? ""],
          ])}
        >
          <div>
            {currentDate.date()} {currentDate.format("MMM")}
            {currentYear !== currentDate.year()
              ? `, ${currentDate.year()}`
              : ""}
          </div>
          {currentDate.format("ddd")}
        </div>
        {timelineDate && (
          <Box
            display="flex"
            justifyContent={
              timelineDate.isEndDate
                ? "flex-end"
                : timelineDate.priority
                ? "flex-start"
                : "center"
            }
            alignItems="center"
            width="100%"
            height="1.2rem"
            overflow="hidden"
            position="absolute"
            bottom="-20px"
          >
            <Tooltip
              when={!!timelineDate.description}
              title={timelineDate.description}
              arrow
              placement="top"
            >
              <Box maxWidth="-webkit-fill-available">{timelineDate.label}</Box>
            </Tooltip>
          </Box>
        )}
      </div>
    );

    currentDate.add(1, "day");
  }

  return (
    <div className={classes.container} style={{ width: columnWidth * days }}>
      {pointers}
      <TimeArrows
        positionShift={props.positionShift ?? 0}
        timelineDates={timelineDates}
        startDate={startDate}
      />
    </div>
  );
};

export default TimeLine;
