import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import { DataGrid } from "../../../../common/modules/materialUI";
import { Columns } from "./Columns";
import Loader from "../../../../common/containers/Loader/Loader";
import { gridClasses } from "@mui/x-data-grid";
import { useFetchProjectListService } from "../../services/useFetchProjectListService";
import { useNavigate } from "react-router-dom";
import { useArchiveProjectService } from "../../services/useArchiveProjectService";
import { IProject } from "../../../../entities/IProject";
import { projectActions } from "../../slices/projectSlice";
import { orgActions } from "../../../org/slices/orgSlice";
import { systemNotificationActions } from "../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { navigationActions } from "../../../../common/store/navigationSlice";

const ProjectManagementGrid = () => {
  const { isLoading } = useFetchProjectListService();
  const projects = useAppSelector((state) => state.project.projectList);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { dispatch: dispatchArchiveProject } = useArchiveProjectService();

  const archiveProjectHandler = (item: IProject) => {
    dispatch(projectActions.removeProject({ ref: item.ref }));
    dispatch(orgActions.removeProject({ ref: item.ref }));
    dispatchArchiveProject({ urlPath: `${item.ref}/archive` })
      .then(() => {
        dispatch(
          systemNotificationActions.open({
            message:
              "Your archive request has been received successfully. It may take some time for all project-related components to be archived across all locations.",
            variant: "success",
          })
        );
      })
      .catch(() => {
        dispatch(navigationActions.refresh());
        dispatch(projectActions.addProject(item));
        dispatch(
          systemNotificationActions.open({
            message: "Unable to archive, please try again.",
            variant: "error",
          })
        );
      });
  };

  const editProjectHandler = (abbr: string) => {
    navigate(`/settings/project/${abbr}`);
  };

  const editStatusFlowsHandler = (abbr: string) => {
    navigate(`/settings/project/${abbr}/statusFlow`);
  };

  const configNonWorkingDaysHandler = (abbr: string) => {
    navigate(`/settings/project/${abbr}/configNonWorkingDays`);
  };

  const manageSprintsHandler = (abbr: string) => {
    navigate(`/settings/project/${abbr}/sprints`);
  };

  return (
    <>
      <DataGrid
        columns={Columns({
          editProjectHandler,
          editStatusFlowsHandler,
          configNonWorkingDaysHandler,
          manageSprintsHandler,
          archiveProjectHandler,
        })}
        getRowHeight={() => "auto"}
        rows={projects}
        disableRowSelectionOnClick
        checkboxSelection={false}
        autoHeight={!projects.length}
        loading={isLoading}
        sx={{
          [`& .${gridClasses.row}`]: {
            minHeight: `72px !important`,
          },
        }}
      />
      {isLoading && <Loader />}
    </>
  );
};

export default ProjectManagementGrid;
