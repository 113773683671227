import { Button as MuiButton } from "@mui/material";
import Loading from "../../../../components/Icon/Loading/Loading";
import { forwardRef } from "react";
import { TButtonProps } from "./types/TButtonProps";
import { darkTheme } from "../../../theme/darkTheme";

const sizeStyles = (isButtonOutlined: boolean) => ({
  large: {
    padding: isButtonOutlined ? "7px 21px" : "8px 22px",
    fontSize: "15px",
    fontWeight: 500,
    lineHeight: "26px",
    letterSpacing: "0.46px",
  },
  medium: {
    padding: isButtonOutlined ? "5px 15px" : "6px 16px",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: "0.4px",
  },
  small: {
    padding: isButtonOutlined ? "3px 9px" : "4px 10px",
    fontSize: "13px",
    fontWeight: 500,
    lineHeight: "22px",
    letterSpacing: "0.46px",
  },
});

type TButtonType = {
  isBorderStyle?: boolean;
} & TButtonProps;

const Button = forwardRef<HTMLButtonElement, TButtonType>(
  (
    {
      loading,
      size = "medium",
      variant = "contained",
      isBorderStyle,
      ...buttonProps
    },
    ref
  ) => {
    const isButtonOutlined = variant === "outlined";

    const borderColor: { color: string } | undefined = isButtonOutlined
      ? (buttonProps?.sx as { color: string })
      : undefined;

    return (
      <MuiButton
        ref={ref}
        {...buttonProps}
        variant={variant}
        startIcon={loading ? <Loading /> : buttonProps.startIcon}
        sx={{
          borderRadius: 2,
          textTransform: "capitalize",
          boxShadow: variant === "contained" ? 2 : 0,
          gap: "8px",
          minWidth: "auto",
          borderColor: borderColor?.color
            ? borderColor?.color + "80"
            : undefined,
          "& .MuiButton-startIcon": { m: 0 },
          ...(isBorderStyle && {
            whiteSpace: "nowrap",
            color: "text.primary",
            borderColor: "_common.black_states.outlinedBorder",
            "&.MuiButtonGroup-firstButton:hover, &.MuiButtonGroup-middleButton:hover":
              {
                borderRightColor: "transparent !important",
              },
            "&.MuiButtonGroup-lastButton:hover": {
              borderRightColor: "_common.black_states.outlinedBorder",
            },
          }),
          color:
            variant === "contained"
              ? darkTheme.palette.text.primary
              : undefined,
          ...sizeStyles(isButtonOutlined)[size],
          ...buttonProps.sx,
        }}
      />
    );
  }
);

export default Button;
