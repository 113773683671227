import { useNavigate, useParams } from "react-router-dom";
import { BoardViewEnum } from "../enums/BoardViewEnum";
import { TBoardParams } from "../../board/containers/Board/types/TBoardParams";
import { VisionEnum } from "../containers/ViewPicker/menu/enums/VisionEnum";
import { readOnlyTokenUtil } from "../../../common/utils/readOnlyTokenUtil";

const useNavigateBoard = () => {
  const readOnlyToken = readOnlyTokenUtil.fetch();
  const { projectAbbr, boardRef } = <TBoardParams>useParams();
  const navigate = useNavigate();

  return (boardView: BoardViewEnum, holisticViewVision?: VisionEnum) => {
    const param1 = `/${boardView}`;
    const param2 = holisticViewVision ? `/${holisticViewVision}` : "";
    const readOnlyParam = readOnlyToken
      ? `?readOnlyToken=${readOnlyToken}`
      : "";
    const shared = readOnlyToken ? "/shared" : "";

    return navigate(
      `${shared}/${projectAbbr}/board/${boardRef}${param1}${param2}${readOnlyParam}`
    );
  };
};

export default useNavigateBoard;
