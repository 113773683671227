import { TSplitsProps } from "./types/TSplitsProps";
import { useEffect } from "react";
import { useGetTaskSegmentsService } from "../../services/useGetTaskSegmentsService";
import { Typography, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid2";
import VerticalDivider from "../../../../../../common/components/Divider/VerticalDivider";
import StatusIcon from "../../../../../../common/components/Task/components/Status/StatusIcon";
import { NavLink, useNavigate } from "react-router-dom";
import { taskUtil } from "../../../../../../common/utils/taskUtil";
import { link } from "../../../../../../common/utils/link";
import { ITaskSegment } from "../../../../../../entities/ITaskSegment";
import { ITaskSegmentsResource } from "../../interfaces/ITaskSegmentsResource";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/hooks/redux";
import { taskActions } from "../../../../slices/taskSlice";
import { number } from "../../../../../../common/utils/number";
import { string } from "../../../../../../common/utils/string";
import AvatarIcon from "../../../../../user/containers/AvatarIcon/AvatarIcon";
import { taskViewActions } from "../../slices/viewSlice";
import { date, FORMAT_MONTH_DAY } from "../../../../../../common/utils/date";
import moment from "moment/moment";
import { systemNotificationActions } from "../../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import usePortalTabActions from "../../hooks/usePortalTabActions";
import { Add } from "@mui/icons-material";
import { AddAssigneesTypeEnum } from "../../slices/enums/AddAssigneesTypeEnum";
import { Tooltip } from "../../../../../../common/modules/materialUI";
import { featureUtil } from "../../../../../../common/modules/accessControl/utils/featureUtil";
import { useIsFeatureAllowed } from "../../../../../../common/modules/accessControl/hooks/useIsFeatureAllowed";
import { FeatureEnum } from "../../../../../../common/modules/accessControl/enums/FeatureEnum";

const Splits = ({
  task,
  users,
  isMobileView,
  tabIndex,
  isPageView,
}: TSplitsProps) => {
  const { CountsPortalComponent, ActionsPortalComponent } =
    usePortalTabActions(tabIndex);
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isManualSplitAllowed = useIsFeatureAllowed(FeatureEnum.MANUAL_SPLIT);
  const { dispatch: dispatchGetSegments } = useGetTaskSegmentsService({
    taskId: task.taskId,
  });
  const taskSegments =
    useAppSelector((state) => state.taskView.taskSegments) ?? [];
  const viewType = useAppSelector((state) => state.taskView.viewType);
  const taskSegment = taskSegments.find(
    (segment) => segment.id === task.segmentId
  );
  const isCurrentTaskSegmentChangedInList =
    !taskSegment ||
    JSON.stringify([
      task.segmentCount,
      task.riskStatus,
      task.status,
      task.number,
      task.startDate,
      task.dueDate,
      task.estimationPoints,
      task.estimationUom,
      task.assignedTo,
    ]) !==
      JSON.stringify([
        taskSegments.length,
        taskSegment.riskStatus,
        taskSegment.status,
        taskSegment.number,
        taskSegment.startDate,
        taskSegment.dueDate,
        taskSegment.estimationPoints,
        taskSegment.estimationUom,
        taskSegment.assignedTo,
      ]);

  useEffect(() => {
    if (task.segmentCount <= 1 || !isCurrentTaskSegmentChangedInList) {
      return;
    }

    dispatchGetSegments()
      .then((data: ITaskSegmentsResource) => {
        dispatch(taskViewActions.setTaskSegments(data.taskSegments));
        dispatch(
          taskActions.setUserData({
            users: data.users,
            userList: data.userList,
          })
        );
      })
      .catch(() => {
        dispatch(
          systemNotificationActions.open({
            message: "Failed to fetch task splits. Please try again.",
            variant: "error",
          })
        );
      });
  }, [task.segmentCount, isCurrentTaskSegmentChangedInList]);

  const taskSegmentClickHandler = (segment: ITaskSegment) => {
    dispatch(
      taskActions.mergeTaskProps({
        ...segment,
        id: task.taskId,
        createdAt: task.createdAt,
        updatedAt: task.updatedAt,
        taskId: task.taskId,
        segmentId: segment.id,
        isSegmentData: true,
      })
    );
    dispatch(taskViewActions.setViewType("segment"));
    isPageView &&
      navigate(`/task/${task.ref}/${segment.number}`, { replace: true });
  };

  return (
    <>
      {CountsPortalComponent(
        <>{task.segmentCount > 1 ? task.segmentCount : 0} Splits</>
      )}
      {ActionsPortalComponent(
        <Tooltip
          title={featureUtil.notAvailableText}
          when={!isManualSplitAllowed}
        >
          <Typography
            color="#818692"
            fontSize="13px"
            display="flex"
            alignItems="center"
            gap="4px"
            sx={{ cursor: "pointer" }}
            onClick={() => {
              isManualSplitAllowed
                ? dispatch(
                    taskViewActions.openAddAssigneesDialog({
                      taskId: task.id,
                      type: AddAssigneesTypeEnum.ADD_SPLIT,
                    })
                  )
                : dispatch(
                    systemNotificationActions.open({
                      message: featureUtil.notAvailableFullText,
                      variant: "warning",
                    })
                  );
            }}
          >
            <Add fontSize="small" />
            Add {!isMobileView && "split"}
          </Typography>
        </Tooltip>
      )}
      {task.segmentCount > 1 && taskSegments && (
        <Grid container flexDirection="column" gap="8px">
          {taskSegments.map((taskSegment, _) => {
            const color =
              viewType === "segment" && task.segmentId === taskSegment.id
                ? theme.palette.taskView.textMainColor
                : theme.palette.taskView.labelColor;

            const user = users.find(
              (user) => user.id === taskSegment.assignedTo
            );

            return (
              <Grid
                key={taskSegment.id}
                px={isMobileView ? 0 : "8px"}
                py="2px"
                display="flex"
                justifyContent="space-between"
                gap="32px"
              >
                <Grid
                  flex={1}
                  minWidth={0}
                  display="flex"
                  alignItems="center"
                  gap="8px"
                >
                  <StatusIcon
                    statusType={taskSegment.statusType}
                    status={taskSegment.status}
                  />

                  <NavLink
                    to={taskUtil.urlByRef(task.ref, taskSegment.number)}
                    onClick={(e) => {
                      link.anchorClickHandler(e);
                      taskSegmentClickHandler(taskSegment);
                    }}
                    style={{ color: color }}
                  >
                    <Typography
                      noWrap
                      fontSize="13px"
                      fontWeight={500}
                      sx={{ cursor: "pointer" }}
                    >
                      {task.ref} #{taskSegment.number}
                    </Typography>
                  </NavLink>

                  <Typography
                    noWrap
                    color={color}
                    fontSize="13px"
                    textTransform="capitalize"
                  >
                    {taskSegment.status.toLowerCase()}
                  </Typography>
                </Grid>

                <Grid display="flex" alignItems="center" gap="8px">
                  <Grid container alignItems="center" gap="4px">
                    <Grid>
                      <Typography
                        color={theme.palette.taskView.textMainColor}
                        fontSize="13px"
                      >
                        {date.format(taskSegment.startDate, FORMAT_MONTH_DAY)}
                      </Typography>
                    </Grid>

                    <Grid>
                      <Typography
                        color={theme.palette.taskView.textMainColor}
                        fontSize="13px"
                      >
                        -
                      </Typography>
                    </Grid>

                    <Grid>
                      <Typography
                        color={theme.palette.taskView.textMainColor}
                        fontSize="13px"
                      >
                        {date.format(
                          date.convertedToPrevDayEnd(
                            moment(taskSegment.dueDate)
                          ),
                          FORMAT_MONTH_DAY
                        )}
                      </Typography>
                    </Grid>

                    <VerticalDivider
                      sx={{
                        border: `0.5px solid ${theme.palette.taskView.textMainColor}`,
                        margin: "6px 4px",
                      }}
                    />

                    <Grid>
                      <Typography
                        color={theme.palette.taskView.textMainColor}
                        fontSize="13px"
                      >
                        {number.format(taskSegment.estimationPoints)}{" "}
                        {string.acronym(taskSegment.estimationUom)}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid>
                    {user ? (
                      <AvatarIcon
                        userName={user.name}
                        tooltip={user.name}
                        fontSize={10}
                        isBold={true}
                        noPadding={true}
                        position="right"
                        size={22}
                        avatarStyles={{ color: "#ffffff", fontWeight: 500 }}
                      />
                    ) : (
                      <Grid width={22} />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      )}
    </>
  );
};

export default Splits;
