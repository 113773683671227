import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../common/hooks/redux";
import { useParams } from "react-router-dom";
import { TBacklogItemPageParams } from "./types/TBacklogItemPageParams";
import { useGetBacklogItemDetailsService } from "../services/useGetBacklogItemDetailsService";
import { useEffect, useState } from "react";
import { backlogItemViewActions } from "../slices/viewSlice";
import NotFound from "../../../../../common/components/Page/NotFound/NotFound";
import BacklogItemContent from "./BacklogItemContent";
import LinkModal from "../../../../link/containers/Create/LinkModal";
import MoveToTaskDialog from "../../moveToTask/containers/MoveToTaskDialog";
import useMedia from "../../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../../common/hooks/useMedia/enums/QueryEnum";
import TaskSkeleton from "../../../../task/modules/view/containers/TaskSkeleton";
import useWindowInnerHeight from "../../../../../common/hooks/useWindowInnerHeight";
import useHeaderHeight from "../../../../../common/containers/Layout/App/Header/hooks/useHeaderHeight";

const BacklogItemViewPage = () => {
  const dispatch = useAppDispatch();
  const { backlogItemRef } = useParams<TBacklogItemPageParams>();
  const [backlogItemExists, setBacklogItemExists] = useState<boolean>(true);
  const [showSkeleton, setShowSkeleton] = useState<boolean>(true);
  const detailsResource = useAppSelector(
    (state) => state.backlogItemView.detailsResource
  );
  const { dispatch: dispatchGetDetails } = useGetBacklogItemDetailsService();
  const isMobileView = useMedia(QueryEnum.MAX_WIDTH_1200);
  const windowHeight = useWindowInnerHeight();
  const { HEADER_HEIGHT } = useHeaderHeight();

  useEffect(() => {
    backlogItemRef &&
      dispatchGetDetails({
        urlPath: `/${backlogItemRef}`,
      })
        .catch(() => {
          setBacklogItemExists(false);
        })
        .finally(() => setShowSkeleton(false));

    return () => {
      dispatch(backlogItemViewActions.reset());
    };
  }, [backlogItemRef]);

  return (
    <>
      {showSkeleton && <TaskSkeleton isMobileView={isMobileView} />}
      {!showSkeleton && backlogItemExists && detailsResource?.backlogItem && (
        <>
          <BacklogItemContent
            detailsResource={detailsResource}
            isMobileView={isMobileView}
            contentHeight={`${windowHeight - HEADER_HEIGHT}px`}
            isPageView
          />

          <LinkModal />

          <MoveToTaskDialog isOnViewPage={true} />
        </>
      )}
      {!backlogItemExists && <NotFound />}
    </>
  );
};

export default BacklogItemViewPage;
