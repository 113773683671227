import ProjectManagementGrid from "./ProjectManagementGrid/ProjectManagementGrid";
import { projectActions } from "../slices/projectSlice";
import { useAppDispatch } from "../../../common/hooks/redux";
import CreateModal from "./CreateProject/CreateModal";
import TitleAndActionButton from "../../../common/components/Title/TitleAndActionButton";

const ProjectManagementPage = () => {
  const dispatch = useAppDispatch();
  return (
    <>
      <TitleAndActionButton
        title="Project Management"
        onClick={() => dispatch(projectActions.openCreationModal())}
        buttonText="Create new project"
      />
      <ProjectManagementGrid />
      <CreateModal />
    </>
  );
};

export default ProjectManagementPage;
