import { useEffect, useState, useTransition } from "react";
import { useDispatch } from "react-redux";
import { userActions } from "../../../../../user/slices/userSlice";
import { useAppSelector } from "../../../../../../common/hooks/redux";
import { boardQueryActions } from "../../../../slices/boardQuerySlice";

const useSelectedUsers = () => {
  const dispatch = useDispatch();

  const userFilter = useAppSelector(
    (state) => state.boardQuery.filters.userIds
  );

  const [selectedIds, setSelectedIds] = useState<number[]>(userFilter);
  const [, startTransition] = useTransition();

  const userFilterHandler = (id: number) => {
    setSelectedIds((prevState) => {
      if (prevState.includes(id)) {
        return prevState.filter((prevId) => prevId !== id);
      } else {
        return [...prevState, id];
      }
    });
    dispatch(boardQueryActions.addUserFilter(id));
  };

  useEffect(() => {
    setSelectedIds(userFilter);
  }, [userFilter]);

  useEffect(() => {
    startTransition(() => {
      dispatch(userActions.setSelectedIds(selectedIds));
      dispatch(userActions.setFilteredList(selectedIds));
    });
  }, [dispatch, selectedIds.length]);

  return {
    userFilterHandler,
  };
};

export default useSelectedUsers;
