import { taskViewActions } from "../../slices/viewSlice";
import { AddAssigneesTypeEnum } from "../../slices/enums/AddAssigneesTypeEnum";
import { Add } from "@mui/icons-material";
import { Button, Tooltip } from "../../../../../../common/modules/materialUI";
import { useAppDispatch } from "../../../../../../common/hooks/redux";
import { ITask } from "../../../../../../entities/ITask";
import { featureUtil } from "../../../../../../common/modules/accessControl/utils/featureUtil";
import { useIsFeatureAllowed } from "../../../../../../common/modules/accessControl/hooks/useIsFeatureAllowed";
import { FeatureEnum } from "../../../../../../common/modules/accessControl/enums/FeatureEnum";
import { systemNotificationActions } from "../../../../../../common/modules/systemNotification/slices/systemNotificationSlice";

type TProps = {
  taskId: ITask["id"];
};

export default function AddAssigneesButton({ taskId }: TProps) {
  const dispatch = useAppDispatch();
  const isManualSplitAllowed = useIsFeatureAllowed(FeatureEnum.MANUAL_SPLIT);

  return (
    <Tooltip
      title={featureUtil.notAvailableText}
      when={!isManualSplitAllowed}
      withSpanWrapper
    >
      <Button
        variant={"text"}
        onClick={() => {
          isManualSplitAllowed
            ? dispatch(
                taskViewActions.openAddAssigneesDialog({
                  taskId: taskId,
                  type: AddAssigneesTypeEnum.ADD_ASSIGNEE,
                })
              )
            : dispatch(
                systemNotificationActions.open({
                  message: featureUtil.notAvailableFullText,
                  variant: "warning",
                })
              );
        }}
        size="small"
        sx={{
          maxWidth: "62px",
          gap: "2px",
          fontSize: "12px",
          textTransform: "capitalize",
        }}
        startIcon={<Add sx={{ fontSize: "13px !important" }} />}
      >
        Add
      </Button>
    </Tooltip>
  );
}
