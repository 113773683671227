import { TMenuItemProps } from "../../../../common/containers/Menu/types/TMenuItemProps";
import Menu from "../../../../common/containers/Menu/Menu";
import EditBoard from "./EditBoard";
import IntegrationMenuItem from "./IntegrationMenuItem";
import { BoardTypeEnum } from "../../../../entities/columns/board/BoardTypeEnum";
import { TBoardRefProp } from "../../types/TBoardRefProp";
import { boardUtil } from "../../../../common/utils/boardUtil";
import {
  Button,
  ConfirmationDialog,
} from "../../../../common/modules/materialUI";
import SettingsIcon from "@mui/icons-material/Settings";
import TaskFieldValues from "./TaskFieldValues";
import { menuItem } from "../../../../common/containers/Menu/utils/menuItem";
import ArchiveBoard from "../Archive/ArchiveBoard";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useArchiveBoardHandler } from "../../hooks/useArchiveBoard";
import { TBoardParams } from "../Board/types/TBoardParams";
import { variantEnum } from "../../../../common/utils/variant";
import { colorEnum } from "../../../../common/utils/color";
import HeaderItem from "../../../../common/containers/Menu/common/HeaderItem";
import useMedia from "../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../common/hooks/useMedia/enums/QueryEnum";
import { AbilityEnum } from "../../../../common/modules/accessControl/enums/AbilityEnum";
import { useAccessControl } from "../../../../common/modules/accessControl/hooks/useAccessControl";

type TProps = {
  projectAbbr: string;
  boardType: BoardTypeEnum;
} & TBoardRefProp;

const BoardMenu = (props: TProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const { projectAbbr, boardRef } = useParams<TBoardParams>();
  const archiveBoard = useArchiveBoardHandler(boardRef!, projectAbbr!);
  const matchesMax768Screen = useMedia(QueryEnum.MAX_WIDTH_768);
  const { can } = useAccessControl();

  return (
    <>
      <Menu
        stretchOnMobile={false}
        button={(clickHandler) => (
          <Button
            size="medium"
            onClick={clickHandler}
            variant="outlined"
            color="inherit"
            isBorderStyle
            sx={{
              p: matchesMax768Screen ? "5px 9px" : "6px 9px",
              borderColor: "_common.black_states.outlinedBorder",
            }}
          >
            <SettingsIcon sx={{ fontSize: "18px" }} />
          </Button>
        )}
        menuItems={
          [
            HeaderItem("Settings"),
            EditBoard(props),
            boardUtil.isTaskDefaultValuesAvailableForType(props.boardType) &&
              TaskFieldValues(props),
            ...(boardUtil.isBoardIntegrationAvailableForType(props.boardType) &&
            can(AbilityEnum.manage)
              ? [IntegrationMenuItem(props)]
              : []),
            menuItem.make({
              component: <ArchiveBoard forMenuItem />,
              hasDivider: false,
              handler: () => {
                setOpen(true);
              },
              menuItemsProps: {
                sx: {
                  marginBottom: 0,
                  py: "8px",
                },
              },
            }),
          ] as TMenuItemProps[]
        }
      />
      <ConfirmationDialog
        open={open}
        title="Archive Board"
        content="Are you sure you want to archive board? You won't be able to unarchived it manually."
        closeHandler={() => setOpen(false)}
        successHandler={archiveBoard}
        disagreeText="Cancel"
        confirmText="Archive"
        confirmColor={colorEnum.ERROR}
        disagreeColor={colorEnum.INHERIT}
        disagreeVariant={variantEnum.OUTLINED}
      />
    </>
  );
};

export default BoardMenu;
