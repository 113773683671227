import { useEffect, useState, useTransition } from "react";
import Grid from "@mui/material/Grid2";
import { DatePicker } from "../../../../../../common/modules/materialUI";
import { systemNotificationActions } from "../../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { useAppDispatch } from "../../../../../../common/hooks/redux";
import { THttpClientError } from "../../../../../../common/modules/httpClient/types/THttpClientError";
import moment from "moment";
import { TDueDateFormProps } from "./types/TDueDateFormProps";
import { EventEnum } from "../../../../../../common/modules/eventProvider/enums/EventEnum";
import { useDispatchEvent } from "../../../../../../common/modules/eventProvider";
import { AggStatusEnum } from "../../../../../../entities/columns/task/AggStatusEnum";
import { TGuesstimationItemUpdatedEvent } from "../types/TGuesstimationItemUpdatedEvent";
import DetailsLabel from "../../../../../task/modules/view/containers/TaskViewDetails/DetailsLabel";
import {
  IResizeGuesstimationItemServiceRequest,
  useResizeGuesstimationItemService,
} from "../../services/useResizeGuesstimationItemService";
import { IGuesstimationItemDetailsResource } from "../../interfaces/IGuesstimationItemDetailsResource";
import { guesstimationItemViewActions } from "../../slices/viewSlice";

const DueDateForm = ({ guesstimationItem }: TDueDateFormProps) => {
  const dispatch = useAppDispatch();
  const dispatchEvent = useDispatchEvent();
  const guesstimationItemDueDate = guesstimationItem.dueDate;

  // The due date must be the end of the previous day if time is 00:00:00
  const [dueDate, setDueDate] = useState<string>(guesstimationItemDueDate);
  const [, startTransition] = useTransition();

  const { dispatch: dispatchResizeGuesstimationItemService } =
    useResizeGuesstimationItemService(guesstimationItem.id);

  useEffect(() => {
    setDueDate(moment(guesstimationItemDueDate).format("YYYY-MM-DD"));
  }, [guesstimationItemDueDate]);

  const submitHandler = (value: Date | null, keyboardInputValue?: string) => {
    if (dueDate === `${value ?? keyboardInputValue}`) {
      return;
    }

    // Save the current due date to revert back in case of error
    const prevDueDate = dueDate;

    // Update the due date in the state
    setDueDate(`${value!}`);

    // Prepare the new due date to send to the server
    const newDueDate = moment(value ?? keyboardInputValue)
      .startOf("day")
      .format("YYYY-MM-DD")
      .toString();

    startTransition(() => {
      dispatchResizeGuesstimationItemService({
        body: {
          dueDate: newDueDate,
        } as IResizeGuesstimationItemServiceRequest,
      })
        .then((payload: IGuesstimationItemDetailsResource) => {
          dispatchEvent(EventEnum.ON_GUESSTIMATION_ITEM_UPDATED, {
            initGuesstimationItem: guesstimationItem,
            guesstimationItem: payload.guesstimationItem,
          } as TGuesstimationItemUpdatedEvent);

          dispatch(guesstimationItemViewActions.setDetailsResource(payload));
        })
        .catch((error: THttpClientError) => {
          // Reverting back the due-date
          setDueDate(prevDueDate);

          if (error.status === 403) {
            dispatch(
              systemNotificationActions.open({
                message: error.data.message,
                variant: "warning",
              })
            );
            return;
          }
          error.status === 422 &&
            (error.data.errors as { collapseError: string }).collapseError &&
            dispatch(
              systemNotificationActions.open({
                message: (error.data.errors as { collapseError: string })
                  .collapseError,
                variant: "warning",
              })
            );

          error.status === 406 &&
            dispatch(
              systemNotificationActions.open({
                message: error.data.message,
                variant: "warning",
              })
            );
          error.status !== 406 &&
            !(error.data.errors as { collapseError: string }).collapseError &&
            dispatch(
              systemNotificationActions.open({
                message:
                  "There was a problem with updating the due date. Please try again.",
                variant: "error",
              })
            );
        });
    });
  };

  return (
    <>
      <Grid size={4}>
        <DetailsLabel>Due Date</DetailsLabel>
      </Grid>
      <Grid size={8}>
        <DatePicker
          disabled={guesstimationItem.statusType === AggStatusEnum.DONE}
          showRequired
          value={moment(dueDate)}
          onChange={submitHandler}
        />
      </Grid>
    </>
  );
};

export default DueDateForm;
