import { Box, Skeleton, Typography } from "@mui/material";
import { useAppSelector } from "../../hooks/redux";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Logo from "./Logo";

export default function Org({
  clickHandler,
  isOpenClose,
  onlyLogo,
}: {
  clickHandler: (event: React.MouseEvent<HTMLElement>) => void;
  isOpenClose: boolean;
  onlyLogo?: boolean;
}) {
  const org = useAppSelector((state) => state.org.init.org);

  return (
    <Box
      id="tip:settings"
      display="flex"
      alignItems="flex-start"
      justifyContent="center"
      sx={{ pl: onlyLogo ? "11px" : "0" }}
    >
      {!org?.name && <Skeleton variant="text" width="50%" />}
      {!!org?.name && (
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          gap={1}
          width="100%"
          onClick={clickHandler}
          sx={{
            cursor: "pointer",
          }}
        >
          <Logo />
          {!onlyLogo && (
            <>
              <Typography
                noWrap
                color="text.primary"
                fontSize="15px"
                variant="subtitle2"
              >
                {org.name}
              </Typography>
              <ArrowDropDownIcon
                sx={{
                  transform: isOpenClose ? "rotate(180deg)" : "rotate(0deg)",
                  transition: "transform 0.2s",
                  color: "action.active",
                }}
              />
            </>
          )}
        </Box>
      )}
    </Box>
  );
}
