import { TBoardSettingsNavigationContentProps } from "./types/TBoardSettingsNavigationContentProps";
import List from "../../common/List/List";
import { useParams } from "react-router-dom";
import { TBoardParams } from "../../../../../../../modules/board/containers/Board/types/TBoardParams";
import BoardSettingsSection from "./BoardSettingsSection";
import { useAppSelector } from "../../../../../../hooks/redux";
import NavigationTitle from "../../../../../../components/navigationTitle/NavigationTitle";

const BoardSettingsNavigationContent = ({
  navigationRequestedHandler,
  orgInitDataLoading,
}: TBoardSettingsNavigationContentProps) => {
  const { projectAbbr, boardRef } = useParams<TBoardParams>();
  const projects = useAppSelector((state) => state.org.init.list);
  const board = projects
    .find((item) => item.project.abbr === projectAbbr)
    ?.boards.find((board) => board.ref === boardRef);

  return (
    <>
      {orgInitDataLoading && (
        <List isDropdownList title="Loading Board" loading={true} />
      )}
      {!orgInitDataLoading && !!board && (
        <>
          <NavigationTitle
            title={board.name ? board.name + " Settings" : "Loading..."}
          />
          <BoardSettingsSection
            onClick={navigationRequestedHandler}
            projectAbbr={projectAbbr}
            boardRef={boardRef}
            board={board}
          />
        </>
      )}
    </>
  );
};

export default BoardSettingsNavigationContent;
