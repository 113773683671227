import { useTheme } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { DashboardCustomizeRounded } from "@mui/icons-material";
import Loader from "../../../../common/containers/Loader/Loader";
import EntityCustomFieldForm from "../EntityCustomFieldForm/EntityCustomFieldForm";
import { TCustomFieldSectionProps } from "./types/TCustomFieldSectionProps";
import useFetchCustomFieldData from "../../hooks/useFetchCustomFieldData";
import Accordion from "../../../../common/modules/taskView/containers/Sections/Accordion";

export default function CustomFieldSection(props: TCustomFieldSectionProps) {
  const { isCustomFieldsLoading, customFieldsResource } =
    useFetchCustomFieldData(
      props.boardRef,
      props.autoFetch,
      props.setHavingCustomFields
    );
  const theme = useTheme();

  const data = customFieldsResource ?? props.resource;

  return (
    <>
      {!!data?.customFields?.length && (
        <Accordion
          title="Custom Fields"
          icon={<DashboardCustomizeRounded />}
          detailsSx={{
            bgcolor: `${
              props.isMobileView
                ? theme.palette.taskView.mainContentBackground
                : theme.palette.taskView.mainBackground
            } !important`,
          }}
        >
          {isCustomFieldsLoading && <Loader />}
          <Grid container spacing="20px" alignItems="center">
            {data!.customFields.map((customField) => (
              <EntityCustomFieldForm
                key={customField.id}
                customField={customField}
                customFieldValues={props.customFieldValues}
                dispatchUpdateService={props.dispatchUpdateService}
              />
            ))}
          </Grid>
        </Accordion>
      )}
    </>
  );
}
