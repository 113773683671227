import useForm from "../../../../../common/hooks/useForm/useForm";
import Form from "../../../../../common/components/Form/Form";
import { THttpClientError } from "../../../../../common/modules/httpClient/types/THttpClientError";
import { IOAuthResource } from "../../../interfaces/IOAuthResource";
import { useNavigate } from "react-router-dom";
import { Button, TextField } from "../../../../../common/modules/materialUI";
import { jwt } from "../../../../../common/utils/jwt";
import { TSignupFormAttributes } from "./types/TSignupFormAttributes";
import PasswordField from "../containers/Password/PasswordField";
import { useUserSignUpService } from "../../../services/useUserSignUpService";
import { ROUTE_ON_SIGNIN } from "../Signin/TSSigninForm";
import { Typography } from "@mui/material";
import useMedia from "../../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../../common/hooks/useMedia/enums/QueryEnum";
import { TTSSignupFormProps } from "./types/TTSSignupFormProps";

const TSSignupForm = (props: TTSSignupFormProps) => {
  const isTouchDevice = useMedia(QueryEnum.IS_TOUCH_DEVICE);
  const navigate = useNavigate();
  const form = useForm<TSignupFormAttributes>();
  const { isLoading, dispatch: dispatchUserSignUp } = useUserSignUpService();

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();

    dispatchUserSignUp({
      body: {
        key: props.signupKey,
        ...form.data,
      },
    })
      .then((payload: IOAuthResource) => {
        jwt.store(payload.accessToken);
        navigate(ROUTE_ON_SIGNIN, { state: null });
      })
      .catch((error: THttpClientError) => {
        form.errorHandler(error);
      });
  };

  return (
    <Form
      onSubmit={submitHandler}
      actionElement={
        <Button
          disabled={isLoading}
          loading={isLoading}
          variant="text"
          type="submit"
          fullWidth
          size="large"
        >
          Sign Up
        </Button>
      }
    >
      {form.hasError("key") && (
        <Typography fontWeight="bold" variant="caption" color="error">
          {form.getError("key")}
        </Typography>
      )}

      <TextField
        forceFocus={!isTouchDevice}
        form={form}
        name="name"
        label="Name"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        showRequired
      />

      <PasswordField form={form} showHint />

      <PasswordField
        form={form}
        name="password_confirmation"
        label="Password Confirmation"
      />
    </Form>
  );
};

export default TSSignupForm;
