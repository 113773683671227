import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import useForm from "../../../../common/hooks/useForm/useForm";
import Form from "../../../../common/components/Form/Form";
import { THttpClientError } from "../../../../common/modules/httpClient/types/THttpClientError";
import {
  Autocomplete,
  Button,
  TextField,
} from "../../../../common/modules/materialUI";
import { TFormAttributesTransformed } from "./types/TFormAttributesTransformed";
import { TFormAttributes } from "./types/TFormAttributes";
import { enumUtil } from "../../../../common/utils/enum";
import { systemNotificationActions } from "../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { useReportContentService } from "../../services/useReportContentService";
import { ReportContentReasonEnum } from "../../enums/ReportContentReasonEnum";

type TProps = {
  onCancel: () => void;
};

const CreateForm = (props: TProps) => {
  const userMessage = useAppSelector((state) => state.aiSentinel.userMessage);
  const aiTextMessage = useAppSelector(
    (state) => state.aiSentinel.aiTextMessage
  );

  const dispatch = useAppDispatch();
  const form = useForm<TFormAttributes>({
    request: userMessage,
    response: aiTextMessage,
  });
  const { isLoading, dispatch: dispatchCreateTask } = useReportContentService();

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();

    dispatchCreateTask({
      body: form.getTransformed<TFormAttributesTransformed>((data) => {
        return {
          ...data,
          reason: data.reason?.id,
        };
      }),
    })
      .then(() => {
        dispatch(
          systemNotificationActions.open({
            message: "Your report has been successfully submitted. Thank you!",
            variant: "success",
          })
        );
        props.onCancel();
      })
      .catch((error: THttpClientError) => {
        form.errorHandler(error);
        if (error.status !== 422) {
          dispatch(
            systemNotificationActions.open({
              message: "Something went wrong, please try again.",
              variant: "error",
            })
          );
        }
      });
  };

  return (
    <Form
      onSubmit={submitHandler}
      actionElement={
        <>
          <Button
            onClick={props.onCancel}
            disabled={isLoading}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            disabled={isLoading || !form.isDirty()}
            variant="contained"
            type="submit"
            loading={isLoading}
          >
            Report
          </Button>
        </>
      }
    >
      <Autocomplete
        form={form}
        name="reason"
        label="Reason"
        options={enumUtil.toList(ReportContentReasonEnum)}
        showRequired
      />

      {form.data?.reason?.id === ReportContentReasonEnum.OTHER && (
        <TextField
          form={form}
          name="otherReason"
          label="Provide Reason"
          showRequired
        />
      )}
    </Form>
  );
};

export default CreateForm;
