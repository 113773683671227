import useTaskType from "../../../../../../common/modules/task/hooks/useTaskType";
import useTaskPriority from "../../../../../../common/modules/task/hooks/useTaskPriority";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/hooks/redux";
import { boardQueryActions } from "../../../../slices/boardQuerySlice";
import useSprint from "../../../../../../common/modules/task/hooks/useSprint";
import useCustomField from "../../../../../../common/modules/task/hooks/useCustomField";
import { useEffect, useState } from "react";
import MultiMenu from "../../../../../../common/modules/materialUI/components/MultiMenu/MultiMenu";
import useFilterTitle from "../../../../../../common/modules/task/hooks/useFilterTitle";
import FilterButton from "../../FilterButton/FilterButton";
import useUserFilter from "../../../../../../common/modules/task/hooks/useUserFilter";
import useMedia from "../../../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../../../common/hooks/useMedia/enums/QueryEnum";
import { IUser } from "../../../../../../entities/IUser";
import { Box } from "@mui/material";
import { Button } from "../../../../../../common/modules/materialUI";
import Badge from "../../../../../../common/modules/materialUI/components/Badge/Badge";
import { userActions } from "../../../../../user/slices/userSlice";

export type TFilterProps = {
  users: IUser[];
};

const Filtering = ({ users }: TFilterProps) => {
  const dispatch = useAppDispatch();

  const filters = useAppSelector((state) => state.boardQuery.extraFilters);
  const sprintList = useAppSelector((state) => state.listing.sprintList);
  const selectedUsersId = useAppSelector((state) => state.user.selectedIds);

  const [filterCount, setFilterCount] = useState(0);
  const [activeFilterList, setActiveFilterList] = useState<{
    filteredItem: string;
  }>({
    filteredItem: "Type",
  });
  const matchesMax768Screen = useMedia(QueryEnum.MAX_WIDTH_768);

  const taskTypeUtil = useTaskType();
  const taskPriorityUtil = useTaskPriority();

  const sprintComponent = useSprint().listFilter(filters.sprintRefs);

  const userListComponent = useUserFilter({
    users,
  });

  const customFieldComponent = useCustomField({
    isBacklog: false,
  }).listFilters(filters.customFields);

  const filtrationTitle = useFilterTitle({
    filters: filters,
    usersFilteredCount: matchesMax768Screen ? selectedUsersId?.length ?? 0 : 0,
    showUser: users.length > 0,
    showSprint: sprintList !== undefined && sprintList.length > 0,
  }).titleList(activeFilterList.filteredItem, (filteredItem: string) => {
    setActiveFilterList({
      filteredItem,
    });
  });

  const taskTypeComponent = taskTypeUtil.listFilter(
    filters.taskTypeIds,
    (values) => {
      dispatch(
        boardQueryActions.setExtraFiltering({
          ...filters,
          taskTypeIds: values,
        })
      );
    }
  );
  const taskPriorityComponent = taskPriorityUtil.listFilter(
    filters.taskPriorityIds,
    (values) =>
      dispatch(
        boardQueryActions.setExtraFiltering({
          ...filters,
          taskPriorityIds: values,
        })
      )
  );

  const clearHandler = () => {
    dispatch(boardQueryActions.clearExtraFiltering());
    if (matchesMax768Screen) {
      dispatch(userActions.deleteSelectedIds());
    }
  };

  useEffect(() => {
    setFilterCount(
      filters.taskTypeIds.length +
        filters.taskPriorityIds.length +
        filters.sprintRefs.length +
        filters.customFields.length +
        (matchesMax768Screen ? selectedUsersId?.length ?? 0 : 0)
    );
  }, [filters, selectedUsersId.length, matchesMax768Screen]);

  useEffect(() => {
    setActiveFilterList({
      filteredItem: "Type",
    });
  }, [matchesMax768Screen]);

  return (
    <MultiMenu
      closeOnSelection={false}
      button={(clickHandler) => (
        <FilterButton clickHandler={clickHandler} filterCount={filterCount} />
      )}
      headerSection={
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mx="18px"
          my="8px"
          height="22px"
        >
          <Badge variant="outlined">Filters</Badge>
          {filterCount > 0 && (
            <Button
              size="small"
              variant="text"
              color="error"
              onClick={clearHandler}
            >
              Clear Filters
            </Button>
          )}
        </Box>
      }
      menus={[
        {
          items: filtrationTitle,
          when: true,
        },
        {
          items: taskTypeComponent,
          when: activeFilterList.filteredItem === "Type",
        },
        {
          items: taskPriorityComponent,
          when: activeFilterList.filteredItem === "Priority",
        },
        {
          items: sprintComponent,
          when: activeFilterList.filteredItem === "Sprint",
        },
        {
          items: userListComponent,
          when: activeFilterList.filteredItem === "People" && users.length >= 0,
        },
        ...(customFieldComponent?.flatMap((items) => {
          return {
            items: items.content,
            when: items.label === activeFilterList.filteredItem,
          };
        }) ?? []),
      ]}
    />
  );
};

export default Filtering;
