import { Box, Tooltip } from "@mui/material";
import useGetTaskPriority from "../../../../../../modules/taskPriority/hooks/useGetTaskPriority";
import { TaskHelper } from "../../../helpers/TaskHelper";
import useGetTaskType from "../../../../../../modules/taskType/hooks/useGetTaskType";
import TaskCost from "../TaskCost";
import { pointConverter } from "../../../../../utils/pointConverter";
import { ITaskSegmentCost } from "../../../../../../entities/transientProps/taskSegment/ITaskSegmentCost";
import { lightTheme } from "../../../../../modules/theme/lightTheme";

const TaskIcons = ({
  taskPriorityId,
  taskTypeId,
  size,
  displayCostPerItem,
  cost,
}: {
  taskPriorityId?: number;
  taskTypeId?: number;
  size?: number;
  displayCostPerItem?: boolean;
  cost?: ITaskSegmentCost;
}) => {
  const sizeXs = TaskHelper.sizeXs(size!);
  const sizeSs = TaskHelper.sizeSs(size!);
  const sizeMd = TaskHelper.sizeMd(size!);
  const sizeLg = TaskHelper.sizeLg(size!);

  const taskPriority = useGetTaskPriority({
    taskPriorityId: taskPriorityId,
  });
  const taskType = useGetTaskType({
    taskTypeId: taskTypeId,
  });

  const showCostOnTheCard = size! > pointConverter.workingHoursToPixels(3);
  const shouldDisplayCost = displayCostPerItem && !!cost;

  return (
    <Box display={"flex"} alignItems={"center"} gap={"4px"} width={"90%"}>
      {(shouldDisplayCost && showCostOnTheCard && sizeSs) ||
        (taskPriority && !sizeXs && (
          <Tooltip title={taskPriority.name} arrow>
            <img
              src={`${process.env.CDN_URL}/${taskPriority.iconFilePath}`}
              alt={taskPriority.name}
              width={16}
              height={16}
              style={{ display: "flex" }}
            />
          </Tooltip>
        ))}
      {(shouldDisplayCost && showCostOnTheCard && sizeMd) ||
        (taskType && !sizeSs && (
          <Tooltip title={taskType.name} arrow>
            <img
              src={`${process.env.CDN_URL}/${taskType.iconFilePath}`}
              alt={taskType.name}
              width={16}
              height={16}
              style={{ display: "flex" }}
            />
          </Tooltip>
        ))}
      {showCostOnTheCard && shouldDisplayCost && (
        <TaskCost
          cost={cost!}
          sx={{
            display: "inline-grid",
            padding: `3px ${
              shouldDisplayCost && sizeLg ? (!sizeSs ? "0" : "2px") : "4px"
            }`,
            bgcolor: "secondary.main",
            color: lightTheme.palette.text.primary,
            borderRadius: 1,
            height: "20px",
            width: "auto",
            marginRight: "2px",
          }}
        />
      )}
    </Box>
  );
};

export default TaskIcons;
