import Menu from "../../../../common/containers/Menu/Menu";
import {
  Description,
  Lightbulb,
  PlayArrow,
  YouTube,
} from "@mui/icons-material";
import { IconButton, ListItemIcon, Tooltip, Typography } from "@mui/material";
import { menuItem } from "../../../../common/containers/Menu/utils/menuItem";
import { NavLink } from "react-router-dom";
import useWindow from "../../../../common/hooks/useWindow";
import { lightTheme } from "../../../../common/modules/theme/lightTheme";
import { Button } from "../../../../common/modules/materialUI";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import { tipsSliderActions } from "../../../tip/slices/tipsSliderSlice";

const DOCS_URL = "https://tasksensor.com/docs/" as const;
const YOUTUBE_URL = "https://www.youtube.com/@TaskSensor" as const;

const InfoCenter = () => {
  const { openNewTab } = useWindow();
  const dispatch = useAppDispatch();
  const { name, data } = useAppSelector(
    (state) => state.tipsSlider.featureData
  );

  return (
    <>
      <Menu
        sx={{
          mt: 1,
          width: "233px",
          // width: "fit-content",
        }}
        padding="8px 0 1px"
        button={(clickHandler) => (
          <Tooltip title="Info Center">
            <IconButton
              id="tip:info-center"
              onClick={clickHandler}
              sx={{
                bgcolor: lightTheme.palette.action.active,
                p: "4px",
              }}
            >
              <Lightbulb sx={{ color: "common.white" }} />
            </IconButton>
          </Tooltip>
        )}
        menuItems={[
          // menuItem.make({
          //   component: (
          //     <>
          //       <ListItemIcon>
          //         <Star />
          //       </ListItemIcon>
          //       <Typography variant="body2" color="textPrimary">
          //         What’s New?
          //       </Typography>
          //     </>
          //   ),
          //   hasDivider: false,
          // }),
          menuItem.make({
            component: (
              <NavLink to={DOCS_URL} style={{ width: "100%" }}>
                <ListItemIcon>
                  <Description
                    sx={{
                      color: "action.active",
                    }}
                  />
                </ListItemIcon>
                <Typography variant="body2" color="textPrimary">
                  Open Docs
                </Typography>
              </NavLink>
            ),
            handler: () => {
              openNewTab(DOCS_URL);
            },
            hasDivider: false,
          }),
          menuItem.make({
            component: (
              <NavLink to={YOUTUBE_URL} style={{ width: "100%" }}>
                <ListItemIcon>
                  <YouTube
                    sx={{
                      color: "action.active",
                    }}
                  />
                </ListItemIcon>
                <Typography variant="body2" color="textPrimary">
                  Open Youtube
                </Typography>
              </NavLink>
            ),
            handler: () => {
              openNewTab(YOUTUBE_URL);
            },
            hasDivider: false,
          }),
          menuItem.make({
            component: (
              <Typography variant="caption" color="textPrimary">
                Learn details about {name}
              </Typography>
            ),
            isHeaderItem: true,
            hasDivider: false,
            headerItemProps: {
              sx: {
                m: 0,
                py: 0,
                pb: "4px",
              },
            },
            when: data.length > 0,
          }),
          menuItem.make({
            component: (
              <Button
                size="small"
                variant="text"
                disableRipple
                startIcon={<PlayArrow sx={{ color: "primary.main" }} />}
                sx={{
                  p: "5px 3px",
                  "&:hover": {
                    background: "none",
                  },
                }}
                onClick={() => dispatch(tipsSliderActions.openTipsSlider())}
              >
                Continue learning
              </Button>
            ),
            when: data.length > 0,
            menuItemsProps: {
              sx: {
                borderBottomLeftRadius: "8px",
                borderBottomRightRadius: "8px",
                py: 0,
              },
            },
          }),
        ]}
      />
    </>
  );
};

export default InfoCenter;
