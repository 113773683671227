import { Button, Tooltip } from "../../../common/modules/materialUI";
import { useContactSupportHandler } from "../hooks/useContactSupportHandler";
import { ContactSupport } from "@mui/icons-material";
import { useAppSelector } from "../../../common/hooks/redux";

const HelpCenterIcon = () => {
  const { openSupportChat } = useContactSupportHandler();
  const projectList = useAppSelector((state) => state.org.init.list);

  const shouldAiRender = projectList.length > 0;

  return (
    <Tooltip
      title={
        <>
          We are here for you. <br />
          Contact us anytime if you need assistance.
        </>
      }
    >
      <Button
        id="tip:live-chat"
        onClick={openSupportChat}
        disableFocusRipple={true}
        sx={{
          p: shouldAiRender ? "8px" : "10px",
          borderRadius: "50%",
          bgcolor: "primary.light",
        }}
      >
        <ContactSupport
          fontSize={shouldAiRender ? undefined : "large"}
          sx={{ color: "common.black" }}
        />
      </Button>
    </Tooltip>
  );
};

export default HelpCenterIcon;
