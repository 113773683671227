import { Box, MenuItem, Typography } from "@mui/material";
import { Tooltip } from "../../materialUI";
import useMedia from "../../../hooks/useMedia/useMedia";
import { QueryEnum } from "../../../hooks/useMedia/enums/QueryEnum";
import AvatarIcon from "../../../../modules/user/containers/AvatarIcon/AvatarIcon";
import classes from "../../../../modules/boardView/containers/Filter/Avatar/Avatar.module.css";
import { TFilterProps } from "../../../../modules/boardView/containers/Filter/Filter/SectionFilter/Filtering";
import { useAppSelector } from "../../../hooks/redux";
import useSelectedUsers from "../../../../modules/boardView/containers/Filter/Avatar/hooks/useSelectedUsers";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";

const useUserFilter = ({ users }: TFilterProps) => {
  const matchesMax768Screen = useMedia(QueryEnum.MAX_WIDTH_768);
  const selectedUsersId = useAppSelector((state) => state.user.selectedIds);

  const { userFilterHandler } = useSelectedUsers();

  return users.length > 0 && matchesMax768Screen ? (
    <>
      {users.map((user, index) => (
        <MenuItem
          key={user.id}
          sx={{
            height: "32px",
            minHeight: "32px",
            p: "4px 16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          onClick={() => userFilterHandler(user.id)}
        >
          <Tooltip title={user.name}>
            <Box display="flex" alignItems="center" height={"100%"}>
              <AvatarIcon
                className={classes.icon}
                key={index}
                userName={user.name}
                size={25}
                fontSize={10}
                isUnassigned={user.id === 0}
                sx={{
                  padding: 0,
                }}
              />

              <Typography noWrap ml="22px" maxWidth="69px" color="text.primary">
                {user.name}
              </Typography>
            </Box>
          </Tooltip>
          <Tooltip title="Move Top">
            {selectedUsersId.findIndex(
              (selectedUserId: number) => selectedUserId === user.id
            ) !== -1 ? (
              <BookmarkIcon
                sx={{ color: "secondary.main", fontSize: "20px" }}
              />
            ) : (
              <BookmarkBorderIcon
                sx={{ color: "action.active", fontSize: "20px" }}
              />
            )}
          </Tooltip>
        </MenuItem>
      ))}
    </>
  ) : null;
};

export default useUserFilter;
