import { useRef } from "react";
import { PositionEnum } from "../containers/LaneItem/LaneItemHorizontalLarge";

export interface StickyElementsRef {
  startStickElement: HTMLDivElement | null;
  endStickElement: HTMLDivElement | null;
}

const useStickyElements = (stickyOrStatic?: PositionEnum) => {
  const ref = useRef<StickyElementsRef>({
    startStickElement: null,
    endStickElement: null,
  });

  if (stickyOrStatic === PositionEnum.STATIC) return;

  return ref;
};

export default useStickyElements;
