import TListItemProps from "../../../../../../components/Navigation/ListItem/types/TListItemProps";
import { PriorityHigh } from "@mui/icons-material";
import { TSectionProps } from "../../types/TSectionProps";

const TaskPrioritiesListItem = (props: TSectionProps) =>
  ({
    id: "TaskPrioritiesListItem",
    name: "Task Priorities",
    icon: (
      <PriorityHigh style={{ color: props.theme?.palette.action.active }} />
    ),
    path: "/settings/taskPriorities",
    navigateToPath: true,
    onNavigate: props.onClick,
  } as TListItemProps);

export default TaskPrioritiesListItem;
