import { GridColDef } from "@mui/x-data-grid";
import { IStripeInvoiceResource } from "../../../interfaces/IStripeInvoiceResource";
import useWindow from "../../../../../common/hooks/useWindow";
import { IconButton, Tooltip } from "@mui/material";
import { Download } from "@mui/icons-material";

export const Columns: () => GridColDef<IStripeInvoiceResource>[] = () => {
  const { openNewTab } = useWindow();

  return [
    {
      field: "actions",
      headerName: "Actions",
      width: 90,
      hideable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          params.row.pdfDownloadUrl && (
            <Tooltip title={`Download Invoice PDF`}>
              <IconButton
                onClick={() => openNewTab(params.row.pdfDownloadUrl)}
                color="primary"
              >
                <Download color="primary" fontSize="small" />
              </IconButton>
            </Tooltip>
          )
        );
      },
    },
    {
      field: "invoiceNumber",
      headerName: "Invoice #",
      width: 170,
      renderCell: (params) => {
        return params.row.invoiceNumber ? params.row.invoiceNumber : "N/A";
      },
    },
    {
      field: "status",
      headerName: "Invoice Status",
      width: 170,
    },
    {
      field: "billingReason",
      headerName: "Billing Reason",
      width: 170,
    },
    {
      field: "subtotal",
      headerName: "Subtotal",
      width: 170,
    },
    {
      field: "currency",
      headerName: "Currency",
      width: 170,
    },
    {
      field: "createdAt",
      headerName: "Date Added",
      width: 170,
    },
  ];
};
