import { useHttpClientDispatchable } from "../../../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { RequestMethodEnum } from "../../../../../common/modules/httpClient/enums/RequestMethodEnum";
import { IGuesstimationItemDetailsResource } from "../interfaces/IGuesstimationItemDetailsResource";

export const useRemoveGuesstimationItemInactiveAssigneeService = (props: {
  itemId: number;
}) => {
  const response = useHttpClientDispatchable({
    url: `/guesstimationItem/${props.itemId}/removeInactiveAssignee`,
    method: RequestMethodEnum.PATCH,
  });

  return {
    ...response,
    data: response.data as IGuesstimationItemDetailsResource,
  };
};
