import { TFirebaseEventParams } from "./types/TFirebaseEventParams";
import { analytics } from "../components/FirebaseConfig";
import { logEvent } from "firebase/analytics";
import { useAppSelector } from "../../../hooks/redux";
import { useEffect } from "react";

let queue: { eventName: string; eventParams?: TFirebaseEventParams }[] = [];

export function useFirebaseEventHelper() {
  const org = useAppSelector((state) => state.org.init.org);
  const userJwt = useAppSelector((state) => state.user.userInfo);

  const readyToLog = !!analytics && !!org?.id && !!userJwt?.id;

  const logFirebaseEvent = (
    eventName: string,
    eventParams?: TFirebaseEventParams
  ) => {
    logEvent(analytics!, eventName, {
      ...eventParams,
      organization_id: org!.id,
      organization_name: org!.name,
      user_id: userJwt!.id,
      user_name: userJwt!.name,
    });
  };

  useEffect(() => {
    if (!readyToLog) {
      return;
    }

    while (queue.length) {
      const { eventName, eventParams } = queue.shift()!;
      logFirebaseEvent(eventName, eventParams);
    }
  }, [analytics !== undefined, org?.id, userJwt?.id, readyToLog]);

  return {
    addToFirebaseQueue: (
      eventName: string,
      eventParams?: TFirebaseEventParams
    ) => {
      if (readyToLog) {
        logFirebaseEvent(eventName, eventParams);
        return;
      }

      // If still not ready to log, add to queue
      queue.push({ eventName, eventParams });
    },
  };
}
