import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  useTheme,
} from "@mui/material";
import { TSectionProps } from "./types/TSectionProps";

export default function Section(props: TSectionProps) {
  const theme = useTheme();

  return (
    <Card sx={props.sx}>
      <CardHeader
        title={props.title}
        titleTypographyProps={{
          variant: "subtitle2",
        }}
      />
      <CardContent
        style={{ backgroundColor: theme.palette.cardsBackground.default }}
      >
        {props.subtitle && (
          <Typography variant="caption" component="div" sx={{ mb: 2 }}>
            {props.subtitle}
          </Typography>
        )}
        {props.children}
      </CardContent>
    </Card>
  );
}
