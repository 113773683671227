import { DialogContent } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useAppDispatch } from "../../../../../../common/hooks/redux";
import { initiativeActions } from "../../../../slices/initiativeSlice";
import DialogTitle from "@mui/material/DialogTitle";
import { TNotesModalProps } from "./types/TNotesModalProps";
import { Dialog } from "../../../../../../common/modules/materialUI";
import NoteTabs from "./noteTabs/NoteTabs";
import useMedia from "../../../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../../../common/hooks/useMedia/enums/QueryEnum";

const NotesModal = (props: TNotesModalProps) => {
  const dispatch = useAppDispatch();
  const isMobileView = useMedia(QueryEnum.MAX_WIDTH_1200);

  const closeHandler =
    props.closeHandler || (() => dispatch(initiativeActions.closeNotesModal()));

  return (
    <Dialog
      closeButtonHandler={closeHandler}
      renderCloseButton
      open={props.show}
      onClose={closeHandler}
      fullWidth
      maxWidth={"lg"}
      fullScreen={isMobileView}
    >
      <DialogTitle>Notes</DialogTitle>
      <DialogContent>
        <Grid container mb="30px" flexDirection="column" gap="8px">
          <NoteTabs
            initiatives={props?.initiatives || []}
            boardId={props.boardId}
            isBoardView={props.isBoardView}
            isMobileView={isMobileView}
          />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default NotesModal;
