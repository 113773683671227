import { enumUtil } from "../../../../../common/utils/enum";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  ListItem,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Skeleton } from "../../../../../common/modules/materialUI";
import { useMemo } from "react";
import { AggStatusEnum } from "../../../../../entities/columns/task/AggStatusEnum";

const KanbanViewSkeleton = () => {
  const theme = useTheme();

  const skeletonCards = useMemo(
    () =>
      Array.from({
        length: Math.floor(Math.random() * 3) + 1,
      }).map((_, index) => (
        <Card
          key={index}
          sx={{
            height: "104px",
            margin: "4px 0",
            borderRadius: "12px",
          }}
        >
          <CardContent sx={{ p: "8px 8px 0" }}>
            <Skeleton variant="text" width="20%" />
          </CardContent>
          <CardActions
            disableSpacing
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              paddingBottom: 0,
            }}
          >
            <Skeleton variant="text" width="100%" />
            <Skeleton variant="text" width="40%" />
          </CardActions>
          <CardActions
            disableSpacing
            sx={{
              paddingTop: 0,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", flex: 1, gap: "8px" }}>
              <Skeleton variant="text" width="15%" />
              <Skeleton variant="text" width="15%" />
            </Box>
            <Box
              sx={{
                display: "flex",
                flex: 1,
                justifyContent: "flex-end",
                gap: "8px",
                alignItems: "center",
              }}
            >
              <Skeleton variant="circular" width="24px" height="24px" />
              <Skeleton variant="circular" width="16px" height="16px" />
            </Box>
          </CardActions>
        </Card>
      )),
    []
  );

  return (
    <div
      style={{
        overflowX: "auto",
        display: "flex",
      }}
    >
      {enumUtil.toList(AggStatusEnum).map((status, index) => {
        return (
          <Card
            key={index}
            style={{
              marginRight: "10px",
              flexGrow: 0,
              flexShrink: 0,
              borderRadius: "12px",
            }}
          >
            <CardContent
              style={{
                width: "316px",
                padding: 0,
                height: "100%",
              }}
            >
              <Grid container>
                <Grid size={12}>
                  <ListItem
                    disableGutters
                    sx={{ padding: "0 16px", height: "50px" }}
                  >
                    <Skeleton variant="text" width="40%" />
                  </ListItem>
                </Grid>
                <Grid
                  size={12}
                  style={{
                    backgroundColor:
                      theme.palette.backgroundElevation
                        .backgroundPaperElevation_1,
                    padding: "8px",
                    height: "100%",
                  }}
                >
                  {skeletonCards}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        );
      })}
    </div>
  );
};

export default KanbanViewSkeleton;
