import Title from "../../../../common/components/Title/Title";
import InvoiceGrid from "./InvoiceGrid/InvoiceGrid";
import { AbilityEnum } from "../../../../common/modules/accessControl/enums/AbilityEnum";
import NotFound from "../../../../common/components/Page/NotFound/NotFound";
import { useAccessControl } from "../../../../common/modules/accessControl/hooks/useAccessControl";

const InvoicePage = () => {
  const { can, acLoaded } = useAccessControl();

  if (!can(AbilityEnum.financing)) {
    return <>{acLoaded && <NotFound />}</>;
  }

  return (
    <>
      <Title>Invoices</Title>
      <InvoiceGrid />
    </>
  );
};

export default InvoicePage;
