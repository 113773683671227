import Menu from "./Menu";
import { Button } from "../../modules/materialUI";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { TMoreOptionsMenuProps } from "./types/TMoreOptionsMenuProps";
import useMedia from "../../hooks/useMedia/useMedia";
import { QueryEnum } from "../../hooks/useMedia/enums/QueryEnum";

const MoreOptionsMenu = (props: TMoreOptionsMenuProps) => {
  const matchesMax768Screen = useMedia(QueryEnum.MAX_WIDTH_768);

  return (
    <Menu
      stretchOnMobile={false}
      button={(clickHandler) => (
        <Button
          size={"small"}
          color={"inherit"}
          onClick={clickHandler}
          variant="outlined"
          isBorderStyle
          sx={{
            p: matchesMax768Screen ? "4px 9px" : "4px 10px",
          }}
          startIcon={<MoreVertIcon sx={{ fontSize: "18px" }} />}
        />
      )}
      menuItems={props.menuItems}
    />
  );
};

export default MoreOptionsMenu;
