import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Skeleton } from "../../../../../common/modules/materialUI";
import MuiAccordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Fragment } from "react";
import { TIsMobileViewProp } from "../../../../../common/modules/taskView/containers/types/TIsMobileViewProp";

const TaskSkeleton = ({ isMobileView }: TIsMobileViewProp) => {
  const textHeight = "25px";

  return (
    <Grid
      container
      spacing={2}
      sx={{ p: isMobileView ? "20px 16px" : "20px 26px" }}
    >
      <Grid size={12}>
        <Skeleton variant="text" width="30%" height={textHeight} />
      </Grid>

      {/*Left Container*/}
      <Grid
        size={isMobileView ? 12 : 7}
        sx={{
          overflowY: "auto",
          height: !isMobileView ? "800px" : null,
          pr: "25px",
        }}
      >
        <Grid container>
          <Grid size={12}>
            <Skeleton variant="text" width="80%" height="40px" />
          </Grid>
        </Grid>
        <Grid container sx={{ pt: "10px", mb: "40px" }}>
          <Grid size={12}>
            {/* Description */}
            <Skeleton variant="rounded" width="100%" height="150px" />
          </Grid>
        </Grid>
        {Array.from({ length: 4 }).map((value, index) => (
          <Grid container alignItems="center" key={index} sx={{ mt: 4 }}>
            <Grid
              size={{
                xs: 3,
                md: 1,
              }}
            >
              <Skeleton variant="circular" width="50px" height="50px" />
            </Grid>
            <Grid
              size={{
                xs: 9,
                md: 11,
              }}
              gap="5px"
            >
              <Skeleton variant="text" width="60%" height={textHeight} />
              <Skeleton variant="rounded" width="100%" height="50px" />
            </Grid>
          </Grid>
        ))}
      </Grid>

      {/*Right Container*/}
      {!isMobileView && (
        <Grid size={5}>
          <Grid container>
            <Grid size={12}>
              <Skeleton variant="text" width="25%" height="40px" />
            </Grid>
          </Grid>

          <Grid container sx={{ mt: "15px" }}>
            <Grid size={12}>
              <div>
                <MuiAccordion defaultExpanded={true}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                  ></AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      {Array.from({ length: 5 }).map((value, index) => (
                        <Fragment key={index}>
                          <Grid size={5}>
                            <Skeleton
                              variant="text"
                              width="50%"
                              height="30px"
                            />
                          </Grid>
                          <Grid size={7}>
                            <Skeleton
                              variant="text"
                              width="100%"
                              height="30px"
                            />
                          </Grid>
                        </Fragment>
                      ))}
                    </Grid>
                  </AccordionDetails>
                </MuiAccordion>
                <Grid container>
                  <Grid size={12}>
                    <Typography variant="caption">
                      <Skeleton variant="text" width="60%" height="30px" />
                    </Typography>
                  </Grid>
                  <Grid size={12}>
                    <Typography variant="caption">
                      <Skeleton variant="text" width="60%" height="30px" />
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default TaskSkeleton;
