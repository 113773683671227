import useForm from "../../../../../../common/hooks/useForm/useForm";
import Form from "../../../../../../common/components/Form/Form";
import { Button, TextField } from "../../../../../../common/modules/materialUI";
import Grid from "@mui/material/Grid2";
import { THttpClientError } from "../../../../../../common/modules/httpClient/types/THttpClientError";
import TUpdateFormProps from "./types/TUpdateFormProps";
import { TUpdateFormAttributes } from "./types/TUpdateFormAttributes";
import { useUpdateUserService } from "../../services/useUpdateUserService";
import { IOAuthResource } from "../../../../interfaces/IOAuthResource";
import { jwt } from "../../../../../../common/utils/jwt";
import { systemNotificationActions } from "../../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { useAppDispatch } from "../../../../../../common/hooks/redux";
import { userActions } from "../../../../slices/userSlice";

const UpdateForm = (props: TUpdateFormProps) => {
  const dispatch = useAppDispatch();
  const form = useForm<TUpdateFormAttributes>(props.data);
  const { isLoading, dispatch: dispatchUpdateUserService } =
    useUpdateUserService();
  const isDisabled = isLoading || props.isDataLoading;

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();

    dispatchUpdateUserService({ body: form.data })
      .then((payload: IOAuthResource) => {
        form.notDirty();

        dispatch(
          systemNotificationActions.open({
            message: "Your account has been successfully updated.",
            variant: "success",
          })
        );

        jwt.store(payload.accessToken);
        dispatch(userActions.updateUserJwtInfo(jwt.parse()!));
      })
      .catch((error: THttpClientError) => {
        form.errorHandler(error);
      });
  };

  return (
    <Form
      onSubmit={submitHandler}
      actionElement={
        <>
          <Button
            disabled={isDisabled || !form.isDirty()}
            variant="contained"
            type="submit"
            loading={isLoading}
          >
            Save
          </Button>
        </>
      }
    >
      <Grid container spacing={2}>
        <Grid
          size={{
            xs: 12,
            sm: 6,
          }}
        >
          <TextField
            form={form}
            name="name"
            label="Name"
            showRequired
            disabled={isDisabled}
          />
        </Grid>

        <Grid
          size={{
            xs: 12,
            sm: 6,
          }}
        >
          <TextField form={form} name="email" label="Email" disabled={true} />
        </Grid>
      </Grid>
    </Form>
  );
};

export default UpdateForm;
