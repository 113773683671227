import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { TIntegrationCardProps } from "./types/TIntegrationCardProps";
import { useFetchInstallResourceService } from "../services/useFetchInstallResourceService";
import { IConfigureIntegrationResource } from "../interfaces/IConfigureIntegrationResource";
import { useConfigureIntegrationService } from "../services/useConfigureIntegrationService";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/redux";
import { integrationActions } from "../slices/integrationSlice";
import {
  Button,
  ConfirmationButton,
  Tooltip,
} from "../../../common/modules/materialUI";
import { systemNotificationActions } from "../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { useTheme } from "@mui/material";
import { useFetchUninstallResourceService } from "../services/useFetchUninstallResourceService";
import useWindow from "../../../common/hooks/useWindow";
import { IntegrationEnum } from "../../../entities/enums/IntegrationEnum";
import { enumUtil } from "../../../common/utils/enum";
import { colorEnum } from "../../../common/utils/color";
import { useAccessControl } from "../../../common/modules/accessControl/hooks/useAccessControl";
import { AbilityEnum } from "../../../common/modules/accessControl/enums/AbilityEnum";

const IntegrationCard = (props: TIntegrationCardProps) => {
  const { openNewTab } = useWindow();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const orgIntegration = useAppSelector(
    (state) => state.integration.orgIntegrationList
  );
  const integrationInstalled = orgIntegration.some(
    (item) => item.integration === props.integration.integration
  );
  const { can } = useAccessControl();

  //Endpoint for integration install execute/redirect URL
  const {
    isLoading: isLoadingInstallResource,
    dispatch: dispatchFetchInstallResourceService,
  } = useFetchInstallResourceService({
    integration: props.integration.integration,
  });

  //Endpoint for integration uninstall execute/redirect URL
  const {
    isLoading: isLoadingUninstallResource,
    dispatch: dispatchFetchUninstallResourceService,
  } = useFetchUninstallResourceService({
    integration: props.integration.integration,
  });

  //Endpoint for installing integration
  const { isLoading: installing, dispatch: dispatchIntegrationInstallService } =
    useConfigureIntegrationService();

  //Endpoint for uninstalling integration
  const {
    isLoading: uninstalling,
    dispatch: dispatchIntegrationUninstallService,
  } = useConfigureIntegrationService();

  const isLoading =
    installing ||
    isLoadingInstallResource ||
    uninstalling ||
    isLoadingUninstallResource;

  const isActive = enumUtil.includes(
    IntegrationEnum,
    props.integration.name as IntegrationEnum
  );

  const manageIntegrationText = !isActive
    ? "Queued"
    : integrationInstalled
    ? "Uninstall"
    : "Install";
  const manageIntegrationLoadingText = integrationInstalled
    ? "Uninstalling"
    : "Installing";

  const isDisabled = !can(AbilityEnum.administrate) || !isActive || isLoading;

  const manageIntegrationHandler = () => {
    if (integrationInstalled) {
      uninstallIntegrationHandler();
    } else {
      installIntegrationHandler();
    }
  };

  const redirectHandler = (url: string, newTab?: boolean) => {
    if (!newTab) {
      // eslint-disable-next-line no-restricted-globals
      location.href = url;
      return;
    }

    openNewTab(url, true);
  };

  const uninstallIntegrationHandler = () => {
    dispatchFetchUninstallResourceService().then(
      (payload: IConfigureIntegrationResource) => {
        if (payload.redirect) {
          redirectHandler(payload.url);
        } else {
          dispatchIntegrationUninstallService({ urlPath: payload.url })
            .then(() => {
              dispatch(
                integrationActions.removeOrgIntegration({
                  integration: props.integration.integration,
                })
              );
              integrationSuccessHandler();
            })
            .catch(() => {
              integrationErrorHandler();
            });
        }
      }
    );
  };

  const installIntegrationHandler = () => {
    dispatchFetchInstallResourceService().then(
      (payload: IConfigureIntegrationResource) => {
        if (!payload) {
          dispatch(
            systemNotificationActions.open({
              message: `The ${props.integration.integration} integration will be available for you soon.`,
              variant: "info",
            })
          );
        } else if (payload.redirect) {
          redirectHandler(payload.url);
        } else {
          dispatchIntegrationInstallService({ urlPath: payload.url })
            .then(() => {
              integrationSuccessHandler();
            })
            .catch(() => {
              integrationErrorHandler();
            });
        }
      }
    );
  };

  const integrationSuccessHandler = () => {
    dispatch(
      systemNotificationActions.open({
        message: `${props.integration.integration} ${
          !integrationInstalled ? "installed" : "uninstalled"
        } successfully.`,
        variant: "success",
      })
    );
  };

  const integrationErrorHandler = () => {
    dispatch(
      systemNotificationActions.open({
        message: `The ${
          !integrationInstalled ? "installation" : "uninstallation"
        } of the ${
          props.integration.integration
        } integration has failed. Please try again.`,
        variant: "error",
      })
    );
  };

  return (
    <Card
      sx={{
        maxWidth: 345,
      }}
    >
      <CardMedia
        sx={{
          width: 180,
          height: 70,
          margin: "auto",
        }}
        image={props.integration.banner[theme.palette.mode]}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {props.integration.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {props.integration.description}
        </Typography>
      </CardContent>
      <CardActions>
        <ConfirmationButton
          title={manageIntegrationText}
          content={`Are you sure you want to ${manageIntegrationText.toLowerCase()} ${
            props.integration.integration
          } integration?`}
          confirmColor={colorEnum.PRIMARY}
          disabled={isDisabled}
          button={
            <Tooltip
              title={
                !can(AbilityEnum.administrate)
                  ? "You don't have permission to manage integrations"
                  : ""
              }
              withSpanWrapper
            >
              <Button
                size="small"
                disabled={isDisabled}
                loading={isLoading}
                type="submit"
              >
                {!isLoading
                  ? manageIntegrationText
                  : manageIntegrationLoadingText}
              </Button>
            </Tooltip>
          }
          data={orgIntegration}
          successHandler={manageIntegrationHandler}
        />
      </CardActions>
    </Card>
  );
};

export default IntegrationCard;
