import Canvas from "./Flow/Canvas/Canvas";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/redux";
import NotFound from "../../../common/components/Page/NotFound/NotFound";
import Loader from "../../../common/containers/Loader/Loader";
import { useEffect } from "react";
import { statusFlowActions } from "../slices/statusFlowSlice";
import { statusUtil } from "../../../common/utils/statusUtil";
import useRegisterHeader from "../../../common/modules/registry/hooks/useRegisterHeader";
import { HeaderRegistryEnum } from "../../../common/modules/registry/enums/HeaderRegistryEnum";
import { useOrgProjectData } from "../../org/hooks/useOrgProjectData";
import { Box } from "@mui/material";
import BreadCrumbs from "../../../common/components/BreadCrumbs/BreadCrumbs";

const StatusFlowPage = () => {
  const { projectAbbr } = useParams();
  const dispatch = useAppDispatch();
  const { statuses, isLoading: isOrgInitLoading } = useAppSelector(
    (state) => state.org.init
  );
  const project = useOrgProjectData().findProjectByAbbr(projectAbbr!)?.project;

  useRegisterHeader(HeaderRegistryEnum.headerBackButtonOnly, [projectAbbr]);

  useEffect(() => {
    if (!project || !statuses?.length) {
      return;
    }

    const projectStatusList = statusUtil.convertStatusesToStatusList(
      statuses.filter((status) => status.projectId === project.id)
    );
    dispatch(statusFlowActions.setStatusList(projectStatusList));

    return () => {
      dispatch(statusFlowActions.resetState());
    };
  }, [project?.id]);

  return (
    <>
      {isOrgInitLoading && <Loader />}

      {!isOrgInitLoading && !project && <NotFound />}

      {!isOrgInitLoading && project && (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb="22px"
          >
            <BreadCrumbs
              links={[
                { label: "Project Management", url: "/settings/project" },
                { label: "Status Flow", url: "/" },
              ]}
            />
          </Box>
          <Canvas project={project} />
        </>
      )}
    </>
  );
};

export default StatusFlowPage;
