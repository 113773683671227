import classes from "./Content.module.scss";
import { useTheme } from "@mui/material";
import useMedia from "../../../../hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../hooks/useMedia/enums/QueryEnum";
import useWindowInnerHeight from "../../../../hooks/useWindowInnerHeight";
import useHeaderHeight from "../Header/hooks/useHeaderHeight";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../../../hooks/redux";
import { TChildren } from "../../../../types/TChildren";

export const MAIN_CONTENT_PADDING_BOTTOM = 96;

type TProps = {} & TChildren;

const Content = (props: TProps) => {
  const { pathname } = useLocation();
  const noPaddingPages = [
    "/task",
    "/backlog",
    "/objective/",
    "/guesstimationItem",
  ];
  const windowHeight = useWindowInnerHeight();
  const matchesMax576Screen = useMedia(QueryEnum.MAX_WIDTH_576);
  const matchesMax992Screen = useMedia(QueryEnum.MAX_WIDTH_992);
  const theme = useTheme();
  const { HEADER_HEIGHT } = useHeaderHeight();
  const { navigationSize, display: navigationDisplayed } = useAppSelector(
    (state) => state.navigation
  );

  return (
    <div
      className={classes.content}
      style={{
        // Pushing content since navigation has position fixed
        // When matches small screen we don't need the margin since navigation is overlaid
        marginLeft:
          !matchesMax992Screen && navigationDisplayed ? navigationSize : 0,
        minHeight: windowHeight - HEADER_HEIGHT,
        backgroundColor: theme.palette.background.default,
        padding: noPaddingPages.some((path) => pathname.startsWith(path))
          ? 0
          : `${
              matchesMax576Screen ? 12 : 20
            }px 12px ${MAIN_CONTENT_PADDING_BOTTOM}px`,
      }}
    >
      {props.children}
    </div>
  );
};

export default Content;
