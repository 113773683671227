import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/hooks/redux";
import { useDispatchEvent } from "../../../../../../common/modules/eventProvider";
import useForm from "../../../../../../common/hooks/useForm/useForm";
import { useRemoveGuesstimationItemAssigneeService } from "../../services/useRemoveGuesstimationItemAssigneeService";
import { guesstimationItemViewActions } from "../../slices/viewSlice";
import { systemNotificationActions } from "../../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { THttpClientError } from "../../../../../../common/modules/httpClient/types/THttpClientError";
import { EventEnum } from "../../../../../../common/modules/eventProvider/enums/EventEnum";
import { TGuesstimationItemUpdatedEvent } from "../types/TGuesstimationItemUpdatedEvent";
import { ConfirmationDialog } from "../../../../../../common/modules/materialUI";
import { IGuesstimationItemDetailsResource } from "../../interfaces/IGuesstimationItemDetailsResource";
import { useRemoveGuesstimationItemInactiveAssigneeService } from "../../services/useRemoveGuesstimationItemInactiveAssigneeService";
import { TUseHttpClientCoreDispatch } from "../../../../../../common/modules/httpClient/hooks/types/TUseHttpClientCoreDispatch";
import { StatusEnum } from "../../../../../../entities/columns/user/StatusEnum";

const RemoveAssigneeConfirmation = () => {
  const dispatch = useAppDispatch();
  const dispatchEvent = useDispatchEvent();
  const { getValidationErrorAtIndex } = useForm();
  const removeAssigneeState = useAppSelector(
    (state) => state.guesstimationItemView.removeAssignee
  );
  const { dispatch: dispatchRemoveAssigneeService, isLoading } =
    useRemoveGuesstimationItemAssigneeService({
      itemId: removeAssigneeState?.item.id ?? 0,
    });

  const {
    dispatch: dispatchRemoveInactiveAssigneeService,
    isLoading: inactiveIsLoading,
  } = useRemoveGuesstimationItemInactiveAssigneeService({
    itemId: removeAssigneeState?.item.id ?? 0,
  });

  const closeConfirmationHandler = () =>
    dispatch(guesstimationItemViewActions.closeRemoveAssigneeConfirmation());

  const successHandler = (dispatchFunction: TUseHttpClientCoreDispatch) => {
    if (!removeAssigneeState) {
      return;
    }

    dispatchFunction({
      body: {
        assignee: removeAssigneeState.assignee.id,
      },
    })
      .then((payload: IGuesstimationItemDetailsResource) => {
        dispatchEvent(EventEnum.ON_GUESSTIMATION_ITEM_UPDATED, {
          initGuesstimationItem: removeAssigneeState.item,
          guesstimationItem: payload.guesstimationItem,
        } as TGuesstimationItemUpdatedEvent);

        dispatch(guesstimationItemViewActions.setDetailsResource(payload));

        dispatch(
          systemNotificationActions.open({
            variant: "success",
            message: `${removeAssigneeState.assignee.name} has been removed successfully`,
          })
        );
      })
      .catch((error: THttpClientError) => {
        if (error.status === 422) {
          const validationFirstError = getValidationErrorAtIndex(0, error);
          if (validationFirstError) {
            dispatch(
              systemNotificationActions.open({
                variant: "error",
                message: validationFirstError,
              })
            );
          }
        } else if (error.status === 403) {
          dispatch(
            systemNotificationActions.open({
              message: error.data.message,
              variant: "warning",
            })
          );
          return;
        } else {
          dispatch(
            systemNotificationActions.open({
              variant: "error",
              message: `Failed to remove ${removeAssigneeState.assignee.name} from item. Please try again.`,
            })
          );
        }
      })
      .finally(() => {
        closeConfirmationHandler();
      });
  };

  if (!removeAssigneeState?.openConfirmation) {
    return null;
  }

  return (
    <ConfirmationDialog
      open={true}
      title={`Remove ${removeAssigneeState.assignee.name}`}
      content={`${removeAssigneeState.assignee.name} will be removed from this item, are you sure you want to proceed?`}
      loading={isLoading || inactiveIsLoading}
      closeHandler={closeConfirmationHandler}
      successHandler={() =>
        successHandler(
          removeAssigneeState.assignee.status === StatusEnum.INACTIVE
            ? dispatchRemoveInactiveAssigneeService
            : dispatchRemoveAssigneeService
        )
      }
    />
  );
};

export default RemoveAssigneeConfirmation;
