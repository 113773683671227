import classes from "../../common/Navigation.module.scss";
import List from "../../common/List/List";
import { Apps, Calculate, TagOutlined } from "@mui/icons-material";
import CircleIcon from "@mui/icons-material/Circle";
import { BoardTypeEnum } from "../../../../../../../entities/columns/board/BoardTypeEnum";
import IconImage from "../../../../../../components/Icon/IconImage/IconImage";
import CreateBoard from "../../../../../../../modules/board/containers/CreateBoard/CreateBoard";
import listClasses from "../../../../../../components/Navigation/List/List.module.css";
import { AbilityEnum } from "../../../../../../modules/accessControl/enums/AbilityEnum";
import { Box, Theme, Typography, useTheme } from "@mui/material";
import BacklogIcon from "../../../../../../components/Icon/IconImage/BacklogIcon";
import { Tooltip } from "../../../../../../modules/materialUI";
import NavigationTitle from "../../../../../../components/navigationTitle/NavigationTitle";
import { TMainNavigationContentProps } from "./types/TMainNavigationContentProps";
import { navigationSize } from "../../Navigation";
import OrgMenu from "../../../../../Menu/OrgMenu/OrgMenu";
import Divider from "../../../../../../components/Divider/Divider";
import { useNavigationLogic } from "../hooks/useNavigationLogic";

export const DIVIDER_MARGIN_Y = "8px";

export const getBoardItemIcon = (theme: Theme, boardType?: BoardTypeEnum) => {
  switch (boardType) {
    case BoardTypeEnum.HOLISTIC:
      return (
        <IconImage
          imgDark="/app-v2/assets/holistic-board-icon-light.svg"
          img="/app-v2/assets/holistic-board-icon-dark.svg"
          size={16}
        />
      );
    case BoardTypeEnum.BACKLOG:
      return <BacklogIcon />;
    case BoardTypeEnum.GUESSTIMATION:
      return <Calculate style={{ color: theme.palette.action.active }} />;
    default:
      return <TagOutlined style={{ color: theme.palette.action.active }} />;
  }
};

const getBoardPath = (
  projectAbbr: string,
  boardRef: string,
  boardType?: BoardTypeEnum
) => {
  switch (boardType) {
    case BoardTypeEnum.BACKLOG:
      return `${projectAbbr}/board/${boardRef}/backlog`;
    default:
      return `${projectAbbr}/board/${boardRef}`;
  }
};

const MainNavigationContent = ({
  navigationRequestedHandler,
  projects,
  orgInitDataLoading,
}: TMainNavigationContentProps) => {
  const theme = useTheme();

  const hasProjects = projects.length > 0;

  const { userName, orgRef, orgRefHeight, HEADER_HEIGHT_PX, can } =
    useNavigationLogic(navigationSize);

  return (
    <nav
      style={{
        backgroundColor: theme.palette.background.paper,
        padding: "0 8px 0 8px",
        width: navigationSize,
        borderRight: "1px solid " + theme.palette.divider,
        height: `calc(100dvh - ${HEADER_HEIGHT_PX})`,
      }}
      className={classes.navigation}
    >
      {orgInitDataLoading && (
        <List isDropdownList title="Loading Boards" loading={true} />
      )}

      {!orgInitDataLoading && (
        <Box ref={orgRef} pt="12px">
          <OrgMenu />
          <Divider sx={{ my: DIVIDER_MARGIN_Y }} />
        </Box>
      )}
      {!orgInitDataLoading && (
        <Box
          overflow="auto"
          height={`calc(100dvh - ${HEADER_HEIGHT_PX} - ${orgRefHeight}px - ${DIVIDER_MARGIN_Y})`}
          pb="10px"
          sx={{
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": { display: "none" },
          }}
        >
          <NavigationTitle title="Views" />
          <List
            items={[
              ...(hasProjects
                ? [
                    {
                      id: "priorityView",
                      name:
                        (userName?.endsWith("s") ? userName : userName + "'s") +
                        " " +
                        "View",
                      path: "/priorityView",
                      icon: (
                        <IconImage
                          imgDark="/app-v2/assets/Priority-View-Light.svg"
                          img="/app-v2/assets/Priority-View-Dark.svg"
                          size={16}
                        />
                      ),
                      onNavigate: navigationRequestedHandler,
                    },
                    {
                      id: "projectView",
                      name: "Project View",
                      path: "/projectView",
                      icon: (
                        <Apps style={{ color: theme.palette.action.active }} />
                      ),
                      onNavigate: navigationRequestedHandler,
                    },
                  ]
                : []),
              ...(can(AbilityEnum.manage)
                ? [
                    {
                      id: "objectives",
                      name: "Objectives",
                      path: "/objectives",
                      icon: (
                        <IconImage
                          imgDark="/app-v2/assets/objective-light.svg"
                          img="/app-v2/assets/objective-dark.svg"
                          size={16}
                        />
                      ),
                      onNavigate: navigationRequestedHandler,
                    },
                  ]
                : []),
            ]}
          />
          <>
            <Divider />
            {projects.length > 0 && <NavigationTitle title={"Projects"} />}

            {projects.map((data) => {
              const boards = [...data.boards];
              const sortedBoards = [
                ...boards.splice(
                  boards.findIndex(
                    (board) => board.type === BoardTypeEnum.HOLISTIC
                  ),
                  1
                ),
                ...boards.splice(
                  boards.findIndex(
                    (board) => board.type === BoardTypeEnum.BACKLOG
                  ),
                  1
                ),
                ...boards,
              ];

              return (
                <List
                  isDropdownList
                  title={
                    <>
                      <CircleIcon
                        style={{
                          color:
                            data.project.color ?? theme.palette.primary.main,
                          marginLeft: "6px",
                          marginRight: "6px",
                          fontSize: "8px",
                        }}
                      />
                      <Tooltip title={data.project.name}>
                        <Typography
                          noWrap
                          width="65%"
                          fontSize="14px"
                          color="text.secondary"
                          fontWeight={500}
                        >
                          {data.project.name}
                        </Typography>
                      </Tooltip>
                    </>
                  }
                  key={data.project.ref}
                  items={sortedBoards.map((board) => {
                    return {
                      className:
                        board.type === BoardTypeEnum.BACKLOG
                          ? classes["board-item-padding-bottom"]
                          : undefined,
                      id: board.ref!,
                      name: board.name,
                      path: getBoardPath(
                        data.project.abbr,
                        board.ref!,
                        board.type
                      ),
                      icon: getBoardItemIcon(theme, board.type),
                      onNavigate: navigationRequestedHandler,
                    };
                  })}
                  action1Element={
                    <CreateBoard
                      className={listClasses["action-1-icon"]}
                      projectRef={data.project.ref!}
                      projectName={data.project.name}
                      onClick={navigationRequestedHandler}
                    />
                  }
                />
              );
            })}
          </>
        </Box>
      )}
    </nav>
  );
};

export default MainNavigationContent;
