import { Box, Typography, useTheme } from "@mui/material";
import { Tooltip } from "../../../../../../common/modules/materialUI";
import { TTaskStatusProps } from "./types/TTaskStatusProps";
import { useAppSelector } from "../../../../../../common/hooks/redux";
import StatusIcon from "../../../../../../common/components/Task/components/Status/StatusIcon";
import { string } from "../../../../../../common/utils/string";

const TaskAggStatus = ({ task, showTitle }: TTaskStatusProps) => {
  const statusReason = useAppSelector((state) => state.task.statusReason);
  const theme = useTheme();

  return (
    <Tooltip title={statusReason}>
      <Box display="flex" alignItems="center" gap="4px">
        <StatusIcon
          status={task.aggStatus}
          statusType={task.aggStatus}
          style={{ display: "flex" }}
          hideTooltip
        />
        {showTitle && (
          <Typography
            color={theme.palette.taskView.textSecondaryColor}
            fontSize="13px"
            fontWeight={500}
          >
            {string.ucwords(task.aggStatus)}
          </Typography>
        )}
      </Box>
    </Tooltip>
  );
};

export default TaskAggStatus;
