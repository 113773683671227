import { TChildren } from "../../../../types/TChildren";
import { useTheme } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { ReactElement } from "react";
import { TIsMobileViewProp } from "../types/TIsMobileViewProp";

export default function SectionWrapper({
  isMobileView,
  contentExtension,
  children,
}: { contentExtension?: ReactElement } & TChildren & TIsMobileViewProp) {
  const theme = useTheme();

  return (
    <>
      <Grid
        container
        bgcolor={theme.palette.taskView.mainContentBackground}
        borderRadius="12px"
        p={isMobileView ? "12px" : "16px"}
        flexDirection="column"
        gap="12px"
      >
        {children}
      </Grid>
      {contentExtension}
    </>
  );
}
