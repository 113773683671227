import { TTaskCostProps } from "./types/TTaskCostProps";
import { currencyUtil } from "../../../../utils/currencyUtil";
import { useEffect, useRef, useState } from "react";
import { Tooltip } from "../../../../modules/materialUI";
import { number } from "../../../../utils/number";
import Chip from "../../../../modules/materialUI/components/Chip/Chip";

const TaskCost = ({
  cost,
  size,
  showOnlySymbol,
  hideTooltip,
  sx,
}: TTaskCostProps) => {
  const textRef = useRef<HTMLSpanElement>(null);
  const [isOverflowing, setIsOverflowing] = useState<boolean>(false);

  const currencySymbol = currencyUtil.getSymbol(cost.costCurrency);
  const costFullText = currencySymbol + number.formatAsPrice(cost.cost);
  const costText = !!showOnlySymbol ? currencySymbol : costFullText;

  // Check if the text is overflowing its container
  useEffect(() => {
    if (textRef.current) {
      setIsOverflowing(
        textRef.current.scrollWidth > textRef.current.clientWidth
      );
    }
  }, [costText]);

  return (
    <Chip
      sx={{
        maxWidth: size ? `${size}px` : undefined,
        ...sx,
      }}
      label={
        <Tooltip
          title={costFullText}
          disableHoverListener={hideTooltip ?? !isOverflowing}
        >
          <>{costText}</>
        </Tooltip>
      }
    />
  );
};

export default TaskCost;
