import { useTheme } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { TQuickActionButtonProps } from "./types/TQuickActionButtonProps";

export default function QuickActionButton({
  onClick,
  children,
  sx,
}: TQuickActionButtonProps) {
  const theme = useTheme();

  return (
    <Grid
      container
      size={12}
      bgcolor="#E8F2F1"
      borderRadius="6px"
      color={theme.palette.taskView.quickActionsLinkColor}
      p="6px 0"
      justifyContent="center"
      gap="8px"
      sx={{
        cursor: "pointer",
        ":hover": {
          opacity: 0.9,
        },
        ...sx,
      }}
      onClick={onClick}
    >
      {children}
    </Grid>
  );
}
