import { TOccupiedSpaceProps } from "./types/TOccupiedSpaceProps";
import { Box, Typography } from "@mui/material";
import { TaskHelper } from "../Task/helpers/TaskHelper";
import Link from "./Link";
import IconImage from "../Icon/IconImage/IconImage";

export default function OccupiedSpaceSmall(props: TOccupiedSpaceProps) {
  const sizeSs = TaskHelper.sizeSs(props.size);
  const sizeXs = TaskHelper.sizeXs(props.size);

  return (
    <Box
      sx={{
        width: props.size,
        height: 110,
        position: "relative",
        overflow: "hidden",
        backgroundColor: "background.default",
        color: "text.disabled",
        ...props.style,
      }}
    >
      <Link {...props}>
        <Box
          sx={{
            display: "inline-flex",
            flexDirection: "column",
            position: "relative",
            overflow: "hidden",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexWrap: sizeXs ? "wrap" : "",
              width: "100%",
              gap: sizeXs ? "12px" : "4px",
            }}
          >
            <IconImage
              img={"/app-v2/assets/Occupied-Icon-Dark.svg"}
              imgDark={"/app-v2/assets/Occupied-Icon-Light.svg"}
              size={16}
            />
            <Typography variant="body2">{props.time}</Typography>
          </Box>

          {!sizeSs && (
            <Typography
              sx={{
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "100%",
                wordBreak: "break-word",
                textAlign: "center",
              }}
            >
              {props.taskRef}
            </Typography>
          )}
        </Box>
      </Link>
    </Box>
  );
}
