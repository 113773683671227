import SprintGrid from "../SprintGrid/SprintGrid";
import useRegisterHeader from "../../../../common/modules/registry/hooks/useRegisterHeader";
import { HeaderRegistryEnum } from "../../../../common/modules/registry/enums/HeaderRegistryEnum";
import { useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import { sprintActions } from "../../slices/sprintSlice";
import CreateSprint from "../CreateSprint/CreateSprint";
import { Box } from "@mui/material";
import AddNew from "../../../project/containers/CreateProject/CreateProject";
import BreadCrumbs from "../../../../common/components/BreadCrumbs/BreadCrumbs";
import useMedia from "../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../common/hooks/useMedia/enums/QueryEnum";

const SprintPage = () => {
  const { projectAbbr } = useParams();
  const [searchParams] = useSearchParams();
  const [modalOpen] = useState<boolean>(
    searchParams.get("modalOpen") === "true"
  );
  const sprintLabel = useAppSelector((state) => state.customs.sprintLabel);
  const dispatch = useAppDispatch();
  const formOpen = useAppSelector(
    (state) => state.sprint.creation.displayModal
  );
  useRegisterHeader(HeaderRegistryEnum.headerBackButtonOnly, [projectAbbr]);
  const matchesMax768Screen = useMedia(QueryEnum.MAX_WIDTH_768);

  useEffect(() => {
    if (modalOpen) {
      dispatch(sprintActions.openCreationModal());
    }
    return () => {
      dispatch(sprintActions.reset());
    };
  }, [modalOpen]);

  const formDisplayHandler = () => {
    formOpen
      ? dispatch(sprintActions.closeCreationModal())
      : dispatch(sprintActions.openCreationModal());
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent={"space-between"}
        alignItems={matchesMax768Screen ? "flex-start" : "center"}
        flexDirection={matchesMax768Screen ? "column" : "row"}
        gap={matchesMax768Screen ? "12px" : 0}
        mb="22px"
      >
        <BreadCrumbs
          links={[
            { label: "Project Management", url: "/settings/project" },
            { label: "Sprint Management", url: "/" },
          ]}
        />
        <Box
          display="flex"
          flexDirection={matchesMax768Screen ? "column" : "row"}
          alignItems={matchesMax768Screen ? "flex-end" : ""}
          width={matchesMax768Screen ? "100%" : "fit-content"}
        >
          <AddNew
            onClick={formDisplayHandler}
            title={`Create new ${sprintLabel}`}
          />
        </Box>
      </Box>
      <SprintGrid />
      <CreateSprint projectAbbr={projectAbbr!} isSprintPage={true} />
    </>
  );
};

export default SprintPage;
