import { Avatar, useTheme } from "@mui/material";
import ApartmentIcon from "@mui/icons-material/Apartment";

const Logo = () => {
  const theme = useTheme();
  return (
    <Avatar
      sx={{
        width: "24px",
        height: "24px",
        bgcolor: theme.palette._components.avatar[2],
      }}
    >
      <ApartmentIcon
        sx={{
          width: "16px",
          height: "16px",
          color: theme.palette._components.avatar.avatarText,
        }}
      />
    </Avatar>
  );
};
export default Logo;
