import { useEffect } from "react";
import { TListener } from "../types/TListener";
import { TEvent } from "../types/TEvent";
import EventProvider from "../EventProvider";

const useSubscribe = (
  event: TEvent | TEvent[],
  listener: TListener,
  deps: any[] = []
) => {
  useEffect(() => {
    const events = typeof event === "object" ? event : [event];

    events.forEach((event) => {
      EventProvider.addEventListener(event, listener);
    });

    return () => {
      events.forEach((event) => {
        EventProvider.removeEventListener(event, listener);
      });
    };
  }, deps);
};

export default useSubscribe;
