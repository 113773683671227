import { iconLibraryUtil } from "../../../iconLibrary/utils/iconLibraryUtil";
import { Autocomplete } from "../../../../common/modules/materialUI";
import { TTaskPriorityAutocompleteProps } from "./types/TTaskPriorityAutocompleteProps";
import { taskPriorityUtil } from "../../utils/taskPriorityUtil";

const TaskPriorityAutocomplete = <TFormAttributes extends unknown>({
  form,
  name,
  loading,
  options,
  taskPriorities,
}: TTaskPriorityAutocompleteProps<TFormAttributes>) => {
  return (
    <Autocomplete
      form={form}
      name={name}
      label="Task Priority"
      loading={loading}
      options={options}
      renderOption={(props, option) =>
        iconLibraryUtil.getAutocompleteRenderOption(
          props,
          option,
          taskPriorityUtil.getSelectedTaskPriority(taskPriorities, option)
            ?.iconFilePath
        )
      }
      textFieldProps={{
        slotProps: {
          input: {
            startAdornment: iconLibraryUtil.getAutocompleteInputStartAdornment(
              taskPriorityUtil.getSelectedTaskPriority(
                taskPriorities,
                form?.get("taskPriorityId" as keyof TFormAttributes)
              )?.iconFilePath
            ),
          },
        },
      }}
    />
  );
};

export default TaskPriorityAutocomplete;
