import { TBoardDefaultProps } from "../../types/TBoardDefaultProps";
import { HeaderRegistryEnum } from "../../../../common/modules/registry/enums/HeaderRegistryEnum";
import useRegisterHeader from "../../../../common/modules/registry/hooks/useRegisterHeader";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { TBoardParams } from "../Board/types/TBoardParams";
import { BoardViewEnum } from "../../../boardView/enums/BoardViewEnum";
import useNavigateBoard from "../../../boardView/hooks/useNavigateBoard";
import { useAppDispatch } from "../../../../common/hooks/redux";
import { userActions } from "../../../user/slices/userSlice";
import Loader from "../../../../common/containers/Loader/Loader";
import { enumUtil } from "../../../../common/utils/enum";
import HolisticView from "../../../boardView/modules/holisticView/containers/HolisticView/HolisticView";
import KanbanView from "../../../boardView/modules/kanbanView/containers/KanbanView";
import ListView from "../../../boardView/modules/listView/containers/ListView";
import NotFound from "../../../../common/components/Page/NotFound/NotFound";
import useKeyboardShortcut from "../../../../common/modules/keyboardShortcut/hooks/useKeyboardShortcut";
import { taskActions } from "../../../task/slices/taskSlice";
import { boardQueryActions } from "../../../boardView/slices/boardQuerySlice";
import { KeyboardShortcutEnum } from "../../../../common/modules/keyboardShortcut/hooks/enums/KeyboardShortcutEnum";
import MoveToBacklogConfirmation from "../../../task/modules/view/containers/MoveToBacklog/MoveToBacklogConfirmation";
import { InternalTypeEnum } from "../../../../entities/columns/task/InternalTypeEnum";
import AddAssigneesDialog from "../../../task/modules/view/containers/AddAssignee/AddAssigneesDialog";
import DeleteSegmentConfirmation from "../../../task/modules/view/containers/DeleteSegment/DeleteSegmentConfirmation";
import ConvertInternalTypeConfirmation from "../../../task/modules/view/containers/ConvertInternalType/ConvertInternalTypeConfirmation";
import { THeaderControlProps as TBoardHeaderControlProps } from "../HeaderControl/types/THeaderControlProps";
import { BoardTypeEnum } from "../../../../entities/columns/board/BoardTypeEnum";
import CreateTask from "../../../task/containers/CreateTask/CreateTask";
import BoardTitle from "../BoardTitle/BoardTitle";
import AddButton from "../../../boardView/containers/AddButton/AddButton";
import ShareBoardModal from "../../../readOnlyAccess/containers/board/ManageModal";
import TipIcon from "../../../tip/containers/TipIcon";

const HolisticBoard = (props: TBoardDefaultProps) => {
  const { boardView: boardViewParam } = useParams<TBoardParams>();
  const navigate = useNavigateBoard();
  useRegisterHeader(HeaderRegistryEnum.board, [props.boardRef], {
    boardRef: props.boardRef,
    projectAbbr: props.projectAbbr,
    boardType: BoardTypeEnum.HOLISTIC,
  } as TBoardHeaderControlProps);
  const dispatch = useAppDispatch();

  const board = props.board!;

  const addTaskClickHandler = () => {
    dispatch(taskActions.openCreationModal(InternalTypeEnum.TASK));
  };

  // For now on quick task creation we will launch the task creation dialog, later to be prompt creation
  useKeyboardShortcut({
    key: KeyboardShortcutEnum.t,
    callback: () =>
      dispatch(taskActions.openCreationModal(InternalTypeEnum.TASK)),
  });

  useEffect(() => {
    if (boardViewParam) {
      return;
    }

    navigate(BoardViewEnum.HOLISTIC_VIEW);
  }, [boardViewParam]);

  useEffect(() => {
    return () => {
      // Reset user list when leaving the board
      dispatch(userActions.reset());
      dispatch(boardQueryActions.reset());
    };
  }, [props.boardRef]);

  const boardViewProps = { ...props, board };

  // Different views will be available here, e.g. Kanban, List...
  return (
    <>
      {!boardViewParam && <Loader />}
      {boardViewParam &&
        (enumUtil.includes(BoardViewEnum, boardViewParam) ? (
          <>
            <BoardTitle
              addClickHandler={addTaskClickHandler}
              board={board}
              projectAbbr={props?.projectAbbr}
              menuComponent={<AddButton />}
              showProgress
              showShareButton
              tipIcon={
                <TipIcon
                  slugPrefix="feature:holistic-view"
                  name="Holistic View"
                />
              }
            />

            {boardViewParam === BoardViewEnum.HOLISTIC_VIEW && (
              <HolisticView {...boardViewProps} />
            )}
            {boardViewParam === BoardViewEnum.KANBAN_VIEW && (
              <KanbanView {...boardViewProps} />
            )}
            {boardViewParam === BoardViewEnum.LIST_VIEW && (
              <ListView {...boardViewProps} />
            )}
            <CreateTask boardRef={props.boardRef} />
            {/*<CreateMonitor boardRef={props.boardRef} />*/}
            <MoveToBacklogConfirmation isOnPage={false} />
            <ConvertInternalTypeConfirmation />
            <AddAssigneesDialog />
            <DeleteSegmentConfirmation isOnPage={false} />
            <ShareBoardModal boardRef={props.boardRef} />
          </>
        ) : (
          <NotFound />
        ))}
    </>
  );
};

export default HolisticBoard;
