import classes from "./TaskHorizontal.module.scss";
import { TTaskHorizontalProps } from "./types/TTaskHorizontalProps";
import { TaskHelper } from "../../helpers/TaskHelper";
import { Box, Typography } from "@mui/material";
import { ForwardedRef, forwardRef, useEffect, useRef, useState } from "react";
import Resizer from "../Resizer/Resizer";
import { useTaskViewHandler } from "../../../../../modules/task/modules/view/hooks/useTaskViewHandler";
import { taskUtil } from "../../../../utils/taskUtil";
import TaskSplitLine from "./TaskSplitLine";
import TaskIcons from "./components/TaskIcons";
import TaskMenuSection from "./components/TaskMenuSection";
import { Tooltip } from "../../../../modules/materialUI";
import useMedia from "../../../../hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../hooks/useMedia/enums/QueryEnum";
import { InternalTypeEnum } from "../../../../../entities/columns/task/InternalTypeEnum";

const TaskHorizontal = forwardRef(
  (props: TTaskHorizontalProps, ref: ForwardedRef<HTMLDivElement>) => {
    const [parentNode, setParentNode] = useState<HTMLDivElement | null>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const { openTaskViewHandler } = useTaskViewHandler({
      taskRef: props.task.ref,
      segmentNumber: props.task.number,
    });
    const sizeXs = TaskHelper.sizeXs(props.size!);
    const isTouchDevice = useMedia(QueryEnum.IS_TOUCH_DEVICE);

    useEffect(() => {
      setParentNode(containerRef.current);
    }, [containerRef.current]);

    return (
      <div
        ref={containerRef}
        className={`${props.className} ${classes.container ?? ""}`}
        style={{
          top: props.positionY ? props.positionY : undefined,
          left: props.positionX,
          width: props.size,
          ...props.style,
        }}
        onMouseEnter={props.mouseEnterHandle}
        onMouseLeave={props.mouseLeaveHandle}
        onTouchStart={props.mouseEnterHandle}
        onDragStart={props.onDragStart}
      >
        <TaskSplitLine
          isSpike={props.task.internalType === InternalTypeEnum.SPIKE}
          left={taskUtil.isSplitLeft(props.splitDirection)}
          right={taskUtil.isSplitRight(props.splitDirection)}
          leftSx={{
            position: "absolute",
            top: "0",
            left: "0.2px",
            width: "15px",
            overflow: "hidden",
          }}
          rightSx={{ position: "absolute", top: "0", right: "0.2px" }}
          imgHeight="108px"
        />
        <Tooltip
          enterTouchDelay={0}
          when={
            props.isHovered &&
            props.style?.opacity !== 0 &&
            !isTouchDevice &&
            props.task.tsPoints < 1
          }
          arrow
          placement="right"
          title={
            <Box display="flex" flexDirection="column" ml="4px">
              <Box display="flex" gap="6px">
                <Box display="inline-grid">
                  <Typography noWrap variant="caption">
                    {props.task.ref}
                  </Typography>
                </Box>
                {props.task.hasSplits && (
                  <Typography variant="caption">
                    #{props.task.number}
                  </Typography>
                )}
              </Box>
              <Typography variant="body2">{props.task.name}</Typography>
            </Box>
          }
        >
          <Box
            onClick={openTaskViewHandler}
            ref={ref}
            className={props.contentClassName}
            style={props.contentStyle}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            position="relative"
          >
            <Box>{props.children}</Box>

            <Box
              display="flex"
              marginBottom="5px"
              margin={!sizeXs ? "0 5px 3px 5px" : "0 3px 3px 0"}
              justifyContent="space-between"
              alignItems="center"
              height="20px"
            >
              <TaskIcons
                taskTypeId={props.task.taskTypeId}
                taskPriorityId={props.task.taskPriorityId}
                size={props.size}
                displayCostPerItem={props.displayCostPerItem}
                cost={props.task.cost}
              />
              <TaskMenuSection
                task={props.task}
                mouseLeaveHandle={props.mouseLeaveHandle}
                isHovered={props.isHovered}
                size={props.size}
                displayCostPerItem={props.displayCostPerItem}
                cost={props.task.cost}
              />
            </Box>
          </Box>
        </Tooltip>
        {props.resizable && (
          <Resizer
            parentNode={parentNode}
            {...props.resizerConfig}
            isSpike={props.task.internalType === InternalTypeEnum.SPIKE}
          />
        )}
      </div>
    );
  }
);

export default TaskHorizontal;
