import { useTheme } from "@mui/material";
import { useAppDispatch } from "../../../../common/hooks/redux";
import { boardActions } from "../../slices/boardSlice";
import { TCreateBoardProps } from "./types/TCreateBoardProps";
import { Add } from "@mui/icons-material";

const CreateBoard = (props: TCreateBoardProps) => {
  const dispatch = useAppDispatch();

  const clickHandler = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.stopPropagation();
    props.onClick && props.onClick();
    dispatch(
      boardActions.openCreationModal({
        projectRef: props.projectRef,
        projectName: props.projectName,
      })
    );
  };
  const theme = useTheme();

  return (
    <span
      style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
      className={props.className}
      onClick={(e) => clickHandler(e)}
    >
      <Add fontSize="small" sx={{ color: theme.palette.action.active }} />
    </span>
  );
};

export default CreateBoard;
