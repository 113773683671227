import { useAppSelector } from "../../../../../../common/hooks/redux";
import { useDrop } from "react-dnd";
import { Box, useTheme } from "@mui/material";
import { TSectionProps } from "../Section/types/TSectionProps";
import { DRAG_TYPE_TASK_CARD } from "../Task/Task";
import useDragOverHelper from "../../../../hooks/useDragOverHelper";

export default function DropTargetContainer(props: TSectionProps) {
  const { isOver, dragOverHandlers } = useDragOverHelper();
  const theme = useTheme();
  const isDragging = useAppSelector((state) => state.kanbanViewTask.isDragging);
  const showShiftingProgress = useAppSelector(
    (state) => state.kanbanViewTask.showShiftingProgress
  );

  const [, drop] = useDrop(
    () => ({
      accept: DRAG_TYPE_TASK_CARD,
      drop: (_, monitor) => props.status,
    }),
    []
  );

  if (!isDragging && !showShiftingProgress) {
    return null;
  }

  return (
    <Box
      {...dragOverHandlers}
      ref={drop}
      sx={{
        backgroundColor: theme.palette.dropTargetBackground.default,
        opacity:
          theme.palette.mode === "dark"
            ? isOver
              ? 0.7
              : 0.4
            : isOver
            ? 0.8
            : 0.6,
        width: "100%",
        height: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 20,
        transition: "opacity 0.2s ease, transform 0.2s ease",
        "&::after": {
          content: '""',
          position: "absolute",
          top: "-200%",
          left: "-200%",
          width: "400%",
          height: "400%",
          background: `linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, ${
            theme.palette.mode === "dark" ? 0.3 : 0.9
          }) 50%, rgba(255, 255, 255, 0) 100%)`,
          transform: "rotate(45deg)",
          animation: `shimmer ${
            theme.palette.mode === "dark" ? "1.7s" : "2s"
          } linear infinite`,
        },
        "@keyframes shimmer": {
          from: {
            transform: "translate(-200%, -200%) rotate(45deg)",
          },
          to: {
            transform: "translate(200%, 200%) rotate(45deg)",
          },
        },
      }}
    />
  );
}
