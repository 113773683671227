import { useState } from "react";
import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import Loader from "../../../../common/containers/Loader/Loader";
import { Navigate, useNavigate } from "react-router-dom";
import { Add, Notifications, StickyNote2 } from "@mui/icons-material";
import { useFetchOrgInitDataService } from "../../../org/services/useFetchOrgInitDataService";
import useRegisterHeader from "../../../../common/modules/registry/hooks/useRegisterHeader";
import { HeaderRegistryEnum } from "../../../../common/modules/registry/enums/HeaderRegistryEnum";
import { Button } from "../../../../common/modules/materialUI";
import NotesModal from "./NotesModal";
import NeedsAttentionModal from "./NeedsAttentionModal";
import { IProject } from "../../../../entities/IProject";
import { useUpdateProjectNeedsAttentionFlagService } from "../../../project/services/useUpdateProjectNeedsAttentionFlagService";
import { TUpdateFormAttributes } from "../../../project/containers/UpdateProject/types/TUpdateFormAttributes";
import { orgActions } from "../../../org/slices/orgSlice";
import { systemNotificationActions } from "../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { useAccessControl } from "../../../../common/modules/accessControl/hooks/useAccessControl";
import { AbilityEnum } from "../../../../common/modules/accessControl/enums/AbilityEnum";
import Divider from "../../../../common/components/Divider/Divider";
import Chip from "../../../../common/modules/materialUI/components/Chip/Chip";
import moment from "moment";
import { FORMAT_YEAR_MONTH_DAY } from "../../../../common/utils/date";
import useMedia from "../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../common/hooks/useMedia/enums/QueryEnum";
import Badge from "../../../../common/modules/materialUI/components/Badge/Badge";

const ProjectView = () => {
  const { can } = useAccessControl();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobileView = useMedia(QueryEnum.MAX_WIDTH_768);
  useRegisterHeader(HeaderRegistryEnum.projectView, []);
  // The service is being called to get updated insights data, as of this moment that information is not
  // saved in states
  const { isLoading } = useFetchOrgInitDataService(false);
  const projectsList = useAppSelector((state) => state.org.init.list);
  const hasProjects = projectsList.length > 0;
  const dispatch = useAppDispatch();
  const [noteSelectedProject, setNoteSelectedProject] = useState<number>(0);
  const [needsAttentionSelectedProjectId, setNeedsAttentionSelectedProjectId] =
    useState<number>(0);
  const {
    dispatch: dispatchUpdateProjectNeedsAttentionFlagService,
    isLoading: updateNeedsAttentionFlagLoading,
  } = useUpdateProjectNeedsAttentionFlagService();

  const needsAttentionSelectedProject = projectsList.find(
    (item) => item.project.id === needsAttentionSelectedProjectId
  )?.project;

  const undoNeedsAttentionHandler = (project: IProject) => {
    if (updateNeedsAttentionFlagLoading) {
      return;
    }

    dispatchUpdateProjectNeedsAttentionFlagService({
      urlPath: project.ref,
      body: {
        needsAttention: false,
      } as TUpdateFormAttributes,
    })
      .then(() => {
        dispatch(
          orgActions.updateProjectData({
            projectRef: project.ref,
            ...project,
            needsAttention: false,
          })
        );

        dispatch(
          systemNotificationActions.open({
            message:
              "Your project has been marked as not requiring immediate attention.",
            variant: "success",
          })
        );
      })
      .catch(() => {
        dispatch(
          systemNotificationActions.open({
            variant: "error",
            message: "Something went wrong. Please try again.",
          })
        );
      });
  };

  return (
    <>
      <Box
        mt={isMobileView ? 0 : "10px"}
        mb={isMobileView ? "12px" : "48px"}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant={isMobileView ? "h6" : "h5"}>Project</Typography>

        {/* <Button
          size={isMobileView ? "small" : "medium"}
          startIcon={
            <Add
              sx={{ fontSize: isMobileView ? "18px !important" : undefined }}
            />
          }
          onClick={() => {}}
          sx={{ py: isMobileView ? "5px" : undefined }}
        >
          Add New Project
        </Button> */}
      </Box>

      {!isLoading && hasProjects && (
        <Grid container gap="24px">
          {projectsList.map(({ project, boards }, index) => {
            const updateNeedsAttentionFlagButtonDisabled =
              !!project.needsAttention && updateNeedsAttentionFlagLoading;

            const projectCreatedDate = moment(project.createdAt).format(
              FORMAT_YEAR_MONTH_DAY
            );

            const projectRepresentativesUsers = project.representatives
              ?.map((user) => {
                const [firstName, lastName] = user.name.split(" ");
                const lastInitial = lastName ? ` ${lastName.charAt(0)}.` : "";
                return `${firstName}${lastInitial}`;
              })
              .join(", ");

            const taskDetailsData = [
              {
                title: "Created",
                details: `On ${projectCreatedDate}`,
              },
              {
                title: "Representatives",
                details: projectRepresentativesUsers,
              },
            ];

            const taskInfoData = [
              {
                title: "Boards",
                count: boards.length,
              },
              {
                title: "Tasks",
                count: project.tasksCount,
              },
              // {
              //   title: "Members",
              //   count: 999,
              // },
            ];

            return (
              <Grid
                key={index}
                bgcolor="background.paper"
                borderBottom={`1px solid ${theme.palette.divider}`}
                borderRadius={2}
                boxShadow={1}
                width="382px"
                display="flex"
                flexDirection="column"
                sx={{
                  "&:active": {
                    bgcolor: "text._states.hover",
                  },
                  "@media (hover: hover)": {
                    "&:hover": {
                      bgcolor: "text._states.hover",
                    },
                  },
                }}
              >
                <Card
                  sx={{
                    bgcolor: "unset",
                    borderRadius: "inherit",
                    boxShadow: 0,
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardActionArea
                    onClick={() =>
                      navigate(
                        `/${project.abbr}/board/${project.holisticBoardRef}/holistic`
                      )
                    }
                    sx={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "stretch",
                    }}
                  >
                    <CardContent
                      sx={{
                        flex: 1,
                        p: "12px",
                        display: "flex",
                        alignItems: "flex-start",
                        gap: "5px",
                      }}
                    >
                      <Box
                        flex={1}
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                        gap="8px"
                        sx={{
                          wordBreak: "break-all",
                        }}
                      >
                        <Box display="flex" alignItems="center" gap="8px">
                          <Box
                            width="8px"
                            minWidth="8px"
                            height="8px"
                            borderRadius="50%"
                            bgcolor={project.color || "info.main"}
                          />

                          <Typography variant="h6">{project.name}</Typography>
                        </Box>

                        <Chip
                          label={project.abbr}
                          size="small"
                          sx={{
                            borderRadius: 1,
                            bgcolor: "action.selected",
                          }}
                        />

                        {taskDetailsData.map(({ title, details }, index) => {
                          if (!details) return null;

                          return (
                            <Box gap="9px" display="flex" key={index}>
                              <Box
                                bgcolor="tertiary.light"
                                borderRadius={1}
                                width="2px"
                              />

                              <Box
                                sx={{
                                  wordBreak: "break-word",
                                }}
                              >
                                <Typography
                                  component="p"
                                  variant="caption"
                                  color="textSecondary"
                                >
                                  {title}
                                </Typography>

                                <Typography variant="caption">
                                  {details}
                                </Typography>
                              </Box>
                            </Box>
                          );
                        })}
                      </Box>

                      <Box
                        alignSelf="flex-end"
                        borderRadius={1}
                        padding="10px"
                        width="104px"
                        bgcolor="backgroundElevation.backgroundPaperElevation_1"
                        display="flex"
                        flexDirection="column"
                        gap="4px"
                      >
                        {taskInfoData.map(({ title, count }, index) => (
                          <Box
                            key={index}
                            display="flex"
                            justifyContent="space-between"
                          >
                            <Badge variant="outlined">{title}</Badge>
                            <Typography variant="caption">{count}</Typography>
                          </Box>
                        ))}
                      </Box>
                    </CardContent>

                    <Divider sx={{ m: "0 12px" }} />
                  </CardActionArea>
                </Card>

                <CardActions
                  disableSpacing
                  sx={{
                    p: "12px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {can(AbilityEnum.manage) && (
                    <Button
                      disabled={updateNeedsAttentionFlagButtonDisabled}
                      loading={updateNeedsAttentionFlagButtonDisabled}
                      startIcon={
                        <Notifications
                          sx={{
                            fontSize: "18px !important",
                          }}
                        />
                      }
                      variant="text"
                      size="small"
                      color={project.needsAttention ? "error" : "text"}
                      sx={{
                        p: "5px",
                      }}
                      onClick={() => {
                        project.needsAttention
                          ? undoNeedsAttentionHandler(project)
                          : setNeedsAttentionSelectedProjectId(project.id);
                      }}
                    >
                      {project.needsAttention
                        ? "Needs Attention"
                        : "Mark as Needs Attention"}
                    </Button>
                  )}

                  <Box ml="auto" display="flex" alignItems="center" gap="4px">
                    <IconButton
                      onClick={() => setNoteSelectedProject(project.id)}
                      size="small"
                      color="text"
                    >
                      <StickyNote2 sx={{ fontSize: "18px" }} />
                    </IconButton>

                    {/* <Badge>{project.id}</Badge> */}
                  </Box>
                </CardActions>
              </Grid>
            );
          })}
        </Grid>
      )}
      {isLoading && <Loader />}
      {!isLoading && !hasProjects && <Navigate to={"/"} replace={true} />}
      {!!needsAttentionSelectedProject && (
        <NeedsAttentionModal
          closeHandler={function () {
            setNeedsAttentionSelectedProjectId(0);
          }}
          project={needsAttentionSelectedProject}
        />
      )}
      {!!noteSelectedProject && (
        <NotesModal
          closeHandler={function () {
            setNoteSelectedProject(0);
          }}
          projectId={noteSelectedProject}
        />
      )}
    </>
  );
};

export default ProjectView;
