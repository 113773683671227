import { useNavigate, useParams } from "react-router-dom";
import { TTaskPageParams } from "./types/TTaskPageParams";
import TaskContent from "./TaskContent";
import { useEffect, useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../common/hooks/redux";
import { ITaskDetailsResource } from "../interfaces/ITaskDetailsResource";
import { taskActions } from "../../../slices/taskSlice";
import NotFound from "../../../../../common/components/Page/NotFound/NotFound";
import LinkModal from "../../../../link/containers/Create/LinkModal";
import { useGetTaskService } from "../services/useGetTaskService";
import { taskViewActions } from "../slices/viewSlice";
import TaskSkeleton from "./TaskSkeleton";
import useWindowInnerHeight from "../../../../../common/hooks/useWindowInnerHeight";
import useHeaderHeight from "../../../../../common/containers/Layout/App/Header/hooks/useHeaderHeight";
import useMedia from "../../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../../common/hooks/useMedia/enums/QueryEnum";
import { useSubscribeEvent } from "../../../../../common/modules/eventProvider";
import { EventEnum } from "../../../../../common/modules/eventProvider/enums/EventEnum";
import { ITask } from "../../../../../entities/ITask";
import MoveToBacklogConfirmation from "./MoveToBacklog/MoveToBacklogConfirmation";
import AddAssigneesDialog from "./AddAssignee/AddAssigneesDialog";
import DeleteSegmentConfirmation from "./DeleteSegment/DeleteSegmentConfirmation";
import ConvertInternalTypeConfirmation from "./ConvertInternalType/ConvertInternalTypeConfirmation";
import TaskIsDeletedContent from "./TaskIsDeletedContent";

const TaskPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { taskRef, segmentNumber } = useParams<TTaskPageParams>();
  const [taskExists, setTaskExists] = useState<boolean>(true);
  const [showSkeleton, setShowSkeleton] = useState<boolean>(true);
  const taskResource = useAppSelector((state) => state.task.resource);
  const taskDeletedRefs = useAppSelector((state) => state.task.deletedTaskRefs);
  const { dispatch: dispatchGetTask } = useGetTaskService();
  const windowHeight = useWindowInnerHeight();
  const isMobileView = useMedia(QueryEnum.MAX_WIDTH_1200);
  const { HEADER_HEIGHT } = useHeaderHeight();

  useEffect(() => {
    taskRef &&
      dispatchGetTask({
        urlPath: `/${taskRef}${segmentNumber ? `/${segmentNumber}` : ""}`,
      })
        .then((data: ITaskDetailsResource) => {
          dispatch(
            taskViewActions.setViewType(
              data.task.isSegmentData ? "segment" : "group"
            )
          );
        })
        .catch((_) => {
          setTaskExists(false);
        })
        .finally(() => setShowSkeleton(false));

    return () => {
      dispatch(taskActions.clearData());
      dispatch(taskViewActions.reset());
    };
  }, [taskRef]);

  useSubscribeEvent(
    EventEnum.ON_TASK_VIEW_MODE_CHANGED,
    (task: ITask) => {
      // When view is group we are dropping the segment number
      (!task.isSegmentData || task.segmentCount === 1) &&
        navigate(`/task/${task.ref}`, { replace: true });
    },
    []
  );

  if (taskRef && taskDeletedRefs.includes(taskRef)) {
    return <TaskIsDeletedContent />;
  }

  return (
    <>
      {showSkeleton && <TaskSkeleton isMobileView={isMobileView} />}
      {!showSkeleton && taskExists && taskResource?.task && (
        <>
          <TaskContent
            task={taskResource.task}
            taskResource={taskResource}
            contentHeight={`${windowHeight - HEADER_HEIGHT}px`}
            isMobileView={isMobileView}
            isPageView
          />

          <LinkModal />

          <MoveToBacklogConfirmation isOnPage={true} />
          <ConvertInternalTypeConfirmation />
          <AddAssigneesDialog />
          <DeleteSegmentConfirmation isOnPage={true} />
        </>
      )}
      {!taskExists && <NotFound />}
    </>
  );
};

export default TaskPage;
