import { useState } from "react";
import Grid from "@mui/material/Grid2";
import {
  Autocomplete,
  Button,
  Tooltip,
} from "../../../../common/modules/materialUI";
import { Add } from "@mui/icons-material";
import {
  boardIntegrations,
  IntegrationEnum,
} from "../../../../entities/enums/IntegrationEnum";
import { IListResource } from "../../../../interfaces/IListResource";
import SlackChannelForm from "./Slack/SlackChannelForm";
import { useFetchIntegrationInstalledListService } from "../../../integrations/services/useFetchIntegrationInstalledListService";
import { TListItem } from "../../../../common/types/TListItem";
import { Box } from "@mui/material";

const AddIntegration = () => {
  const [formOpen, setFormOpen] = useState<boolean>(false);
  const [selectedIntegration, setSelectedIntegration] =
    useState<IListResource | null>(null);
  const { data, isLoading } = useFetchIntegrationInstalledListService();
  const hasInstalledIntegrations = data?.length;

  const formDisplayHandler = () => {
    setSelectedIntegration(null);
    setFormOpen((prevState) => !prevState);
  };

  const onChangeHandler = (value: IListResource) => {
    setSelectedIntegration(value ?? null);
  };

  return (
    <>
      <Grid container>
        <Grid
          size={{
            xs: 12,
            md: 6,
          }}
        >
          {!formOpen && (
            <Tooltip
              title="There are not any installed integrations in your organization"
              when={!isLoading && !hasInstalledIntegrations}
            >
              <Box>
                <Button
                  variant="outlined"
                  startIcon={<Add />}
                  onClick={formDisplayHandler}
                  loading={isLoading}
                  disabled={isLoading || !hasInstalledIntegrations}
                >
                  Add integration
                </Button>
              </Box>
            </Tooltip>
          )}
        </Grid>
      </Grid>

      {formOpen && (
        <>
          <Grid container>
            <Grid
              mb="1rem"
              size={{
                xs: 12,
                md: 6,
              }}
            >
              <Autocomplete
                value={selectedIntegration}
                name="integration"
                label="Integration"
                options={data.map(
                  (item) =>
                    ({
                      id: item.integration,
                      label: item.integration,
                    } as TListItem<string>)
                )}
                showRequired
                onChange={(e, value) => onChangeHandler(value)}
              />
            </Grid>
          </Grid>

          {selectedIntegration?.id === IntegrationEnum.SLACK && (
            <SlackChannelForm formDisplayHandler={formDisplayHandler} />
          )}

          {selectedIntegration?.id &&
            !boardIntegrations.includes(
              selectedIntegration.id as IntegrationEnum
            ) && (
              <>
                Contact us if you need board specific settings for this
                integration
              </>
            )}

          {!selectedIntegration && (
            <Grid container>
              <Grid
                size={{
                  xs: 12,
                  md: 6,
                }}
              >
                <Button onClick={formDisplayHandler} variant="outlined">
                  Cancel
                </Button>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default AddIntegration;
