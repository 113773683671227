import { Box, Tooltip } from "@mui/material";
import { TTaskMenuProps } from "./types/TTaskMenuProps";
import Menu from "../../../../containers/Menu/Menu";
import TaskLinkMenuItem from "./TaskLinkMenuItem";
import { TMenuItemProps } from "../../../../containers/Menu/types/TMenuItemProps";
import classes from "./TaskMenu.module.css";
import MoveToBacklogMenuItem from "../../../../../modules/task/modules/view/containers/MoveToBacklog/MoveToBacklogMenuItem";
import { InternalTypeEnum } from "../../../../../entities/columns/task/InternalTypeEnum";
import ConvertInternalTypeMenuItem from "../../../../../modules/task/modules/view/containers/ConvertInternalType/ConvertInternalTypeMenuItem";
import { useAppDispatch } from "../../../../hooks/redux";
import { useIsFeatureAllowed } from "../../../../modules/accessControl/hooks/useIsFeatureAllowed";
import { FeatureEnum } from "../../../../modules/accessControl/enums/FeatureEnum";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { menuItem } from "../../../../containers/Menu/utils/menuItem";
import useMedia from "../../../../hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../hooks/useMedia/enums/QueryEnum";

const TaskMenu = (props: TTaskMenuProps) => {
  const dispatch = useAppDispatch();
  const isBacklogAllowed = useIsFeatureAllowed(FeatureEnum.BACKLOG_ICEBOX);
  const matchesMax992Screen = useMedia(QueryEnum.MAX_WIDTH_992);

  return (
    <Menu
      closeOnSelection
      openHandler={props.openHandler}
      stretchOnMobile={false}
      indentSubheader={props.indentSubheader}
      button={(clickHandler) => (
        <Box
          sx={{
            display: matchesMax992Screen
              ? "flex"
              : props.showButton !== false || props.isOpenTaskMenu
              ? "flex"
              : "none",
            justifyContent: "center",
            height: "18px",
            width: "18px",
          }}
          className={props.hidden ? classes.hidden : undefined}
          onClick={clickHandler}
        >
          <Tooltip title="More options" arrow>
            <MoreVertIcon
              sx={{
                fontSize: props.fontSize ?? "1rem",
                ...props.sx,
              }}
            />
          </Tooltip>
        </Box>
      )}
      menuItems={
        [
          menuItem.make({
            component: props.listSubheader,
            isHeaderItem: true,
            headerItemProps: {
              py: 0,
              px: "4px",
            },
            closeOnClickInHeader: true,
          }),
          TaskLinkMenuItem(props),
          ...(props.taskInternalType === InternalTypeEnum.TASK
            ? [
                MoveToBacklogMenuItem({
                  taskId: props.taskId,
                  dispatch: dispatch,
                  isBacklogAllowed: isBacklogAllowed,
                  shouldRender:
                    props.taskInternalType === InternalTypeEnum.TASK,
                }),
              ]
            : []),
          ...([InternalTypeEnum.TASK, InternalTypeEnum.SPIKE].includes(
            props.taskInternalType
          )
            ? [
                ConvertInternalTypeMenuItem({
                  taskId: props.taskId,
                  taskInternalType: props.taskInternalType,
                  dispatch: dispatch,
                  shouldRender: [
                    InternalTypeEnum.TASK,
                    InternalTypeEnum.SPIKE,
                  ].includes(props.taskInternalType),
                }),
              ]
            : []),
        ] as TMenuItemProps[]
      }
    />
  );
};

export default TaskMenu;
