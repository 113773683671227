import { TMenuItemProps } from "../../../../common/containers/Menu/types/TMenuItemProps";
import Menu from "../../../../common/containers/Menu/Menu";
import { AddRoad, AddTask } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import { taskActions } from "../../../task/slices/taskSlice";
import Section from "./Section";
import AddOfftimeMenu from "../../../offtime/containers/Menu/AddOfftimeMenu";
import ShortcutProTip from "../../../../common/modules/keyboardShortcut/containers/ProTip/ShortcutProTip";
import { menuItem } from "../../../../common/containers/Menu/utils/menuItem";
import { KeyboardShortcutEnum } from "../../../../common/modules/keyboardShortcut/hooks/enums/KeyboardShortcutEnum";
import { useNavigate, useParams } from "react-router-dom";
import { TBoardParams } from "../../../board/containers/Board/types/TBoardParams";
import { InternalTypeEnum } from "../../../../entities/columns/task/InternalTypeEnum";
import { useIsFeatureAllowed } from "../../../../common/modules/accessControl/hooks/useIsFeatureAllowed";
import { FeatureEnum } from "../../../../common/modules/accessControl/enums/FeatureEnum";
import { systemNotificationActions } from "../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { featureUtil } from "../../../../common/modules/accessControl/utils/featureUtil";
import { Button } from "../../../../common/modules/materialUI";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useState } from "react";
import IconImage from "../../../../common/components/Icon/IconImage/IconImage";

const AddButton = () => {
  const taskLabel = useAppSelector((state) => state.customs.taskLabel);
  const sprintLabel = useAppSelector((state) => state.customs.sprintLabel);
  const { projectAbbr } = useParams<TBoardParams>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isSpikeCreationAllowed = useIsFeatureAllowed(FeatureEnum.SPIKE);
  const [isOpenClose, setOpenClose] = useState<boolean>(false);

  const addTaskClickHandler = () => {
    dispatch(taskActions.openCreationModal(InternalTypeEnum.TASK));
  };

  const addSpikeClickHandler = () => {
    isSpikeCreationAllowed
      ? dispatch(taskActions.openCreationModal(InternalTypeEnum.SPIKE))
      : dispatch(
          systemNotificationActions.open({
            message: featureUtil.notAvailableFullText,
            variant: "warning",
          })
        );
  };

  const addSprintClickHandler = () => {
    navigate(`/settings/project/${projectAbbr}/sprints?modalOpen=true`);
  };

  return (
    <Menu
      openHandler={(openClose) => setOpenClose(openClose)}
      stretchOnMobile={false}
      closeOnSelection={true}
      button={(clickHandler) => (
        <Button onClick={clickHandler} sx={{ padding: "0" }}>
          <ArrowDropDownIcon
            sx={{
              transform: isOpenClose ? "rotate(180deg)" : "rotate(0deg)",
            }}
          />
        </Button>
      )}
      menuItems={
        [
          menuItem.make({
            component: (
              <Section
                helperText={
                  <ShortcutProTip
                    shortcutKeyString={KeyboardShortcutEnum.t}
                    description="add a task"
                  />
                }
                icon={<AddTask sx={{ color: "action.active" }} />}
                name={`Add ${taskLabel}`}
              />
            ),
            handler: addTaskClickHandler,
          }),
          AddOfftimeMenu(),
          menuItem.make({
            component: (
              <Section
                icon={
                  <IconImage
                    img={"/app-v2/assets/spikeDark.svg"}
                    imgDark={"/app-v2/assets/spikeLight.svg"}
                  />
                }
                name="Add Spike"
              />
            ),
            handler: addSpikeClickHandler,
            disabled: !isSpikeCreationAllowed,
            disabledTooltipMessage: featureUtil.notAvailableText,
          }),
          menuItem.make({
            component: (
              <Section
                icon={<AddRoad sx={{ color: "action.active" }} />}
                name={`Add ${sprintLabel}`}
              />
            ),
            handler: addSprintClickHandler,
          }),
        ] as TMenuItemProps[]
      }
    />
  );
};

export default AddButton;
