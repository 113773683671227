import { Breadcrumbs, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import useMedia from "../../hooks/useMedia/useMedia";
import { QueryEnum } from "../../hooks/useMedia/enums/QueryEnum";

const BreadCrumbs = ({
  links,
}: {
  links: { label: string; url: string }[];
}) => {
  const theme = useTheme();
  const matchesMax576Screen = useMedia(QueryEnum.MAX_WIDTH_576);

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {links.map((link, index) => {
        if (links.length === index + 1) {
          return (
            <Typography
              key={link.label}
              variant={"h5"}
              sx={{
                color: "text.primary",
                fontSize: matchesMax576Screen ? "17px" : undefined,
              }}
            >
              {link.label}
            </Typography>
          );
        }
        return (
          <Link
            key={link.url}
            to={link.url}
            style={{
              color: theme.palette.text.primary,
              fontSize: matchesMax576Screen ? "14px" : "15px",
              fontWeight: 400,
              lineHeight: "150%",
              letterSpacing: "0.15px",
            }}
          >
            {link.label}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};
export default BreadCrumbs;
