import { TBoardViewProps } from "../../../../../board/types/TBoardViewProps";
import ViewContainer from "../../../../containers/ViewContainer/ViewContainer";
import { Box, Card, CardContent, Typography, useTheme } from "@mui/material";
import { Fragment, useEffect, useRef } from "react";
import TimeLine from "../../../../../../common/components/TimeLine/TimeLine";
import moment from "moment/moment";
import { useAppSelector } from "../../../../../../common/hooks/redux";
import { pointConverter } from "../../../../../../common/utils/pointConverter";
import { date } from "../../../../../../common/utils/date";
import Lane from "../Lane/Lane";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import LaneItemCustomDragLayer from "../LaneItem/LaneItemCustomDragLayer";
import { IGuesstimationViewDataResource } from "../../../../interfaces/IGuesstimationViewDataResource";
import {
  captureLastContainerRef,
  useVirtualizationHelper,
} from "../../../holisticView/hooks/useVirtualizationHelper";
import { IGuesstimationItem } from "../../../../../../entities/IGuesstimationItem";
import { USER_NAME_HEIGHT } from "../../../holisticView/containers/UserLane/UserLane";

type TProps = {
  data: IGuesstimationViewDataResource;
  gridItems: IGuesstimationItem[];
} & TBoardViewProps;

const GuesstimationViewData = ({ data, gridItems, ...props }: TProps) => {
  const theme = useTheme();

  const containerRef = useRef<HTMLDivElement>(null);

  const timelineMinDate = moment(
    useAppSelector((state) => state.guesstimationView.timelineDisplayFromDate)
  );
  const timelineMaxDate = moment(
    useAppSelector((state) => state.guesstimationView.timelineDisplayToDate)
  );
  const { scrollToToday } = useVirtualizationHelper();
  const canvasWidth =
    pointConverter.pointToXSizeInPixels() *
    date.intervalToDays(timelineMinDate, timelineMaxDate, true, false);

  useEffect(() => {
    // Set horizontal scroller to be few days back from today's date for better and easier visibility
    if (containerRef && containerRef.current) {
      // Cache the last container reference, so it can be used for later dynamic scroll operations
      captureLastContainerRef(containerRef.current);

      // Scroll to today's date as a starting point
      scrollToToday(timelineMinDate);
    }
  }, [containerRef]);

  return (
    <ViewContainer boardRef={props.boardRef}>
      <Card sx={{ borderRadius: 3 }}>
        <CardContent
          ref={containerRef}
          sx={{
            overflowX: "auto",
            position: "relative",

            padding: `1.5rem 2rem 0.5rem 0 !important`,
            backgroundColor: "backgroundElevation.backgroundPaperElevation_0",

            borderLeft:
              "8px solid " +
              theme.palette.backgroundElevation.backgroundPaperElevation_0,
            borderRight:
              "8px solid " +
              theme.palette.backgroundElevation.backgroundPaperElevation_0,
          }}
        >
          <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <TimeLine
              startDate={timelineMinDate}
              endDate={timelineMaxDate}
              sprints={data.sprints}
              nonWorkdays={data.nonWorkdays}
            />
          </Box>
          <DndProvider backend={HTML5Backend}>
            <LaneItemCustomDragLayer />
            {gridItems.map((guesstimationItem) => (
              <Fragment key={guesstimationItem.id}>
                <Box
                  sx={{
                    width: "100%",
                    position: "sticky",
                    left: 0,
                    zIndex: 20,
                    height: USER_NAME_HEIGHT + "px",
                  }}
                >
                  <Typography
                    variant="caption"
                    fontWeight={500}
                    color="text.primary"
                  >
                    {guesstimationItem.phaseRef}
                  </Typography>
                </Box>

                <Lane
                  key={`t-l-${guesstimationItem.id ?? 0}`}
                  minDate={timelineMinDate}
                  maxDate={timelineMaxDate}
                  width={canvasWidth}
                  guesstimationItems={
                    data.guesstimationItems[guesstimationItem.phaseRef]
                  }
                  users={data.users}
                />
              </Fragment>
            ))}
          </DndProvider>
        </CardContent>
      </Card>
    </ViewContainer>
  );
};

export default GuesstimationViewData;
