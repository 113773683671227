import UserMenu from "../../../../../../modules/user/containers/Menu/UserMenu";
import NavigationIcon from "../common/NavigationIcon/NavigationIcon";
import TaskSensorIcon from "../../../../../components/Logo/TaskSensorIcon";
import { NavLink } from "react-router-dom";
import { Box } from "@mui/material";
import InfoCenter from "../../../../../../modules/user/containers/Menu/InfoCenter";
import ActivePlan from "../../../../../../modules/user/modules/freeTrial/containers/ActivePlan";
import { Rocket } from "@mui/icons-material";
import useLandingPageHelper from "../../../../../modules/landingPage/hooks/useLandingPageHelper";
import { Button } from "../../../../../modules/materialUI";
import { lightTheme } from "../../../../../modules/theme/lightTheme";

const Mobile = () => {
  const {
    isLoading: quickStartLoading,
    needsQuickStart,
    progress,
  } = useLandingPageHelper();

  return (
    <>
      <Box display="flex" alignItems="center" gap="4px">
        <NavigationIcon />

        <NavLink to={"/"}>
          <TaskSensorIcon size={70} colorCombo={"white-green"} width={22} />
        </NavLink>

        <Box ml="12px">
          <ActivePlan isMobile />
        </Box>
      </Box>

      <Box display="flex" alignItems="center" gap="8px">
        {!quickStartLoading && needsQuickStart && (
          <NavLink to={"/"}>
            <Button
              variant="outlined"
              size="small"
              startIcon={<Rocket sx={{ fontSize: "18px !important" }} />}
              sx={{
                height: "32px",
                gap: "4px",
                px: "4px",
                color: "common.white",
                border: "none",
                bgcolor: lightTheme.palette.action.active,
              }}
            >
              {progress}%
            </Button>
          </NavLink>
        )}

        <InfoCenter />
        <UserMenu />
      </Box>
    </>
  );
};

export default Mobile;
