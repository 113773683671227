import { Box, ListSubheader, Menu as MuiMenu, MenuItem } from "@mui/material";
import useMedia from "../../hooks/useMedia/useMedia";
import { QueryEnum } from "../../hooks/useMedia/enums/QueryEnum";
import { TMenuProps } from "./types/TMenuProps";
import { useState } from "react";
import { Tooltip } from "../../modules/materialUI";
import { useAppDispatch } from "../../hooks/redux";
import { systemNotificationActions } from "../../modules/systemNotification/slices/systemNotificationSlice";
import { featureUtil } from "../../modules/accessControl/utils/featureUtil";
import Divider from "../../components/Divider/Divider";

const Menu = (props: TMenuProps) => {
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const matchesMax576Screen = useMedia(QueryEnum.MAX_WIDTH_576);
  const open = Boolean(anchorEl);

  const clickHandler = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    props.openHandler?.(true);
  };

  const closeHandler = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(null);
    props.openHandler?.(false);
  };

  const menuItems = props.menuItems
    .filter(
      (item) => !!item.component && (item.when === undefined || item.when)
    )
    .map((value, index, array) => {
      const notLastItem = array.length > index + 1;

      return {
        ...value,
        hasDivider: value.hasDivider ?? notLastItem,
        marginBottom:
          value.hasDivider === false && notLastItem ? "8px" : undefined,
      };
    });

  return menuItems.length > 0 ? (
    <>
      {props.button(clickHandler)}
      <MuiMenu
        anchorEl={anchorEl}
        open={open}
        onClose={closeHandler}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              ...((props.stretchOnMobile ?? true) &&
                matchesMax576Screen && { width: "95%" }),
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              borderRadius: 2,
              backgroundColor: "background.paper",
              boxShadow: 8,
              "& .MuiMenu-list": {
                padding: props.padding ?? "8px 0",
              },
              ...props.sx,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {props.listSubheader && (
          <ListSubheader
            inset={props.indentSubheader ?? true}
            sx={{ lineHeight: 2 }}
          >
            {props.listSubheader}
          </ListSubheader>
        )}
        {menuItems.map((item, index) => [
          item.isHeaderItem ? (
            <Box
              p="4px 16px"
              mb={item.marginBottom}
              lineHeight={0}
              onClick={(event) => {
                if (!item.disabled) {
                  item.handler?.();
                } else {
                  dispatch(
                    systemNotificationActions.open({
                      message: featureUtil.notAvailableFullText,
                      variant: "warning",
                    })
                  );
                }

                if (
                  props.closeOnSelection !== false &&
                  item.closeOnClickInHeader
                ) {
                  closeHandler(event);
                }
              }}
              {...item.headerItemProps}
            >
              {item.component}
            </Box>
          ) : (
            <Tooltip
              title={item.disabledTooltipMessage}
              when={!!item.disabled && !!item.disabledTooltipMessage}
            >
              <MenuItem
                key={index}
                onClick={(event) => {
                  if (!item.disabled) {
                    item.handler?.();
                  } else {
                    dispatch(
                      systemNotificationActions.open({
                        message: featureUtil.notAvailableFullText,
                        variant: "warning",
                      })
                    );
                  }
                  if (item.closeOnClick === false) {
                    return;
                  }

                  if (props.closeOnSelection !== false) {
                    closeHandler(event);
                  }
                }}
                {...item.menuItemsProps}
                sx={{
                  minHeight: matchesMax576Screen ? "20px" : "auto",
                  p: "4px 16px",
                  mb: item.marginBottom,
                  ...item.menuItemsProps?.sx,
                }}
              >
                {item.component}
              </MenuItem>
            </Tooltip>
          ),
          item?.hasDivider && (
            <Divider sx={item.dividerSx ? item.dividerSx : {}} />
          ),
        ])}
      </MuiMenu>
    </>
  ) : null;
};

export default Menu;
