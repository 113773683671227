import { array } from "../../../../../../common/utils/array";
import classes from "./Overlay.module.scss";
import animateClasses from "./OverlayAnimate.module.css";
import { useAppSelector } from "../../../../../../common/hooks/redux";
import { useTheme } from "@mui/material";

const Overlay = () => {
  const theme = useTheme();
  const overlay = useAppSelector(
    (state) => state.guesstimationView.laneOverlay
  );

  const shouldDisplay = overlay?.display || overlay?.animate;

  return (
    <div
      className={array.toClassName([
        classes.overlay,
        overlay?.animate ? animateClasses.animate : "",
      ])}
      style={{
        opacity: theme.palette.mode === "dark" ? 0.3 : 0.5,
        display: !shouldDisplay ? "none" : undefined,
        backgroundColor: theme.palette.dropTargetBackground.default,
      }}
    />
  );
};

export default Overlay;
