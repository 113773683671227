import Title from "../../../../common/components/Title/Title";
import AddIntegration from "./AddIntegration";
import LinkedIntegrationList from "./LinkedIntegrationList";
import { HeaderRegistryEnum } from "../../../../common/modules/registry/enums/HeaderRegistryEnum";
import useRegisterHeader from "../../../../common/modules/registry/hooks/useRegisterHeader";
import { useParams } from "react-router-dom";
import { TBoardParams } from "../Board/types/TBoardParams";
import Loader from "../../../../common/containers/Loader/Loader";
import NotFound from "../../../../common/components/Page/NotFound/NotFound";
import { boardUtil } from "../../../../common/utils/boardUtil";
import { useGetBoardByRef } from "../../hooks/useGetBoardByRef";

const IntegrationPage = () => {
  useRegisterHeader(HeaderRegistryEnum.headerBackButtonOnly, []);
  const { boardRef } = useParams<TBoardParams>();
  const { board, isLoading: isBoardLoading } = useGetBoardByRef(boardRef);

  const isPageAllowedForBoard =
    !!board && boardUtil.isBoardIntegrationAvailableForType(board.type);

  return (
    <>
      {isBoardLoading && <Loader />}

      {!isBoardLoading && !isPageAllowedForBoard && <NotFound />}

      {!isBoardLoading && isPageAllowedForBoard && (
        <>
          <Title>Board Integrations</Title>
          <AddIntegration />
          <LinkedIntegrationList />
        </>
      )}
    </>
  );
};

export default IntegrationPage;
