import { Box, Typography } from "@mui/material";
import BoardInfoDisplay from "../HolisticBoard/BoardInfoDisplay";
import { Button } from "../../../../common/modules/materialUI";
import ButtonGroup from "../../../../common/modules/materialUI/components/ButtonGroup/ButtonGroup";
import { Add } from "@mui/icons-material";
import useMedia from "../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../common/hooks/useMedia/enums/QueryEnum";
import { IBoard } from "../../../../entities/IBoard";
import { readOnlyAccessActions } from "../../../readOnlyAccess/slices/readOnlyAccessSlice";
import { useAppDispatch } from "../../../../common/hooks/redux";
import { readOnlyTokenUtil } from "../../../../common/utils/readOnlyTokenUtil";

const BoardTitle = ({
  addClickHandler,
  board,
  projectAbbr,
  showProgress,
  showShareButton,
  menuComponent,
  tipIcon,
}: {
  addClickHandler: () => void;
  board: IBoard;
  projectAbbr: string;
  menuComponent: JSX.Element;
  showProgress?: boolean;
  showShareButton?: boolean;
  tipIcon?: JSX.Element;
}) => {
  const readonlyToken = readOnlyTokenUtil.fetch();
  const matchesMax576Screen = useMedia(QueryEnum.MAX_WIDTH_576);
  const dispatch = useAppDispatch();

  const shareBoardHandler = () =>
    dispatch(readOnlyAccessActions.openManageModal());

  return (
    <Box
      display={matchesMax576Screen ? "block" : "flex"}
      alignItems="center"
      justifyContent="space-between"
      marginBottom="30px"
    >
      <Box
        display="flex"
        alignItems="center"
        gap="12px"
        marginBottom={matchesMax576Screen ? "12px" : "0"}
        marginRight={"10px"}
      >
        <Typography variant="h5">{board.name}</Typography>
        {!matchesMax576Screen && (
          <BoardInfoDisplay
            projectAbbr={projectAbbr}
            board={board}
            showProgress={showProgress}
            tipIcon={tipIcon}
          />
        )}
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent={matchesMax576Screen ? "space-between" : ""}
      >
        {matchesMax576Screen && (
          <BoardInfoDisplay
            projectAbbr={projectAbbr}
            board={board}
            showProgress={showProgress}
            tipIcon={tipIcon}
          />
        )}
        {!readonlyToken && (
          <Box display={"flex"} alignItems={"center"} gap="12px">
            {!matchesMax576Screen && showShareButton && (
              <Button variant="outlined" onClick={shareBoardHandler}>
                Share
              </Button>
            )}
            <ButtonGroup variant="contained" color="primary">
              <Button
                startIcon={<Add />}
                sx={{ whiteSpace: "nowrap" }}
                onClick={addClickHandler}
              >
                Add New
              </Button>
              {menuComponent}
            </ButtonGroup>
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default BoardTitle;
