import { useAccessControl } from "../../../common/modules/accessControl/hooks/useAccessControl";
import { AbilityEnum } from "../../../common/modules/accessControl/enums/AbilityEnum";
import NotFound from "../../../common/components/Page/NotFound/NotFound";
import { useAppDispatch } from "../../../common/hooks/redux";
import { customFieldActions } from "../slices/customFieldSlice";
import CreateModal from "./CreateCustomField/CreateModal";
import CustomFieldGrid from "./CustomFieldGrid/CustomFieldGrid";
import { useFetchProjectListService } from "../../project/services/useFetchProjectListService";
import { useIsFeatureAllowed } from "../../../common/modules/accessControl/hooks/useIsFeatureAllowed";
import { FeatureEnum } from "../../../common/modules/accessControl/enums/FeatureEnum";
import { featureUtil } from "../../../common/modules/accessControl/utils/featureUtil";
import { systemNotificationActions } from "../../../common/modules/systemNotification/slices/systemNotificationSlice";
import TitleAndActionButton from "../../../common/components/Title/TitleAndActionButton";

const CustomFieldsPage = () => {
  const { can, acLoaded } = useAccessControl();
  const dispatch = useAppDispatch();
  const isCustomFieldsAllowed = useIsFeatureAllowed(FeatureEnum.CUSTOM_FIELDS);
  // Fetching projects data, it's needed for: grid, create and update forms
  useFetchProjectListService();

  if (!can(AbilityEnum.administrate)) {
    return <>{acLoaded && <NotFound />}</>;
  }

  return (
    <>
      <TitleAndActionButton
        title="Custom Fields"
        buttonText="Add Custom Field"
        onClick={() =>
          isCustomFieldsAllowed
            ? dispatch(customFieldActions.openCreateModal())
            : dispatch(
                systemNotificationActions.open({
                  message: featureUtil.notAvailableFullText,
                  variant: "warning",
                })
              )
        }
      />

      <CustomFieldGrid />
      <CreateModal />
    </>
  );
};

export default CustomFieldsPage;
