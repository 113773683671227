import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { TSectionProps } from "./types/TSectionProps";
import TaskRow from "../../../../../../common/components/Task/components/TaskRow/TaskRow";
import { ExpandMore } from "@mui/icons-material";
import VerticalDivider from "../../../../../../common/components/Divider/VerticalDivider";
import classes from "./Section.module.css";

const Section = (props: TSectionProps) => {
  return (
    <Accordion
      defaultExpanded={props.index === 0}
      sx={{
        margin: "0 0 16px 0 !important",
        borderRadius: "12px !important",
        "&:before": {
          display: "none",
        },
        overflow: "hidden",
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        sx={{
          backgroundColor: "backgroundElevation.backgroundPaperElevation_0",
          // Non expended height
          minHeight: "35px",
          "& .MuiAccordionSummary-content": {
            margin: "0px !important",
          },
          // Expended height
          "&.Mui-expanded": {
            minHeight: "35px !important",
          },
        }}
      >
        <Grid container>
          {props.name && (
            <>
              <Grid>
                <Typography variant="caption" sx={{ pl: 0.5 }}>
                  {props.name}
                </Typography>
              </Grid>
              <Grid>
                <VerticalDivider sx={{ height: "4px", margin: "9px" }} />
              </Grid>
            </>
          )}
          <Grid>
            <Typography variant="caption" component="span">
              {props.tasks.length} task{props.tasks.length > 1 ? "s" : ""}
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>

      <AccordionDetails
        className={classes.details}
        sx={{
          backgroundColor: "backgroundElevation.backgroundPaperElevation_0",
        }}
      >
        {props.tasks.map((task, index) => (
          <TaskRow
            key={`lv-tr-${index}`}
            style={{ marginBottom: "1px", height: "55px" }}
            task={task}
            mainTaskRef={task.ref}
            sprints={props.sprints}
            users={props.users}
            color={
              props.activeTaskKey === `${task.ref}-${task.number}`
                ? "brightCardBackground.highlightColor"
                : undefined
            }
            isSegmentView={props.showSegments}
          />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default Section;
