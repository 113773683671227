export const sendMessage = (key: string, value: string | null = null) => {
  // @ts-expect-error
  if (window.ReactNativeWebView) {
    // @ts-expect-error
    window.ReactNativeWebView.postMessage(JSON.stringify({ [key]: value }));
  }
};

export const useSendMessage = () => {
  return {
    sendMessage,
  };
};
